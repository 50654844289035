import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { memo } from "react";
import { connect } from "react-redux";

import { isOnPrem as isOnPremSelector } from "modules/systemConfig";

import { getMetadataFields } from "../../metadata/selectors";
import { submitInterpretationRequest } from "../actions";
import { isOncologyProject } from "../selectors";
import {
  getValidationSchema,
  ONCOLOGY_VALIDATION_SCHEMA,
  validate,
} from "../validation";

import IRFormGrid from "./IRFormGrid";

const IRForm = memo(
  ({
    submitInterpretationRequest,
    metadataFields,
    projectId,
    isOncologyProject,
    isOnPrem,
  }) => (
    <Formik
      initialValues={{ projectId, identifier: "", samples: [] }}
      validate={isOncologyProject ? null : validate}
      validationSchema={
        isOncologyProject
          ? ONCOLOGY_VALIDATION_SCHEMA
          : getValidationSchema(metadataFields, isOnPrem)
      }
      validateOnMount
      onSubmit={values => {
        if (values.samples.length) {
          submitInterpretationRequest(values);
        }
      }}
    >
      {props => <IRFormGrid projectId={projectId} {...props} />}
    </Formik>
  )
);

IRForm.propTypes = {
  /**
   * the id of the project an IR is created in
   */
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  /**
   * IR request handler function
   */
  submitInterpretationRequest: PropTypes.func.isRequired,
  /**
   * a dynamic list of metadata fields specific to each project
   */
  metadataFields: PropTypes.arrayOf(
    PropTypes.shape({
      required: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
};

const mapStateToProps = state => ({
  metadataFields: getMetadataFields(state),
  isOncologyProject: isOncologyProject(state),
  isOnPrem: isOnPremSelector(state),
});

const mapDispatchToProps = {
  submitInterpretationRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(IRForm);
