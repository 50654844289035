import configData, {
  AUTO_ACMG_SUGGESTED_PATHOGENICITY_KEY,
} from "modules/config/reducer.data";
import { ComparisonOutcomes } from "modules/sequenceVariantsSummary/constants";
import { getVepConsequenceLabel } from "modules/utils/common";

const reduceToFilterGrouping = (accumulator, entry) => {
  accumulator.push(entry);
  return accumulator;
};

const data = {
  filterGrouping: [
    {
      key: "populationFrequency",
      label: "Population Frequency",
      type: "values",
    },
    {
      key: "populationAlleleCount",
      label: "Population allele count",
      type: "values",
      values: configData.allPopulationAc,
    },
    {
      key: "vepConsequence",
      label: "Consequence",
      type: "array",
      format: getVepConsequenceLabel,
    },
    {
      key: "zygosity",
      label: "Zygosity",
      type: "array",
      values: configData.allZygosityValues.reduce(reduceToFilterGrouping, []),
    },
    {
      key: "scores",
      label: "Scores",
      type: "values",
      values: [
        ...configData.allScoresValues.scoreFields,
        ...configData.allScoresValues.splicingFields,
        ...configData.allScoresValues.constraintsFields,
      ],
    },
    {
      key: "inheritance",
      label: "Inheritance",
      type: "array",
      values: configData.allInheritanceValues,
    },
    {
      key: "moi",
      label: "Consistency with mode of inheritance",
      type: "array",
      values: configData.allModeOfInheritanceValues,
    },
    {
      key: "familyComparisons",
      label: "Family Comparisions",
      type: "array",
      values: configData.allFamilyComparisonValues,
    },
    {
      key: "denovoStatus",
      label: "De novo Status",
      type: "array",
      values: configData.allDenovoStatusValues,
    },
    {
      key: "customTier",
      label: "Tier",
      type: "array",
      values: configData.allTierValues,
    },
    {
      key: "customPenetrance",
      label: "Penetrance",
      type: "array",
      values: configData.allPenetranceValues,
    },
    {
      key: "quality",
      label: "Quality",
      type: "values",
      values: configData.allQualityValues.reduce(reduceToFilterGrouping, []),
    },
    {
      key: "prioritisation",
      label: "Prioritisation",
      type: "array",
      values: configData.prioritisationDefaults,
    },
    {
      key: "curatedList",
      label: "Curated list",
      type: "nested",
      values: [
        {
          key: "curatedListKnowledgebase",
          label: "Knowledgebase",
          type: "array",
        },
        {
          key: "curatedListWhitelist",
          label: "IncludeList",
          type: "array",
        },
        {
          key: "curatedListBlacklist",
          label: "ExcludeList",
          type: "array",
        },
      ],
    },
    {
      key: AUTO_ACMG_SUGGESTED_PATHOGENICITY_KEY,
      label: "Suggested ACMG Pathogenicity",
      type: "array",
      values: configData.allSuggestedPathogenicityValues.reduce(
        reduceToFilterGrouping,
        []
      ),
    },
  ],
  allZygosityValues: {
    Homozygous: "Homozygous",
    Heterozygous: "Heterozygous",
    Hemizygous: "Hemizygous",
    "Not Specified": "Not Specified",
  },
  prioritisationDefaults: {
    afMax: "Max AF",
    polyphenScore: "Polyphen Score",
    siftScore: "SIFT Score",
    exomiserGeneCombinedScore: "Exomiser Gene Combined Score",
    exomiserGenePhenoScore: "Exomiser Gene Pheno Score",
    exomiserGeneVariantScore: "Exomiser Gene Variant Score",
    exomiserVariantScore: "Exomiser Variant Score",
  },
  allInheritanceValues: {
    paternal: "Paternal",
    maternal: "Maternal",
    biparental: "Biparental",
    "de-novo": "De-novo",
    "de-novo-fp": "De-novo false positive",
    "putative de-novo": "Putative de-novo",
    "phase unknown": "Phase unknown",
    indeterminable: "Indeterminable",
  },
  allFamilyComparisonValues: ComparisonOutcomes,
  allDenovoStatusValues: {
    LOW_CONF: "LOW_CONF",
    MED_CONF: "MED_CONF",
    HIGH_CONF: "HIGH_CONF",
    DNG_PASS: "DNG_PASS",
    ASSUMED_PARENTAL_GENOTYPE: "ASSUMED_PARENTAL_GENOTYPE",
    DNG_ANALYSED: "DNG_ANALYSED",
    DNG_NOT_RUN: "DNG_NOT_RUN",
    MEDIUM_CONF: "MEDIUM_CONF",
    NO_EXACT_MATCH_GENE_SPECIFIC: "NO_EXACT_MATCH_GENE_SPECIFIC",
    PARENTAL_VARIANTS_ARE_NOT_EXACT_MATCHES:
      "PARENTAL_VARIANTS_ARE_NOT_EXACT_MATCHES",
    HET_MALE_ON_X: "HET_MALE_ON_X",
  },
  allModeOfInheritanceValues: {
    ad: "Autosomal Dominant (AD)",
    arh: "Autosomal Recessive, Homozygous (ARH)",
    arch: "Autosomal Recessive, Compound Heterozygous (ARCH)",
    xld: "X-Linked Dominant (XLD)",
    xlr: "X-Linked Recessive (XLR)",
    xlrch: "X-Linked Recessive, Compound Heterozygous (XLRCH)",
  },
};

export default data;
