import classNames from "classnames";
import { set } from "lodash";
import { isEmpty, isNil } from "ramda";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";

import { Actions, Button, ConfirmationModal, Loading } from "pattern-library";

import { hpoTermsSelector } from "modules/hpoTerms/selectors";
import {
  filesProcessing as filesProcessingSelector,
  getFileTypesOptions,
  getProtocolNames,
  getSampleTypes,
  isOncologyProject,
} from "modules/interpretationRequests/selectors";
import * as messageActions from "modules/messages/actions";
import { getCamelizedMetadataFields } from "modules/metadata/selectors";
import { isOnPrem as isOnPremSelector } from "modules/systemConfig";
import { notEmpty } from "modules/utils/array";
import ReaderFactory from "modules/utils/files/readers/ReaderFactory";
import {
  CSVFileResult,
  FILE_READER_TYPES,
  FileReaderError,
  FileReaderResult,
} from "modules/utils/files/readers/types";

import { setFilesProcessing } from "../actions";
import {
  ALLOWED_FILE_EXTENSIONS,
  FORM_FILE_TYPES_TO_READER_TYPE,
} from "../constants";
import {
  FORM_FILE_TYPES,
  IrSample,
  SampleFileTypes,
  SampleFileTypesGroupedBySampleName,
  SamplesGroupedByName,
  SampleWithNameAndFiles,
} from "../types";
import useRowSelection from "../useRowSelection";
import { getEmptySample } from "../utils";

import SampleDetails from "./SampleDetails";
import ValidationModal from "./ValidationModal";
import {
  ADD_BUTTON_NAME,
  BLANK_ROW_OPTION,
  DOWNLOAD_IR_CSV_TEMPLATE,
  FILE_OPTION,
  FILE_OPTION_TOOLTIP,
} from "./componentConstants";
import { getCsvHeadersMap } from "./csvUtils";
import { getCsvSchema, validateHeaders } from "./csvValidation";
import OncologySamplesTable from "./oncology/table/SamplesTable";
import SamplesTable from "./table/SamplesTable";
import useDropStatus from "./useDropStatus";

import { useEnsemblVersion } from "hooks/useEnsemblVersion";
import useProjectGenePanels from "hooks/useProjectGenePanels";

const updateNewSampleFiles = (
  sample: SampleWithNameAndFiles,
  fileType: FORM_FILE_TYPES,
  fileName: string
) => {
  const filesOfType = sample.files[fileType];
  if (filesOfType) {
    filesOfType.push(fileName);
  } else {
    sample.files[fileType] = [fileName];
  }
};

const addNewSample = (
  newSamples: SamplesGroupedByName,
  sampleName: string,
  fileType: FORM_FILE_TYPES,
  fileName: string
): void => {
  newSamples[sampleName] = {
    sampleName,
    files: { [fileType]: [fileName] },
  };
};

const groupNewSamplesByName = (
  readerResult: FileReaderResult
): SamplesGroupedByName => {
  const newSamples: SamplesGroupedByName = {};
  Object.keys(FORM_FILE_TYPES).forEach(formFileTypeKey => {
    const filesOfType =
      readerResult[FORM_FILE_TYPES_TO_READER_TYPE[formFileTypeKey]];
    if (!filesOfType) {
      return;
    }
    const formFileType = FORM_FILE_TYPES[formFileTypeKey];
    filesOfType.forEach(({ id: sampleName, name: fileName }) => {
      const alreadyProcessedSample = newSamples[sampleName];
      if (alreadyProcessedSample) {
        updateNewSampleFiles(alreadyProcessedSample, formFileType, fileName);
      } else {
        addNewSample(newSamples, sampleName, formFileType, fileName);
      }
    });
  });

  return newSamples;
};

const getFileTypesAsObject = (
  fileTypesArray: Array<string>,
  fileTypesDefaultValues: SampleFileTypes
): SampleFileTypes =>
  (fileTypesArray || []).reduce(
    (acc, fileType) => ({
      ...acc,
      [fileType]: true,
    }),
    { ...fileTypesDefaultValues }
  );

const getFileTypesAsArray = (
  fileTypes?: SampleFileTypes | null
): Array<string> =>
  fileTypes
    ? Object.keys(fileTypes).filter(fileType => fileTypes[fileType] === true)
    : [];

const groupExistingSamplesByName = (
  samples: Array<IrSample>
): SampleFileTypesGroupedBySampleName =>
  samples.reduce((acc, { name = "", fileTypes }, sampleIndex) => {
    acc[name] = {
      fileTypesArray: getFileTypesAsArray(fileTypes),
      sampleIndex,
    };
    return acc;
  }, {} as SampleFileTypesGroupedBySampleName);

interface Props extends PropsFromRedux {
  form: {
    values: { samples: Array<IrSample> };
    setFieldValue: (name: string, value: any) => void;
  };
  push: (sample: IrSample) => number;
  remove: (index: number) => void;
  replace: (index: number, sample: IrSample) => void;
  fieldsDisabled: boolean;
  projectId: number | string;
  projectGenePanelsIds: number[];
  projectActiveEnsemblGenePanelsIds: number[];
}

export const TableFormComponent = ({
  form: {
    values: { samples = [] },
    setFieldValue,
  },
  push,
  remove,
  replace,
  error,
  warning,
  fieldsDisabled,
  projectId,
  isOncologyProject,
  typeOptions,
  isOnPrem,
  allHpoTerms,
  filesProcessing,
  setFilesProcessing,
  sampleTypes = [],
  projectGenePanelsIds = [],
  projectActiveEnsemblGenePanelsIds = [],
  protocolNames = [],
  metadataFields = [],
}: Props) => {
  const [validationErrors, setValidationErrors] = useState([]);
  const inputFile = useRef<HTMLInputElement | null>(null);
  // to make React reset file input and be able to try uploading the same file again
  const [fileInputKey, setFileInputKey] = useState(0);

  const {
    selectedRow,
    setSelectedRow,
    clearDependenciesAndRemoveRow,
    removeRowHandler,
    showDeleteConfirmationModal,
    toggleDeleteConfirmationModal,
    deleteConfirmationMessage,
  } = useRowSelection(samples, fieldsDisabled, setFieldValue, remove);

  useEffect(() => {
    if (samples.length === 0) {
      window.onbeforeunload = null;
    } else if (!window.onbeforeunload) {
      window.onbeforeunload = function () {
        return true;
      };
    }
  }, [samples]);

  const allowedFilesExtensions = useMemo(
    () =>
      isOnPrem ? ALLOWED_FILE_EXTENSIONS.ON_PREM : ALLOWED_FILE_EXTENSIONS.SAAS,
    [isOnPrem]
  );

  const fileTypesDefaultValues = useMemo(
    () =>
      typeOptions.reduce((result, { name }) => {
        result[name] = false;
        return result;
      }, {}),
    [typeOptions]
  );

  const processReaderResult = (result: FileReaderResult) => {
    const errors = ([] as Array<FileReaderError>).concat(
      ...Object.values(result.error)
    );

    errors.forEach(({ name, error: message }) => {
      error(`${name} - ${message}`);
    });

    const sequenceFilesSamplesGroupedByName = groupNewSamplesByName(result);
    const existingSamplesTypes = groupExistingSamplesByName(samples);

    const csvResult = result[FILE_READER_TYPES.CSV];
    if (csvResult) {
      addSamplesFromCSV(csvResult, existingSamplesTypes);
    }

    addSamples(sequenceFilesSamplesGroupedByName, existingSamplesTypes);

    setFileInputKey(k => k + 1);
    setFilesProcessing(false);
  };

  const sampleTypeNames = useMemo(
    () => sampleTypes.map(({ attributes: { name = "" } = {} }) => name),
    [sampleTypes]
  );

  const csvHeadersMap = useMemo(
    () =>
      getCsvHeadersMap({
        allHpoTerms,
        sampleTypeNames,
        isOnPrem,
        metadataFields,
      }),
    [allHpoTerms, sampleTypeNames, isOnPrem, metadataFields]
  );

  const csvRowToSample = (csvRow: Record<string, any>): IrSample => {
    const sampleRow: IrSample = {};
    Object.entries(csvRow).forEach(([csvHeader, value]) => {
      if (!isNil(csvHeader) && !isEmpty(csvHeader)) {
        const { name: fieldName, format } = csvHeadersMap[csvHeader] || {};
        if (fieldName) {
          const formattedValue = format ? format(value) : value;
          set(sampleRow, fieldName, formattedValue);
        }
      }
    });
    return sampleRow;
  };

  const addSamplesFromCSV = (
    csvResults: Array<CSVFileResult>,
    existingSamplesTypes: SampleFileTypesGroupedBySampleName
  ): void => {
    csvResults.forEach(csvResult => {
      const {
        data: csvRows = [],
        fileName: csvName,
        headers: csvHeaders = [],
      } = csvResult;

      if (
        !validateHeaders({
          csvName,
          csvHeaders,
          csvHeadersMap,
          error,
          warning,
        })
      ) {
        return;
      }

      if (isEmpty(csvRows)) {
        error(`${csvName} - file doesn't have sample data`);
        return;
      }

      let errors = [];
      try {
        const schema = getCsvSchema({
          csvRows,
          existingSamplesTypes,
          allHpoTerms,
          projectGenePanelsIds,
          projectActiveEnsemblGenePanelsIds,
          sampleTypeNames,
          protocolNames,
          isOnPrem,
          metadataFields,
        });
        schema.validateSync(csvRows, {
          abortEarly: false,
        });
      } catch (e) {
        errors = e.inner;
      }

      setValidationErrors(errors);
      if (notEmpty(errors)) {
        return;
      }

      csvRows.forEach(csvRow => {
        const { name: sampleName } = csvRow;
        const existingSample = existingSamplesTypes[sampleName];
        if (isNil(existingSample)) {
          const newSample: IrSample = csvRowToSample(csvRow);
          const newSampleIndex = addSample({
            ...getEmptySample(),
            ...newSample,
          });

          if (newSampleIndex !== undefined) {
            const { fileTypes } = newSample;
            existingSamplesTypes[sampleName] = {
              fileTypesArray: getFileTypesAsArray(fileTypes),
              sampleIndex: newSampleIndex,
            };
          }
        } else {
          uniqueSampleError(sampleName, csvName);
        }
      });
    });
  };

  const [drop, isActive] = useDropStatus(
    processReaderResult,
    fieldsDisabled || isOncologyProject || filesProcessing,
    allowedFilesExtensions,
    setFilesProcessing
  );

  const addSample = (sample: IrSample): number | undefined => {
    if (fieldsDisabled) {
      return;
    }
    const newSampleIndex = samples.length;
    push(sample);
    setSelectedRow(newSampleIndex);
    return newSampleIndex;
  };

  const updateSample = (
    sampleIndex: number,
    propsToUpdate: Partial<IrSample>
  ) => {
    if (fieldsDisabled) {
      return;
    }
    replace(sampleIndex, {
      ...samples[sampleIndex],
      ...propsToUpdate,
    });

    setSelectedRow(sampleIndex);
  };

  const uniqueSampleError = (sampleName: string, fileName: string): void => {
    error(
      `Record with identical ID/name (${sampleName}, ${fileName}) exists. Please remove the existing record and try again`
    );
  };

  const addSamples = (
    newSamples: SamplesGroupedByName,
    existingSamplesTypes: SampleFileTypesGroupedBySampleName
  ) => {
    Object.values(newSamples).forEach(({ sampleName, files }) => {
      const fileTypesArray: Array<string> = Object.keys(files);
      const existingSample = existingSamplesTypes[sampleName];
      const {
        fileTypesArray: existingSampleFileTypesArray = [],
        sampleIndex: existingSampleIndex,
      } = existingSample || {};
      if (isNil(existingSample)) {
        const newSampleIndex = addSample({
          ...getEmptySample(),
          name: sampleName,
          fileExists: true,
          fileTypes: getFileTypesAsObject(
            fileTypesArray,
            fileTypesDefaultValues
          ),
        });
        if (newSampleIndex !== undefined) {
          existingSamplesTypes[sampleName] = {
            fileTypesArray,
            sampleIndex: newSampleIndex,
          };
        }
      } else {
        fileTypesArray.forEach(newSampleFileType => {
          if (existingSampleFileTypesArray.includes(newSampleFileType)) {
            uniqueSampleError(sampleName, files[newSampleFileType].join(", "));
          } else {
            updateSample(existingSampleIndex, {
              fileExists: true,
              fileTypes: {
                ...getFileTypesAsObject(
                  existingSampleFileTypesArray,
                  fileTypesDefaultValues
                ),
                [newSampleFileType]: true,
              },
            });
            existingSampleFileTypesArray.push(newSampleFileType);
          }
        });
      }
    });
  };

  const addEmptySample = () => {
    if (fieldsDisabled) {
      return;
    }
    addSample(getEmptySample(isOncologyProject));
  };

  const onFileOptionClick = () => {
    inputFile.current?.click();
  };

  const onSelectFiles = async ({ currentTarget: { files } }) => {
    if (files.length && !fieldsDisabled) {
      setFilesProcessing(true);
      processReaderResult(
        await ReaderFactory([...files], allowedFilesExtensions)
      );
    }
  };

  return (
    <div>
      <div
        ref={drop}
        className={classNames(
          {
            "dnd-target-area-active": !isOncologyProject && isActive,
            "ir-table-empty": !samples.length,
            "dnd-target-area": !isOncologyProject,
          },
          "ir-table"
        )}
      >
        {isOncologyProject === true && (
          <>
            <OncologySamplesTable
              samples={samples}
              removeRowHandler={removeRowHandler}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              fieldsDisabled={fieldsDisabled}
            />
            {/* TODO: look into initially adding empty sample? */}
            <Button
              disabled={fieldsDisabled || samples.length !== 0}
              className="ir-table-buttons pull-right"
              type="button"
              onClick={addEmptySample}
            >
              {ADD_BUTTON_NAME}
            </Button>
          </>
        )}
        {isOncologyProject === false && (
          <>
            <Actions
              justifyContent="start"
              className="add-patients-actions"
              actions={[
                {
                  label: FILE_OPTION,
                  disabled: fieldsDisabled,
                  action: onFileOptionClick,
                  className: "btn btn-primary",
                  type: "button",
                  tooltip: {
                    content: FILE_OPTION_TOOLTIP,
                    placement: "bottom",
                  },
                  icon: "upload",
                },
                {
                  label: BLANK_ROW_OPTION,
                  disabled: fieldsDisabled,
                  action: addEmptySample,
                  className: "btn",
                  type: "button",
                },
                {
                  label: DOWNLOAD_IR_CSV_TEMPLATE,
                  href: `/catalyst/api/ir_csv_template/${projectId}`,
                  target: "_blank",
                  type: "link",
                  icon: "downloadAlt",
                  className: "ir-template-button",
                },
              ]}
            />
            <SamplesTable
              samples={samples}
              removeRowHandler={removeRowHandler}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              fieldsDisabled={fieldsDisabled}
            />
            {!fieldsDisabled && (
              <input
                key={fileInputKey}
                type="file"
                id="file"
                onChange={onSelectFiles}
                ref={inputFile}
                className="ir-table-files"
                multiple
                data-testid="select-files"
              />
            )}
          </>
        )}
      </div>
      {isOncologyProject === false && (
        <div
          className={classNames(
            { "ir-table-details-active": selectedRow !== null },
            "ir-table-details"
          )}
        >
          {!isNil(selectedRow) && (
            <SampleDetails
              selectedRowNumber={selectedRow}
              disabled={fieldsDisabled}
              projectId={projectId}
              isOnPrem={isOnPrem}
            />
          )}
        </div>
      )}
      <ConfirmationModal
        confirmationText={deleteConfirmationMessage}
        showConfirmationModal={showDeleteConfirmationModal}
        closeClickHandler={toggleDeleteConfirmationModal}
        yesClickHandler={clearDependenciesAndRemoveRow}
      />
      <ValidationModal
        show={notEmpty(validationErrors)}
        closeModal={() => setValidationErrors([])}
        errors={validationErrors}
      />
    </div>
  );
};

const ApiConnectedComponent = (props: Props & PropsFromRedux) => {
  const { genePanels, isLoading: genePanelsLoading } = useProjectGenePanels({
    projectId: Number(props.projectId),
  });

  const {
    ensemblVersion: activeEnsemblVersion,
    isLoading: ensemblVersionLoading,
  } = useEnsemblVersion();

  const projectActiveEnsemblGenePanelsIds = useMemo(() => {
    if (!activeEnsemblVersion) return [];

    return genePanels.reduce((ids, { ensemblVersion, genePanelId }) => {
      const includePanel = ensemblVersion
        ? ensemblVersion === activeEnsemblVersion
        : true;
      if (includePanel) ids.push(genePanelId);
      return ids;
    }, [] as number[]);
  }, [activeEnsemblVersion, genePanels]);

  const projectGenePanelsIds = useMemo(
    () => genePanels.map(({ genePanelId }) => genePanelId),
    [genePanels]
  );

  if (ensemblVersionLoading || genePanelsLoading) {
    return <Loading />;
  }
  return (
    <TableFormComponent
      {...props}
      projectActiveEnsemblGenePanelsIds={projectActiveEnsemblGenePanelsIds}
      projectGenePanelsIds={projectGenePanelsIds}
    />
  );
};

const mapStateToProps = state => ({
  isOncologyProject: isOncologyProject(state),
  typeOptions: getFileTypesOptions(state),
  isOnPrem: isOnPremSelector(state),
  allHpoTerms: hpoTermsSelector(state),
  filesProcessing: filesProcessingSelector(state),
  sampleTypes: getSampleTypes(state),
  protocolNames: getProtocolNames(state),
  metadataFields: getCamelizedMetadataFields(state),
});

const mapDispatchToProps = {
  error: messageActions.error,
  warning: messageActions.warning,
  setFilesProcessing,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export const TableForm = connector(ApiConnectedComponent);
