import React, { memo, useMemo } from "react";

import { ComposableTabs, Tab } from "pattern-library";

import { GeneListProps } from "../../../types";

import GenesListTab from "./gene-tabs/GenesListTab";
import SearchTab from "./gene-tabs/SearchTab";

interface Props {
  projectId: number;
  genePanelId: number;
  canEdit: boolean;
}

const GenesTab = memo(({ projectId, genePanelId, canEdit }: Props) => {
  const tabs = useMemo(() => {
    const props: GeneListProps = { projectId, genePanelId, canEdit };

    return [
      {
        component: GenesListTab,
        name: "List of genes",
        props,
      },
      {
        component: SearchTab,
        name: "Add new gene",
        props,
      },
    ];
  }, [genePanelId, projectId, canEdit]);

  return (
    <ComposableTabs navStyle className="alternative-tabs-style">
      {tabs.map(tab => {
        const { name, component: Component, props = {} as GeneListProps } = tab;
        return (
          <Tab key={name} name={name}>
            <Component {...props} />
          </Tab>
        );
      })}
    </ComposableTabs>
  );
});

export default memo(GenesTab);
