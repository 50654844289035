export const NAMESPACE = "patient" as const;

export const READ_PATIENT = "READ_PATIENT" as const;
export const READ_PATIENT_SUCCESS = "READ_PATIENT_SUCCESS" as const;
export const READ_PATIENT_FAILURE = "READ_PATIENT_FAILURE" as const;

export const SET_PATIENT_TABS = "SET_PATIENT_TABS";
export const SET_CUSTOM_IFRAME_CONFIG = "SET_CUSTOM_IFRAME_CONFIG";
export const RELOAD_PATIENT_REPORTS = "RELOAD_PATIENT_REPORTS" as const;

export const THIRDPARTY_SOFTWARE = {
  MAXENTSCAN: "MAXENTSCAN",
  SSF: "SSF",
  DBSC_SNV: "DBSC_SNV",
  SPLICE_AI: "SPLICE_AI",
};
