import { CATALYST_BASE_URL } from "../utils/baseUrls";

const getEnsemblVersionQueryParam = (
  ensemblVersion?: string,
  isFirst = false
): string => {
  if (ensemblVersion) {
    const param = `ensembl_version=${ensemblVersion}`;
    return isFirst ? `?${param}` : `&${param}`;
  }
  return "";
};

export const getGeneOptions = (ensemblVersion?: string) => input =>
  fetch(
    `${CATALYST_BASE_URL}/gene/search?search=${input}${getEnsemblVersionQueryParam(
      ensemblVersion
    )}`,
    {
      credentials: "include",
    }
  )
    .then(request => request.json())
    .then(json =>
      json.genes.map(gene => ({
        value: gene.gene_id,
        label: gene.name,
      }))
    );
