import classNames from "classnames";
import type { FC } from "react";
import React, { useRef } from "react";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { connect, ConnectedProps } from "react-redux";

import { Button, Icon } from "pattern-library";

import * as messageActions from "modules/messages/actions";

import styles from "./FileUploader.module.scss";

interface Props extends PropsFromRedux {
  uploadedFileName: string | undefined;
  allowedFileExtension: string;
  onChange: (file: File) => void;
  disabled: boolean;
  title: string;
  selectFileButtonTestId: string;
}

export const FileUploader: FC<Props> = ({
  allowedFileExtension,
  onChange,
  disabled,
  showError,
  title,
  uploadedFileName,
  selectFileButtonTestId,
}: Props) => {
  const selectFile = useRef<HTMLInputElement | null>(null);

  const onClickSelectFile = (e: Event) => {
    e.preventDefault();
    if (selectFile.current) {
      selectFile.current.click();
    }
  };

  const onSelectFileChange = ({ target: { files } }) => {
    process(files);
  };

  const process = (files: Array<File>) => {
    if (files.length === 0) {
      return;
    }
    if (files.length > 1) {
      showError("Only single file uploading is accepted");
      return;
    }
    const file: File = files[0];
    const { name } = file;

    if (allowedFileExtension && !name.endsWith(allowedFileExtension)) {
      showError(
        `${name} - Unsupported file type "${name.substring(
          name.indexOf(".")
        )}". Supported format is "${allowedFileExtension}"`
      );
      return;
    }
    onChange(file);
  };

  const [{ canDrop, isOver }, drop] = useDrop({
    canDrop: () => !disabled,
    accept: [NativeTypes.FILE],

    async drop(item: any, monitor) {
      try {
        const fileSystemEntry = item.items?.[0]?.webkitGetAsEntry();
        if (fileSystemEntry.isDirectory) {
          showError("Folders are not accepted");
        } else if (fileSystemEntry.isFile) {
          process(monitor.getItem().files);
        } else {
          showError("Unexpected object");
        }
      } catch (_) {
        //if webkitGetAsEntry is not supported by the user browser version we should still process files
        process(monitor.getItem().files);
      }
    },

    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div className={styles.fileUploader}>
      <h3>{title}</h3>
      <div
        ref={drop}
        className={classNames(
          "dnd-target-area",
          styles.fileUploader__drop,
          canDrop && isOver && styles.fileUploader__drop__active,
          disabled && styles.fileUploader__drop__disabled
        )}
      >
        <div className={styles.fileUploader__drop__indicator}>
          <Icon type="file" />
        </div>
        <div className={styles.fileUploader__drop__label}>
          Drag & Drop File Here
        </div>
      </div>
      <Button
        className={styles.fileUploader__file}
        disabled={disabled}
        onClick={onClickSelectFile}
      >
        Browse Files...
      </Button>
      <input
        data-testid={selectFileButtonTestId}
        ref={selectFile}
        className={styles.fileUploader__file}
        type="file"
        accept={allowedFileExtension}
        style={{ display: "none" }}
        disabled={disabled}
        onChange={onSelectFileChange}
      />
      {uploadedFileName && <div>File name: {uploadedFileName}</div>}
    </div>
  );
};

const mapDispatchToProps: { showError: (message: string) => void } = {
  showError: messageActions.error,
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FileUploader);
