import classNames from "classnames";
import { useField } from "formik";
import React, { FC, useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";

import {
  FORM_FILE_TYPES,
  VCF_FILE_SIZE_THRESHOLD,
  ONE_MB,
} from "modules/interpretationRequests";
import { FileUploader } from "modules/interpretationRequests/components/FileUploader";
import * as messageActions from "modules/messages/actions";

import { toBase64 } from "../../../utils/base64";
import { IrSample } from "../../types";
import { ACCEPTED_FILE_TYPES_MESSAGE_ONCOLOGY } from "../componentConstants";

interface Props extends PropsFromRedux {
  error: (message: string) => void;
  isModal: boolean;
  withRna: boolean;
}

export const FileChecklist: FC<Props> = ({
  error,
  isModal = false,
  withRna = false,
}: Props) => {
  const [{ value: vcfFile }, , { setValue }] = useField<any>(
    "samples[0].files[0]"
  );
  const [{ value: samples }] = useField<Array<IrSample>>("samples");

  const fileTypes = useMemo<string>(
    () =>
      samples.length > 0 && samples[0].files && samples[0].files.length > 0
        ? samples[0].files[0].fileType
        : FORM_FILE_TYPES.FASTQ,
    [samples]
  );

  const onSelectFile = async (file: File) => {
    if (file.size > VCF_FILE_SIZE_THRESHOLD) {
      error(
        `File size should be less than ${(
          VCF_FILE_SIZE_THRESHOLD / ONE_MB
        ).toFixed(2)} MB`
      );
    } else {
      try {
        const fileBase64Contents = await toBase64(file);
        setValue({
          ...vcfFile,
          fileName: file.name,
          fileBase64Contents,
        });
      } catch (readerError) {
        error(`Error: ${readerError}`);
      }
    }
  };

  const hasSample = useMemo<boolean>(() => {
    const name = samples?.[0]?.name;
    return Boolean(name && name.length > 0);
  }, [samples]);

  if (fileTypes === FORM_FILE_TYPES.VCF) {
    if (!isModal) {
      return (
        <div data-testid="file-uploader" className="file-checklist-container">
          <FileUploader
            fileName={vcfFile.fileName}
            title="Upload VCF File"
            allowedFilesExtensions={[".vcf"]}
            onChange={onSelectFile}
          />
        </div>
      );
    }
    return null;
  }

  return (
    <div
      className={classNames("file-checklist-container", {
        "bg-warning": isModal,
      })}
    >
      <div className="file-checklist">
        {!isModal && (
          <div className="file-checklist-header">
            <h5>Expected SFTP file checklist</h5>
          </div>
        )}

        {!samples.length && (
          <div className="no-files-message">
            <span className="message">No files yet, add a patient first</span>
          </div>
        )}

        {isModal && samples.length > 0 && (
          <>
            <div key="file-entry-1" className="file-entry">
              <i className="fa fa-file-archive-o" aria-hidden="true" />
              <span className="file-entry-name">{`${samples[0].name}_S1_R1_001_dna.fastq.gz`}</span>
            </div>
            <div key="file-entry-2" className="file-entry">
              <i className="fa fa-file-archive-o" aria-hidden="true" />
              <span className="file-entry-name">{`${samples[0].name}_S1_R2_001_dna.fastq.gz`}</span>
            </div>
            {withRna && (
              <>
                <div key="file-entry-3" className="file-entry">
                  <i className="fa fa-file-archive-o" aria-hidden="true" />
                  <span className="file-entry-name">{`${samples[0].name}_S1_R1_001_rna.fastq.gz`}</span>
                </div>
                <div key="file-entry-4" className="file-entry">
                  <i className="fa fa-file-archive-o" aria-hidden="true" />
                  <span className="file-entry-name">{`${samples[0].name}_S1_R2_001_rna.fastq.gz`}</span>
                </div>
              </>
            )}
          </>
        )}

        {!isModal && samples.length > 0 && (
          <>
            <small data-testid="file-checklist">
              Filename uses Illumina name convention (except underscores in the
              sample name are forbidden):
              <br />
              <code>
                &lt;SampleID&gt;_S&lt;number&gt;_R&lt;number&gt;_001_&lt;NucleicAcid&gt;.fastq.gz
              </code>
              <br />
              OR
              <br />
              <code>
                &lt;SampleID&gt;_S&lt;number&gt;_L&lt;number&gt;_R&lt;number&gt;_001_&lt;NucleicAcid&gt;.fastq.gz
              </code>
              <h4>Template Legend:</h4>
              <b>SampleID</b> - Must only contain alphanumeric UTF8 characters,
              hyphen or full stop. Underscores are forbidden as they are used as
              separators between parts.
              <br />
              <b>S</b> - Sample number in IR (any number allowed, e.g. <i>1</i>
              ).
              <br />
              <b>L</b> (optional) - Lane number (any number allowed, e.g.{" "}
              <i>1</i>
              ). Multiple lane files should be concatenated via Linux command:
              <br />
              <code>
                cat
                &lt;SampleID&gt;_S&lt;number&gt;_L*_R&lt;number&gt;_001_&lt;NucleicAcid&gt;.fastq.gz&gt;
                Sample1_S1_R1_001_dna.fastq.gz
              </code>
              <br />
              <b>R</b> - Forward/backward read number (either <i>1</i> or{" "}
              <i>2</i> is allowed).
              <br />
              <b>001</b> - The last portion of the Illumina file name is always{" "}
              <i>001</i>.
              <br />
              <b>NucleicAcid</b> - Nucleic acid type (either dna or rna is
              allowed).
              <br />
              <h4>Suggested names:</h4>
              <code>
                {`${
                  hasSample
                    ? samples[0].name
                    : `<SampleID - substitute this for real id from user input>`
                }_S1_R1_001_dna.fastq.gz`}
              </code>
              <br />
              <code>
                {`${
                  hasSample ? samples[0].name : `<SampleID>`
                }_S1_R2_001_dna.fastq.gz`}
              </code>
              <br />
              {withRna && (
                <>
                  <code>
                    {`${
                      hasSample ? samples[0].name : `<SampleID>`
                    }_S1_R1_001_rna.fastq.gz`}
                  </code>
                  <br />
                  <code>
                    {`${
                      hasSample ? samples[0].name : `<SampleID>`
                    }_S1_R2_001_rna.fastq.gz`}
                  </code>
                  <br />
                </>
              )}
              <br />
            </small>
            <div className="footer-note text-danger">
              <span>
                <i className="fa fa-info-circle" aria-hidden="true" />
              </span>
              <span>
                Please upload the files specified above to the SFTP server after
                the IR submission
              </span>

              <span>
                <i className="fa fa-info-circle" aria-hidden="true" />
              </span>
              <span>{ACCEPTED_FILE_TYPES_MESSAGE_ONCOLOGY}</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  error: messageActions.error,
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FileChecklist);
