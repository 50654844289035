import { isEmpty, isNil } from "ramda";
import { useMemo } from "react";

import { ONCOLOGY_PROJECT_TYPE } from "modules/projects/constants";

import { useCurrentProject } from "./useCurrentProject";

import congenicaApi from "api/congenica-api";

const { useGetSystemConfigQuery } = congenicaApi;

const getVersionText = (versionLabel, version) =>
  isNil(version) || isEmpty(version)
    ? "Congenica"
    : `${versionLabel} v${version}`;

export const useAppVersion = (): string => {
  const { rareDisease, oncology, productVersionMode } = useGetSystemConfigQuery(
    undefined,
    {
      selectFromResult: ({ data }) => ({
        rareDisease: data?.versions?.rareDisease,
        oncology: data?.versions?.oncology,
        productVersionMode: data?.productVersionMode,
      }),
    }
  );
  const response = useCurrentProject();

  return useMemo(() => {
    if (productVersionMode === "always_oncology") {
      return getVersionText("Congenica Oncology", oncology);
    }

    return response?.data?.projectTypeInternalName === ONCOLOGY_PROJECT_TYPE
      ? getVersionText("Congenica Oncology", oncology)
      : getVersionText("Congenica Rare Disease", rareDisease);
  }, [response?.data, oncology, rareDisease, productVersionMode]);
};
