import { decamelizeKeys } from "humps";
import moment from "moment";
import { isNil, not } from "ramda";

import { FILE_TYPES } from "./constants";
import { FORM_FILE_TYPES } from "./types";

const countName = (counter, name) => {
  counter[name] = counter[name] || 0;
  counter[name] += 1;
};

export const getProbandsPerFamilyCount = (samples = []) =>
  samples.reduce((accumulator, sample) => {
    const { familyName, isProband } = sample;
    if (familyName && isProband) {
      countName(accumulator, familyName);
    }

    return accumulator;
  }, {});

/**
 * get samples indices grouped by the same name
 * e.g. {'name2', [0], 'name1': [1, 3]}
 */
export const getIndicesBySampleName = (samples = []) =>
  samples.reduce((accumulator, sample, index) => {
    const { name } = sample;
    if (name) {
      accumulator[name] = accumulator[name] || [];
      accumulator[name].push(index);
    }

    return accumulator;
  }, {});

export const getFileName = (sampleName, extension) =>
  `${sampleName}.${extension}`;

export const getFileUri = (
  defaultSampleFilesRootFolder,
  sampleName,
  extension
) => `${defaultSampleFilesRootFolder}/${getFileName(sampleName, extension)}`;

export const getIRExpectedFiles = (
  { fileTypes: formFileTypes, name },
  isOnPrem = false,
  defaultSampleFilesRootFolder,
  config
) => {
  const { addFileNames, addIndexFiles } = config;
  if (!formFileTypes) {
    return [];
  }
  return Object.entries(formFileTypes).reduce(
    (accumulator, [formFileType, value]) => {
      if (!value) {
        return accumulator;
      }
      const fileConfigs = FILE_TYPES[formFileType]?.fileConfigs || [];
      fileConfigs.forEach(({ type, subtype, extension, indexFileExt }) => {
        const fileToPush = {
          fileType: type,
          fileSubtype: subtype,
        };
        if (isOnPrem) {
          fileToPush.fileUri = getFileUri(
            defaultSampleFilesRootFolder,
            name,
            extension
          );
        }
        if (addFileNames) {
          fileToPush.fileName = getFileName(name, extension);
        }
        accumulator.push(fileToPush);

        if (addIndexFiles && indexFileExt) {
          accumulator.push({
            ...fileToPush,
            fileName: getFileName(name, `${extension}.${indexFileExt}`),
          });
        }
      });
      return accumulator;
    },
    []
  );
};

const adjustMetadata = metadata =>
  Object.entries(metadata).reduce((accumulator, [key, value]) => {
    if (value instanceof Date) {
      accumulator[key] = moment(value).format("DD/MM/YYYY");
    } else if (value && typeof value === "object" && value.value) {
      accumulator[key] = value.value;
    } else {
      accumulator[key] = value;
    }

    return accumulator;
  }, {});

const adjustHpoTerms = (hpoTerms = []) => hpoTerms.map(item => item.code);

const deleteIfEmpty = (object, keys) => {
  // the backend doesn't tolerate some fields to be present but empty
  keys.forEach(key => {
    if (!object[key]) {
      delete object[key];
    }
  });
};

export const prepareInterpretationRequest = (
  formIR,
  isOncologyProject = false,
  isOnPrem = false,
  defaultSampleFilesRootFolder
) => {
  const normalisedSamples = formIR.samples.map(sample => {
    const normalisedSample = decamelizeKeys(sample);
    delete normalisedSample.file_types;
    delete normalisedSample.file_name;
    if (!isOncologyProject) {
      normalisedSample.files = decamelizeKeys(
        getIRExpectedFiles(sample, isOnPrem, defaultSampleFilesRootFolder, {
          addFileNames: false,
          addIndexFiles: false,
        })
      );
      normalisedSample.metadata = adjustMetadata(sample.metadata);
      normalisedSample.hpo_terms = adjustHpoTerms(sample.hpoTerms);
      normalisedSample.cnv_analysis_requested =
        normalisedSample.cnv_analysis_requested ? 1 : 0;
      // references have to be sent in a separated CNV analysis request
      delete normalisedSample.references;
      deleteIfEmpty(normalisedSample, [
        "sample_type",
        "protocol",
        "mother_name",
        "father_name",
        "family_name",
      ]);
    } else {
      if (normalisedSample.files[0].file_type === FORM_FILE_TYPES.FASTQ) {
        const dnaFiles = [
          { file_type: "fastq", file_subtype: "r1", nucleic_acid: "DNA" },
          { file_type: "fastq", file_subtype: "r2", nucleic_acid: "DNA" },
        ];

        normalisedSample.files = normalisedSample.with_rna
          ? [
              ...dnaFiles,
              { file_type: "fastq", file_subtype: "r1", nucleic_acid: "RNA" },
              { file_type: "fastq", file_subtype: "r2", nucleic_acid: "RNA" },
            ]
          : dnaFiles;

        delete normalisedSample.with_rna;
      } else {
        delete normalisedSample.baitset;
        delete normalisedSample.with_rna;
      }

      normalisedSample.tumour_type = {
        oncotree_code: normalisedSample.tumour_type,
        oncotree_version: "oncotree_2019_12_01",
      };
    }

    return normalisedSample;
  });
  const result = {
    ...decamelizeKeys(formIR),
    samples: normalisedSamples,
    version: isOncologyProject ? 3 : 1,
    identifier: formIR.identifier.trim(),
  };
  if (isOncologyProject) {
    result.project_type = "oncology";
  }
  return result;
};

export const extractReferencePanels = formIR => {
  const { projectId, samples } = formIR;
  const referencePanelsPayload = [];
  samples.forEach(({ name, cnvAnalysisRequested, references = [] }) => {
    if (cnvAnalysisRequested) {
      referencePanelsPayload.push({
        name,
        project_id: projectId,
        references:
          references?.map(reference => decamelizeKeys(reference)) || [],
      });
    }
  });

  return referencePanelsPayload;
};

export const getEmptySample = (isOncologyProject = false) => {
  if (isOncologyProject) {
    return {
      name: "",
      files: [
        {
          fileType: FORM_FILE_TYPES.FASTQ,
          file_subtype: "oncology_snvs_indels",
        },
      ],
      with_rna: false,
    };
  }
  return {
    name: "",
    isProband: false,
    cnvAnalysisRequested: false,
    references: [],
    genePanelIds: [],
    hpoTerms: [],
    metadata: {},
  };
};

export const getPossibleParent = (samples, { name }, isMother) =>
  samples.reduce((accumulator, { name: memberName, sex: memberSex }) => {
    if (
      !!memberName &&
      memberName !== name &&
      (isMother
        ? !memberSex || memberSex === "Female"
        : !memberSex || memberSex === "Male")
    ) {
      accumulator[memberName] = memberName;
    }
    return accumulator;
  }, {});

export const getSupportInfo = samples => {
  const nameToIndex = {};
  const parentToChildren = {};

  samples.forEach(({ name }, index) => {
    nameToIndex[name] = index;
  });

  samples.forEach(({ fatherName, motherName }, index) => {
    if (motherName) {
      if (isNil(parentToChildren[nameToIndex[motherName]])) {
        parentToChildren[nameToIndex[motherName]] = [];
      }
      parentToChildren[nameToIndex[motherName]].push(index);
    }
    if (fatherName) {
      if (isNil(parentToChildren[nameToIndex[fatherName]])) {
        parentToChildren[nameToIndex[fatherName]] = [];
      }
      parentToChildren[nameToIndex[fatherName]].push(index);
    }
  });

  return [nameToIndex, parentToChildren];
};

const processSample = (sampleIndex, relatives, samples, supportInfo) => {
  processParent(sampleIndex, relatives, samples, supportInfo);
  processChildren(sampleIndex, relatives, samples, supportInfo);
};

const processParent = (sampleIndex, relatives, samples, supportInfo) => {
  const { motherName, fatherName } = samples[sampleIndex];
  const [nameToIndex] = supportInfo;
  const motherSampleIndex = nameToIndex[motherName];
  if (not(isNil(motherSampleIndex)) && !relatives.has(motherSampleIndex)) {
    relatives.add(motherSampleIndex);
    processSample(motherSampleIndex, relatives, samples, supportInfo);
  }

  const fatherSampleIndex = nameToIndex[fatherName];
  if (not(isNil(fatherSampleIndex)) && !relatives.has(fatherSampleIndex)) {
    relatives.add(fatherSampleIndex);
    processSample(fatherSampleIndex, relatives, samples, supportInfo);
  }
};

const processChildren = (sampleIndex, relatives, samples, supportInfo) => {
  const { name } = samples[sampleIndex];
  const [nameToIndex, parentToChildren] = supportInfo;
  const children = parentToChildren[nameToIndex[name]];
  if (not(isNil(children))) {
    children.forEach(childIndex => {
      if (!relatives.has(childIndex)) {
        relatives.add(childIndex);
        processSample(childIndex, relatives, samples, supportInfo);
      }
    });
  }
};

export const getRelatives = (targetSampleIndex, samples, supportInfo) => {
  const relatives = new Set();
  processSample(targetSampleIndex, relatives, samples, supportInfo);
  return relatives;
};

/**
 * @return object whose keys are each sample indices
 * and values are Sets containing family samples indices including the target sample itself
 * Family are the samples that have child - parent or parent - child relationship, siblings included
 * e.g. for two samples where sample 1 is the parent of the sample 0 the result is
 * {0: Set{1, 0}, 1: Set{0, 1}}
 */
export const getRelativesPerSample = (samples = []) => {
  const supportInfo = getSupportInfo(samples);
  const samplesNumber = samples.length;
  const result = {};
  for (let index = 0; index < samplesNumber; index++) {
    result[index] = getRelatives(index, samples, supportInfo);
  }
  return result;
};

export const getSamplesByNames = (samples = []) =>
  samples.reduce((result, sample) => {
    result[sample.name] = sample;
    return result;
  }, {});

export const getFieldNameFormPath = path => path.match(/\[[0-9]+].(.*)/)?.[1];
