import { useCallback, useMemo, useState } from "react";

import { PatientOperation } from "./types";

import legacyApi from "api/legacy-api";

const {
  useAddPatientToGenePanelMutation,
  useRemovePatientFromGenePanelMutation,
  useAddAllPatientsToGenePanelMutation,
  useRemoveAllPatientsFromGenePanelMutation,
} = legacyApi;

/**
 * Updates gene panel patients
 */
const useUpdateGenePanelPatients = () => {
  const [addPatientToGenePanel, addPatientToGenePanelResult] =
    useAddPatientToGenePanelMutation();
  const [removePatientFromGenePanel, removePatientFromGenePanelResult] =
    useRemovePatientFromGenePanelMutation();
  const [addAllPatientsToGenePanel, addAllPatientsToGenePanelResult] =
    useAddAllPatientsToGenePanelMutation();
  const [removeAllPatientsFromGenePanel, removeAllPatientsFromGenePanelResult] =
    useRemoveAllPatientsFromGenePanelMutation();

  const resultMap = useMemo(
    () => ({
      [PatientOperation.ADD_ONE]: addPatientToGenePanelResult,
      [PatientOperation.REMOVE_ONE]: removePatientFromGenePanelResult,
      [PatientOperation.ADD_ALL]: addAllPatientsToGenePanelResult,
      [PatientOperation.REMOVE_ALL]: removeAllPatientsFromGenePanelResult,
    }),
    [
      addPatientToGenePanelResult,
      removePatientFromGenePanelResult,
      addAllPatientsToGenePanelResult,
      removeAllPatientsFromGenePanelResult,
    ]
  );

  const [operation, setOperation] = useState<PatientOperation>();

  const updatePatients = useCallback(
    (
      operation: PatientOperation,
      projectId: number,
      genePanelId: number,
      patientId?: number
    ) => {
      setOperation(operation);
      switch (operation) {
        case PatientOperation.ADD_ONE:
          if (patientId) {
            addPatientToGenePanel({ projectId, genePanelId, patientId });
          }
          break;
        case PatientOperation.REMOVE_ONE:
          if (patientId) {
            removePatientFromGenePanel({ projectId, genePanelId, patientId });
          }
          break;
        case PatientOperation.ADD_ALL:
          addAllPatientsToGenePanel({ projectId, genePanelId });
          break;
        case PatientOperation.REMOVE_ALL:
          removeAllPatientsFromGenePanel({ projectId, genePanelId });
          break;
        default:
          break;
      }
    },
    [
      addPatientToGenePanel,
      removePatientFromGenePanel,
      addAllPatientsToGenePanel,
      removeAllPatientsFromGenePanel,
      setOperation,
    ]
  );

  return useMemo(
    () => ({
      updatePatients,
      resultMap,
      operation,
    }),
    [updatePatients, operation, resultMap]
  );
};

export default useUpdateGenePanelPatients;
