import { toBoolean } from "modules/interpretationRequests/components/csvUtils";

import { flattenApiEntity } from "./common";

export const ADMIN_ROLE = {
  name: "admin",
  label: "Administrator",
  description: "Administrator",
};

const getRoles = (access_level, roles) => {
  const parsedRoles = roles
    ? typeof roles === "string"
      ? JSON.parse(roles)
      : roles
    : [];
  return access_level === "admin" ? [ADMIN_ROLE] : parsedRoles;
};

export const prepareCatalystUser = ({
  user_id,
  email,
  fullname,
  admin,
  visibility_level_id,
  visibility_level_description,
  visibility_level_name,
  access_level,
  project_code,
  roles,
  lastlogin,
  authentication_provider_id,
  authentication_provider_name,
}: Record<string, any>): ProjectUser => ({
  userId: user_id,
  email,
  fullName: fullname,
  roles: getRoles(access_level, roles),
  isAdmin: Boolean(admin),
  visibilityLevelId: visibility_level_id
    ? Number(visibility_level_id)
    : visibility_level_id,
  visibilityLevelDescription: visibility_level_description,
  visibilityLevelName: visibility_level_name,
  accessLevel: access_level,
  projectCode: project_code,
  lastLogin: lastlogin,
  authenticationProviderId: authentication_provider_id,
  authenticationProviderName: authentication_provider_name,
});

export const prepareCatalystUsers = (
  users: Record<string, any>[]
): ProjectUser[] =>
  users.map((user: Record<string, any>) => prepareCatalystUser(user));

export const prepareRoles = (resp: APIEntityArray) => {
  const result = resp.data.map(flattenApiEntity);
  result.push(ADMIN_ROLE);
  return result.sort((a, b) => a.label.localeCompare(b.label));
};

export const getUserRoles = (access_levels: any[]) =>
  access_levels
    .filter(({ assigned }) => toBoolean(assigned))
    .map(
      ({
        role_name,
        only_double_user_check,
        user_project_access_level,
        description,
        id,
        label,
      }) => ({
        name: role_name,
        label,
        description,
        id,
        onlyDoubleUserCheck: toBoolean(only_double_user_check),
        userProjectAccessLevel: user_project_access_level,
      })
    );
