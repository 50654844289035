import classNames from "classnames";

import { getReclassifiedStatus } from "modules/oncologyExplore/utils";

import {
  ReclassifiedStatus,
  VariantDriverClassifications,
} from "../common-types";

export const getRowProps =
  (
    values: VariantDriverClassifications,
    onClickHandler?: (variant?: any) => void
  ) =>
  (_, { row: { original } }) => {
    const reclassifiedStatus = getReclassifiedStatus(
      values[original.id],
      original.pipeline_driver,
      original.reclassified_driver
    );

    return {
      key: original.id,
      className: classNames(
        reclassifiedStatus === ReclassifiedStatus.RECLASSIFIED_AS_PASSENGER &&
          "info",
        reclassifiedStatus === ReclassifiedStatus.RECLASSIFIED_AS_DRIVER &&
          "warning"
      ),
      onClick: () => {
        if (onClickHandler) {
          onClickHandler(original);
        }
      },
    };
  };

// A way to provide a cell with custom props that are passed to a column config
export const getCellProps = (_, { cell: { column } }) => ({
  onClick: column?.onClick,
  className: column?.className,
});
