import * as yup from "yup";

import { FORM_FILE_TYPES } from "modules/oncologyInterpretationRequests/types";

import { REQUIRED_FIELD_MESSAGE } from "../messages/constants";

const FASTQ_SAMPLE_ID_ALLOWED_CHARS_MESSAGE = `Only standard Latin letters, numbers, hyphens(-), and dots(.) are permitted when choosing FASTQ`;
const FASTQ_SAMPLE_ID_REGEX = /^[a-zA-Z0-9.-]+$/;

export const VALIDATION_SCHEMA = yup.object().shape({
  patientId: yup
    .string()
    .nullable()
    .required(REQUIRED_FIELD_MESSAGE)
    // TODO: validation for a vcf file sampleId
    .when(["fileType"], {
      is: value => value === FORM_FILE_TYPES.FASTQ,
      then: yup.string().matches(FASTQ_SAMPLE_ID_REGEX, {
        message: FASTQ_SAMPLE_ID_ALLOWED_CHARS_MESSAGE,
      }),
    }),
  tumourType: yup.string().nullable().required(REQUIRED_FIELD_MESSAGE),
  neoplasticCellularity: yup
    .number()
    .nullable()
    .required(REQUIRED_FIELD_MESSAGE),
  sex: yup.string().nullable().required(REQUIRED_FIELD_MESSAGE),
  baitset: yup
    .string()
    .nullable()
    .when(["fileType"], {
      is: value => value === FORM_FILE_TYPES.FASTQ,
      then: yup.string().required(REQUIRED_FIELD_MESSAGE),
    }),
  uploadedVcfFileName: yup.string().when(["fileType"], {
    is: value => value === FORM_FILE_TYPES.VCF,
    then: yup.string().required("You must upload a VCF file"),
  }),
  uploadedQcFileName: yup.string().when(["fileType"], {
    is: value => value === FORM_FILE_TYPES.VCF,
    then: yup.string().required("You must upload a QC file"),
  }),
});
