import {
  SNV_CVL_VARIANTS_TYPE,
  SUCCESS_STATUS,
} from "modules/curatedVariantsLists/constants";

import congenicaApi from "api/congenica-api";

const { useGetProjectCuratedListsQuery } = congenicaApi;

type QueryParams = Parameters<typeof useGetProjectCuratedListsQuery>;
type HookOptions = QueryParams[1];

/**
 * Retrieves Project CVLs and temporarily filters only automation ones in the UI
 */
const useProjectAutomationCVLs = (
  { projectId }: ProjectDependant,
  hookOptions: HookOptions = {}
) =>
  useGetProjectCuratedListsQuery(
    {
      projectId,
      filterOptions: {
        is_automated_annotation_decisions: true,
        variant_type: SNV_CVL_VARIANTS_TYPE,
        validation_status: SUCCESS_STATUS,
      },
    },
    hookOptions
  );

export default useProjectAutomationCVLs;
