import { isNil } from "ramda";
import { useMemo } from "react";

import { VariantType } from "../common-types";

type SplitDriverPassengerVariants = {
  drivers: VariantType[];
  passengers: VariantType[];
};

export const useSplitDriverPassengerVariants = (
  variants: VariantType[]
): SplitDriverPassengerVariants =>
  useMemo(
    () =>
      variants.reduce(
        (acc, variant) => {
          const driver = isNil(variant.reclassified_driver)
            ? variant.pipeline_driver
            : variant.reclassified_driver;

          if (driver) {
            acc.drivers.push(variant);
          } else {
            acc.passengers.push(variant);
          }

          return acc;
        },
        { drivers: [] as VariantType[], passengers: [] as VariantType[] }
      ),
    [variants]
  );
