import { APISaga, VariantSaga as VariantModelSaga } from "data-layer/index";
import { init as oncologyIrModule } from "modules/oncologyInterpretationRequests/redux/saga";
import { PatientMetadataSaga } from "modules/patientMetadata";
import { init as projectSettings } from "modules/projectSettings/saga";

import { init as acmgModule } from "./modules/acmg/saga";
import { init as AriadneSaga } from "./modules/ariadne/saga";
import { init as auditModule } from "./modules/audit/saga";
import { init as authModule } from "./modules/auth/saga";
import { init as configModule } from "./modules/config/saga";
import { init as curatedVariantsListsModule } from "./modules/curatedVariantsLists/saga";
import { FeedbackSaga } from "./modules/feedback";
import { init as genoverseModule } from "./modules/genoverse/saga";
import { init as hpoTermsModule } from "./modules/hpoTerms/saga";
import { init as rdIrModule } from "./modules/interpretationRequests/saga";
import { init as legacyModule } from "./modules/legacy/saga";
import { init as licencesModule } from "./modules/licences/saga";
import { init as metadataModule } from "./modules/metadata/saga";
import { init as patientSaga } from "./modules/patient/saga";
import { init as patientsModule } from "./modules/patients/saga";
import { init as preferencesModule } from "./modules/preferences/saga";
import { init as projectModule } from "./modules/project/saga";
import { init as projectInterpretationRequestsSaga } from "./modules/projectInterpretationRequests/saga";
import { init as filterPresetsModule } from "./modules/projectSettings/components/filterPresets/saga";
import { init as projectUsersModule } from "./modules/projectUsers/saga";
import { init as projectsModule } from "./modules/projects/saga";
import { refNoCallSaga } from "./modules/refNoCall";
import { init as rolesSaga } from "./modules/roles/saga";
import { init as sequenceVariantsPresetsModule } from "./modules/sequenceVariantsPresets/saga";
import { init as sequenceVariantsSummaryModule } from "./modules/sequenceVariantsSummary/saga";
import { STRsSaga } from "./modules/str";
import { init as structuralVariantsModule } from "./modules/structuralVariants/saga";
import { init as structuralVariantsPresetsModule } from "./modules/structuralVariantsPresets/saga";
import { init as systemConfigModule } from "./modules/systemConfig";
import { init as VariantHistoryModule } from "./modules/variantHistory/saga";
import { init as variantsModule } from "./modules/variants/saga";
import { init as versionsModule } from "./modules/versions/saga";

export default [
  APISaga,
  variantsModule,
  sequenceVariantsPresetsModule,
  structuralVariantsPresetsModule,
  curatedVariantsListsModule,
  configModule,
  authModule,
  hpoTermsModule,
  acmgModule,
  metadataModule,
  projectsModule,
  projectModule,
  systemConfigModule,
  projectUsersModule,
  legacyModule,
  VariantModelSaga,
  rdIrModule,
  oncologyIrModule,
  refNoCallSaga,
  filterPresetsModule,
  patientsModule,
  auditModule,
  genoverseModule,
  patientSaga,
  rolesSaga,
  projectInterpretationRequestsSaga,
  STRsSaga,
  projectSettings,
  PatientMetadataSaga,
  structuralVariantsModule,
  FeedbackSaga,
  VariantHistoryModule,
  AriadneSaga,
  preferencesModule,
  sequenceVariantsSummaryModule,
  versionsModule,
  licencesModule,
];
