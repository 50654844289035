import { FORM_FILE_TYPES, IrFormValues } from "./types";

const FASTQ_DNA_FILES = [
  {
    file_type: "fastq",
    file_subtype: "r1",
    nucleic_acid: "DNA",
  },
  {
    file_type: "fastq",
    file_subtype: "r2",
    nucleic_acid: "DNA",
  },
];

const FASTQ_RNA_FILES = [
  {
    file_type: "fastq",
    file_subtype: "r1",
    nucleic_acid: "RNA",
  },
  {
    file_type: "fastq",
    file_subtype: "r2",
    nucleic_acid: "RNA",
  },
];

export const getStandardTextFieldPlaceholder = (fieldLabel: string) =>
  `Enter ${fieldLabel}`;

const METADATA_FIELDS_MAPPING: Partial<Record<keyof IrFormValues, string>> = {
  patientName: "patient_name",
  dateOfBirth: "date_of_birth",
  nhsNumber: "nhs_number",
  treatingClinician: "treating_clinician",
  testOrderedBy: "test_ordered_by",
  testRequestDate: "test_request_date",
  biopsyDateTime: "biopsy_date_time",
  biopsySiteHospitalNumber: "biopsy_site_hospital_number",
  pastCurrentTumourTreatment: "past_current_tumour_treatment",
  caseIdPathology: "case_id_pathology",
  caseIdMolecularDx: "case_id_molecular_dx",
  specimenId: "specimen_id",
  specimenType: "specimen_type",
  pathologyBlockNumber: "pathology_block_number",
  requestedMolecularTest: "requested_molecular_test",
};

const mapMetadataSampleFields = (
  formValues: IrFormValues
): Record<string, string> | null => {
  const mappedMetadataFields: Record<string, string> = {};

  Object.keys(METADATA_FIELDS_MAPPING).forEach(key => {
    const mappedKey = METADATA_FIELDS_MAPPING[key];
    const metadataFormValue = formValues[key];

    // do not include fields if they don't have a relevant value
    if (
      metadataFormValue !== undefined &&
      metadataFormValue !== null &&
      metadataFormValue !== ""
    ) {
      mappedMetadataFields[mappedKey] = metadataFormValue;
    }
  });

  return Object.keys(mappedMetadataFields).length > 0
    ? mappedMetadataFields
    : null;
};

const mapGenericSampleFields = (formValues: IrFormValues) => {
  const metadata = mapMetadataSampleFields(formValues);

  return {
    name: formValues.patientId?.trim(),
    tumour_type: {
      oncotree_code: formValues.tumourType,
      oncotree_version: "oncotree_2019_12_01",
    },
    neoplastic_cellularity: formValues.neoplasticCellularity,
    sex: formValues.sex,
    ...(metadata ? { metadata } : {}), // do not include metadata property if none of the fields were input
  };
};

const mapFastqSample = (formValues: IrFormValues) => ({
  files: formValues.withRna
    ? [...FASTQ_DNA_FILES, ...FASTQ_RNA_FILES]
    : FASTQ_DNA_FILES,
  baitset: formValues.baitset,
  sample_type:
    formValues.baitset === "unimib_v1_PEDHEM" ? "Blood" : "Solid Tumour",
  ...mapGenericSampleFields(formValues),
});

const mapVcfSample = (formValues: IrFormValues) => ({
  files: [
    {
      file_type: "vcf",
      file_subtype: "oncology_annotated_all_variants",
      file_name: formValues.uploadedVcfFileName,
      file_base64_contents: formValues.uploadedVcfFileBase64Contents,
    },
    {
      file_type: "oncology_qc",
      file_subtype: "standard",
      file_name: formValues.uploadedQcFileName,
      file_base64_contents: formValues.uploadedQcFileBase64Contents,
    },
  ],
  ...mapGenericSampleFields(formValues),
});

export const mapIrRequest = (formValues: IrFormValues & ProjectDependant) => ({
  project_id: formValues.projectId,
  identifier: formValues.patientId?.trim(),
  samples: [
    formValues.fileType === FORM_FILE_TYPES.FASTQ
      ? mapFastqSample(formValues)
      : mapVcfSample(formValues),
  ],
  version: 3,
  project_type: "oncology",
});
