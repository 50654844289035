import { isNil } from "ramda";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect, ConnectedProps } from "react-redux";

import { Icon } from "pattern-library";
import { DEFAULT_PAGE_SIZE, Table } from "pattern-library/elements/table7";

import { getFetchRequestParams } from "modules/utils/table";

import { getCVLVariants } from "../actions";
import { VARIANT_LAST_UPDATED_FIELD } from "../constants";
import {
  getProjectId,
  getSelectedVariants,
  variantsLoading,
} from "../selectors";
import { ProjectCuratedVariantList } from "../types";

import { getColumns } from "./variants-columns";

import { useEnsemblOptions } from "hooks/useEnsemblOptions";

interface CVLVariantsProps extends PropsFromRedux {
  cvl: ProjectCuratedVariantList;
  ensemblVersion?: string;
  showEnsemblVersionLabel: boolean;
}
export const CVLVariants = ({
  cvl: { curatedVariantListId, curatedDataSchema, name },
  projectId,
  getCVLVariants,
  variants,
  isLoading,
  ensemblVersion,
  showEnsemblVersionLabel,
}: CVLVariantsProps) => {
  const [requestParams, setRequestParams] = useState<any>({});

  const list = variants ? variants.list : [];
  const totalCount = variants ? variants.totalCount : 0;

  const columns = useMemo(
    () => getColumns(curatedDataSchema),
    [curatedDataSchema]
  );

  const fetchData = useCallback(
    args => {
      setRequestParams({
        ...requestParams,
        ...getFetchRequestParams(args),
      });
    },
    [setRequestParams, requestParams]
  );

  useEffect(() => {
    const { pageNumber, pageSize } = requestParams;
    if (
      !isNil(pageNumber) &&
      !isNil(pageSize) &&
      curatedVariantListId &&
      ensemblVersion &&
      projectId
    ) {
      getCVLVariants(curatedVariantListId, projectId, {
        ...requestParams,
        ensembl_version: ensemblVersion,
      });
    }
  }, [
    getCVLVariants,
    curatedVariantListId,
    projectId,
    requestParams,
    ensemblVersion,
  ]);

  return (
    <>
      {showEnsemblVersionLabel && (
        <span className="help-block">
          <Icon type="infoSign" /> Curated variants are shown in the context of
          the Ensembl {ensemblVersion} version
        </span>
      )}
      <Table
        title={`Curated variants in ${name}`}
        columns={columns}
        data={list}
        showPagination={totalCount > DEFAULT_PAGE_SIZE}
        pageSizeOptions={[10, 25, 50, 100]}
        autoResetPage={false}
        loading={isLoading}
        loadingText=""
        fetchData={fetchData}
        manualPagination
        totalCount={totalCount}
        manualSortBy
        className="curated-lists-variants"
        enableFilter
        sortBy={[
          {
            id: VARIANT_LAST_UPDATED_FIELD,
            desc: true,
          },
        ]}
      />
    </>
  );
};

export const CVLVariantsConnectedComponent = props => {
  const { activeVersion, ensemblOptions } = useEnsemblOptions();
  return (
    <CVLVariants
      {...props}
      ensemblVersion={activeVersion}
      showEnsemblVersionLabel={ensemblOptions.length > 1}
    />
  );
};

const mapStateToProps = state => ({
  variants: getSelectedVariants(state),
  isLoading: variantsLoading(state),
  projectId: getProjectId(state),
});

const mapDispatchToProps = {
  getCVLVariants,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CVLVariantsConnectedComponent);
