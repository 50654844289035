import { FC, memo, useMemo } from "react";

import { ButtonDropdown, Download, Link } from "pattern-library";

import {
  CATALYST_BASE_URL,
  API_ENTITIES_BASE_URL,
} from "modules/utils/baseUrls";

import congenicaApi from "api/congenica-api";

const { useGetOncologyPatientDataQuery } = congenicaApi;

interface Props {
  loading: boolean;
  patientId: number;
}
const PatientDataDownload: FC<Props> = ({ patientId, loading }) => {
  const {
    reportDataFileName,
    secondaryAnalysisDataFileName,
    oncologyPatientDataLoading,
  } = useGetOncologyPatientDataQuery(
    { patientId },
    {
      selectFromResult: ({ data, isLoading }) => {
        const prefix =
          data?.reference && data.reference.length <= 200
            ? data.reference
            : patientId;
        return {
          oncologyPatientDataLoading: isLoading,
          reportDataFileName: `${prefix}_report_data.json`,
          secondaryAnalysisDataFileName: `${prefix}_secondary_analysis_data.zip`,
        };
      },
    }
  );

  const disabled = useMemo(
    () => oncologyPatientDataLoading || loading,
    [oncologyPatientDataLoading, loading]
  );

  return (
    <ButtonDropdown
      containerClassName="btn-group btn-group-sm"
      disabled={disabled}
      btnContent="Download Patient Data"
      list={[
        {
          key: "report-tertiary-data-download",
          content: (
            <Download
              Component={Link}
              url={`${CATALYST_BASE_URL}/patient/${patientId}/report/patient-data/oncology`}
              filename={reportDataFileName}
              caption="Report Data"
              dataTestId="report-data-download"
              disabled={disabled}
            />
          ),
        },
        {
          key: "report-secondary-data-download",
          content: (
            <Download
              Component={Link}
              url={`${API_ENTITIES_BASE_URL}/patients/${patientId}/oncology/secondary_analysis_data/download`}
              filename={secondaryAnalysisDataFileName}
              caption="Secondary Analysis Data"
              dataTestId="secondary-analysis-data-download"
              disabled={disabled}
            />
          ),
        },
      ]}
    />
  );
};

export default memo(PatientDataDownload);
