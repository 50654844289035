import React from "react";
import { connect, ConnectedProps } from "react-redux";

import { Actions } from "pattern-library";

import { getCVLDetails, exportCvlVariants } from "../actions";
import {
  canExportCVL,
  canRemoveFailedCVL,
  canRemoveFromProject,
} from "../selectors";
import { CVLAction, ProjectCuratedVariantList } from "../types";
import {
  getCuratedVariantsExportAction,
  getCVLIconStyles,
  getViewEditAction,
  isCVLStatusFailed,
  isCVLStatusSuccess,
} from "../utils";

import { useEnsemblVersion } from "hooks/useEnsemblVersion";

const getRemoveAction = (tooltip, onRemoveCallback, cvl) => ({
  action: () => onRemoveCallback(cvl),
  className: getCVLIconStyles("remove"),
  icon: "trash",
  tooltip: {
    content: tooltip,
    placement: "right",
    container: "span",
  },
});

interface ProjectOwnCVLRowActionsProps
  extends ProjectOwnCVLRowActionsConnectedFormProps {
  ensemblVersion?: string;
}

interface ProjectOwnCVLRowActionsConnectedFormProps extends PropsFromRedux {
  cvl: ProjectCuratedVariantList;
  onRemoveCallback: () => void;
}

export const ProjectOwnCVLRowActions = ({
  cvl,
  onRemoveCallback,
  getCVLDetails,
  canRemoveFromProject,
  canRemoveFailedCVL,
  exportCvlVariants,
  canExportCVL,
  ensemblVersion,
}: ProjectOwnCVLRowActionsProps) => {
  const {
    validationStatus,
    curatedVariantListId,
    originProjectId,
    variantCount,
  } = cvl;

  const actions: Array<CVLAction> = [];

  if (isCVLStatusSuccess(validationStatus) && curatedVariantListId) {
    actions.push(
      getViewEditAction(getCVLDetails, curatedVariantListId, originProjectId)
    );

    if (canRemoveFromProject) {
      actions.push(
        getRemoveAction("Remove from Project", onRemoveCallback, cvl)
      );
    }

    if (canExportCVL && ensemblVersion) {
      actions.push(
        getCuratedVariantsExportAction(
          exportCvlVariants,
          curatedVariantListId,
          variantCount,
          ensemblVersion
        )
      );
    }
  }

  if (isCVLStatusFailed(validationStatus) && canRemoveFailedCVL) {
    actions.push(getRemoveAction("Remove CVL", onRemoveCallback, cvl));
  }

  return <Actions actions={actions} justifyContent="space-around" />;
};

const ProjectOwnCVLRowActionsConnectedForm = (
  props: ProjectOwnCVLRowActionsConnectedFormProps
) => {
  const { ensemblVersion } = useEnsemblVersion();

  return <ProjectOwnCVLRowActions {...props} ensemblVersion={ensemblVersion} />;
};

const mapStateToProps = (state, { cvl }) => ({
  canRemoveFromProject: canRemoveFromProject(state),
  canRemoveFailedCVL: canRemoveFailedCVL(state),
  canExportCVL: canExportCVL(state, cvl),
});

const mapDispatchToProps = {
  getCVLDetails,
  exportCvlVariants,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProjectOwnCVLRowActionsConnectedForm);
