import * as constants from "modules/oncologyInterpretationRequests/constants";
import { IrFormValues } from "modules/oncologyInterpretationRequests/types";
import { createAction } from "modules/utils";

export type InitIRAction = FSA<typeof constants.INIT_IR, { projectId: number }>;

export const initIR = (projectId: number): InitIRAction =>
  createAction(constants.INIT_IR, { projectId });

export type SubmitIRAction = FSA<
  typeof constants.SUBMIT_IR,
  IrFormValues & ProjectDependant
>;

export const submitInterpretationRequest = (
  values: IrFormValues & ProjectDependant
): SubmitIRAction => createAction(constants.SUBMIT_IR, values);

type SetProjectRequestStatusAction = FSA<
  typeof constants.SET_PROJECT_REQUEST_STATUS,
  RequestSubmissionStatus
>;

export const setProjectRequestStatus = (
  status: RequestSubmissionStatus
): SetProjectRequestStatusAction =>
  createAction(constants.SET_PROJECT_REQUEST_STATUS, status);

type SetIRSubmissionStatusAction = FSA<
  typeof constants.SET_IR_SUBMISSION_STATUS,
  RequestSubmissionStatus
>;

export const setIRSubmissionStatus = (
  status: RequestSubmissionStatus
): SetIRSubmissionStatusAction =>
  createAction(constants.SET_IR_SUBMISSION_STATUS, status);

type ResetIRSubmissionStatusAction = FSA<
  typeof constants.RESET_IR_SUBMISSION_STATUS
>;

export const resetIRSubmissionStatus = (): ResetIRSubmissionStatusAction =>
  createAction(constants.RESET_IR_SUBMISSION_STATUS);

type SetBaitsetsAction = FSA<typeof constants.SET_BAITSETS, Array<string>>;

export const setBaitsets = (baitsets: Array<string>): SetBaitsetsAction =>
  createAction(constants.SET_BAITSETS, baitsets);

export type Action =
  | InitIRAction
  | SubmitIRAction
  | SetProjectRequestStatusAction
  | SetIRSubmissionStatusAction
  | ResetIRSubmissionStatusAction
  | SetBaitsetsAction;
