import React, { useState, useCallback } from "react";
import { InjectedFormProps, reduxForm } from "redux-form";

import { Button } from "pattern-library";

import { GENE_PANEL_EDIT_NOTIFICATION_FORM_NAME } from "../../constants";

import legacyApi from "api/legacy-api";

const { useAddExistingGenePanelToProjectMutation } = legacyApi;

export interface EditNotificationProps {
  projectId: number;
  genePanelId: number;
}

const EditNotification = ({
  projectId,
  genePanelId,
  handleSubmit,
}: EditNotificationProps & InjectedFormProps<Index, EditNotificationProps>) => {
  const [submitted, setSubmitted] = useState(false);

  const [copyGenePanel] = useAddExistingGenePanelToProjectMutation();

  const onFormSubmit = useCallback(() => {
    if (submitted) return;
    setSubmitted(true);
    copyGenePanel({ projectId, genePanelId });
  }, [setSubmitted, projectId, genePanelId, copyGenePanel, submitted]);

  return (
    <div>
      <div className="alert alert-info alert-adding-gene-panel" role="alert">
        <p>
          It is not possible to edit a gene panel that has been applied to one
          or more patients, or has been automatically imported from an external
          source such as PanelApp.
        </p>
        <p>
          If you would like to edit this panel, please copy the existing gene
          panel and modify the copy.
        </p>
        <form
          id={`add-gene-panel-${genePanelId}`}
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <Button
            type="submit"
            context={submitted ? "success" : "primary"}
            disabled={submitted ? "disabled" : undefined}
          >
            {submitted ? "Gene panel copied..." : "Copy gene panel"}
          </Button>
        </form>
      </div>
      <br />
    </div>
  );
};

export default reduxForm<Index, EditNotificationProps>({
  form: GENE_PANEL_EDIT_NOTIFICATION_FORM_NAME,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(EditNotification);
