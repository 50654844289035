import PropTypes from "prop-types";
import React from "react";

const IRSubmittedToolbar = ({ projectId, show, createNewIR }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="alert fade in alert-success success-toolbar">
      <a
        className="btn btn-info btn-md"
        href={`/projects/${projectId}/patients`}
      >
        Back to Project
      </a>
      <button
        type="button"
        className="btn btn-success btn-md"
        onClick={createNewIR}
      >
        Create New Interpretation Request
      </button>
    </div>
  );
};

IRSubmittedToolbar.propTypes = {
  /**
   * the id of the project an IR is created in
   */
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  /**
   * a property that shows/hides the toolbar
   */
  show: PropTypes.bool.isRequired,
  /**
   * add new interpretation request form handler
   */
  createNewIR: PropTypes.func.isRequired,
};

export default React.memo(IRSubmittedToolbar);
