import { isNil, isEmpty } from "ramda";

import {
  VariantType,
  VariantDriverClassifications,
  ReclassifiedVariantCounts,
  ReclassifiedStatus,
} from "./common-types";

export const getVariantDriverClassifications = (
  variants?: VariantType[]
): VariantDriverClassifications =>
  variants?.reduce(
    (
      variantDriverClassifications: VariantDriverClassifications,
      { id, reclassified_driver, pipeline_driver }
    ) => {
      variantDriverClassifications[id] = reclassified_driver ?? pipeline_driver;
      return variantDriverClassifications;
    },
    {}
  ) ?? {};

export const getVariantDriverClassificationsDiff = (
  variantDriverClassifications: VariantDriverClassifications,
  variants?: VariantType[]
): VariantDriverClassifications =>
  variants?.reduce(
    (
      variantDriverClassificationsDiff,
      { id, reclassified_driver, pipeline_driver }
    ) => {
      if (isNil(reclassified_driver)) {
        if (pipeline_driver !== variantDriverClassifications[id]) {
          variantDriverClassificationsDiff[id] =
            variantDriverClassifications[id];
        }
      } else {
        if (reclassified_driver !== variantDriverClassifications[id]) {
          variantDriverClassificationsDiff[id] =
            variantDriverClassifications[id];
        }
      }
      return variantDriverClassificationsDiff;
    },
    {}
  ) ?? {};

export const getReclassifiedStatus = (
  driverClassification: boolean,
  pipelineDriver: boolean,
  persistedDriverClassification: boolean | null
): ReclassifiedStatus => {
  if (
    pipelineDriver === persistedDriverClassification &&
    driverClassification === pipelineDriver
  ) {
    return ReclassifiedStatus.NOT_RECLASSIFIED;
  }

  if (driverClassification === pipelineDriver) {
    return ReclassifiedStatus.NOT_RECLASSIFIED;
  }

  return driverClassification
    ? ReclassifiedStatus.RECLASSIFIED_AS_DRIVER
    : ReclassifiedStatus.RECLASSIFIED_AS_PASSENGER;
};

export const getReclassifiedVariantsCounts = (
  variants: VariantType[],
  variantClassifications: VariantDriverClassifications
): ReclassifiedVariantCounts => {
  const initialReclassifiedVariantsCounts: ReclassifiedVariantCounts = {
    reclassifiedAsDriverCount: 0,
    reclassifiedAsPassengerCount: 0,
  };

  if (isEmpty(variantClassifications)) {
    return initialReclassifiedVariantsCounts;
  }

  return variants.reduce((acc, variant) => {
    const reclassifiedStatus = getReclassifiedStatus(
      variantClassifications[variant.id],
      variant.pipeline_driver,
      variant.reclassified_driver
    );

    if (reclassifiedStatus === ReclassifiedStatus.RECLASSIFIED_AS_DRIVER) {
      acc.reclassifiedAsDriverCount += 1;
    }

    if (reclassifiedStatus === ReclassifiedStatus.RECLASSIFIED_AS_PASSENGER) {
      acc.reclassifiedAsPassengerCount += 1;
    }

    return acc;
  }, initialReclassifiedVariantsCounts);
};
