import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { connect } from "react-redux";

import { Link } from "pattern-library";
import { DEFAULT_PAGE_SIZE, Table } from "pattern-library/elements/table7";

import { isCurrentUserAdmin as isAdmin } from "modules/auth/selectors";
import ReportsModal from "modules/patients/components/ReportsModal";
import { Preferences, usePreferencesStateWrapper } from "modules/preferences";
import { AUTO_ACMG_FEATURE } from "modules/project/constants";
import {
  getCurrentProjectTypeInternalName,
  isProjectFeatureActive,
} from "modules/project/selectors";
import { ONCOLOGY_PROJECT_TYPE } from "modules/projects/constants";

import { getProjectName } from "../curatedVariantsLists/selectors";

import * as actions from "./actions";
import PatientCustomFilter from "./components/PatientCustomFilter";
import { getColumns } from "./components/columns";
import * as selectors from "./selectors";
import usePatientsTableData from "./usePatientsTableData";

/**
 * This function is used to resolve any props
 * needed for this row.
 *
 * @param state
 * @param rowInfo
 * @param column
 * @param instance
 */
const onRowClick = (
  _,
  {
    row: {
      original: { patientId },
    },
  }
) => ({
  key: patientId,
  onClick: () => {
    window.location = `/patient/${patientId}`;
  },
  onMouseUp: ({ button }) => {
    const middleMouseButton = 1;
    if (button === middleMouseButton) {
      window.open(`/patient/${patientId}`);
    }
  },
  style: {
    cursor: "pointer",
  },
});

export const PatientsContainer = ({
  projectId,
  projectType,
  isAdmin = false,
  writeAccess = false,
  showSampleUploadButton = false,
  initialise,
  resetPatientStatus,
  setPatientsFilter,
  setReportsModalVisible,
  loadReports,
  isLoading,
  data = [],
  metaData = [],
  isReportsModalVisible,
  className = "",
  isAutoAcmgActive,
  projectName,
}) => {
  const onFilterChange = useCallback(
    ({ filter = "" }) => {
      setPatientsFilter(filter);
    },
    [setPatientsFilter]
  );

  const toggleConfirmationModal = useCallback(() => {
    setReportsModalVisible(!isReportsModalVisible);
  }, [isReportsModalVisible, setReportsModalVisible]);

  useEffect(() => {
    initialise(projectId);
    setPatientsFilter("");
  }, [projectId, initialise, setPatientsFilter]);

  const tableRef = useRef(null);

  useEffect(() => {
    if (projectId && tableRef.current) {
      tableRef.current.resetPage();
    }
  }, [projectId, tableRef]);

  const resetStatus = useCallback(
    patientId => {
      resetPatientStatus({ patientId, projectId });
    },
    [projectId, resetPatientStatus]
  );

  const showReports = useCallback(
    patientId => {
      loadReports(patientId);
      toggleConfirmationModal();
    },
    [toggleConfirmationModal, loadReports]
  );

  const columns = useMemo(() => {
    const showEnsemblColumn =
      data && data.some(patient => patient.annotationSources?.length > 0);
    return getColumns(
      isAdmin,
      metaData,
      resetStatus,
      showReports,
      isAutoAcmgActive,
      projectType,
      showEnsemblColumn
    );
  }, [
    data,
    isAdmin,
    metaData,
    resetStatus,
    showReports,
    isAutoAcmgActive,
    projectType,
  ]);

  const [preferredPageSize, setPreferredPageSize] = usePreferencesStateWrapper(
    Preferences.patientsListPageSize,
    DEFAULT_PAGE_SIZE
  );

  const [hideReviewCompletePrefs, setHideReviewCompletePrefs] =
    usePreferencesStateWrapper(Preferences.patientsHideReviewComplete, false);

  const oncologyProject = useMemo(
    () => projectType === ONCOLOGY_PROJECT_TYPE,
    [projectType]
  );
  const { getProjectPatientsOfflineStatusesFetching, tableData } =
    usePatientsTableData(projectId, data, hideReviewCompletePrefs, projectType);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.toggleSortBy(
        oncologyProject ? "updated" : "reference",
        oncologyProject
      );
    }
  }, [tableRef, oncologyProject]);

  return (
    <div className="patients-pane">
      <Table
        tableRef={tableRef}
        className={classNames(className)}
        columns={columns}
        data={tableData}
        showPagination={tableData.length > DEFAULT_PAGE_SIZE}
        defaultPageSize={preferredPageSize}
        onPageSizeChange={setPreferredPageSize}
        pageSizeOptions={[10, 25, 50, 100]}
        autoResetPage={false}
        noDataText="No data available in table"
        loading={isLoading || getProjectPatientsOfflineStatusesFetching}
        getRowProps={onRowClick}
        enableFilter
        fetchData={onFilterChange}
        title={`Patients in ${projectName}`}
        showTitleInfo
        autoResetSortBy={false}
        CustomFilterComponent={
          <PatientCustomFilter
            hideReviewCompletePrefs={hideReviewCompletePrefs}
            onHideReviewCompleteChange={setHideReviewCompletePrefs}
          />
        }
      />

      {writeAccess && projectType && (
        <div className="patients-pane-row-action">
          {showSampleUploadButton && (
            <Link
              data-testid="ir-submission"
              className="btn btn-primary patients-pane-row-action-btn"
              href={`/projects/${projectId}/ir-submission`}
            >
              IR Submission
            </Link>
          )}
          <Link
            data-testid="interpretation-request-csv-template"
            className="btn btn-primary patients-pane-row-action-btn"
            href={`/catalyst/api/ir_csv_template/${projectId}`}
          >
            Interpretation Request CSV Template
          </Link>
        </div>
      )}
      <ReportsModal
        show={isReportsModalVisible}
        close={toggleConfirmationModal}
      />
    </div>
  );
};

PatientsContainer.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  writeAccess: PropTypes.bool,
  showSampleUploadButton: PropTypes.bool,
  initialise: PropTypes.func,
  resetPatientStatus: PropTypes.func,
  setPatientsFilter: PropTypes.func,
  setReportsModalVisible: PropTypes.func,
  loadReports: PropTypes.func,
  isLoading: PropTypes.bool,
  data: PropTypes.array,
  metaData: PropTypes.array,
  isReportsModalVisible: PropTypes.bool,
  className: PropTypes.string,
  isAdmin: PropTypes.bool,
  isAutoAcmgActive: PropTypes.bool,
};

const mapStateToProps = (state, { projectId }) => ({
  isLoading: selectors.isLoading(state),
  data: selectors.getFilteredData(state),
  metaData: selectors.getMetaData(state),
  isReportsModalVisible: selectors.isReportsModalVisible(state),
  isAdmin: isAdmin(state),
  isAutoAcmgActive: isProjectFeatureActive(state, AUTO_ACMG_FEATURE),
  projectType: getCurrentProjectTypeInternalName(state),
  projectName: getProjectName(state, projectId),
});

const mapDispatchToProps = {
  initialise: actions.initialise,
  resetPatientStatus: actions.resetPatientStatus,
  setPatientsFilter: actions.setPatientsFilter,
  setReportsModalVisible: actions.setReportsModalVisible,
  loadReports: actions.loadReports,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientsContainer);
