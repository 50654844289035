import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { Checkbox, Tag } from "pattern-library";

import { notEmpty } from "modules/utils/array";
import { CheckboxOptionProp } from "modules/utils/prop-types/CheckboxOptionProp";

import { withTooltip } from "../checkbox-list/DraggableItem";

const CheckboxGroup = ({
  options = [],
  value = [],
  onChange,
  label,
  showArchived = false,
  valueSort = true,
  disabled: groupDisabled = false,
  boldSelected = true,
  preserveOptionsOrderInValue = false,
}) => {
  const optionsKeys = (options || [])
    .map(({ key, values }) => values || key)
    .flat();
  const sortByOptions = (a, b) =>
    optionsKeys.indexOf(a) - optionsKeys.indexOf(b);
  const handleChange = (key, isChecked) => () => {
    const arrayOfValues = Array.isArray(key) ? key : [key];
    const uniqueValues = new Set(value);

    arrayOfValues.forEach(val => {
      if (isChecked) {
        uniqueValues.delete(val);
      } else if (!uniqueValues.has(val)) {
        uniqueValues.add(val);
      }
    });

    // ensure the same order
    const result = preserveOptionsOrderInValue
      ? [...uniqueValues].sort(sortByOptions)
      : valueSort
      ? [...uniqueValues].sort()
      : [...uniqueValues];

    onChange(result);
  };

  const values = new Set(value);

  return (
    <div className="form-group checkbox-group">
      {label && <label className="control-label col-xs-3">{label}</label>}
      <div
        className={classNames({
          "col-xs-9": !!label,
          "col-xs-12": label,
        })}
      >
        {(options || []).map(
          ({
            key,
            label,
            archived = false,
            disabled = false,
            tooltip,
            // Take values from the checkbox option, as this allows us to define
            // multiple values to add to the set of selected options for a single checkbox
            values: optionValues,
            tags = [],
          }) => {
            const checked = (() => {
              if (value) {
                const anyAdditionalValueChecked = (optionValues || []).some(
                  val => values.has(val)
                );
                return values.has(key) || anyAdditionalValueChecked;
              }

              return false;
            })();
            const outlined =
              boldSelected && checked ? <strong>{label}</strong> : label;
            const withArchivedLabel =
              showArchived && archived ? (
                <>
                  {outlined} <small className="text-muted">(archived)</small>
                </>
              ) : (
                outlined
              );
            const withTags = notEmpty(tags) ? (
              <>
                {withArchivedLabel}
                {tags.map(({ label, className = "" }) => (
                  <Tag key={label} className={className}>
                    {label}
                  </Tag>
                ))}
              </>
            ) : (
              withArchivedLabel
            );
            return withTooltip(
              <Checkbox
                key={key}
                name={key + ""}
                label={withTags}
                checked={checked}
                onChange={handleChange(optionValues || key, checked)}
                disabled={groupDisabled || disabled}
              />,
              tooltip ? { ...tooltip, key } : null
            );
          }
        )}
      </div>
      <div className="clearfix" />
    </div>
  );
};

CheckboxGroup.propTypes = {
  /**
   * checkbox group label
   */
  label: PropTypes.string,
  /**
   * the callback to pass the value back to the enclosing form when a checkbox is (de)selected
   */
  onChange: PropTypes.func.isRequired,
  /**
   * all checkbox options. Can be marked archived
   */
  options: PropTypes.arrayOf(CheckboxOptionProp),
  /**
   * checkboxes selected values
   */
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  /**
   * if true, sorts values by default js sort function
   */
  valueSort: PropTypes.bool,
  /**
   * if true, adds " (archived)" labels to the items that have archived=true
   */
  showArchived: PropTypes.bool,
  /**
   * if true, disables all options in the current group
   */
  disabled: PropTypes.bool,
  /**
   * if true, bolds options when selected
   */
  boldSelected: PropTypes.bool,
};

export default CheckboxGroup;
