import classNames from "classnames";
import { FormikErrors, FormikTouched } from "formik";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { LoadingOverlay } from "pattern-library";

import FilesPanel from "modules/oncologyInterpretationRequests/components/FilesPanel";
import FormSubmissionErrorsModal from "modules/oncologyInterpretationRequests/components/FormSubmissionErrorsModal";
import IRFormFields from "modules/oncologyInterpretationRequests/components/IRFormFields";
import { initIR } from "modules/oncologyInterpretationRequests/redux/actions";
import {
  hasProjectRequestFailed,
  isIRSubmissionCompleted,
  isIRSubmissionSuccessful,
} from "modules/oncologyInterpretationRequests/redux/selectors";
import { IrFormValues } from "modules/oncologyInterpretationRequests/types";

import IRSubmittedModal from "./IRSubmittedModal";
import IRSubmittedToolbar from "./IRSubmittedToolbar";

interface OwnProps extends ProjectDependant {
  /**
   * Formik form value object
   */
  values: IrFormValues;
  /**
   * Formik errors object
   */
  errors: FormikErrors<IrFormValues>;
  /**
   * Formik submit function
   */
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  /**
   * Formik form validation result
   */
  isValid: boolean;
  /**
   * Formik property automatically set to true when submit is started.
   * For synchronous forms has to be set back to false manually when submission is done
   */
  isSubmitting: boolean;
  /**
   * Formik function that allows to manually control isSubmitting value
   */
  setSubmitting: (submitting: boolean) => void;
  /**
   * reset Formik forms
   */
  resetForm: () => void;
  /**
   * Formik object on which fields have been touched/visited
   */
  touched: FormikTouched<IrFormValues>;
}

interface DispatchProps {
  /**
   * the action that triggers IR supporting data (e.g. project, baitsets) to load
   */
  initIR: (projectId: number) => void;
}

interface ReduxStateProps {
  /**
   * true if IR submission was successful
   */
  isIRSubmissionSuccessful: boolean;
  /**
   * true if IR submission finished (failed or succeeded)
   */
  isIRSubmissionCompleted: boolean;
  /**
   * indicates if any IR supporting data (Project) wasn't loaded
   */
  isDataLoadError: boolean;
}

type Props = OwnProps & DispatchProps & ReduxStateProps;

const IRForm: React.FC<Props> = ({
  values,
  errors,
  handleSubmit,
  isValid,
  isSubmitting,
  isIRSubmissionSuccessful,
  isIRSubmissionCompleted,
  setSubmitting,
  resetForm,
  touched,
  projectId,
  initIR,
  isDataLoadError,
}) => {
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    const hasTouchedFields = Object.keys(touched).some(key => touched[key]);

    if (isIRSubmissionSuccessful) {
      window.onbeforeunload = null;
    } else if (!window.onbeforeunload && hasTouchedFields) {
      window.onbeforeunload = function () {
        return true;
      };
    }
  }, [touched, isIRSubmissionSuccessful]);

  useEffect(() => {
    if (isIRSubmissionSuccessful) {
      setShowSuccessModal(true);
    }
  }, [isIRSubmissionSuccessful]);

  useEffect(() => {
    if (isIRSubmissionCompleted) {
      // mark Formik form submission end
      setSubmitting(false);
    }
  }, [isIRSubmissionCompleted, setSubmitting]);

  const closeIRSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const toggleWarningsModal = () => {
    setShowWarningModal(!showWarningModal);
  };

  const onSubmit = e => {
    handleSubmit(e);
    if (!isValid) {
      toggleWarningsModal();
    }
  };

  const createNewIR = React.useCallback(() => {
    resetForm(); // reset Formik form initialValues
    initIR(projectId); // reset request submission status, project, etc
  }, [resetForm, initIR, projectId]);

  // The submit button should be disabled if
  // - IR supporting data (project, etc.) is not loaded
  // - submission is in progress
  const disabled = isDataLoadError || isSubmitting || isIRSubmissionSuccessful;

  const { withRna } = values;

  return (
    <>
      <form className="form-horizontal" onSubmit={onSubmit}>
        <LoadingOverlay loading={isSubmitting}>
          <div>
            <div className={classNames("ir-content", "ir-content-oncology")}>
              <IRFormFields values={values} disabled={disabled} />
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">
              <FilesPanel
                isModal={false}
                withRna={withRna}
                disabled={disabled}
              />
            </div>
            <div className="col-md-4">
              <div className="visible-xs visible-sm mt-1" />
              {!isIRSubmissionSuccessful && (
                <button
                  type="submit"
                  disabled={disabled}
                  className="btn btn-primary pull-right"
                >
                  Submit Interpretation Request
                </button>
              )}
            </div>
          </div>
        </LoadingOverlay>
      </form>
      {isIRSubmissionSuccessful && (
        <IRSubmittedToolbar projectId={projectId} createNewIR={createNewIR} />
      )}
      <FormSubmissionErrorsModal
        closeModal={toggleWarningsModal}
        show={showWarningModal}
        errors={errors}
      />
      {isIRSubmissionSuccessful && (
        <IRSubmittedModal
          show={showSuccessModal}
          close={closeIRSuccessModal}
          values={values}
          createNewIR={createNewIR}
        />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  isIRSubmissionSuccessful: isIRSubmissionSuccessful(state),
  isIRSubmissionCompleted: isIRSubmissionCompleted(state),
  isDataLoadError: hasProjectRequestFailed(state),
});

const mapDispatchToProps = {
  initIR,
};

export default connect(mapStateToProps, mapDispatchToProps)(IRForm);
