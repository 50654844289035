import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import api from "../api/index";
import RootReducer from "../reducers";
import Sagas from "../saga";

import { rtkQueryErrorLogger } from "./rtkQueryErrorLogger";
import rtkQueryResponseMessageHandler from "./rtkQueryResponseMessageHandler";

const sagaMiddleware = createSagaMiddleware();

export const getStore = (
  /**
   * Optional initial state to pass to configureStore - only to be used for test configurations
   */
  initialState?: any
) => {
  const store = configureStore({
    ...(initialState && { preloadedState: initialState }),
    devTools: {
      trace: true,
      traceLimit: 25,
    },
    reducer: RootReducer,
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware({
        // We have existing code that violates immutability and serializability checks:
        // https://redux-toolkit.js.org/api/getDefaultMiddleware#included-default-middleware
        // https://redux-toolkit.js.org/api/immutabilityMiddleware
        // https://redux-toolkit.js.org/api/serializabilityMiddleware
        // Until these are identified and fixed across the application, disable these middleware
        immutableCheck: false,
        serializableCheck: false,
      }),
      sagaMiddleware,
      rtkQueryErrorLogger,
      rtkQueryResponseMessageHandler,

      // RTK Query API
      api.middleware,
    ],
  });
  for (const saga of Sagas) {
    sagaMiddleware.run(saga);
  }
  return store;
};

export type RootState = ReturnType<typeof RootReducer>;

export default getStore();
