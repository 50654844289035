import React, { FC, memo } from "react";

import { Download } from "pattern-library";

interface Props {
  patientId: number;
  reportServiceTemplateUuid: string | undefined;
}
const PatientGeneratedReportDownload: FC<Props> = ({
  patientId,
  reportServiceTemplateUuid,
}) => (
  <Download
    // if the report PDF doesn't exist, and it should then the endpoint should attempt to recreate the report
    url={`/webapi/entities/patients/${patientId}/oncology/lab_report`}
    filename={`${reportServiceTemplateUuid || patientId}.pdf`}
    caption="Download Report"
    dataTestId="report-generated-download"
    context="default"
  />
);
export default memo(PatientGeneratedReportDownload);
