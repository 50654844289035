import { OncologyCNV } from "types/CNV";

export interface OncologyExploreComponentProps
  extends DriverClassificationCellProps {
  data: VariantType[];
}

export interface DriverClassificationCellProps {
  variantDriverClassifications: VariantDriverClassifications;
  setVariantDriverClassifications: (
    values: VariantDriverClassifications
  ) => void;
  reclassificationInProgress: boolean;
}

export type VariantDriverClassifications = Record<string, boolean>;

export type VariantType = OncologyCNV | OncologySNV | OncologyFusion;

export interface ReclassifiedVariantCounts {
  reclassifiedAsDriverCount: number;
  reclassifiedAsPassengerCount: number;
}

export enum ReclassifiedStatus {
  RECLASSIFIED_AS_DRIVER = "RECLASSIFIED_AS_DRIVER",
  RECLASSIFIED_AS_PASSENGER = "RECLASSIFIED_AS_PASSENGER",
  NOT_RECLASSIFIED = "NOT_RECLASSIFIED",
}
