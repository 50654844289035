import { useMemo } from "react";

import { Button, Icon } from "pattern-library";

import { FAILED, REVIEW_COMPLETE } from "../constants";

import congenicaApi from "api/congenica-api";

enum ButtonContext {
  DEFAULT = "default",
  SUCCESS = "success",
  DANGER = "danger",
}

const { useGetOncologyPatientWorkflowStatusQuery } = congenicaApi;

const Status = ({ patientId }: any) => {
  const { data: oncologyPatientWorkflowStatus, isLoading } =
    useGetOncologyPatientWorkflowStatusQuery({
      patientId,
    });

  const buttonContext = useMemo<ButtonContext>(() => {
    if (!oncologyPatientWorkflowStatus?.currentStatus) {
      return ButtonContext.DEFAULT;
    }
    const { currentStatus } = oncologyPatientWorkflowStatus;

    if (currentStatus === REVIEW_COMPLETE) {
      return ButtonContext.SUCCESS;
    } else if (currentStatus === FAILED) {
      return ButtonContext.DANGER;
    }

    return ButtonContext.DEFAULT;
  }, [oncologyPatientWorkflowStatus]);

  return (
    <Button
      className="oncology-report__actions__button__status"
      context={buttonContext}
      disabled
    >
      {isLoading && <Icon type="spinner" />}
      {!isLoading && (
        <Icon type="infoSign" className="oncology-report__button__icon" />
      )}

      {oncologyPatientWorkflowStatus?.currentStatus || ""}
    </Button>
  );
};

export default Status;
