import { Formik } from "formik";
import React from "react";
import { connect } from "react-redux";

import IRForm from "modules/oncologyInterpretationRequests/components/IRForm";
import { submitInterpretationRequest } from "modules/oncologyInterpretationRequests/redux/actions";

import { FORM_FILE_TYPES, IrFormValues } from "../types";
import { VALIDATION_SCHEMA } from "../validation";

const INITIAL_FORM_VALUES: IrFormValues = {
  patientId: null,
  tumourType: null,
  sex: null,
  baitset: null,
  sampleType: null,
  fileType: FORM_FILE_TYPES.FASTQ,
  withRna: false,
  neoplasticCellularity: null,
  patientName: null,
  dateOfBirth: null,
  nhsNumber: null,
  treatingClinician: null,
  testOrderedBy: null,
  testRequestDate: null,
  biopsyDateTime: null,
  biopsySiteHospitalNumber: null,
  pastCurrentTumourTreatment: null,
  caseIdPathology: null,
  caseIdMolecularDx: null,
  specimenId: null,
  specimenType: null,
  pathologyBlockNumber: null,
  requestedMolecularTest: null,
};

interface Props extends ProjectDependant {
  /**
   * IR request handler function
   */
  submitInterpretationRequest: (
    values: IrFormValues & ProjectDependant
  ) => void;
}

const IRFormWrapper: React.FC<Props> = ({
  submitInterpretationRequest,
  projectId,
}) => (
  <Formik<IrFormValues>
    initialValues={INITIAL_FORM_VALUES}
    validationSchema={VALIDATION_SCHEMA}
    validateOnMount // to have error object available straight away for WarningsModal (Formik isValid check)
    onSubmit={(values: IrFormValues) => {
      submitInterpretationRequest({ ...values, projectId });
    }}
  >
    {props => <IRForm projectId={projectId} {...props} />}
  </Formik>
);

const mapDispatchToProps = {
  submitInterpretationRequest,
};

export default connect(null, mapDispatchToProps)(IRFormWrapper);
