import catalystApi from "api/catalyst-api";
import congenicaApi from "api/congenica-api";
import { OncologyPatientWorkflowStatus } from "types/oncologyPatientWorkflowStatus";
import { OncologyDnaQcData, OncologyRnaQcData } from "types/oncologyQc";

const { useGetSignificantStatusChangeQuery } = catalystApi;

const {
  useGetOncologyDnaQcMetricsQuery,
  useGetOncologyRnaQcMetricsQuery,
  useGetOncologyPatientWorkflowStatusQuery,
  useGetOncologyPatientDataQuery,
} = congenicaApi;

interface UseOncologyQCDataReturnType {
  dnaQc: OncologyDnaQcData | undefined;
  rnaQc: OncologyRnaQcData | undefined;
  oncologyPatientWorkflowStatus: OncologyPatientWorkflowStatus | undefined;
  significantStatusChanges: Partial<SignificantStatusChange>;
  qcJsonFileNamePrefix: string | number;
  loading: boolean;
}

export const useOncologyQCData = ({
  patientId,
}: PatientDependant): UseOncologyQCDataReturnType => {
  const { data: dnaQc, isLoading: dnaQcLoading } =
    useGetOncologyDnaQcMetricsQuery({
      patientId,
    });

  const { data: rnaQc, isLoading: rnaQcLoading } =
    useGetOncologyRnaQcMetricsQuery({
      patientId,
    });

  const {
    data: significantStatusChanges = {},
    isLoading: significantStatusChangeLoading,
  } = useGetSignificantStatusChangeQuery({ patientId });

  const {
    data: oncologyPatientWorkflowStatus,
    isLoading: oncologyPatientWorkflowStatusLoading,
  } = useGetOncologyPatientWorkflowStatusQuery({
    patientId,
  });

  const { qcJsonFileNamePrefix, oncologyPatientDataLoading } =
    useGetOncologyPatientDataQuery(
      { patientId },
      {
        selectFromResult: ({ data, isLoading }) => {
          const prefix =
            data?.reference && data.reference.length <= 200
              ? data.reference
              : patientId;

          return {
            oncologyPatientDataLoading: isLoading,
            qcJsonFileNamePrefix: prefix,
          };
        },
      }
    );

  const loading =
    oncologyPatientWorkflowStatusLoading ||
    significantStatusChangeLoading ||
    dnaQcLoading ||
    rnaQcLoading ||
    oncologyPatientDataLoading;

  return {
    dnaQc,
    rnaQc,
    significantStatusChanges,
    oncologyPatientWorkflowStatus,
    qcJsonFileNamePrefix,
    loading,
  };
};
