export type State = {
  ui: {
    irSubmissionStatus: RequestSubmissionStatus | null;
    projectRequestStatus: RequestSubmissionStatus | null;
  };
  data: {
    baitsets: string[];
  };
};

export enum FORM_FILE_TYPES {
  VCF = "vcf",
  FASTQ = "fastq",
}

export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export interface Baitset {
  name: string;
}

export type Baitsets = Record<string, Baitset>;

export interface IrFormValues {
  patientId: string | null;
  tumourType: string | null; // TODO: in req this is Cancer Type
  neoplasticCellularity: number | null; // TODO: add in label (pathology estimation) as in req?
  sex: string | null; // TODO: add in label (biological) as in req?
  sampleType: string | null;
  baitset: string | null;
  fileType: FORM_FILE_TYPES;
  withRna: boolean;
  uploadedVcfFileName?: string;
  uploadedVcfFileBase64Contents?: string | ArrayBuffer | null;
  uploadedQcFileName?: string;
  uploadedQcFileBase64Contents?: string | ArrayBuffer | null;

  // metadata fields
  patientName: string | null;
  dateOfBirth: string | null; // TODO: should be date
  nhsNumber: string | null; // TODO: should probably be a number in the future
  treatingClinician: string | null;
  testOrderedBy: string | null;
  testRequestDate: string | null; // TODO: should be date
  biopsyDateTime: string | null; // TODO: should be date
  biopsySiteHospitalNumber: string | null;
  pastCurrentTumourTreatment: string | null;
  caseIdPathology: string | null;
  caseIdMolecularDx: string | null;
  specimenId: string | null;
  specimenType: string | null;
  pathologyBlockNumber: string | null;
  requestedMolecularTest: string | null;
}
