import { createAction } from "../utils";

import { Mode, UserInfo } from "./types";

export const actionType = Object.freeze({
  INIT: "INIT",
  FETCH_USERS_START: "FETCH_USERS_START",
  FETCH_USERS_SUCCESS: "FETCH_USERS_SUCCESS",
  FETCH_USERS_FAILURE: "FETCH_USERS_FAILURE",
  SET_USER_INFO: "SET_USER_INFO",
  RESET_USER_INFO: "RESET_USER_INFO",
  ADD_PROJECT_USER: "ADD_PROJECT_USER",
  UPDATE_PROJECT_USER: "UPDATE_PROJECT_USER",
  ADD_PERMISSIONS_PROJECT_USER: "ADD_PERMISSIONS_PROJECT_USER",
  DELETE_PROJECT_USER: "DELETE_PROJECT_USER",
  FETCH_EXISTING_USERS_START: "FETCH_EXISTING_USERS_START",
  FETCH_EXISTING_USERS_SUCCESS: "FETCH_EXISTING_USERS_SUCCESS",
  FETCH_EXISTING_USERS_FAILURE: "FETCH_EXISTING_USERS_FAILURE",
  FETCH_EXISTING_USER_DETAILS_START: "FETCH_EXISTING_USER_DETAILS_START",
  FETCH_EXISTING_USER_DETAILS_SUCCESS: "FETCH_EXISTING_USER_DETAILS_SUCCESS",
  FETCH_EXISTING_USER_DETAILS_FAILURE: "FETCH_EXISTING_USER_DETAILS_FAILURE",
  SET_PROJECT_USERS_LOADING: "SET_PROJECT_USERS_LOADING",
  SET_DOUBLE_USER_CHECK: "SET_DOUBLE_USER_CHECK",
  FETCH_CURRENT_USER_AUTH_PROVIDERS_START:
    "FETCH_CURRENT_USER_AUTH_PROVIDERS_START",
  FETCH_CURRENT_USER_AUTH_PROVIDERS_SUCCESS:
    "FETCH_CURRENT_USER_AUTH_PROVIDERS_SUCCESS",
  FETCH_CURRENT_USER_AUTH_PROVIDERS_FAILURE:
    "FETCH_CURRENT_USER_AUTH_PROVIDERS_FAILURE",
});

export type InitializeAction = FSA<typeof actionType.INIT, number>;
export const initialize = (projectId: number): InitializeAction =>
  createAction(actionType.INIT, projectId);

export const fetchUsers: AsyncActionCreator = {
  start: (
    projectId: number
  ): FSA<typeof actionType.FETCH_USERS_START, number> =>
    createAction(actionType.FETCH_USERS_START, projectId),
  success: (data: {
    projectUsers: Array<ProjectUser>;
    inheritedUsers: Array<ProjectUser>;
  }): FSA<
    typeof actionType.FETCH_USERS_SUCCESS,
    { projectUsers: Array<ProjectUser>; inheritedUsers: Array<ProjectUser> }
  > => createAction(actionType.FETCH_USERS_SUCCESS, data),
  failure: (): FSA<typeof actionType.FETCH_USERS_FAILURE> =>
    createAction(actionType.FETCH_USERS_FAILURE),
};

export const fetchExistingUsers: AsyncActionCreator = {
  start: (
    projectId
  ): FSA<typeof actionType.FETCH_EXISTING_USERS_START, number> =>
    createAction(actionType.FETCH_EXISTING_USERS_START, projectId),
  success: (
    data: Array<User>
  ): FSA<typeof actionType.FETCH_EXISTING_USERS_SUCCESS, Array<User>> =>
    createAction(actionType.FETCH_EXISTING_USERS_SUCCESS, data),
  failure: (): FSA<typeof actionType.FETCH_EXISTING_USERS_FAILURE> =>
    createAction(actionType.FETCH_EXISTING_USERS_FAILURE),
};

export const fetchExistingUserDetails: AsyncActionCreator = {
  start: (
    projectId,
    userId
  ): FSA<
    typeof actionType.FETCH_EXISTING_USER_DETAILS_START,
    { projectId: number; userId: number }
  > =>
    createAction(actionType.FETCH_EXISTING_USER_DETAILS_START, {
      projectId,
      userId,
    }),
  success: (
    data: Array<User>
  ): FSA<typeof actionType.FETCH_EXISTING_USER_DETAILS_SUCCESS, Array<User>> =>
    createAction(actionType.FETCH_EXISTING_USER_DETAILS_SUCCESS, data),
  failure: (): FSA<typeof actionType.FETCH_EXISTING_USER_DETAILS_FAILURE> =>
    createAction(actionType.FETCH_EXISTING_USER_DETAILS_FAILURE),
};

export const fetchCurrentUserAuthProviders: AsyncActionCreator = {
  start: (): FSA<typeof actionType.FETCH_CURRENT_USER_AUTH_PROVIDERS_START> =>
    createAction(actionType.FETCH_CURRENT_USER_AUTH_PROVIDERS_START),
  success: (
    data: Array<AuthProvider>
  ): FSA<
    typeof actionType.FETCH_CURRENT_USER_AUTH_PROVIDERS_SUCCESS,
    Array<AuthProvider>
  > => createAction(actionType.FETCH_CURRENT_USER_AUTH_PROVIDERS_SUCCESS, data),
  failure: (): FSA<
    typeof actionType.FETCH_CURRENT_USER_AUTH_PROVIDERS_FAILURE
  > => createAction(actionType.FETCH_CURRENT_USER_AUTH_PROVIDERS_FAILURE),
};

type SetUserInfoAction = FSA<typeof actionType.SET_USER_INFO, UserInfo>;
export const setUserInfo = (
  user: Partial<ProjectUser>,
  mode: Mode
): SetUserInfoAction => createAction(actionType.SET_USER_INFO, { user, mode });

export const resetUserInfo = () =>
  createAction(actionType.SET_USER_INFO, undefined);

export type DeleteProjectUserAction = FSA<
  typeof actionType.DELETE_PROJECT_USER,
  { projectId: number; userId: number }
>;
export const deleteProjectUser = (
  projectId: number,
  userId: number
): DeleteProjectUserAction =>
  createAction(actionType.DELETE_PROJECT_USER, { projectId, userId });

export type AddProjectUserAction = FSA<
  typeof actionType.ADD_PROJECT_USER,
  EditableProjectUser
>;
export const addProjectUser = (
  user: EditableProjectUser
): AddProjectUserAction => createAction(actionType.ADD_PROJECT_USER, user);

export type UpdateProjectUserAction = FSA<
  typeof actionType.UPDATE_PROJECT_USER,
  any
>;
export const updateProjectUser = (
  user: EditableProjectUser
): UpdateProjectUserAction =>
  createAction(actionType.UPDATE_PROJECT_USER, user);

export type AddPermissionsProjectUserAction = FSA<
  typeof actionType.ADD_PERMISSIONS_PROJECT_USER,
  EditableProjectUser
>;
export const addPermissionsProjectUser = (
  user: EditableProjectUser
): AddPermissionsProjectUserAction =>
  createAction(actionType.ADD_PERMISSIONS_PROJECT_USER, user);

export type SetProjectUsersLoadingAction = FSA<
  typeof actionType.SET_PROJECT_USERS_LOADING,
  boolean
>;
export const setProjectUsersLoading = (
  loading: boolean
): SetProjectUsersLoadingAction =>
  createAction(actionType.SET_PROJECT_USERS_LOADING, loading);

export type SetDoubleUserCheckAction = FSA<
  typeof actionType.SET_DOUBLE_USER_CHECK,
  boolean
>;
export const setDoubleUserCheck = (
  checkEnabled: boolean
): SetDoubleUserCheckAction =>
  createAction(actionType.SET_DOUBLE_USER_CHECK, checkEnabled);

export type Action =
  | ReturnType<typeof fetchUsers.start>
  | ReturnType<typeof fetchUsers.success>
  | ReturnType<typeof fetchUsers.failure>
  | ReturnType<typeof fetchExistingUsers.start>
  | ReturnType<typeof fetchExistingUsers.success>
  | ReturnType<typeof fetchExistingUsers.failure>
  | ReturnType<typeof fetchExistingUserDetails.start>
  | ReturnType<typeof fetchExistingUserDetails.success>
  | ReturnType<typeof fetchExistingUserDetails.failure>
  | SetUserInfoAction
  | AddProjectUserAction
  | UpdateProjectUserAction
  | AddPermissionsProjectUserAction
  | DeleteProjectUserAction
  | InitializeAction
  | SetProjectUsersLoadingAction;
