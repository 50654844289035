import classnames from "classnames";
import React, { useCallback, useState, useMemo } from "react";
import { connect } from "react-redux";

import {
  Button,
  Actions,
  LoadingOverlay,
  Icon,
  Divider,
  GenePanelTitle,
} from "pattern-library";

import { showModal } from "modules/modalRegistry/actions";
import { modalTypes } from "modules/modalRegistry/constants";
import { formatDate } from "modules/utils";

import styles from "./GenePanels.module.scss";
import GenePanelsTable from "./components/GenePanelsTable";
import { PANEL_SOURCE_MAP } from "./constants";

import catalystApi from "api/catalyst-api";
import congenicaApi from "api/congenica-api";
import legacyApi from "api/legacy-api";

const { useGetThirdPartyDataVersionsQuery, useGetCurrentUserQuery } =
  congenicaApi;
const { useGetProjectGenePanelsQuery } = legacyApi;
const { useGetProjectCurrentUserQuery, useGetProjectQuery } = catalystApi;

type Props = {
  projectId: number;
  showModal: (modalType: string, modalPayload?: any) => any;
};

export const GenePanels: React.FC<Props> = ({ projectId, showModal }) => {
  const { data: currentProject, isLoading: isCurrentProjectLoading } =
    useGetProjectQuery({ projectId });
  const { code } = currentProject || { code: "" };
  const [showArchived, setShowArchived] = useState(false);
  const toggleShowArchived = () => setShowArchived(!showArchived);

  const { data: projectCurrentUser } = useGetProjectCurrentUserQuery({
    projectId,
  });

  const { data: currentUser } = useGetCurrentUserQuery();

  const { data: ensemblVersions = [], isLoading: ensemblVersionsLoading } =
    useGetThirdPartyDataVersionsQuery({
      name: "ENSEMBL",
    });

  const {
    data: { genePanels = [], inheritedGenePanels: parentGenePanels = [] } = {},
    isLoading: loading,
  } = useGetProjectGenePanelsQuery({
    projectId,
    archived: showArchived,
  });

  const getGenePanelActions = useCallback(
    (code, genePanel, inherited) => {
      const actions = [
        {
          icon: "viewEdit",
          className: "btn-default btn-xs",
          action: () => {
            showModal(modalTypes.EDIT_GENE_PANEL_MODAL, {
              projectId,
              genePanelId: genePanel.genePanelId,
              inherited,
              panelSource: genePanel.panelSource,
            });
          },
          tooltip: {
            content: "Edit panel",
          },
          isDisabled: Boolean(genePanel.archived),
        },
        {
          icon: "export",
          className: "btn-default btn-xs",
          action: () => {
            showModal(modalTypes.EXPORT_GENE_PANEL_MODAL, {
              projectId,
              genePanelId: genePanel.genePanelId,
            });
          },
          tooltip: {
            content: "Export panel",
          },
        },
      ];

      if (!inherited) {
        actions.push(
          {
            icon: "briefcase",
            className: "btn-default btn-xs",
            action: () => {
              showModal(modalTypes.ARCHIVE_GENE_PANEL_MODAL, {
                projectId,
                genePanelId: genePanel.genePanelId,
              });
            },
            tooltip: {
              content: "Archive panel",
            },
            isDisabled: Boolean(genePanel.archived),
          },
          {
            icon: "trash",
            className: "btn-default btn-xs",
            action: () => {
              showModal(modalTypes.DELETE_GENE_PANEL_MODAL, {
                projectId,
                genePanelId: genePanel.genePanelId,
              });
            },
            tooltip: {
              content: "Remove panel",
            },
          }
        );
      }
      return <Actions sticky group justifyContent="start" actions={actions} />;
    },
    [showModal, projectId]
  );

  const getColumns = useCallback(
    (inherited, currentUser, showEnsemblVersion) => {
      const genePanelsColumns: any[] = [
        {
          Header: "ID",
          accessor: "genePanelId",
          headerClassName: "col-md-1",
        },
        {
          Header: "Gene panel",
          accessor: "title",
          headerClassName: "col-md-2",
          Cell: ({
            row: {
              original: { title, version },
            },
          }) => <GenePanelTitle title={title} version={version} />,
        },
        showEnsemblVersion
          ? {
              Header: "Ensembl version",
              accessor: "ensemblVersion",
            }
          : null,
        {
          Header: "Description",
          accessor: "description",
          headerClassName: "col-md-2",
        },
      ];

      if (inherited) {
        genePanelsColumns.push({
          Header: "Parent project",
          accessor: "projectCode",
          headerClassName: "col-md-1",
        });
      }

      genePanelsColumns.push(
        {
          Header: "Base panel",
          accessor: "basePanelTitle",
          headerClassName: "col-md-2",
        },
        {
          Header: "Patients",
          accessor: "patientsCount",
          headerClassName: "col-md-1",
          Cell: ({
            row: {
              original: { patientsCount, totalPatientsCount },
            },
          }) =>
            `${patientsCount}${
              totalPatientsCount > patientsCount
                ? ` ( ${totalPatientsCount} in all projects )`
                : ""
            }`,
        },
        {
          Header: "Genes",
          accessor: "genesCount",
          headerClassName: "col-md-1",
        },
        {
          Header: "Panel source",
          headerClassName: "col-md-1",
          accessor: ({ panelSource }) =>
            PANEL_SOURCE_MAP[panelSource] || panelSource || "",
        },
        {
          Header: "Last updated",
          accessor: "updated",
          headerClassName: "col-md-1",
          Cell: ({
            row: {
              original: { updated },
            },
          }) => formatDate(updated * 1000),
        }
      );

      if (showArchived) {
        genePanelsColumns.push({
          Header: "Archived",
          accessor: "archived",
          headerClassName: "col-md-1",
          Cell: ({
            row: {
              original: { archived },
            },
          }) => {
            const iconClass = archived ? "tick" : "cross";
            return <Icon type={iconClass} />;
          },
          disableGlobalFilter: true,
        });
      }

      if (
        (currentUser && currentUser.admin) ||
        (projectCurrentUser &&
          projectCurrentUser.permissions &&
          projectCurrentUser.permissions.addEditGenePanels)
      ) {
        genePanelsColumns.push({
          Header: "Actions",
          headerClassName: "col-md-2",
          accessor: genePanel =>
            getGenePanelActions(code, genePanel, inherited),
          disableSortBy: true,
          disableGlobalFilter: true,
        });
      }

      return genePanelsColumns.filter(Boolean);
    },
    [code, getGenePanelActions, projectCurrentUser, showArchived]
  );

  const showEnsemblVersionsColumn = useMemo(
    () => ensemblVersions.length > 1,
    [ensemblVersions]
  );
  const genePanelsColumns = getColumns(
    false,
    currentUser,
    showEnsemblVersionsColumn
  );
  const parentGenePanelColumns = getColumns(
    true,
    currentUser,
    showEnsemblVersionsColumn
  );
  const genePanelsTitle = `Gene Panels in ${code}`;

  const genePanelTables = (
    <div className={styles.component}>
      <div id="project-gene-panels-table_wrapper">
        <GenePanelsTable
          genePanels={genePanels}
          columns={genePanelsColumns}
          title={genePanelsTitle}
          ensemblVersions={ensemblVersions}
          isLoading={loading}
          id="projectGenePanels"
        />
        <div className={classnames("clearfix", styles.componentButtons)}>
          <div className="table-buttons inline-buttons">
            <Button
              context="primary"
              onClick={() => {
                showModal(modalTypes.ADD_GENE_PANEL_MODAL);
              }}
            >
              Add gene panel
            </Button>
            <Button onClick={toggleShowArchived}>
              {showArchived ? "Hide archived" : "Show archived"}
            </Button>
          </div>
        </div>
      </div>
      <div className="mt-5 mb-4">
        <Divider />
      </div>
      <GenePanelsTable
        genePanels={parentGenePanels}
        columns={parentGenePanelColumns}
        title="Gene Panels in parent projects"
        ensemblVersions={ensemblVersions}
        isLoading={loading}
        id="parentGenePanels"
      />
    </div>
  );

  return (
    <LoadingOverlay
      loading={loading || ensemblVersionsLoading || isCurrentProjectLoading}
    >
      {genePanelTables}
    </LoadingOverlay>
  );
};

const mapDispatchToProps = {
  showModal,
};

export default connect(null, mapDispatchToProps)(GenePanels) as React.FC<
  Pick<Props, "projectId">
>;
