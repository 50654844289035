import classnames from "classnames";
import { equals, not, or } from "ramda";
import React, { ReactElement, useCallback, useMemo, useState } from "react";

import { makeSafeForCSS } from "common/utils";

import DefaultTabHeader from "./DefaultTabHeader";

const stackedLeft = equals("left");

type Props = {
  /**
   * Custom container classname
   */
  className: string;
  /**
   * A custom component to render in each tab header
   */
  tabHeaderComponent: any;
  /**
   * Should the tabs be at the top or left/right from the content
   */
  stacked: boolean;
  /**
   * Position of tabs is side mode
   */
  stackedPosition: "left" | "right";
  /**
   * If navStyle is true selected tab is highlighted in blue
   */
  navStyle: boolean;
  /**
   * If defaultActive is set to tab name value it will show that tab active by default
   */
  defaultActive: string;
  /**
   * tabs content
   */
  children: Array<ReactElement>;
};

const ComposableTabs: React.FC<Props> = ({
  className,
  children,
  defaultActive,
  navStyle = true,
  stacked = false,
  stackedPosition = "left",
  tabHeaderComponent,
}) => {
  const getDefaultCurrentTab = () => {
    if (defaultActive) {
      return defaultActive;
    }
    if (children && Array.isArray(children)) {
      const [
        {
          props: { name },
        },
      ] = children;
      return name;
    }

    return null;
  };

  const [currentTabName, setCurrentTab] = useState(getDefaultCurrentTab());

  const isTabActive = useCallback(
    (name: string) => currentTabName === name,
    [currentTabName]
  );

  const renderTabs = useCallback(
    filteredChildren => {
      const onClick = setCurrentTab;
      const HeaderComponent = tabHeaderComponent || DefaultTabHeader;

      return (
        <div className={classnames({ "col-xs-4": stacked })}>
          <ul
            className={classnames("nav", {
              "nav-pills nav-stacked": stacked,
              "nav-tabs": !stacked,
              "stacked-left": stacked && stackedLeft(stackedPosition),
              "stacked-right": stacked && not(stackedLeft(stackedPosition)),
            })}
          >
            {filteredChildren.map(({ props }) => {
              const {
                name,
                className,
                hasValue = false,
                label,
                count,
                icon,
                hasError,
                info,
              } = props;
              const active = isTabActive(name);
              return (
                <li
                  key={`nav-item-${name}`}
                  data-testid={`tab-header-${makeSafeForCSS(name)}`}
                  className={classnames(
                    className,
                    {
                      active: active && !navStyle,
                    },
                    {
                      error: hasError,
                    }
                  )}
                >
                  <HeaderComponent
                    strong={hasValue}
                    name={name}
                    onClick={onClick}
                    label={label}
                    count={count}
                    icon={icon}
                    info={info}
                    navLinkClassName={classnames({
                      "bg-info": hasValue,
                      active: active && navStyle,
                    })}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      );
    },
    [isTabActive, navStyle, stacked, stackedPosition, tabHeaderComponent]
  );

  const renderContents = useCallback(
    filteredChildren =>
      filteredChildren.map(({ props: { name, children: tabContent } }) => {
        const active = isTabActive(name);
        return (
          <div
            key={`tab-content-${name}`}
            data-testid={`tab-content-${makeSafeForCSS(name)}`}
            className={classnames("content block", {
              hidden: !active,
            })}
          >
            {tabContent}
          </div>
        );
      }),
    [isTabActive]
  );

  const filteredTabs = useMemo(
    () => children?.filter(tab => !tab.props.isHidden),
    [children]
  );

  if (!filteredTabs) {
    return null;
  }

  return (
    <div className={classnames("tabs", className, { row: stacked })}>
      {or(!stacked, stacked && stackedLeft(stackedPosition)) &&
        renderTabs(filteredTabs)}
      <div className={classnames("tabs-contents", { "col-xs-8": stacked })}>
        {renderContents(filteredTabs)}
      </div>
      {stacked && not(stackedLeft(stackedPosition)) && renderTabs(filteredTabs)}
    </div>
  );
};
ComposableTabs.displayName = "Tabs";

export default ComposableTabs;
