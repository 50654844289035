import { createSelector } from "@reduxjs/toolkit";
import { path } from "ramda";

import {
  getCurrentProject,
  getCurrentProjectTypeInternalName,
} from "modules/project/selectors";
import { ONCOLOGY_PROJECT_TYPE } from "modules/projects/constants";
import {
  isOnPrem as isOnPremSelector,
  oncologyAllowThirdPartyVariantCalling,
} from "modules/systemConfig";

import {
  hasRequestFailed,
  isRequestCompleted,
  isRequestInProgress,
  isRequestSkipped,
  isRequestSuccessful,
} from "../../common/selectors";

import {
  FILE_TYPE_OPTIONS_ON_PREM,
  FILE_TYPE_OPTIONS_SAAS,
  FILE_TYPES,
  MODULE_NAME,
} from "./constants";
import { Baitsets, Entries, FORM_FILE_TYPES } from "./types";

export const uiPath = (...items: any) => path([MODULE_NAME, "ui", ...items]);

export const dataPath = (...items: any) =>
  path([MODULE_NAME, "data", ...items]);

const projectRequestStatus = uiPath("projectRequestStatus");
export const filesProcessing = uiPath("filesProcessing");

export const isProjectLoading = state =>
  isRequestInProgress(projectRequestStatus(state));

export const isProjectLoadCompleted = state =>
  isRequestCompleted(projectRequestStatus(state));

export const hasProjectRequestFailed = state =>
  hasRequestFailed(projectRequestStatus(state));

export const getIRSubmissionStatus = uiPath("irSubmissionStatus");
export const getCNVCallingRequestStatus = uiPath("cnvCallingRequestStatus");

export const isIRRequestSuccessful = state =>
  isRequestSuccessful(getIRSubmissionStatus(state));

export const isIRRequestInProgress = state =>
  isRequestInProgress(getIRSubmissionStatus(state));

export const isCNVRequestInProgress = state =>
  isRequestInProgress(getCNVCallingRequestStatus(state));

export const isCNVRequestSuccessful = state =>
  isRequestSuccessful(getCNVCallingRequestStatus(state));

export const isCNVRequestSkipped = state =>
  isRequestSkipped(getCNVCallingRequestStatus(state));

export const hasCNVRequestFailed = state =>
  hasRequestFailed(getCNVCallingRequestStatus(state));

export const getCNVCallingRequestError = uiPath("cnvCallingRequestError");

export const getProtocols = dataPath("protocols");

export const getSampleTypes = dataPath("sampleTypes");

export const getBaitsets = dataPath("baitsets");

export const getBaitsetsAsOptions = createSelector(
  getBaitsets,
  (baitsets: Baitsets = {}): Array<DropdownOption> => {
    const accumulator: Array<DropdownOption> = [];
    for (const [key, { name }] of Object.entries(
      baitsets
    ) as Entries<Baitsets>) {
      accumulator.push({ value: key, label: name });
    }
    return accumulator.sort((a, b) => a.label.localeCompare(b.label));
  }
);

const dataToOptions = data =>
  data.reduce((accumulator, { attributes: { name } }) => {
    accumulator[name] = name;
    return accumulator;
  }, {});

export const getProtocolsAsOptions = createSelector(
  getProtocols,
  (protocols = []): [] => dataToOptions(protocols)
);

export const getSampleTypesAsOptions = createSelector(
  getSampleTypes,
  (sampleTypes = []): [] => dataToOptions(sampleTypes)
);

export const getProtocolNames = createSelector(
  getProtocols,
  (protocols: Array<any> = []): Array<string> =>
    protocols.map(({ attributes: { name } }) => name)
);

// IR submission is a two step process, need to check that both calls were completed
// or the first one is complete and the second one was skipped
export const isIRFullSubmissionCompleted = state => {
  const irStatus = getIRSubmissionStatus(state);
  const cnvStatus = getCNVCallingRequestStatus(state);
  if (isOncologyProject(state)) {
    return isRequestCompleted(irStatus);
  }
  return (
    isRequestCompleted(irStatus) &&
    (isCNVRequestSkipped(state) || isRequestCompleted(cnvStatus))
  );
};

// IR submission is a two step process, successful submission means
// both IR and CNV requests succeeded or the first one succeeded and the second one was skipped
export const isIRFullSubmissionSuccessful = state => {
  if (isOncologyProject(state)) {
    return isIRRequestSuccessful(state);
  }
  return (
    isIRRequestSuccessful(state) &&
    (isCNVRequestSuccessful(state) || isCNVRequestSkipped(state))
  );
};

export const isIRFullSubmissionInProgress = state =>
  isIRRequestInProgress(state) || isCNVRequestInProgress(state);

export const isOncologyProject = createSelector(getCurrentProject, project => {
  if (!project) {
    return null;
  }
  return project.projectTypeInternalName === ONCOLOGY_PROJECT_TYPE;
});

export const getFileTypesOptions = createSelector(
  getCurrentProjectTypeInternalName,
  isOnPremSelector,
  oncologyAllowThirdPartyVariantCalling,
  (projectTypeInternalName, isOnPrem, oncologyAllowThirdPartyVariantCalling) =>
    projectTypeInternalName === ONCOLOGY_PROJECT_TYPE
      ? oncologyAllowThirdPartyVariantCalling
        ? [FILE_TYPES[FORM_FILE_TYPES.VCF], FILE_TYPES[FORM_FILE_TYPES.FASTQ]]
        : [FILE_TYPES[FORM_FILE_TYPES.FASTQ]]
      : isOnPrem
      ? FILE_TYPE_OPTIONS_ON_PREM
      : FILE_TYPE_OPTIONS_SAAS
);
