import React, { useEffect, useState } from "react";
import { Field, InjectedFormProps, reduxForm } from "redux-form";

import {
  FormGroup,
  FileInput,
  Dropdown,
  LoadingOverlay,
} from "pattern-library";

import {
  fileTypeOptions,
  UPLOAD_FORM_NAME,
} from "modules/projectGenePanels/constants";

import { useEnsemblOptions } from "hooks/useEnsemblOptions";

type UploadGenePanelFormProps = {
  onSubmit: (unknown) => void;
};

type Props = UploadGenePanelFormProps &
  InjectedFormProps<unknown, UploadGenePanelFormProps>;

const UploadGenePanelForm: React.FC<Props> = ({ handleSubmit, onSubmit }) => {
  const { ensemblOptions = [], activeVersion, isLoading } = useEnsemblOptions();
  const [ensemblVersion, setEnsemblVersion] = useState("");
  const onFormSubmit = data => {
    onSubmit({
      ...data,
      ensemblVersion,
    });
  };

  useEffect(() => {
    setEnsemblVersion(activeVersion || "");
  }, [activeVersion]);

  const ensemblSelector = (
    <div className="row">
      <div className="mb-2 col-md-6">
        <label htmlFor="uploadGenePanelVersion">
          Ensembl version to use for uploaded gene panel:
        </label>
        <Dropdown
          value={ensemblVersion}
          id="uploadGenePanelVersion"
          className="mb-0"
          options={ensemblOptions}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setEnsemblVersion(e.currentTarget.value)
          }
        />
      </div>
    </div>
  );

  const uploadForm = (
    <form id="form_upload_gene_panel" onSubmit={handleSubmit(onFormSubmit)}>
      {ensemblOptions.length > 1 ? ensemblSelector : null}
      <div className="row">
        <div className="col-md-3">
          <FormGroup>
            <label htmlFor="uploadGenePanelCsvFormat">
              Template file type:
            </label>
            <Field
              id="uploadGenePanelCsvFormat"
              name="csvFormat"
              component="select"
              className="form-control"
            >
              {fileTypeOptions.map(fileType => (
                <option key={fileType.value} value={fileType.value}>
                  {fileType.label}
                </option>
              ))}
            </Field>
          </FormGroup>
        </div>
        <div className="col-md-9">
          <FormGroup>
            <label htmlFor="uploadGenePanelFile">Gene panel file:</label>
            <Field
              id="uploadGenePanelFile"
              name="genePanelFile"
              className="form-control"
              component={FileInput}
            />
          </FormGroup>
        </div>
      </div>

      <div className="well">
        <blockquote>Download and edit a template</blockquote>
        <div className="row">
          <div className="col-xs-12 center-block">
            <img
              src="/assets/img/gene_panel_template.png"
              className="img-responsive banner-image"
              alt=""
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-6">
            <ul className="list-group">
              <li className="list-group-item">
                <strong>chr:</strong> <em>alphanumeric</em>, Chromosome where
                gene is located.
              </li>
              <li className="list-group-item">
                <strong>start:</strong> <em>numeric</em>, Gene start position.
              </li>
              <li className="list-group-item">
                <strong>stop:</strong> <em>numeric</em>, Gene end position.
              </li>
              <li className="list-group-item">
                <strong>gene:</strong> <em>alphanumeric</em>, Gene name. A
                simple gene list can be achived by leaving the other fields
                blank.
                <p className="text-danger">Note this field is mandatory.</p>
              </li>
              <li className="list-group-item">
                <strong>transcript:</strong> <em>alphanumeric</em>, Transcript
                associate with this gene.
              </li>
              <li className="list-group-item">
                <strong>autosomal_recessive:</strong> <em>numeric</em>,
                Autosomal recessive mode of inheritance (0 or 1 - If the gene
                shows an autosomal recessive mode of inheritance this value is
                coded as 1, else it is coded as 0).
              </li>
              <li className="list-group-item">
                <strong>autosomal_dominant:</strong> <em>numeric</em>, Autosomal
                dominant mode of inheritance (0 or 1 - If the gene shows an
                autosomal recessive mode of inheritance this value is coded as
                1, else it is coded as 0).
              </li>
              <li className="list-group-item">
                <strong>x_linked:</strong> <em>numeric</em>, X-linked mode of
                inheritance (0 or 1 - If the gene shows an autosomal recessive
                mode of inheritance this value is coded as 1, else it is coded
                as 0).
              </li>
              <li className="list-group-item">
                <strong>condition:</strong> <em>alphanumeric</em>, Notes on an
                associated condition.
              </li>
            </ul>
          </div>
          <div className="col-xs-6">
            <div className="btn-group-vertical">
              <a
                href="/docs/gene_panel_template.csv"
                download="template_csv.csv"
                className="btn btn-default"
              >
                Download CSV Template
              </a>
              <a
                href="/docs/gene_panel_template.tsv"
                download="template_tsv.tsv"
                className="btn btn-default"
              >
                Download TSV Template
              </a>
              <a
                href="/docs/gene_panel_template.bed"
                download="template_bed.bed"
                className="btn btn-default"
              >
                Download BED Template
              </a>
            </div>
          </div>
        </div>
      </div>

      <FormGroup>
        <input
          className="btn btn-primary"
          type="submit"
          value="Upload gene panel"
        />
      </FormGroup>
    </form>
  );

  return <LoadingOverlay loading={isLoading}>{uploadForm}</LoadingOverlay>;
};

const initialValues = {
  csvFormat: fileTypeOptions[0].value,
  genePanelFile: null,
};

export default reduxForm<unknown, UploadGenePanelFormProps>({
  form: UPLOAD_FORM_NAME,
  destroyOnUnmount: true,
  enableReinitialize: true,
  initialValues,
})(UploadGenePanelForm);
