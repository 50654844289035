import React, { useState, useCallback, memo } from "react";
import { Field, InjectedFormProps, reduxForm } from "redux-form";

import { Button, LoadingOverlay } from "pattern-library";

import { UPLOAD_GENE_PANEL_INFO_FORM_NAME } from "../../../../constants";

import legacyApi from "api/legacy-api";

const { useGetTempGenePanelQuery, useUpdateTempGenePanelInfoMutation } =
  legacyApi;

interface Props {
  projectId: number;
  tempGenePanelId: number;
}

const NameAndDescriptionForm = memo(
  ({
    handleSubmit,
    projectId,
    tempGenePanelId,
  }: Props & InjectedFormProps<Index, Props>) => {
    const [editing, setEditing] = useState(false);

    const { data: tempGenePanel, isLoading } = useGetTempGenePanelQuery({
      projectId,
      tempGenePanelId,
    });

    const [updateTempGenePanel, { isLoading: isSubmitting }] =
      useUpdateTempGenePanelInfoMutation();

    const onFormSubmit = useCallback(
      ({ title, description }: ProjectGenePanelBase) => {
        updateTempGenePanel({
          projectId,
          tempGenePanelId,
          data: { title, description },
        });
        setEditing(false);
      },
      [updateTempGenePanel, projectId, tempGenePanelId, setEditing]
    );

    if (!tempGenePanel) return null;

    return (
      <LoadingOverlay loading={isLoading || isSubmitting}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <div id="info">
            <table className="table table-striped table-hover">
              <tbody>
                <tr>
                  <td className="col-md-6">Gene panel</td>
                  <td className="col-md-6">
                    {editing ? (
                      <Field
                        name="title"
                        className="form-control"
                        type="text"
                        component="input"
                        value={tempGenePanel.title}
                      />
                    ) : (
                      tempGenePanel.title
                    )}
                  </td>
                </tr>

                <tr>
                  <td className="col-md-6">Description</td>
                  <td className="col-md-6">
                    {editing ? (
                      <Field
                        name="description"
                        className="form-control"
                        component="textarea"
                        value={tempGenePanel.description}
                      />
                    ) : (
                      tempGenePanel.description
                    )}
                  </td>
                </tr>
                {!editing && tempGenePanel.ensemblVersion && (
                  <tr>
                    <td className="col-md-6">Ensembl version</td>
                    <td className="col-md-6">{tempGenePanel.ensemblVersion}</td>
                  </tr>
                )}
              </tbody>
            </table>
            {editing ? (
              <div className="pull-right">
                <Button
                  type="button"
                  onClick={() => {
                    setEditing(false);
                  }}
                >
                  Cancel
                </Button>
                <Button context="primary" type="submit">
                  Save panel
                </Button>
              </div>
            ) : (
              <div className="pull-right">
                <Button
                  context="primary"
                  type="button"
                  onClick={() => {
                    setEditing(true);
                  }}
                >
                  Edit panel
                </Button>
              </div>
            )}
            <div className="clearfix" />
          </div>
        </form>
      </LoadingOverlay>
    );
  }
);

export default reduxForm<Index, Props>({
  form: UPLOAD_GENE_PANEL_INFO_FORM_NAME,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(NameAndDescriptionForm);
