import React, { PropsWithChildren } from "react";

import { TabHeaderProps } from "./DefaultTabHeader";

type Props = TabHeaderProps & {
  /**
   * whether the tab should be hidden
   */
  isHidden: boolean;
};

/**
 * An abstract wrapper used to describe tabs in ComposableTabs component and is not meant to be rendered.
 * @return {null}
 */
const Tab: React.FC<PropsWithChildren<Props>> = () => null;

export default Tab;
