import { set } from "dot-prop-immutable";

import {
  SET_IR_SUBMISSION_STATUS,
  RESET_IR_SUBMISSION_STATUS,
  SET_PROJECT_REQUEST_STATUS,
  SET_BAITSETS,
} from "modules/oncologyInterpretationRequests/constants";
import type { Action } from "modules/oncologyInterpretationRequests/redux/actions";
import type { State } from "modules/oncologyInterpretationRequests/types";

export const initialState: State = {
  ui: {
    irSubmissionStatus: null,
    projectRequestStatus: null,
  },
  data: {
    baitsets: [],
  },
};

export default function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case SET_PROJECT_REQUEST_STATUS:
      return set(state, "ui.projectRequestStatus", action.payload);
    case SET_IR_SUBMISSION_STATUS:
      return set(state, "ui.irSubmissionStatus", action.payload);
    case RESET_IR_SUBMISSION_STATUS:
      return set(state, "ui.irSubmissionStatus", null);
    case SET_BAITSETS:
      return set(state, "data.baitsets", action.payload);
    default:
      return state;
  }
}
