import React from "react";

import { OncologyExplore } from "modules/oncologyExplore";
import OncologyPatientOverview from "modules/oncologyPatientOverview/OncologyPatientOverview";
import { OncologyReport } from "modules/oncologyReport";
import RDPatientAudit from "modules/patient/components/audit/rare-disease/PatientAudit";
import { Pedigree } from "modules/pedigree/Pedigree";
import QCContainer from "modules/qc/Container";
import { RefNoCall } from "modules/refNoCall";

import VariantsLayout from "../VariantsLayout";

function getPatientTabset({
  patientId,
  projectId,
  tabs = {},
  customIframe = {},
}) {
  return [
    {
      name: customIframe.label || "Custom iFrame",
      isHidden: !tabs.customIframe,
      component: ({ iframeUrl }) => <div>{iframeUrl}</div>,
      componentProps: {
        iframeUrl: customIframe.tabUrl,
      },
      url: "/custom-iframe",
      className: "pull-left",
    },
    {
      name: "Overview",
      component: () => (
        <div>
          <div className="alert alert-secondary">
            <h3>
              <strong>Work in progress!</strong>
            </h3>
            <p>
              This is a placeholder/page that is currently displaying the
              Oncology overview, for local development only!
            </p>
          </div>
          <OncologyPatientOverview patientId={patientId} />
        </div>
      ),
      componentProps: {
        patientId,
      },
      url: "/overview",
      className: "pull-left",
    },
    {
      name: "Family",
      isHidden: !tabs.family,
      url: "/family",
      className: "pull-left",
      component: () => <Pedigree patientId={patientId} />,
      componentProps: {
        patientId,
      },
    },
    {
      name: "HPO terms",
      url: "/phenotype",
      className: "pull-left",
    },
    {
      name: "Supporting information",
      url: "/files",
      className: "pull-left",
    },
    {
      name: "Reports",
      isHidden: !tabs.reports,
      url: "/reports",
      className: "pull-right",
    },
    {
      name: "Explore",
      isHidden: !tabs.oncologyExplore,
      url: "/oncology-explore",
      className: "pull-right",
      exact: false,
      component: OncologyExplore,
      componentProps: {
        patientId,
        projectId,
      },
    },
    {
      name: "Report",
      isHidden: !tabs.oncologyReport,
      url: "/oncology-report",
      className: "pull-right",
      component: OncologyReport,
      componentProps: {
        patientId,
        projectId,
      },
    },
    {
      name: "Audit",
      isHidden: !tabs.audit,
      url: "/audit",
      className: "pull-right",
      component: RDPatientAudit,
      componentProps: {
        patientId,
      },
    },
    {
      name: "Variants",
      isHidden: !tabs.variants,
      url: "/variants",
      className: "pull-right",
      exact: false,
      component: VariantsLayout,
      componentProps: {
        patientId,
        projectId,
      },
    },
    {
      name: "QC",
      isHidden: !tabs.qc && !tabs.oncologyQc,
      url: "/qc",
      component: QCContainer,
      className: "pull-right",
      componentProps: {
        patientId,
        projectId,
      },
    },
    {
      name: "Genes coverage (to do)",
      isHidden: !tabs.genesCoverage,
      url: "/genes-coverage",
      className: "pull-right",
    },
    {
      name: "Genotype (todo)",
      isHidden: !tabs.genotype,
      url: "/genotype",
      className: "pull-right",
      component: RefNoCall,
      componentProps: {
        patientId,
        projectId,
      },
    },
  ];
}

export default getPatientTabset;
