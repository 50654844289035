import React, { memo, useCallback, useEffect, useState } from "react";
import { Column } from "react-table-7";

import { DEFAULT_PAGE_SIZE, Table } from "pattern-library/elements/table7";

import { lastLoginProcessor } from "./columns";

type Props = Record<string, any> & {
  isLoading: boolean;
  items: User[];
  columns: Column[];
  title: string;
};

const identity = val => val;
const propertyMap = {
  lastlogin: lastLoginProcessor,
  roles: roles =>
    roles && roles.map(({ label }) => label.toUpperCase()).join(", "),
  admin: admin => (Boolean(admin) ? "Administrator" : ""),
};

const filter = (
  data: Array<ProjectUser> = [],
  filterValue: string
): Array<ProjectUser> =>
  data.filter(user => {
    for (const [key, value] of Object.entries(user)) {
      const valueMapper = propertyMap[key] || identity;
      const mappedValue: any = valueMapper(value);
      if (
        mappedValue &&
        mappedValue.toString().toUpperCase().includes(filterValue.toUpperCase())
      ) {
        return true;
      }
    }
    return false;
  });

export const UsersTable: React.FC<Props> = memo(
  ({
    items = [],
    columns = [],
    isLoading = false,
    title,
    ...otherTableProps
  }) => {
    const [filterValue, setFilterValue] = useState<string>("");
    const [filteredItems, setFilteredItems] = useState<ProjectUser[]>([]);

    useEffect(() => {
      setFilteredItems(filter(items, filterValue));
    }, [items, filterValue, setFilteredItems]);

    const onFilterChange = useCallback(
      ({ filter = "" }) => {
        setFilterValue(filter);
      },
      [setFilterValue]
    );

    return (
      <Table
        showTitleInfo
        title={title}
        fetchData={onFilterChange}
        enableFilter
        columns={columns}
        data={filteredItems}
        showPagination={filteredItems.length > DEFAULT_PAGE_SIZE}
        autoResetPage={false}
        loading={isLoading}
        sortBy={[
          {
            id: "fullName",
            desc: false,
          },
        ]}
        {...otherTableProps}
      />
    );
  }
);
