import { buildDataTableQuery } from "modules/utils/fetchData";

import { getArrayAsQueryParam } from "./utils/common";
import { prepareCatalystUsers } from "./utils/users";

import baseApi from "./index";

const baseUrl = "/catalyst/api";

const catalystApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getProjectUsers: builder.query<
      {
        projectUsers: ProjectUser[];
        inheritedUsers: ProjectUser[];
      },
      ProjectDependant
    >({
      query: ({ projectId }) => ({
        url: `${baseUrl}/project/${projectId}/users`,
      }),
      transformResponse: ({ inherited_users, project_users }) => ({
        inheritedUsers: prepareCatalystUsers(inherited_users),
        projectUsers: prepareCatalystUsers(project_users),
      }),
    }),

    getJurisdictions: builder.query<Array<Jurisdiction>, ProjectDependant>({
      query: ({ projectId }) => ({
        url: `${baseUrl}/project/${projectId}/oncology/jurisdictions`,
      }),
      transformResponse: (response: { jurisdictions }) =>
        response.jurisdictions,
      extraOptions: {
        camelize: true,
      },
    }),

    getSignificantStatusChange: builder.query<
      SignificantStatusChange,
      PatientDependant
    >({
      query: ({ patientId }) => ({
        url: `${baseUrl}/patient/${patientId}/significant_status_change`,
      }),
      providesTags: _ => ["PatientStatus"],
    }),

    getPatientAndTabs: builder.query<
      { patient: Patient; tabs: Record<string, boolean> },
      PatientDependant
    >({
      query: ({ patientId }) => ({
        url: `${baseUrl}/patient/${patientId}`,
      }),
      providesTags: _ => ["PatientStatus"],
      extraOptions: {
        camelize: true,
      },
    }),

    getProject: builder.query<Project, ProjectDependant>({
      query: ({ projectId }) => ({
        url: `${baseUrl}/project/${projectId}`,
      }),
      transformResponse: (response: { project }) => response.project,
      extraOptions: {
        camelize: true,
      },
    }),

    getProjectCurrentUser: builder.query<any, ProjectDependant>({
      query: ({ projectId }) => ({
        url: `${baseUrl}/project/${projectId}/current_user`,
      }),
      extraOptions: {
        camelize: true,
      },
    }),

    getAvailableTemplatesForProject: builder.query<
      ReportTemplate[],
      { projectId: number; params?: Partial<TableRequestParams> }
    >({
      query: ({ projectId, params }) => ({
        url: `${baseUrl}/project/${projectId}/report-templates/available`,
        params: buildDataTableQuery(params),
      }),
      transformResponse: (response: { data }) => response.data,
      extraOptions: {
        camelize: true,
      },
    }),

    getPatientGenePanels: builder.query<
      Array<PatientGenePanel>,
      PatientDependant
    >({
      query: ({ patientId }) => ({
        url: `${baseUrl}/patient/${patientId}/project/gene-panels`,
      }),
      transformResponse: (response: { genePanels }) =>
        response.genePanels.map(panel => ({
          ...panel,
          isPatientGenePanel: Boolean(panel.isPatientGenePanel),
          archived: Boolean(panel.archived),
          genePanelId: Number(panel.genePanelId),
        })),
      extraOptions: {
        camelize: true,
      },
    }),

    getGene: builder.query<
      GeneSearchResult | undefined,
      { geneSymbol: string; ensemblVersion: string }
    >({
      query: ({ geneSymbol, ensemblVersion }) => ({
        url: `${baseUrl}/gene/search`,
        params: {
          search: geneSymbol,
          ensembl_version: ensemblVersion,
        },
      }),
      transformResponse: ({ genes }, _, { geneSymbol }) =>
        (genes as GeneSearchResult[]).find(
          gene => gene.name.toLowerCase() === geneSymbol.toLowerCase()
        ),
    }),

    getGenePanelsWithGenes: builder.query<
      GenePanelWithGeneResponse,
      PatientDependant & { genePanelIds: Array<string> }
    >({
      query: ({ patientId, genePanelIds }) => ({
        url: `${baseUrl}/patient/${patientId}/gene_panel_with_genes`,
        params: getArrayAsQueryParam(genePanelIds, "gene_panel_id"),
      }),
      extraOptions: {
        camelize: true,
      },
    }),
  }),
});

export default catalystApi;
