import { FC, useMemo } from "react";

import { Icon, Link } from "pattern-library";

import { RNA_ASSAY_QC_FIELDS_CONFIG } from "modules/oncologyQC/constants";
import RnaAssayQcTables from "modules/oncologyQC/rna/RnaAssayQcTables";
import { isOncologyQcDataOutOfRange } from "modules/oncologyQC/utils";

import { OncologyRnaQcData } from "types/oncologyQc";

interface Props {
  rnaQcData: OncologyRnaQcData;
}

const RnaQc: FC<Props> = ({ rnaQcData }) => {
  const showWarning = useMemo<boolean>(
    () =>
      Boolean(
        isOncologyQcDataOutOfRange(
          rnaQcData,
          Object.values(RNA_ASSAY_QC_FIELDS_CONFIG).flatMap(
            ({ fields }) => fields
          )
        )
      ),
    [rnaQcData]
  );

  return (
    <div className="qc__oncology container">
      <h1>Assay QC</h1>
      <div className="row mt-4">
        <RnaAssayQcTables rnaQcData={rnaQcData} />
        {showWarning && (
          <div className="col-md-6 mt-2 pull-right">
            <div
              data-testid="qc__oncology__json_warning"
              className="qc__oncology__json_warning"
            >
              <Icon
                className="qc__oncology__json_warning_icon"
                type="warning"
              />
              QC metrics are outside recommended range. See the
              <Link
                className="qc__oncology__json_table__warning__link"
                target="_blank"
                // TODO: maybe the link should change?
                href="/user-guide/oncology/row/en-us/Default.htm#Oncology/5%20Reviewing%20patient%20data/Reviewing%20oncology%20QC%20data.htm%3FTocPath%3DReviewing%2520patient%2520data%7C_____2"
              >
                user guide
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RnaQc;
