import { set } from "dot-prop-immutable";
import React, { memo, useCallback, useMemo, useState } from "react";

import { Button, Icon } from "pattern-library";
import { DEFAULT_PAGE_SIZE, Table } from "pattern-library/elements/table7";

import legacyApi from "api/legacy-api";

const { useAddGeneToGenePanelMutation } = legacyApi;

const highlightSearchResult = (text, query) => {
  if (!text || !text.replaceAll) return null;
  return (
    <span
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: text.replaceAll(
          query.toUpperCase(),
          `<strong>${query.toUpperCase()}</strong>`
        ),
      }}
    />
  );
};

interface TableFieldOwnProps {
  geneName: string;
  fieldName: string;
  searchTableFields?: GeneFields;
  setSearchTableFields: (fields: GeneFields) => void;
}

const TableField = ({
  geneName,
  fieldName,
  searchTableFields = {},
  setSearchTableFields,
}: TableFieldOwnProps) => {
  const handleChange = useCallback(
    e => {
      setSearchTableFields(
        set(searchTableFields, `${geneName}.${fieldName}`, e.target.checked)
      );
    },
    [searchTableFields, setSearchTableFields, geneName, fieldName]
  );
  return (
    <div style={{ textAlign: "center" }}>
      <input
        type="checkbox"
        checked={!!searchTableFields[geneName]?.[fieldName]}
        onChange={handleChange}
      />
    </div>
  );
};
interface AddGeneButtonOwnProps {
  geneName: string;
  projectId: number;
  genePanelId: number;
  fields?: GeneFields;
}
const AddGeneButton = ({
  projectId,
  genePanelId,
  geneName,
  fields = {},
}: AddGeneButtonOwnProps) => {
  const [submitting, setSubmitting] = useState(false);

  const [addGeneToGenePanel] = useAddGeneToGenePanelMutation();

  const handleClick = useCallback(() => {
    addGeneToGenePanel({ projectId, genePanelId, geneName, fields });
    setSubmitting(true);
  }, [projectId, genePanelId, geneName, fields, addGeneToGenePanel]);

  return (
    <Button
      className="btn-xs"
      context={submitting ? "danger" : "primary"}
      disabled={submitting}
      onClick={handleClick}
    >
      {submitting ? (
        "Adding gene..."
      ) : (
        <>
          {" "}
          <Icon type="plus" /> Add gene
        </>
      )}
    </Button>
  );
};

interface SearchGenesTableProps {
  projectId: number;
  genePanelId: number;
  isGenesSearchInProgress: boolean;
  genes: Array<InPanelGene>;
  query: string;
}
const SearchGenesTable = memo(
  ({
    genes,
    query,
    projectId,
    genePanelId,
    isGenesSearchInProgress,
  }: SearchGenesTableProps) => {
    const [searchTableFields, setSearchTableFields] = useState<GeneFields>();

    const columns = useMemo(
      () => [
        {
          Header: "Name",
          accessor: "name",
          id: "name",
          headerStyle: { width: "81px" },
          Cell: ({ row: { original } }) => {
            const { name } = original;
            return highlightSearchResult(name || "", query);
          },
        },
        {
          Header: "Old",
          accessor: "oldName",
          headerStyle: { width: "108px" },
          Cell: ({ row: { original } }) => {
            const { oldName } = original;
            return highlightSearchResult(oldName || "", query);
          },
        },
        {
          Header: "Synonyms",
          accessor: "synonyms",
          Cell: ({ row: { original } }) => {
            const { synonyms } = original;
            return highlightSearchResult(synonyms || "", query);
          },
        },
        {
          Header: "Autosomal Recessive",
          id: "autosomalRecessive",
          headerStyle: { width: "71px" },
          Cell: ({ row: { original } }) => {
            const { name, autosomalRecessive, inPanel } = original;
            if (inPanel) {
              return (
                <div style={{ textAlign: "center" }}>
                  {autosomalRecessive ? <Icon type="tick" /> : null}
                </div>
              );
            }
            return (
              <TableField
                geneName={name}
                fieldName="autosomal_recessive"
                searchTableFields={searchTableFields}
                setSearchTableFields={setSearchTableFields}
              />
            );
          },
        },
        {
          Header: "Autosomal Dominant",
          id: "autosomalDominant",
          headerStyle: { width: "71px" },
          Cell: ({ row: { original } }) => {
            const { name, autosomalDominant, inPanel } = original;
            if (inPanel) {
              return (
                <div style={{ textAlign: "center" }}>
                  {autosomalDominant ? <Icon type="tick" /> : null}
                </div>
              );
            }
            return (
              <TableField
                geneName={name}
                fieldName="autosomal_dominant"
                searchTableFields={searchTableFields}
                setSearchTableFields={setSearchTableFields}
              />
            );
          },
        },
        {
          Header: "X Linked",
          id: "xLinked",
          headerStyle: { width: "71px" },
          Cell: ({ row: { original } }) => {
            const { name, inPanel, xLinked } = original;
            if (inPanel) {
              return (
                <div style={{ textAlign: "center" }}>
                  {xLinked ? <Icon type="tick" /> : null}
                </div>
              );
            }
            return (
              <TableField
                geneName={name}
                fieldName="x_linked"
                searchTableFields={searchTableFields}
                setSearchTableFields={setSearchTableFields}
              />
            );
          },
        },
        {
          Header: "Actions",
          id: "actions",
          headerStyle: { width: "79px" },
          Cell: ({ row: { original } }) => {
            const { name, inPanel } = original;
            if (inPanel) {
              return (
                <div style={{ textAlign: "center" }}>
                  <Icon type="tick" /> In Panel
                </div>
              );
            }

            return (
              <AddGeneButton
                projectId={projectId}
                genePanelId={genePanelId}
                geneName={name}
                fields={searchTableFields?.[name]}
              />
            );
          },
        },
      ],
      [query, projectId, genePanelId, searchTableFields, setSearchTableFields]
    );

    return (
      <div>
        <Table
          showTitleInfo
          title={`Gene match [${query}]`}
          columns={columns}
          data={genes}
          showPagination={genes.length > DEFAULT_PAGE_SIZE}
          autoResetPage={false}
          loading={isGenesSearchInProgress}
          sortBy={[
            {
              id: "name",
              desc: false,
            },
          ]}
        />
      </div>
    );
  }
);

export default memo(SearchGenesTable);
