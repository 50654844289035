import { asyncActionCreatorGenerator } from "modules/utils/async";

import { createAction } from "../utils";

import * as constants from "./constants";

/////////////////
// Get Patient //
/////////////////
export type ReadPatientAction = FSA<
  typeof constants.READ_PATIENT,
  number | string
>;

export const readPatient = (patientId: number | string): ReadPatientAction =>
  createAction(constants.READ_PATIENT, patientId);

/////////////////////////
// Get Patient Success //
/////////////////////////
export type ReadPatientSuccessAction = FSA<
  typeof constants.READ_PATIENT_SUCCESS,
  any
>;

export const readPatientSuccess = (
  patient: Patient
): ReadPatientSuccessAction =>
  createAction(constants.READ_PATIENT_SUCCESS, patient);

/////////////////////////
// Get Patient Failure //
/////////////////////////
export type ReadPatientFailureAction = FSA<
  typeof constants.READ_PATIENT_FAILURE,
  any
>;

export const readPatientFailure = (): ReadPatientFailureAction =>
  createAction(constants.READ_PATIENT_FAILURE);

///////////////////
// Tabs & iFrame //
///////////////////
export type SetPatientTabsAction = FSA<typeof constants.SET_PATIENT_TABS, any>;

export const setPatientTabs = (tabs: TabConfig): SetPatientTabsAction =>
  createAction(constants.SET_PATIENT_TABS, tabs);

export type SetPatientCustomIframeConfigAction = FSA<
  typeof constants.SET_CUSTOM_IFRAME_CONFIG,
  any
>;

export const setCustomIframeConfig = (
  config: CustomIframeConfig
): SetPatientCustomIframeConfigAction =>
  createAction(constants.SET_CUSTOM_IFRAME_CONFIG, config);

export const reloadPatientReports: AsyncActionCreatorEx =
  asyncActionCreatorGenerator<number, Array<any>>(
    constants.RELOAD_PATIENT_REPORTS
  );

export type Action =
  | ReadPatientAction
  | ReadPatientSuccessAction
  | ReadPatientFailureAction
  | SetPatientTabsAction
  | ReturnType<typeof reloadPatientReports.start>
  | ReturnType<typeof reloadPatientReports.success>
  | ReturnType<typeof reloadPatientReports.failure>;
