import React from "react";

interface Props {
  projectId: number | string;
  createNewIR: () => void;
}

const IRSubmittedToolbar: React.FC<Props> = ({ projectId, createNewIR }) => (
  <div className="alert fade in alert-success success-toolbar">
    <a className="btn btn-info btn-md" href={`/projects/${projectId}/patients`}>
      Back to Project
    </a>
    <button
      type="button"
      className="btn btn-success btn-md"
      onClick={createNewIR}
    >
      Create New IR
    </button>
  </div>
);

export default IRSubmittedToolbar;
