import { Field } from "formik";
import React from "react";

import FormikFormField from "modules/forms/components/formikFormField/FormikFormField";
import {
  IR_FORM_FIELD_KEYS,
  IR_FORM_FIELDS_LABELS,
} from "modules/oncologyInterpretationRequests/constants";
import { getStandardTextFieldPlaceholder } from "modules/oncologyInterpretationRequests/utils";

interface Props {
  disabled: boolean;
}

const getDateFieldPlaceholder = (fieldLabel: string) =>
  `${getStandardTextFieldPlaceholder(fieldLabel)} as dd/mm/yyyy`;

const IRMetadataFormFields = ({ disabled }: Props) => (
  <>
    <h3>Patient information (optional)</h3>
    <div className="row mt-3">
      <div className="col-md-6">
        <Field
          component={FormikFormField}
          name={IR_FORM_FIELD_KEYS.patientName}
          id={IR_FORM_FIELD_KEYS.patientName}
          disabled={disabled}
          type="text"
          trimOnBlur
          placeholder={getStandardTextFieldPlaceholder(
            IR_FORM_FIELDS_LABELS.patientName
          )}
          label={IR_FORM_FIELDS_LABELS.patientName}
          narrow
          data-test-id={IR_FORM_FIELD_KEYS.patientName}
        />
      </div>
      <div className="col-md-6">
        <Field
          component={FormikFormField}
          name={IR_FORM_FIELD_KEYS.dateOfBirth}
          id={IR_FORM_FIELD_KEYS.dateOfBirth}
          disabled={disabled}
          // TODO: need to use date instead, it (Datepicker) needs to be extended to allow supplying format that is acceptable
          //  and then parse that format to what is expected to be supplied as metadata
          type="text"
          trimOnBlur
          placeholder={getDateFieldPlaceholder(
            IR_FORM_FIELDS_LABELS.dateOfBirth
          )}
          label={IR_FORM_FIELDS_LABELS.dateOfBirth}
          narrow
          data-test-id={IR_FORM_FIELD_KEYS.dateOfBirth}
        />
      </div>
    </div>

    <div className="row">
      <div className="col-md-6">
        <Field
          name={IR_FORM_FIELD_KEYS.nhsNumber}
          id={IR_FORM_FIELD_KEYS.nhsNumber}
          component={FormikFormField}
          // TODO: make it a number?
          type="text"
          trimOnBlur
          disabled={disabled}
          placeholder={getStandardTextFieldPlaceholder(
            IR_FORM_FIELDS_LABELS.nhsNumber
          )}
          label={IR_FORM_FIELDS_LABELS.nhsNumber}
          narrow
          data-test-id={IR_FORM_FIELD_KEYS.nhsNumber}
        />
      </div>
      <div className="col-md-6">
        <Field
          component={FormikFormField}
          name={IR_FORM_FIELD_KEYS.treatingClinician}
          id={IR_FORM_FIELD_KEYS.treatingClinician}
          disabled={disabled}
          type="text"
          trimOnBlur
          placeholder={getStandardTextFieldPlaceholder(
            IR_FORM_FIELDS_LABELS.treatingClinician
          )}
          label={IR_FORM_FIELDS_LABELS.treatingClinician}
          narrow
          data-test-id={IR_FORM_FIELD_KEYS.treatingClinician}
        />
      </div>
    </div>

    <div className="row">
      <div className="col-md-6">
        <Field
          component={FormikFormField}
          name={IR_FORM_FIELD_KEYS.testOrderedBy}
          id={IR_FORM_FIELD_KEYS.testOrderedBy}
          disabled={disabled}
          type="text"
          trimOnBlur
          placeholder={getStandardTextFieldPlaceholder(
            IR_FORM_FIELDS_LABELS.testOrderedBy
          )}
          label={IR_FORM_FIELDS_LABELS.testOrderedBy}
          narrow
          data-test-id={IR_FORM_FIELD_KEYS.testOrderedBy}
        />
      </div>
      <div className="col-md-6">
        <Field
          component={FormikFormField}
          name={IR_FORM_FIELD_KEYS.testRequestDate}
          id={IR_FORM_FIELD_KEYS.testRequestDate}
          disabled={disabled}
          // TODO: need to use date instead, it (Datepicker) needs to be extended to allow supplying format that is acceptable
          //  and then parse that format to what is expected to be supplied as metadata
          type="text"
          trimOnBlur
          placeholder={getDateFieldPlaceholder(
            IR_FORM_FIELDS_LABELS.testRequestDate
          )}
          label={IR_FORM_FIELDS_LABELS.testRequestDate}
          narrow
          data-test-id={IR_FORM_FIELD_KEYS.testRequestDate}
        />
      </div>
    </div>

    <div className="row">
      <div className="col-md-6">
        <Field
          component={FormikFormField}
          name={IR_FORM_FIELD_KEYS.biopsyDateTime}
          id={IR_FORM_FIELD_KEYS.biopsyDateTime}
          disabled={disabled}
          // TODO: should be date & time but we don't have a component for that now
          //  and it would take more time than we would want to extend (Datepicker react-datepicker used has options)
          // TODO: need to use datetime instead, it (Datepicker) needs to be extended to allow supplying format that is acceptable
          //  and then parse that format to what is expected to be supplied as metadata
          type="text"
          trimOnBlur
          placeholder={getDateFieldPlaceholder(
            IR_FORM_FIELDS_LABELS.biopsyDateTime
          )}
          label={IR_FORM_FIELDS_LABELS.biopsyDateTime}
          narrow
          data-test-id={IR_FORM_FIELD_KEYS.biopsyDateTime}
        />
      </div>
      <div className="col-md-6">
        <Field
          component={FormikFormField}
          name={IR_FORM_FIELD_KEYS.biopsySiteHospitalNumber}
          id={IR_FORM_FIELD_KEYS.biopsySiteHospitalNumber}
          disabled={disabled}
          type="text"
          trimOnBlur
          placeholder={getStandardTextFieldPlaceholder(
            IR_FORM_FIELDS_LABELS.biopsySiteHospitalNumber
          )}
          label={IR_FORM_FIELDS_LABELS.biopsySiteHospitalNumber}
          narrow
          data-test-id={IR_FORM_FIELD_KEYS.biopsySiteHospitalNumber}
        />
      </div>
    </div>

    <div className="row">
      <div className="col-md-6">
        <Field
          component={FormikFormField}
          name={IR_FORM_FIELD_KEYS.pastCurrentTumourTreatment}
          id={IR_FORM_FIELD_KEYS.pastCurrentTumourTreatment}
          disabled={disabled}
          type="textarea"
          trimOnBlur
          placeholder={getStandardTextFieldPlaceholder(
            IR_FORM_FIELDS_LABELS.pastCurrentTumourTreatment
          )}
          label={IR_FORM_FIELDS_LABELS.pastCurrentTumourTreatment}
          narrow
          data-test-id={IR_FORM_FIELD_KEYS.pastCurrentTumourTreatment}
        />
      </div>
      <div className="col-md-6">
        <Field
          component={FormikFormField}
          name={IR_FORM_FIELD_KEYS.caseIdPathology}
          id={IR_FORM_FIELD_KEYS.caseIdPathology}
          disabled={disabled}
          type="text"
          trimOnBlur
          placeholder={getStandardTextFieldPlaceholder(
            IR_FORM_FIELDS_LABELS.caseIdPathology
          )}
          label={IR_FORM_FIELDS_LABELS.caseIdPathology}
          narrow
          data-test-id={IR_FORM_FIELD_KEYS.caseIdPathology}
        />
      </div>
    </div>

    <div className="row">
      <div className="col-md-6">
        <Field
          component={FormikFormField}
          name={IR_FORM_FIELD_KEYS.caseIdMolecularDx}
          id={IR_FORM_FIELD_KEYS.caseIdMolecularDx}
          disabled={disabled}
          type="text"
          trimOnBlur
          placeholder={getStandardTextFieldPlaceholder(
            IR_FORM_FIELDS_LABELS.caseIdMolecularDx
          )}
          label={IR_FORM_FIELDS_LABELS.caseIdMolecularDx}
          narrow
          data-test-id={IR_FORM_FIELD_KEYS.caseIdMolecularDx}
        />
      </div>
      <div className="col-md-6">
        <Field
          component={FormikFormField}
          name={IR_FORM_FIELD_KEYS.specimenId}
          id={IR_FORM_FIELD_KEYS.specimenId}
          disabled={disabled}
          type="text"
          trimOnBlur
          placeholder={getStandardTextFieldPlaceholder(
            IR_FORM_FIELDS_LABELS.specimenId
          )}
          label={IR_FORM_FIELDS_LABELS.specimenId}
          narrow
          data-test-id={IR_FORM_FIELD_KEYS.specimenId}
        />
      </div>
    </div>

    <div className="row">
      <div className="col-md-6">
        <Field
          component={FormikFormField}
          name={IR_FORM_FIELD_KEYS.specimenType}
          id={IR_FORM_FIELD_KEYS.specimenType}
          disabled={disabled}
          type="text"
          trimOnBlur
          placeholder={getStandardTextFieldPlaceholder(
            IR_FORM_FIELDS_LABELS.specimenType
          )}
          label={IR_FORM_FIELDS_LABELS.specimenType}
          narrow
          data-test-id={IR_FORM_FIELD_KEYS.specimenType}
        />
      </div>
      <div className="col-md-6">
        <Field
          component={FormikFormField}
          name={IR_FORM_FIELD_KEYS.pathologyBlockNumber}
          id={IR_FORM_FIELD_KEYS.pathologyBlockNumber}
          disabled={disabled}
          type="text"
          trimOnBlur
          placeholder={getStandardTextFieldPlaceholder(
            IR_FORM_FIELDS_LABELS.pathologyBlockNumber
          )}
          label={IR_FORM_FIELDS_LABELS.pathologyBlockNumber}
          narrow
          data-test-id={IR_FORM_FIELD_KEYS.pathologyBlockNumber}
        />
      </div>
    </div>

    <div className="row">
      <div className="col-md-6">
        <Field
          component={FormikFormField}
          name={IR_FORM_FIELD_KEYS.requestedMolecularTest}
          id={IR_FORM_FIELD_KEYS.requestedMolecularTest}
          disabled={disabled}
          type="text"
          trimOnBlur
          placeholder={`${getStandardTextFieldPlaceholder(
            IR_FORM_FIELDS_LABELS.requestedMolecularTest
          )} (DNA / RNA)`}
          label={IR_FORM_FIELDS_LABELS.requestedMolecularTest}
          narrow
          data-test-id={IR_FORM_FIELD_KEYS.requestedMolecularTest}
        />
      </div>
    </div>
  </>
);

export default IRMetadataFormFields;
