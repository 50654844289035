import { formatDate } from "modules/utils";

const checkKeyContainsValue = (
  panelValue: any,
  filterValue: string,
  replaceUnderscores: boolean
) =>
  panelValue
    .toString()
    .toUpperCase()
    .replace(replaceUnderscores ? "_" : " ", " ")
    .includes(filterValue.toUpperCase());

export const filterGenePanels = (
  data: (ProjectGenePanel | CopyProjectGenePanel)[] = [],
  filterValue: string,
  ensemblVersion: string | "ALL"
) =>
  data.filter(genePanel => {
    if (
      ensemblVersion &&
      ensemblVersion !== "ALL" &&
      genePanel.ensemblVersion !== ensemblVersion
    )
      return false;
    for (const key of Object.keys(genePanel)) {
      const dateContainsValue =
        key === "updated" &&
        "updated" in genePanel &&
        formatDate(genePanel.updated * 1000).includes(filterValue);

      const panelSourceContainsValue =
        key === "panelSource" &&
        "panelSource" in genePanel &&
        checkKeyContainsValue(genePanel.panelSource, filterValue, true);

      const otherKeyContainsValue =
        genePanel[key] &&
        checkKeyContainsValue(genePanel[key], filterValue, false);

      const keyContainsValue =
        dateContainsValue || panelSourceContainsValue || otherKeyContainsValue;

      if (keyContainsValue) {
        return true;
      }
    }
    return false;
  });

export const transformFieldsOptions = (fields?: GeneFields) => {
  if (!fields) {
    return fields;
  }
  const updatedFields = {
    ...fields,
  };
  Object.keys(fields).forEach(fieldKey => {
    if (fields[fieldKey].options) {
      updatedFields[fieldKey] = {
        ...updatedFields[fieldKey],
        options: fields[fieldKey].options.map(option => ({
          value: option.value,
          label: option.name,
        })),
      };
    }
  });

  return updatedFields;
};

export const getEditGeneFormValues = (gene, columns, fields) => {
  if (!gene || !columns || !fields) return undefined;
  const values = {};
  columns.forEach(column => {
    const field = fields[column];
    if (field && field.input && field.optional !== "No") {
      values[column] = gene[column] !== null ? gene[column] : false;
    }
  });
  return values;
};

export const getGenePanelPatientsCombined = (
  projectPatients?: Array<GenePanelPatient>,
  panelPatients?: GenePanelPatientsCounts
) => {
  if (!projectPatients || !panelPatients) return [];

  return projectPatients.map(patient => ({
    ...patient,
    isSelected: !!panelPatients[patient.patientId],
  }));
};

export const isAllGenePanelPatientsSelected = (
  projectPatients?: Array<GenePanelPatient>,
  panelPatients?: GenePanelPatientsCounts
): boolean => {
  if (!projectPatients || !panelPatients) return true;

  const selectedPatientsCount = projectPatients
    .map(patient => !!panelPatients[patient.patientId])
    .filter(Boolean).length;

  return selectedPatientsCount === projectPatients.length;
};
