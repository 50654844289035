import lodash from "lodash";
import { isNil } from "ramda";
import { useMemo } from "react";

import legacyApi from "api/legacy-api";

const { useLazySearchGenePanelGenesQuery, useGetGenePanelGenesQuery } =
  legacyApi;

export const getSearchGenes = (
  searchResultGenes?: Array<GeneBase>,
  genePanelGenes?: Array<GenePanelGene>
): Array<InPanelGene> => {
  if (!searchResultGenes || !genePanelGenes) return [];
  const genesByName = lodash.keyBy(genePanelGenes, ({ name }) =>
    name.toUpperCase()
  );
  return searchResultGenes.map(gene => {
    const { autosomalRecessive, autosomalDominant, xLinked } =
      genesByName[gene.name] || {};
    return {
      ...gene,
      autosomalRecessive,
      autosomalDominant,
      xLinked,
      inPanel: !isNil(genesByName[gene.name.toUpperCase()]),
    };
  });
};

/**
 * Retrieves gene panel gene search result and enriches it with MOI info
 */
const useGenePanelGeneSearch = (projectId: number, genePanelId: number) => {
  const [searchGenes, { isLoading: genesSearchLoading, data: searchResponse }] =
    useLazySearchGenePanelGenesQuery();

  const { data: genePanelGenes, isLoading: genePanelGenesLoading } =
    useGetGenePanelGenesQuery({
      projectId,
      genePanelId,
    });

  const result = useMemo(
    () => ({
      genes: getSearchGenes(searchResponse?.genes, genePanelGenes),
    }),
    [searchResponse, genePanelGenes]
  );

  return useMemo(
    () => ({
      searchGenes,
      result,
      isLoading: genesSearchLoading || genePanelGenesLoading,
    }),
    [searchGenes, result, genesSearchLoading, genePanelGenesLoading]
  );
};

export default useGenePanelGeneSearch;
