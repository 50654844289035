import React from "react";

import { Icon } from "pattern-library";

import { ReclassifiedVariantCounts } from "../common-types";

import styles from "./ReclassifiedCounts.module.scss";

interface Props {
  reclassifiedCounts: ReclassifiedVariantCounts;
}

export const RECLASSIFIED_AS_DRIVER_COUNT_TEST_ID =
  "reclassified-as-driver-count";
export const RECLASSIFIED_AS_PASSENGER_COUNT_TEST_ID =
  "reclassified-as-passenger-count";

export const ReclassifiedCounts: React.FC<Props> = ({ reclassifiedCounts }) => (
  <div className={styles.container}>
    <Icon type="check" />
    <span data-testid={RECLASSIFIED_AS_DRIVER_COUNT_TEST_ID}>
      {reclassifiedCounts.reclassifiedAsDriverCount}
    </span>
    <Icon type="unchecked" />
    <span data-testid={RECLASSIFIED_AS_PASSENGER_COUNT_TEST_ID}>
      {reclassifiedCounts.reclassifiedAsPassengerCount}
    </span>
  </div>
);
