import React from "react";

import { Icon, Link, Tooltip } from "pattern-library";

import * as constants from "../constants";
import {
  formatCVLDate,
  formatVariantCount,
  getCvlTypeLabel,
  getLastCuratorEmailLink,
  getYesNoLabel,
  isCVLStatusFailed,
} from "../utils";

import InheritedCVLRowAction from "./InheritedCVLRowActions";
import ProjectOwnCVLRowActions from "./ProjectOwnCVLRowActions";

const getCVLNameColumn = cvlIdUsedForAutomation => ({
  Header: "Curated variant list",
  id: constants.NAME_FIELD,
  accessor: constants.NAME_FIELD,
  Cell: ({
    row: {
      original: {
        [constants.NAME_FIELD]: name,
        [constants.ID_FIELD]: curatedVariantListId,
      },
    },
  }) => (
    <>
      {name}
      {Number(cvlIdUsedForAutomation) === Number(curatedVariantListId) && (
        <>
          &nbsp;
          <Tooltip content="CVL is currently selected for project automation">
            <Link>
              <Icon type="cogs" />
            </Link>
          </Tooltip>
        </>
      )}
    </>
  ),
});

const commonColumns = {
  description: {
    Header: "Description",
    accessor: constants.DESCRIPTION_FIELD,
  },
  type: {
    Header: "Type",
    accessor: ({ [constants.LIST_TYPE_FIELD]: type }) => getCvlTypeLabel(type),
  },
  variantsType: {
    Header: "Variants Type",
    accessor: constants.VARIANTS_TYPE_FIELD,
  },
  isAutomationCVL: {
    Header: "Is Automation",
    accessor: ({ [constants.AUTOMATION_FIELD]: value }) => getYesNoLabel(value),
  },
  isEligibleForAutomatedAnnotationDecisions: {
    Header: "Is eligible for Project Automation",
    accessor: ({
      [constants.ELIGIBLE_FOR_AUTOMATED_ANNOTATION_DECISIONS_FIELD]: value,
    }) => getYesNoLabel(value),
  },
  variantCount: {
    Header: "Variants",
    accessor: constants.VARIANT_COUNT_FIELD,
    Cell: ({ row: { original } }) => formatVariantCount(original),
  },
  projectCount: {
    Header: "Projects",
    accessor: constants.PROJECT_COUNT_FIELD,
  },
  userCount: {
    Header: "Users/Curators",
    accessor: constants.USER_COUNT_FIELD,
  },
  lastUpdated: {
    Header: "Last updated",
    accessor: constants.LAST_UPDATED_FIELD,
    Cell: ({
      row: {
        original: { updated },
      },
    }) => formatCVLDate(updated),
  },
  lastCurator: {
    Header: "Last curated by",
    accessor: constants.LAST_CURATOR_NAME_FIELD,
    Cell: ({ row: { original } }) => getLastCuratorEmailLink(original),
  },
  owner: {
    Header: "Owner",
    accessor: constants.OWNER_NAME_FIELD,
    Cell: ({
      row: {
        original: { ownerName, ownerEmail, name },
      },
    }) => (
      <Link
        href={`mailto:${ownerEmail}?subject=About the Congenica curated list '${name}'&body=Dear ${ownerName},`}
      >
        {ownerName}
      </Link>
    ),
  },
};

export const getProjectOwnColumns = (
  onRemoveCVLFromProject,
  errorReportAvailable,
  cvlIdUsedForAutomation,
  showEligibleForAutomatedAnnotationDecisionsColumn
) =>
  [
    getCVLNameColumn(cvlIdUsedForAutomation),
    commonColumns.description,
    commonColumns.type,
    {
      Header: "Status",
      accessor: constants.VALIDATION_STATUS_FIELD,
      Cell: ({
        row: {
          original: { validationStatus, errorReportLink },
        },
      }) => {
        const showErrorReport =
          isCVLStatusFailed(validationStatus) && errorReportAvailable;
        return (
          <>
            {validationStatus.toUpperCase()}
            {showErrorReport && (
              <>
                <br />
                <Link href={errorReportLink} className="text-nowrap">
                  <Icon type="downloadAlt" />
                  &nbsp;Error report
                </Link>
              </>
            )}
          </>
        );
      },
    },
    commonColumns.variantsType,
    commonColumns.isAutomationCVL,
    showEligibleForAutomatedAnnotationDecisionsColumn
      ? commonColumns.isEligibleForAutomatedAnnotationDecisions
      : null,
    commonColumns.variantCount,
    commonColumns.projectCount,
    commonColumns.userCount,
    commonColumns.lastUpdated,
    commonColumns.lastCurator,
    commonColumns.owner,
    {
      Header: "Actions",
      Cell: ({ row: { original: cvl } }) => (
        <ProjectOwnCVLRowActions
          cvl={cvl}
          onRemoveCallback={onRemoveCVLFromProject}
        />
      ),
    },
  ].filter(Boolean);
export const getInheritedColumns = (
  cvlIdUsedForAutomation,
  showEligibleForAutomatedAnnotationDecisionsColumn
) =>
  [
    getCVLNameColumn(cvlIdUsedForAutomation),
    {
      Header: "Parent project",
      accessor: constants.PARENT_PROJECT_FIELD,
    },
    commonColumns.description,
    commonColumns.type,
    commonColumns.variantsType,
    commonColumns.isAutomationCVL,
    showEligibleForAutomatedAnnotationDecisionsColumn
      ? commonColumns.isEligibleForAutomatedAnnotationDecisions
      : null,
    commonColumns.variantCount,
    commonColumns.projectCount,
    commonColumns.userCount,
    commonColumns.lastUpdated,
    commonColumns.lastCurator,
    commonColumns.owner,
    {
      Header: "Actions",
      Cell: ({ row: { original: cvl } }) => <InheritedCVLRowAction cvl={cvl} />,
    },
  ].filter(Boolean);
