import { FC, useMemo } from "react";

import { Icon, Tooltip } from "pattern-library";

import {
  DnaAssayQcFieldKey,
  OncologyAssayQcField,
  RnaAssayQcFieldKey,
} from "modules/oncologyQC/types";
import { isOncologyQcValueOutOfRange } from "modules/oncologyQC/utils";
import { toDecimalPlaces } from "modules/utils/format";

interface Props
  extends OncologyAssayQcField<DnaAssayQcFieldKey | RnaAssayQcFieldKey> {
  value: string | number | undefined;
}

const AssayQcRow: FC<Props> = ({
  value,
  label,
  description,
  field,
  decimalPlaces,
  warningMessage,
  rule,
  unit,
}) => {
  const showWarning = isOncologyQcValueOutOfRange(rule, value);
  const showTooltip = Boolean(description || showWarning);

  const rowNode = useMemo(
    () => (
      <tr>
        <th scope="colgroup">{label}</th>
        <td>
          {toDecimalPlaces(value, decimalPlaces)}
          {unit}
        </td>
        <td>{showWarning && <Icon type="warning" />}</td>
      </tr>
    ),
    [label, value, decimalPlaces, showWarning, unit]
  );

  if (showTooltip) {
    return (
      <Tooltip
        key={field}
        content={
          <span>
            {description}
            {showWarning && (
              <span className="qc__oncology__json_table__warning">
                {warningMessage
                  ? `${description ? "\n" : ""}${warningMessage}`
                  : ""}
              </span>
            )}
          </span>
        }
        placement="right"
      >
        {rowNode}
      </Tooltip>
    );
  }

  return rowNode;
};

export default AssayQcRow;
