import classNames from "classnames";
import React, { useEffect, useState, memo } from "react";

import { Icon } from "pattern-library";
import { DEFAULT_PAGE_SIZE, Table } from "pattern-library/elements/table7";

import { toBoolean } from "modules/interpretationRequests/components/csvUtils";
import { filterProcessor } from "modules/utils/table";

import legacyApi from "api/legacy-api";

const { useGetTempGenePanelNotFoundGenesQuery } = legacyApi;

const getPropertyMap = () => ({
  autosomalRecessive: value => (value ? "Yes" : "No"),
  autosomalDominant: value => (value ? "Yes" : "No"),
  xLinked: value => (value ? "Yes" : "No"),
});

const geneProperties = [
  "name",
  "synonyms",
  "chr",
  "start",
  "end",
  "transcriptName",
  "autosomalRecessive",
  "autosomalDominant",
  "xLinked",
];

const geneFilter = filterProcessor(geneProperties, getPropertyMap);

interface Props {
  projectId: number;
  tempGenePanelId: number;
}

const GenesNotFoundTable = ({ projectId, tempGenePanelId }: Props) => {
  const [filterValue, setFilterValue] = useState("");
  const [filteredGenes, setFilteredGenes] = useState([]);

  const { data: genes, isLoading } = useGetTempGenePanelNotFoundGenesQuery({
    projectId,
    tempGenePanelId,
  });

  useEffect(() => {
    setFilteredGenes(geneFilter(genes, filterValue));
  }, [genes, filterValue, setFilteredGenes]);

  const onFilterChange = ({ filter }) => {
    setFilterValue(filter);
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      id: "name",
      Cell: ({ row: { original } }) => {
        const { name, status } = original;
        return (
          <>
            {status && status.statusCheckName ? (
              <Icon type="okSign" className="icon-success" />
            ) : (
              <Icon type="exclamationSign" className="icon-error" />
            )}{" "}
            <strong>{name}</strong>
          </>
        );
      },
    },
    {
      Header: "Position",
      accessor: "chr",
      Cell: ({ row: { original } }) => {
        const { chr, start, end, status } = original;
        return (
          <>
            {chr && (
              <>
                {status && status.statusCheckCoord ? (
                  <Icon type="okSign" className="icon-success" />
                ) : (
                  <Icon type="exclamationSign" className="icon-error" />
                )}{" "}
                {`${chr}:${start}-${end}`}
              </>
            )}
          </>
        );
      },
    },
    {
      Header: "Transcript",
      accessor: "transcriptId",
      Cell: ({ row: { original } }) => {
        const { transcript, status } = original;

        if (!transcript) return null;

        return (
          <>
            {status && status.statusCheckTranscript ? (
              <Icon type="okSign" className="icon-success" />
            ) : (
              <Icon type="exclamationSign" className="icon-error" />
            )}{" "}
            {transcript}
          </>
        );
      },
    },
    {
      Header: "Autosomal Recessive",
      accessor: "autosomalRecessive",
      Cell: ({ row: { original } }) => {
        const { autosomalRecessive } = original;
        return (
          <div style={{ textAlign: "center" }}>
            {toBoolean(autosomalRecessive) ? (
              <Icon type="okSign" className="icon-success" />
            ) : null}
          </div>
        );
      },
    },
    {
      Header: "Autosomal Dominant",
      accessor: "autosomalDominant",
      Cell: ({ row: { original } }) => {
        const { autosomalDominant } = original;
        return (
          <div style={{ textAlign: "center" }}>
            {toBoolean(autosomalDominant) ? (
              <Icon type="okSign" className="icon-success" />
            ) : null}
          </div>
        );
      },
    },
    {
      Header: "X Linked",
      accessor: "xLinked",
      Cell: ({ row: { original } }) => {
        const { xLinked } = original;
        return (
          <div style={{ textAlign: "center" }}>
            {toBoolean(xLinked) ? (
              <Icon type="okSign" className="icon-success" />
            ) : null}
          </div>
        );
      },
    },
  ];

  return (
    <Table
      showTitleInfo
      title="Genes not found"
      fetchData={onFilterChange}
      enableFilter
      className={classNames({
        "project-temp-gene-panel-genes-not-found-table":
          filteredGenes.length === 0,
      })}
      columns={columns}
      data={filteredGenes}
      showPagination={filteredGenes.length > DEFAULT_PAGE_SIZE}
      autoResetPage={false}
      loading={isLoading}
      sortBy={[
        {
          id: "name",
          desc: false,
        },
      ]}
    />
  );
};

export default memo(GenesNotFoundTable);
