import { Loading, Alert } from "pattern-library";

import RdIRContainer from "modules/interpretationRequests/RdIRContainer";
import OncologyIRContainer from "modules/oncologyInterpretationRequests/OncologyIRContainer";
import { ONCOLOGY_PROJECT_TYPE } from "modules/projects/constants";

import catalystApi from "api/catalyst-api";

const { useGetProjectQuery } = catalystApi;

const IRContainer = ({ projectId }: ProjectDependant) => {
  const { data: project, isLoading } = useGetProjectQuery({ projectId });

  if (isLoading) {
    return <Loading />;
  }

  if (!project) {
    return (
      <Alert severity="error" icon>
        Interpretation request project failed to load successfully. Please try
        again later.
      </Alert>
    );
  }

  return project.projectTypeInternalName === ONCOLOGY_PROJECT_TYPE ? (
    <OncologyIRContainer projectId={projectId} />
  ) : (
    <RdIRContainer projectId={projectId} />
  );
};

export default IRContainer;
