import { call, put, select, take, takeLatest } from "redux-saga/effects";

import { getPatientInfo, getPatientReports } from "modules/api/patients";
import { error } from "modules/messages/actions";
import { getPatient } from "modules/patient/selectors";
import { getOrReloadCurrentProject } from "modules/project/saga";

import * as actions from "./actions";
import * as constants from "./constants";

export function* init() {
  yield takeLatest(constants.READ_PATIENT, fetchPatient);
  yield takeLatest(
    actions.reloadPatientReports.START_ACTION,
    fetchPatientReports
  );
}

export function* getOrReloadCurrentPatient(patientId: number | string) {
  const patient = yield select(getPatient);
  // eslint-disable-next-line eqeqeq
  if (patient && patient.patientId == patientId) {
    return patient;
  }
  yield put(actions.readPatient(patientId));

  const action = yield take(constants.READ_PATIENT_SUCCESS);
  return action.payload;
}

export function* fetchPatient({
  payload: patientId,
}: actions.ReadPatientAction) {
  try {
    const { patient, customIframe, tabs } = yield call(
      getPatientInfo,
      patientId
    );
    yield call(getOrReloadCurrentProject, patient.projectId);
    yield put(actions.readPatientSuccess(patient));
    yield put(actions.setPatientTabs(tabs));
    yield put(actions.setCustomIframeConfig(customIframe));
  } catch ({ message }) {
    yield put(actions.readPatientFailure());
    yield put(error(message ? message : "Cannot load patient"));
  }
}

export function* fetchPatientReports({
  payload: patientId,
}: ReturnType<typeof actions.reloadPatientReports.start>) {
  try {
    const {
      payload: { data: reports },
    } = yield call(getPatientReports, patientId);
    yield put(actions.reloadPatientReports.success(reports));
  } catch ({ message }) {
    yield put(actions.reloadPatientReports.failure());
    yield put(error(message ? message : "Cannot load patient"));
  }
}
