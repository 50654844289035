import { SerializedError } from "@reduxjs/toolkit";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

import { UNKNOWN_ERROR } from "../../common/rtkQueryErrorLogger";

export const getJsonApiAttrs = (resp: { data: { attributes: any } }) =>
  resp?.data?.attributes;

export const getApiListData = <T>(resp: { data }): Array<T> => resp?.data;

/**
 * Takes an object from a webapi response,
 * and flattens the attributes, ID, and type together
 *
 * e.g.
 * `
 * {
 *   attributes: {
 *     foo: 'bar'
 *   },
 *   id: '1',
 *   type: 'some_entity_type'
 * }
 * `
 *
 * becomes
 *
 * `
 * {
 *   foo: 'bar'
 *   id: '1',
 *   type: 'some_entity_type'
 * }
 * `
 */
export const flattenApiEntity = (apiObject: APIObject) => {
  if (apiObject.attributes.id || apiObject.attributes.type) {
    throw new Error("ID or Type defined on API Object!");
  }
  return {
    ...apiObject.attributes,
    id: apiObject.id,
    type: apiObject.type,
  };
};

/**
 * Flattens an array of entities returned on an API response
 *
 * e.g.
 * `{ data: [...array of entities...] }`
 *
 * becomes
 *
 * `[ ...flattenApiEntity results]`
 */
export const flattenApiList = (resp: APIEntityArray) =>
  resp.data.map(flattenApiEntity);

/**
 * Flatten a single entity from API response
 *
 * e.g.
 * `
 * { data: { entity } }
 * `
 *
 * becomes
 *
 * `
 * { ...flattenedApiEntity }
 * `
 */
export const flattenApiResponse = (resp: APIEntity) =>
  flattenApiEntity(resp.data);

/**
 * Converts an object of simple values into a format that UrlSearchParams can accept,
 * for constructuring query parameters for JsonAPI requests.
 *
 * e.g. `{ foo: 'bar', thing: true }`
 * becomes `[['filter[foo]', 'bar'], ['filter[thing]', 'true']]`
 *
 */
export const getFilterParams = (
  filters: Record<string, string | boolean | number | undefined>
) =>
  Object.entries(filters).reduce((accum, [filterKey, filterValue]) => {
    if (filterValue !== undefined) {
      accum.push([`filter[${filterKey}]`, String(filterValue)]);
    }
    return accum;
  }, [] as string[][]);

const processErrorData = (data: APIErrorData): string => {
  if (data && data.errors) {
    const errorMessages = data.errors.map(
      ({ title, detail }) =>
        [title, detail].filter(Boolean).join(": ") || UNKNOWN_ERROR
    );

    return errorMessages.join("\n");
  }
  return data?.error || UNKNOWN_ERROR;
};

export const normalizeError = (
  error: FetchBaseQueryError | SerializedError
): {
  status?: number;
  message: string;
} => {
  if ("status" in error) {
    switch (error.status) {
      case "FETCH_ERROR":
      case "PARSING_ERROR":
      case "TIMEOUT_ERROR":
      case "CUSTOM_ERROR":
        return {
          message: error.error,
        };
      default:
        return {
          status: error.status,
          message: processErrorData(error.data as APIErrorData),
        };
    }
  }
  return {
    message: error.message ? error.message : "Unknown serialized error",
  };
};

export const getArrayAsQueryParam = (
  array: Array<string> = [],
  paramName: string
) => {
  const params = new URLSearchParams();

  array.forEach(item => params.append(paramName, item));
  return params;
};
