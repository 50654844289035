import {
  DnaAssayQcFieldKey,
  OncologyAssayQcField,
  RnaAssayQcFieldKey,
  OncologyAssayQcRule,
} from "./types";

import { OncologyDnaQcData, OncologyRnaQcData } from "types/oncologyQc";

export const getGeneCoverageQcByThreshold = (
  threshold: number,
  geneCoverageQcList: OncologyDnaQcData["gene_coverage_qc"],
  coverageThreshold?: number
): OncologyDnaQcData["gene_coverage_qc"][number]["genes"] => {
  const geneCoverageQc = geneCoverageQcList.find(
    ({ coverage_threshold: key }) => key === coverageThreshold
  );

  if (geneCoverageQc) {
    return geneCoverageQc.genes
      .filter(({ covered_pct }) => covered_pct <= threshold)
      .sort((a, b) => {
        if (a.covered_pct > b.covered_pct) {
          return 1;
        }
        if (a.covered_pct < b.covered_pct) {
          return -1;
        }
        return 0;
      });
  }

  return [];
};

export const isOncologyQcDataOutOfRange = <
  T extends DnaAssayQcFieldKey | RnaAssayQcFieldKey
>(
  qcData: Partial<OncologyDnaQcData> | Partial<OncologyRnaQcData>,
  fieldsConfig: OncologyAssayQcField<T>[]
): boolean => {
  const keys = Object.keys(qcData);

  return Boolean(
    keys.find(key => {
      const rule = fieldsConfig.find(({ field }) => field === key)?.rule;
      return isOncologyQcValueOutOfRange(rule, qcData[key]);
    })
  );
};

export const isOncologyQcValueOutOfRange = (
  rule: OncologyAssayQcRule | undefined,
  value: string | number | undefined
): boolean => Boolean(rule && value && !rule(Number(value)));
