import { Track } from "../core/Exac";
import { Colors, Genomes } from "../utils";

const NameHash = {
  [Genomes.GRCH37]: "Exac (v0.3)",
  [Genomes.GRCH38]: "GnomAD (v2.0.2)",
};

const getTitleUrl =
  genome =>
  ({ chr, start, ref_allele, alt_allele }) =>
    ({
      [Genomes.GRCH37]: {
        label: `ExAC ${chr}:${start}(${ref_allele}/${alt_allele})`,
        url: `https://gnomad.broadinstitute.org/variant/${chr}-${start}-${ref_allele}-${alt_allele}?dataset=exac`,
      },
      [Genomes.GRCH38]: {
        label: `GnomAD ${chr}:${start}(${ref_allele}/${alt_allele})`,
        url: undefined, // TODO: GRCH38 is to come in 2021 with gnomAD v4!
      },
    }[genome]);

const getPopulation = (title, prefix) => feature => {
  const afValue = Number(feature[`${prefix}_af`]);
  return [
    title,
    afValue === -1 ? "-" : afValue.toPrecision(3),
    `${feature[`${prefix}_ac`]}/${feature[`${prefix}_an`]}`,
    feature[`${prefix}_hom`],
  ];
};

export const createTrackConfig = ({ genome }) => ({
  id: "Congenica.Exac",
  namespace: "Congenica.Exac",
  name: NameHash[genome],
  info: "Population frequency data for variation at this locus.  Click on the bar to open a pop-up showing more detailed population data.",
  url: "/API/SNV/AFData.json?chr=__CHR__&start=__START__&end=__END__",
  menuElements: {
    title: getTitleUrl(genome),
    populationsValues: ["Population", "AF", "AC/AN", "Hom"],
    populations: [
      getPopulation("African & Afr. American", "afr"),
      getPopulation("American", "amr"),
      getPopulation("East Asian", "eas"),
      getPopulation("Finnish", "fin"),
      getPopulation("Non-Finnish European", "nfe"),
      getPopulation("South Asian", "sas"),
      getPopulation("Other", "oth"),
      ({ af, ac, an, hom }) =>
        getPopulation(
          "Other Total",
          "total"
        )({
          total_af: af,
          total_ac: ac,
          total_an: an,
          total_hom: hom,
        }),
    ],
  },
  colors: [
    {
      predicate: ({ af }) => af <= 0.00001,
      legend: "≤0.001%",
      color: Colors.RED,
      labelColor: Colors.WHITE,
    },
    {
      predicate: ({ af }) => af <= 0.0001,
      legend: "≤0.01%",
      color: Colors.RED,
      labelColor: Colors.WHITE,
    },
    {
      predicate: ({ af }) => af <= 0.001,
      legend: "≤0.1%",
      color: Colors.RED,
      labelColor: Colors.WHITE,
    },
    {
      predicate: ({ af }) => af <= 0.01,
      legend: "≤1%",
      color: Colors.ORANGE_DARK,
      labelColor: Colors.WHITE,
    },
    {
      predicate: ({ af }) => af <= 0.1,
      legend: "≤10%",
      color: Colors.BLUE,
      labelColor: Colors.WHITE,
    },
    {
      predicate: ({ af }) => af > 0.1,
      legend: ">10%",
      color: Colors.GREY_LIGHT,
      labelColor: Colors.WHITE,
    },
  ],
});

export const Exac = (config = {}) => Track.extend(createTrackConfig(config));
