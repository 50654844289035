import {
  prop,
  pipe,
  gt,
  any,
  identity,
  unapply,
  flip,
  reject,
  isNil,
  isEmpty,
  groupBy,
  mergeWith,
  concat,
} from "ramda";
import deepEqual from "redux-form/lib/structure/plain/deepEqual";

import { EXAC_EXOMES_FIELDS } from "modules/exac/constants";
import { GNOMAD_EXOMES_AF_FIELDS } from "modules/gnomad/constants";
import { GenomeAssembly } from "modules/systemConfig/types";
import { toDecimalPlaces } from "modules/utils/format";

import { EXAC_LEGACY_VERSION, GNOMAD_LEGACY_VERSION } from "./constants";
import type {
  ConfigColumnsStateValues,
  ConfigExclusions,
  ConfigStateValues,
} from "./flow-types";
import data, { CONSEQUENCES_TABLE_KEY } from "./reducer.data";

export const hasAnyElements = pipe(
  unapply(identity),
  any(pipe(prop("length"), flip(gt)(0)))
);

const exacExomesFeatureFlaggedKeys = Object.values(EXAC_EXOMES_FIELDS).map(
  value => value.key
);

const gnomADExomesFeatureFlaggedKeys = Object.values(
  GNOMAD_EXOMES_AF_FIELDS
).map(value => value.key);

export const hasAnyValidNumbers = (...rest) => arrayHasAnyValidNumber(rest);

export const arrayHasAnyValidNumber = (arr = []) =>
  arr.some(val => (val || val === 0) && !isNaN(val));

export const hasAnyTruthyValues = (...rest) => rest.some(Boolean);

export const removeTrailingZeros = (item: number) =>
  item ? Number.parseFloat(item.toString()) : item;

export const convertFormValuesToFilters = (filtersFromState: any) => {
  const filters = {
    ...filtersFromState,
  };

  Object.entries(filters).forEach(([key, value]) => {
    // remove undefined entries if any
    if (value === undefined) {
      delete filters[key];
    }
  });

  return filters;
};

export const parseFlatColumnsValues = (
  flatColumns = []
): ConfigColumnsStateValues => {
  const {
    consequenceColumns: consequenceColumnsDefaults,
    variantColumns: variantColumnsDefaults,
  } = data;

  const consequenceColumns = [];
  const variantColumns = [];

  const mapVariantColumnDefault = groupBy(({ key }) => key)(
    variantColumnsDefaults
  );
  const mapConsequenceColumnDefault = groupBy(({ key }) => key)(
    consequenceColumnsDefaults
  );

  flatColumns.forEach(key => {
    if (mapVariantColumnDefault[key]) {
      variantColumns.push(key);
    }
    if (mapConsequenceColumnDefault[key]) {
      consequenceColumns.push(key);
    }
  });

  return {
    variantColumns,
    consequenceColumns,
  };
};

export const getFlatColumnsValues = columns => {
  if (isNil(columns) || isEmpty(columns)) {
    return [];
  }
  const { variantColumns, consequenceColumns } = columns;
  const selectedValues = [...variantColumns];
  selectedValues.splice(
    variantColumns.indexOf(CONSEQUENCES_TABLE_KEY) + 1,
    0,
    ...consequenceColumns
  );
  return selectedValues;
};

export const adjustFilters = (
  filters: SNVConfigFilters,
  patient: Patient,
  exclusions: Array<string>
): SNVConfigFilters => {
  const { genePanels } = patient;
  const configFilters = {
    ...filters,
    genePanels: genePanels.map(({ genePanelId }) => genePanelId),
  };
  exclusions.forEach(excludedField => {
    delete configFilters[excludedField];
  });

  return configFilters;
};

export const adjustColumns = (
  columns: SNVColumns,
  exclusions: { variantColumns: Array<string> }
) => {
  const { hiddenColumns, disabledColumns } = exclusions;
  const {
    variantColumns: variantColsExclusions,
    consequenceColumns: consequenceColsExclusions,
  } = mergeWith(concat, hiddenColumns, disabledColumns);
  const { variantColumns, consequenceColumns } = columns;
  return {
    consequenceColumns: consequenceColumns.filter(
      col => !consequenceColsExclusions.includes(col)
    ),
    variantColumns: variantColumns.filter(
      col => !variantColsExclusions.includes(col)
    ),
  };
};

export const snvPresetToConfig = (
  preset: SNVPreset,
  patient: Patient,
  exclusions: ConfigExclusions
): ConfigStateValues => {
  const {
    attributes: {
      config: presetConfig,
      config: { filters, columns, prioritisation },
    },
  } = preset;
  const filteredColumns = adjustColumns(columns, exclusions);
  const { prioritisation: prioritisationExcl } = exclusions;
  const adjustedPresetConfig = {
    ...presetConfig,
    prioritisation: prioritisation.filter(p => !prioritisationExcl.includes(p)),
    filters: removeEmptyStrings(
      adjustFilters(filters, patient, exclusions.filters)
    ),
    ...filteredColumns,
  };
  delete adjustedPresetConfig.columns;

  return adjustedPresetConfig;
};

export const retainRegisteredFields = (
  initialFormValue: SNVConfigFilters,
  registeredFields: { [string]: {} }
) => {
  const formValue = { ...initialFormValue };
  const registeredFieldsSet = new Set(Object.keys(registeredFields || {}));
  Object.keys(formValue).forEach(field => {
    if (!registeredFieldsSet.has(field)) {
      delete formValue[field];
    }
  });

  return formValue;
};

const removeEmptyValues = o => reject(prop => isNil(prop) || isEmpty(prop), o);
const removeEmptyStrings = o => reject(prop => isNil(prop) || prop === "", o);

export const isFormPristine = (
  stateValues: ConfigStateValues,
  formValues: ConfigStateValues
) => {
  const { filters: filtersStateValues } = stateValues;
  const filteredStateValue = {
    ...stateValues,
    filters: removeEmptyValues(filtersStateValues),
  };

  const { filters } = formValues;
  const filteredFormValue = {
    ...formValues,
    filters: removeEmptyValues(filters),
  };

  return deepEqual(filteredStateValue, filteredFormValue);
};

export const getPopulationLabelByAssembly = (key, label, assembly) => {
  if (exacExomesFeatureFlaggedKeys.includes(key)) {
    if (assembly === GenomeAssembly.GRCH37) {
      return `ExAC exomes AF - ${label} (${EXAC_LEGACY_VERSION})`;
    } else {
      return `GnomAD exomes AF - ${label} (${GNOMAD_LEGACY_VERSION})`;
    }
  }

  if (gnomADExomesFeatureFlaggedKeys.includes(key)) {
    return `GnomAD exomes AF - ${label}`;
  }
  return label;
};

// If the formatted value with trailing zeros removed is 0,
// then we want this to still display in the table
//
// If we didn't do this explicitly then falsy checks in the VariantColumn component
// would make it fall back to ""
//
// it's safer to handle this here in the specific case for AF formatting
// SAP-17675
const formatZeroValue = (value: number): number | string =>
  value === 0 ? "0" : value;

export const formatVariantColumnValue = (
  score: number,
  precision?: number
): number | string =>
  formatZeroValue(removeTrailingZeros(toDecimalPlaces(score, precision)));

export function afFormat(af: number | string): number | string {
  return af?.toString() === "-1" ? "-" : formatVariantColumnValue(af, 5);
}

export function exomiserFormat(af: number): number {
  return toDecimalPlaces(af, 5);
}

const groupDisplayMap = {
  exac: "ExAC",
  gnomad: "gnomAD",
};

export const populationGroupToSuffix = (group, assembly) => {
  if (assembly === "grch38" && group === "exac") {
    return groupDisplayMap.gnomad;
  }

  return groupDisplayMap[group] || group;
};

export const formatInSilicoScores = (score: number): number | string =>
  formatVariantColumnValue(score, 3);

export const filterArchivedGenePanels = (panels, showArchived = false) => {
  const safeList = panels || [];
  return showArchived
    ? safeList
    : safeList.filter(panel => !panel.archived || panel.isPatientGenePanel);
};
