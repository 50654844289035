export interface GeneListProps {
  projectId: number;
  genePanelId: number;
  canEdit: boolean;
}

export interface EditGenePanelTabProps {
  projectId: number;
  genePanelId: number;
  canEdit: boolean;
}

export interface Gene {
  name: string;
  synonyms: string;
  old_name: string;
  chr: number;
  start: number;
  end: number;
  omimId: number | string | Array<any>;
  morbidId: number | string | Array<any>;
  ensemblId: number | string;
  refseqId: number | string;
  uniprotId: number | string;
}

export enum PatientOperation {
  ADD_ONE = "ADD_ONE",
  REMOVE_ONE = "REMOVE_ONE",
  ADD_ALL = "ADD_ALL",
  REMOVE_ALL = "REMOVE_ALL",
}
