import { IrFormValues } from "modules/oncologyInterpretationRequests/types";

export const MODULE_NAME = "oncologyInterpretationRequests";

const ACTION_TYPES_NAMESPACE = "ONCOLOGY";
export const INIT_IR = `${ACTION_TYPES_NAMESPACE}/INIT_IR`;
export const SUBMIT_IR = `${ACTION_TYPES_NAMESPACE}/SUBMIT_IR`;
export const SET_PROJECT_REQUEST_STATUS = `${ACTION_TYPES_NAMESPACE}/SET_PROJECT_REQUEST_STATUS`;
export const SET_IR_SUBMISSION_STATUS = `${ACTION_TYPES_NAMESPACE}/SET_IR_SUBMISSION_STATUS`;
export const RESET_IR_SUBMISSION_STATUS = `${ACTION_TYPES_NAMESPACE}/RESET_IR_SUBMISSION_STATUS`;
export const SET_BAITSETS = `${ACTION_TYPES_NAMESPACE}/SET_BAITSETS`;

export const IR_FORM_FIELDS_LABELS: Record<
  keyof typeof IR_FORM_FIELD_KEYS,
  string
> = {
  patientId: "Patient ID",
  tumourType: "Tumour Type",
  neoplasticCellularity: "Neoplastic Cellularity (Pathology Estimation %)",
  sex: "Sex (Biological)",
  baitset: "Baitset",
  sampleType: "Sample Type",
  fileType: "File Type",
  withRna: "With RNA",
  patientName: "Patient Name",
  dateOfBirth: "Date Of Birth",
  nhsNumber: "NHS Number",
  treatingClinician: "Treating Clinician / Referring Clinician",
  testOrderedBy: "Test Ordered By",
  testRequestDate: "Test Request Date / Referral Date",
  biopsyDateTime: "Biopsy Date",
  biopsySiteHospitalNumber: "Biopsy Site / Hospital Number",
  pastCurrentTumourTreatment: "Previous / Current Tumour-Related Treatment",
  caseIdPathology: "Case ID (Pathology)",
  caseIdMolecularDx: "Case ID (Molecular Dx)",
  specimenId: "Specimen ID",
  specimenType: "Specimen Type",
  pathologyBlockNumber: "Pathology Block Number",
  requestedMolecularTest: "Requested Molecular Test",
};

export const IR_FORM_FIELD_KEYS: KeysAsValues<
  keyof Omit<
    IrFormValues,
    | "uploadedVcfFileName"
    | "uploadedVcfFileBase64Contents"
    | "uploadedQcFileName"
    | "uploadedQcFileBase64Contents"
  >
> = {
  patientId: "patientId",
  tumourType: "tumourType",
  neoplasticCellularity: "neoplasticCellularity",
  sex: "sex",
  baitset: "baitset",
  sampleType: "sampleType",
  withRna: "withRna",
  fileType: "fileType",
  patientName: "patientName",
  dateOfBirth: "dateOfBirth",
  nhsNumber: "nhsNumber",
  treatingClinician: "treatingClinician",
  testOrderedBy: "testOrderedBy",
  testRequestDate: "testRequestDate",
  biopsyDateTime: "biopsyDateTime",
  biopsySiteHospitalNumber: "biopsySiteHospitalNumber",
  pastCurrentTumourTreatment: "pastCurrentTumourTreatment",
  caseIdPathology: "caseIdPathology",
  caseIdMolecularDx: "caseIdMolecularDx",
  specimenId: "specimenId",
  specimenType: "specimenType",
  pathologyBlockNumber: "pathologyBlockNumber",
  requestedMolecularTest: "requestedMolecularTest",
};

export const VCF_ALLOWED_EXTENSION = ".vcf";
export const QC_ALLOWED_EXTENSION = ".json";

export const VCF_SELECT_FILE_TEST_ID = `select-file-${VCF_ALLOWED_EXTENSION}`;
export const QC_SELECT_FILE_TEST_ID = `select-file-${QC_ALLOWED_EXTENSION}`;
