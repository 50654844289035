import { react as autoBind } from "auto-bind";
import classNames from "classnames";
import PropTypes from "prop-types";
import { isNil, difference } from "ramda";
import React, { PureComponent } from "react";

import AccordionSectionContent from "./AccordionSectionContent";
import AccordionSectionHeader from "./AccordionSectionHeader";

export default class Accordion extends PureComponent {
  static displayName = "Accordion";

  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * section header name
         */
        sectionName: PropTypes.string.isRequired,
        /**
         * accordion section header tags
         */
        sectionTags: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string.isRequired,
            className: PropTypes.string,
          })
        ),
        /**
         * accordion section header custom content
         */
        children: PropTypes.node,
        /**
         * section content
         */
        content: PropTypes.node,
        /**
         * Specifies if section should be opened when the component is initialized
         * If component prop allowMultipleOpen is set to false
         * only the first prop occurrence is taken into account
         */
        initiallyOpen: PropTypes.bool,
        /**
         * Whether the accordion should show an error state.
         */
        showError: PropTypes.bool,
      })
    ).isRequired,
    /**
     * top level accordion element custom class
     */
    className: PropTypes.string,
    /**
     * if true, allows for multiple items to be open at one time.
     * if false, then when an option is selected the other one is automatically closed
     */
    allowMultipleOpen: PropTypes.bool,
  };

  static defaultProps = {
    className: "",
    allowMultipleOpen: true,
  };

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      // object containing entries sectionName: expanded/collapsed flag
      sectionsOpenState: {},
    };
  }

  onClick(e) {
    const sectionName = e;
    this.setState((prevState, props) => {
      const { sectionsOpenState = {} } = prevState;
      const { allowMultipleOpen } = props;

      const isOpen = sectionsOpenState[sectionName];

      // If multiple items are allowed or the clicked section is about to be closed
      // switch that single item state flag
      if (allowMultipleOpen === true || isOpen) {
        return {
          sectionsOpenState: { ...sectionsOpenState, [sectionName]: !isOpen },
        };
      }

      // if a single open section is allowed at a time and current section is about to be open
      // close the section opened before if exists
      const currentOpenSectionItem = Object.entries(sectionsOpenState).find(
        section => section[1]
      );

      // toggle clicked section, collapse other sections in single mode, return new state
      if (isNil(currentOpenSectionItem)) {
        return {
          sectionsOpenState: { ...sectionsOpenState, [sectionName]: !isOpen },
        };
      } else {
        const [sectionToClose] = currentOpenSectionItem;
        return {
          sectionsOpenState: {
            ...sectionsOpenState,
            [sectionName]: !isOpen,
            [sectionToClose]: false,
          },
        };
      }
    });
  }

  static getDerivedStateFromProps(props, state) {
    const { allowMultipleOpen, data = [] } = props;
    const { sectionsOpenState } = state;
    const existingSections = Object.keys(sectionsOpenState);
    const updatedSections = data.map(({ sectionName }) => sectionName);

    const newSections = difference(updatedSections, existingSections);

    const needToRemoveSections = !!existingSections.find(
      existingSection => !updatedSections.includes(existingSection)
    );

    const multipleSectionsOpen =
      Object.values(sectionsOpenState).filter(isOpen => isOpen === true)
        .length > 1;

    if (
      !newSections.length &&
      needToRemoveSections === false &&
      (allowMultipleOpen === true ||
        (allowMultipleOpen === false && !multipleSectionsOpen))
    ) {
      //if no sections are added or removed and if open sections number is consistent with allowMultipleOpen flag,
      // state should not be modified
      return null;
    }

    // the new list of open section names including newly added ones marked as initially open
    let updatedOpenSectionsList = data
      .filter(({ sectionName, initiallyOpen = false }) => {
        // existing sections should use the current expand/collapse flag
        if (existingSections.includes(sectionName)) {
          return sectionsOpenState[sectionName] === true;
        }

        //newly added sections should only check 'initiallyOpen' prop
        return initiallyOpen === true;
      })
      .map(({ sectionName }) => sectionName);

    if (allowMultipleOpen === false && updatedOpenSectionsList.length > 1) {
      // If the exclusive mode is set and multiple sections have been opened before
      // only the first section will be left open.
      updatedOpenSectionsList = updatedOpenSectionsList.slice(0, 1);
    }

    // updated state
    const newSectionsOpenState = updatedSections.reduce((obj, section) => {
      obj[section] = updatedOpenSectionsList.includes(section);
      return obj;
    }, {});

    return { sectionsOpenState: newSectionsOpenState };
  }

  render() {
    const { data = [], className, children } = this.props;
    const { sectionsOpenState } = this.state;

    if (!data.length) {
      return null;
    }

    return (
      <div className={classNames("accordion", className)}>
        {data.map(
          ({
            sectionName,
            content,
            showError = false,
            sectionSubtitle,
            sectionTags,
          }) => {
            const isOpen = sectionsOpenState[sectionName];

            return (
              <div
                key={sectionName}
                className={classNames("accordion-section", {
                  "accordion-section-active": isOpen,
                })}
              >
                <AccordionSectionHeader
                  sectionName={sectionName}
                  sectionSubtitle={sectionSubtitle}
                  sectionTags={sectionTags}
                  onClick={this.onClick}
                  isDisabled={isNil(content)}
                  hasError={showError}
                >
                  {children}
                </AccordionSectionHeader>
                <AccordionSectionContent isOpen={isOpen}>
                  {content}
                </AccordionSectionContent>
              </div>
            );
          }
        )}
      </div>
    );
  }
}
