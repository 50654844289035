import { react as autoBind } from "auto-bind";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export default class ThComponent extends PureComponent {
  static displayName = "Th";

  static propTypes = {
    /**
     * Class to apply to the component
     */
    className: PropTypes.string,
    /**
     * Content to render inside the component
     */
    children: PropTypes.node,
    /**
     * Function for toggle ascending descending sorting
     */
    toggleSort: PropTypes.func,
  };

  static defaultProps = {
    className: "",
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  onClick(e) {
    const { toggleSort } = this.props;
    if (typeof toggleSort === "function") {
      toggleSort(e);
    }
  }

  // Our react-table-7 allows to multi sort columns out of the box with shift click.
  // This prevents user from selecting text when performing such operation as it would not be desired.
  onMouseDown(e) {
    if (e.shiftKey) {
      e.preventDefault();
    }
  }

  render() {
    const { toggleSort, className, children, ...rest } = this.props;

    return (
      <th
        className={classNames(className, "table-th")}
        onClick={this.onClick}
        onMouseDown={this.onMouseDown}
        tabIndex="-1" // Resolves eslint issues without implementing keyboard navigation incorrectly
        {...rest}
      >
        {children}
      </th>
    );
  }
}
