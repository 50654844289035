import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export const allowedIcons = {
  //bootstrap icons
  left: "glyphicon glyphicon-chevron-left",
  comment: "glyphicon glyphicon-comment",
  right: "glyphicon glyphicon-chevron-right",
  user: "glyphicon glyphicon-user",
  group: "glyphicon glyphicon-user",
  search: "glyphicon glyphicon-search",
  cross: "glyphicon glyphicon-remove",
  tick: "glyphicon glyphicon-ok",
  okSign: "glyphicon glyphicon-ok-sign",
  removeSign: "glyphicon glyphicon-remove-sign",
  help: "glyphicon glyphicon-question-sign",
  file: "glyphicon glyphicon-file",
  briefcase: "glyphicon glyphicon-briefcase",
  blank: "glyphicon glyphicon-file",
  warningSign: "glyphicon glyphicon-warning-sign",
  download: "glyphicon glyphicon-download",
  downloadAlt: "glyphicon glyphicon-download-alt",
  upload: "glyphicon glyphicon-upload",
  uploadCloud: "glyphicon glyphicon-cloud-upload",
  alert: "glyphicon glyphicon-alert",
  infoSign: "glyphicon glyphicon-info-sign",
  exclamationSign: "glyphicon glyphicon-exclamation-sign",
  filter: "glyphicon glyphicon-filter",
  folderOpen: "glyphicon glyphicon-folder-open",
  folderClose: "glyphicon glyphicon-folder-close",
  menuLeft: "glyphicon glyphicon-menu-left",
  menuRight: "glyphicon glyphicon-menu-right",
  cog: "glyphicon glyphicon-cog",
  inbox: "glyphicon glyphicon-inbox",
  pencil: "glyphicon glyphicon-pencil",
  envelope: "glyphicon glyphicon-envelope",
  flag: "glyphicon glyphicon-flag",
  eyeOpen: "glyphicon glyphicon-eye-open",
  eyeClose: "glyphicon glyphicon-eye-close",
  bookmark: "glyphicon glyphicon-bookmark",
  export: "glyphicon glyphicon-export",
  link: "glyphicon glyphicon-link",
  listAlt: "glyphicon glyphicon-list-alt",
  trash: "glyphicon glyphicon-trash",
  plus: "glyphicon glyphicon-plus",
  minus: "glyphicon glyphicon-minus",
  resizeHorizontal: "glyphicon glyphicon-resize-horizontal",
  resizeVertical: "glyphicon glyphicon-resize-vertical",
  collapseDown: "glyphicon glyphicon-collapse-down",
  collapseUp: "glyphicon glyphicon-collapse-up",
  expand: "glyphicon glyphicon-expand",
  chevronLeft: "glyphicon glyphicon-chevron-left",
  chevronRight: "glyphicon glyphicon-chevron-right",
  banCircle: "glyphicon glyphicon-ban-circle",

  check: "glyphicon glyphicon-check",
  unchecked: "glyphicon glyphicon-unchecked",

  repeat: "glyphicon glyphicon-repeat",
  newWindow: "glyphicon glyphicon-new-window",
  handRight: "glyphicon glyphicon-hand-right",

  //Font Awesome icons
  chevronCircleLeft: "fa fa-chevron-circle-left",
  chevronCircleRight: "fa fa-chevron-circle-right",
  infoCircle: "fa fa-info-circle",
  fileArchiveO: "fa fa-file-archive-o",
  lock: "fa fa-lock",
  unlock: "fa fa-unlock",
  server: "fa fa-server",
  spinner: "fa fa-spinner fa-spin fa-fw",
  cogs: "fa fa-cogs",
  lightbulb: "fa fa-lightbulb-o",
  thumbsDown: "fa fa-thumbs-down",
  print: "fa fa-print",
  save: "fa fa-save",
  hourglassHalf: "fa fa-hourglass-half",
  check2: "fa fa-check",

  //Custom icons
  viewEdit: "curated-lists-view-edit-icon",

  //CE Marking
  ceMark: "icon-custom ce-mark",
  instructions: "icon-custom instructions",
  ivd: "icon-custom ivd",
  warning: "icon-custom warning",
  caution: "icon-custom caution",
  manufacturer: "icon-custom manufacturer",
  ecRep: "icon-custom ec-rep",
  ukFlag: "icon-custom uk-flag",
  euFlag: "icon-custom eu-flag",
  usaFlag: "icon-custom usa-flag",
};

export default class Icon extends PureComponent {
  static displayName = "Icon";

  static types = allowedIcons;

  static propTypes = {
    /**
     * The type of icon to render
     */
    type: PropTypes.oneOf(Object.keys(allowedIcons)).isRequired,
    /**
     * A caption to go under the icon
     */
    caption: PropTypes.string,
    /**
     * if true, apply pointer cursor
     */
    clickable: PropTypes.bool,
    /**
     * A class to apply to the icon span
     */
    className: PropTypes.string,
  };

  static defaultProps = {
    caption: "",
    className: "",
  };

  render() {
    const { type, caption, className, clickable, ...otherProps } = this.props;

    const icon = (
      <span
        {...otherProps}
        className={classNames("icon", Icon.types[type], className, {
          clickable,
        })}
      />
    );

    if (caption) {
      return (
        <div className="icon icon--with-caption">
          {icon}
          <p className="icon-caption">{caption}</p>
        </div>
      );
    }
    return icon;
  }
}
