import { ORM } from "redux-orm";

import { Decision, Gene } from "./models";

const orm = new ORM({
  stateSelector: state => state.orm,
});

function proxyClassForORM(klass) {
  return new Proxy(klass, {
    apply(target, thisArg, rest) {
      return new target(...rest);
    },
  });
}

orm.register(proxyClassForORM(Decision), proxyClassForORM(Gene));

export default orm;
