import { useMemo } from "react";

import catalystApi from "api/catalyst-api";
import congenicaApi from "api/congenica-api";
import { OncologyPatientWorkflowStatus } from "types/oncologyPatientWorkflowStatus";

const { useGetAvailableTemplatesForProjectQuery } = catalystApi;

const {
  useGetLatestReportProposalQuery,
  useGetPatientReportsQuery,
  useGetOncologyPatientWorkflowStatusQuery,
} = congenicaApi;

interface OncologyReportData {
  reports: PatientReport[];
  templates: ReportTemplate[];
  oncologyPatientWorkflowStatus: OncologyPatientWorkflowStatus | undefined;
  latestReportProposal: ReportProposal | undefined;
  isLoading: boolean;
}

export const useOncologyReportData = (
  patientId: number,
  projectId: number
): OncologyReportData => {
  const { data: reports = [], isLoading: isGetPatientReportsLoading } =
    useGetPatientReportsQuery({ patientId });

  const {
    data: templates = [],
    isLoading: isGetAvailableTemplatesForProjectLoading,
  } = useGetAvailableTemplatesForProjectQuery({ projectId });

  const {
    data: oncologyPatientWorkflowStatus,
    isLoading: oncologyPatientWorkflowStatusLoading,
  } = useGetOncologyPatientWorkflowStatusQuery({
    patientId,
  });
  const {
    data: latestReportProposal,
    isLoading: isLatestReportProposalLoading,
  } = useGetLatestReportProposalQuery({ patientId });

  const isLoading = useMemo(
    () =>
      oncologyPatientWorkflowStatusLoading ||
      isLatestReportProposalLoading ||
      isGetAvailableTemplatesForProjectLoading ||
      isGetPatientReportsLoading,
    [
      oncologyPatientWorkflowStatusLoading,
      isLatestReportProposalLoading,
      isGetAvailableTemplatesForProjectLoading,
      isGetPatientReportsLoading,
    ]
  );

  return {
    reports,
    templates,
    oncologyPatientWorkflowStatus,
    latestReportProposal,
    isLoading,
  };
};
