import { Track } from "../core/SpliceSite";

const getSpliceSiteTrackUrl = ({ patientId, transcriptId }, jsonName) =>
  `/patient/${patientId}/splice/site/${jsonName}.json?chr=__CHR__&start=__START__&end=__END__&transcript_id=${transcriptId}`;

export const SpliceSiteKnown = config =>
  Track.extend({
    namespace: "Congenica.SpliceSiteKnown",
    url: getSpliceSiteTrackUrl(config, "known"),
    transcriptId: parseInt(config.transcriptId),
    patientId: config.patientId,
    name: "Splice sites (Known)",
    configName: ["Splice Sites", "(Known)"],
    id: "spliceSiteKnownVariant",
    type: "Known",
    snvId: config.snvId,
    spliceAiEnabled: config.spliceAiEnabled,
    info: "Consensus splice sites across the genome are displayed with their MaxEntScan scores. If a variant is present within a known splice motif the MaxEntScan score is recalculated and the new score displayed above the variant in the browser. The calculated scores relating to the reference allele and the variant are also displayed in the variant table, along with the difference between them. Only raw values are displayed, without interpretation, as the cut-off criteria for determining if a change is likely to be significant should be decided locally.",
  });
