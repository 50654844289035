import { decamelizeKeys } from "humps";
import { isEmpty, isNil, path } from "ramda";

import {
  ANY_GENE_PANEL_ID,
  LOCATION_TYPES,
} from "modules/projectSettings/components/filterPresets/constants";
import notIsNil from "modules/utils/notIsNil";

/**
 * This method converts redux form values to values acceptable for legacy code
 */
export const fromValuesToFilters = (values, backendFormat = true) => {
  const filters: any = {};
  const numberFilters = ["sv_bayes_factor_filter"];
  Object.entries(decamelizeKeys(values)).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      filters[key] = !backendFormat
        ? value
        : value
            .map(item => {
              if (typeof item !== "object") {
                return item;
              }
              return item.value ?? item.gene_panel_id;
            })
            .join("::");
    } else if (numberFilters.includes(key)) {
      filters[key] = value ? parseFloat(value as string) : value;
    } else {
      filters[key] = value;
    }

    if (!filters[key] && !numberFilters.includes(key)) {
      filters[key] = null;
    }
  });

  if (values.sv_location_filter === LOCATION_TYPES.genes.value) {
    delete filters.sv_gene_panel_filter;
  } else {
    delete filters.sv_genes_filter;
  }
  // We need to ensure there's always a location set, as the datatables in the Perl template
  // can trigger a call to the backend before all of the filters are correctly rendered,
  // and this can result in an error message showing to the user when loading a specific variant
  filters.sv_location_filter = filters.sv_location_filter || "panels";
  return filters;
};

export const tuneKey = key => `sv_${key}_filter`;

export const tuneFilters = obj => {
  const output = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      output[tuneKey(key)] = obj[key];
    }
  }
  return output;
};

export const filterPatientGenePanels = (
  genePanelsFilterValues = [],
  patientGenePanels: Array<PatientGenePanel> = []
) =>
  genePanelsFilterValues.filter(
    ({ genePanelId: filterValue }) =>
      patientGenePanels.some(
        ({ genePanelId }) => String(genePanelId) === String(filterValue)
      ) || isAnyGeneOption(filterValue)
  );

/**
 * This method converts preset config values to values acceptable for redux form
 */
export const prepareFilters = filters => {
  Object.keys(filters).forEach(key => {
    const value = filters[key];
    if (notIsNil(value)) {
      if (key === "genePanel") {
        filters[key] = value.map(({ genePanelId }) => "" + genePanelId);
      } else if (key === "genes") {
        filters[key] = value;
      } else if (Array.isArray(value)) {
        filters[key] = value.map(value => "" + value);
      } else {
        filters[key] = "" + value;
      }
    }
  });
  return tuneFilters(filters);
};

const isAnyGeneOption = value => value === ANY_GENE_PANEL_ID;

export const isGenePanelOptionDisabled = (
  value,
  isAnyGeneSelected,
  hasSelectedValues
) =>
  hasSelectedValues &&
  ((!isAnyGeneOption(value) && isAnyGeneSelected) ||
    (isAnyGeneOption(value) && !isAnyGeneSelected));

export const getGenePanelsOptions = (
  genePanels: Array<PatientGenePanel> = [],
  selectedPanelsIds = [],
  selectedFilterPreset
) => {
  if (!genePanels.length) {
    return;
  }

  const currentFilterPresetPanels =
    path(["value", "attributes", "config", "filters", "genePanel"])(
      selectedFilterPreset
    ) || [];

  const isAnyGeneSelected = selectedPanelsIds
    ? selectedPanelsIds.some(panelId => panelId === ANY_GENE_PANEL_ID)
    : false;
  const hasSelectedValues =
    !isNil(selectedPanelsIds) && !isEmpty(selectedPanelsIds);

  const panelIsInPreset = id =>
    currentFilterPresetPanels.some(
      ({ genePanelId }) => String(genePanelId) === String(id)
    );

  const mapPanels = panel => ({
    label: panel.title,
    archived: panel.archived,
    value: String(panel.genePanelId),
    key: panel.genePanelId,
    isDisabled: isGenePanelOptionDisabled(
      String(panel.genePanelId),
      isAnyGeneSelected,
      hasSelectedValues
    ),
    isPatientGenePanel: panel.isPatientGenePanel,
    ...(panel.archived && {
      subLabel: "(archived)",
    }),
    ...(panel.version && {
      tags: [{ label: `version ${panel.version}`, className: "version-label" }],
    }),
  });

  const mappedPanels = genePanels.map(mapPanels);

  return [
    {
      label: "",
      options: [
        {
          label: "Any SV associated with a gene",
          value: ANY_GENE_PANEL_ID,
          key: ANY_GENE_PANEL_ID,
          isDisabled: isGenePanelOptionDisabled(
            ANY_GENE_PANEL_ID,
            isAnyGeneSelected,
            hasSelectedValues
          ),
        },
      ],
    },
    {
      label: "Patient panels",
      options: mappedPanels.filter(panel => panel.isPatientGenePanel),
    },
    {
      label: "Project panels",
      options: mappedPanels.filter(
        panel =>
          (!panel.archived || panelIsInPreset(panel.key)) &&
          !panel.isPatientGenePanel
      ),
    },
  ];
};
