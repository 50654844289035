import classNames from "classnames";
import React, { useEffect, useState, memo, useMemo, useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";

import { Actions, Icon } from "pattern-library";
import { DEFAULT_PAGE_SIZE, Table } from "pattern-library/elements/table7";

import { showModal } from "modules/modalRegistry/actions";
import { modalTypes } from "modules/modalRegistry/constants";
import { formatDate } from "modules/utils";

import { GeneListProps } from "../../../../types";
import EditNotification from "../../EditNotification";

import legacyApi from "api/legacy-api";

const { useGetGenePanelInfoQuery, useGetGenePanelGenesQuery } = legacyApi;

const GenesListTab = memo(
  ({
    projectId,
    genePanelId,
    canEdit,
    showModal,
  }: GeneListProps & PropsFromRedux) => {
    const [filterValue, setFilterValue] = useState("");
    const [filteredGenes, setFilteredGenes] = useState([]);

    const { data: genePanelInfo } = useGetGenePanelInfoQuery({
      projectId,
      genePanelId,
    });

    const { data: genes, isLoading } = useGetGenePanelGenesQuery({
      projectId,
      genePanelId,
    });

    const filter = useCallback(
      (data, filterString = "") => {
        if (data) {
          const newGenes = data.filter(gene => {
            if (!gene) return false;

            const geneValues = Object.values(gene);
            let result = false;
            geneValues.forEach(value => {
              if (
                value &&
                value
                  .toString()
                  .toUpperCase()
                  .includes(filterString.toUpperCase())
              ) {
                result = true;
              }
            });
            return result;
          });
          setFilteredGenes(newGenes);
        }
      },
      [setFilteredGenes]
    );

    useEffect(() => {
      filter(genes, filterValue);
    }, [genes, filterValue, filter]);

    const onFilterChange = useCallback(
      ({ filter }) => {
        setFilterValue(filter);
      },
      [setFilterValue]
    );

    const getActions = useCallback(
      geneName => [
        {
          icon: "pencil",
          className: "btn-default btn-xs",
          action: () => {
            showModal(modalTypes.EDIT_GENE_MODAL, {
              geneName,
              projectId,
              genePanelId,
            });
          },
          tooltip: {
            content: "Edit gene",
          },
        },
        {
          icon: "trash",
          className: "btn-default btn-xs",
          action: () => {
            showModal(modalTypes.DELETE_GENE_FROM_GENE_PANEL_MODAL, {
              projectId,
              genePanelId,
              geneName,
            });
          },
          tooltip: {
            content: "Delete gene",
          },
        },
      ],
      [projectId, genePanelId, showModal]
    );

    const columns = useMemo(
      () => [
        {
          Header: "Name",
          accessor: "name",
          id: "name",
          Cell: ({ row: { original } }) => {
            const { name } = original;
            return (
              <a
                onClick={() => {
                  showModal(modalTypes.GENE_INFO_MODAL, {
                    geneName: name,
                    ensemblVersion: genePanelInfo?.ensemblVersion,
                  });
                }}
              >
                {name}
              </a>
            );
          },
        },
        {
          Header: "Old Name",
          accessor: "oldName",
        },
        {
          Header: "Synonyms",
          accessor: "synonyms",
        },
        {
          Header: "Transcript",
          accessor: "transcriptId",
          Cell: ({ row: { original } }) => {
            const { transcript } = original;
            return (
              <>
                {transcript ? (
                  <a
                    href={transcript.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {transcript.name}
                  </a>
                ) : null}
              </>
            );
          },
        },
        {
          Header: "Autosomal Recessive",
          accessor: "autosomalRecessive",
          Cell: ({ row: { original } }) => {
            const { autosomalRecessive } = original;
            return (
              <div style={{ textAlign: "center" }}>
                {autosomalRecessive ? <Icon type="tick" /> : null}
              </div>
            );
          },
        },
        {
          Header: "Autosomal Dominant",
          accessor: "autosomalDominant",
          Cell: ({ row: { original } }) => {
            const { autosomalDominant } = original;
            return (
              <div style={{ textAlign: "center" }}>
                {autosomalDominant ? <Icon type="tick" /> : null}
              </div>
            );
          },
        },
        {
          Header: "X Linked",
          accessor: "xLinked",
          Cell: ({ row: { original } }) => {
            const { xLinked } = original;
            return (
              <div style={{ textAlign: "center" }}>
                {xLinked ? <Icon type="tick" /> : null}
              </div>
            );
          },
        },
        {
          Header: "Condition",
          accessor: "condition",
        },
        {
          Header: "In base panel",
          accessor: "inBasePanel",
          Cell: ({ row: { original } }) => {
            const { inBasePanel } = original;
            return (
              <div style={{ textAlign: "center" }}>
                {inBasePanel ? <Icon type="tick" /> : null}
              </div>
            );
          },
        },
        {
          Header: "Added",
          accessor: "updated",
          Cell: ({
            row: {
              original: { updated },
            },
          }) => formatDate(updated * 1000),
        },
        {
          Header: "Actions",
          id: "actions",
          accessor: ({ name }) =>
            canEdit ? <Actions actions={getActions(name)} /> : null,
        },
      ],
      [getActions, canEdit, showModal, genePanelInfo]
    );

    return (
      <div>
        {!canEdit && (
          <EditNotification projectId={projectId} genePanelId={genePanelId} />
        )}
        <Table
          showTitleInfo
          title={`Genes in ${genePanelInfo ? genePanelInfo.title : ""}`}
          fetchData={onFilterChange}
          enableFilter
          className={classNames({
            "project-gene-panel-genes-table": filteredGenes.length === 0,
          })}
          columns={columns}
          data={filteredGenes}
          showPagination={filteredGenes.length > DEFAULT_PAGE_SIZE}
          autoResetPage={false}
          loading={isLoading}
          sortBy={[
            {
              id: "name",
              desc: false,
            },
          ]}
        />
      </div>
    );
  }
);

const mapDispatchToProps = {
  showModal,
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(GenesListTab);
