import { useEffect, useMemo } from "react";

import { ONCOLOGY_PROJECT_TYPE } from "modules/projects/constants";

import congenicaApi from "api/congenica-api";

const { useLazyGetProjectPatientsOfflineStatusesQuery } = congenicaApi;

// This data type comes from code without TypeScript and it's quite dynamic based on various conditions
// hence the details of this type are kept as a blackbox and only used properties are typed
interface PatientPartial {
  patientId: number;
  status: string;
}

interface PatientPartialWithOfflineStatuses extends PatientPartial {
  offlineStatus?: boolean;
  goingOffline?: boolean;
  goingOnline?: boolean;
}

interface PatientsTableData {
  getProjectPatientsOfflineStatusesFetching: boolean;
  tableData: PatientPartialWithOfflineStatuses[];
}

const usePatientsTableData = (
  projectId: number | undefined | null,
  data: PatientPartial[] | undefined | null,
  hideReviewCompletePrefs: boolean | undefined | null,
  projectType: string | undefined
): PatientsTableData => {
  const [
    getProjectPatientsOfflineStatuses,
    {
      isFetching: getProjectPatientsOfflineStatusesFetching,
      data: projectPatientsOfflineStatuses,
    },
  ] = useLazyGetProjectPatientsOfflineStatusesQuery();

  // Fetch project patients offline statuses
  useEffect(() => {
    if (!projectId || !projectType || projectType === ONCOLOGY_PROJECT_TYPE) {
      return;
    }

    getProjectPatientsOfflineStatuses({ projectId });
  }, [projectId, getProjectPatientsOfflineStatuses, projectType]);

  const tableData = useMemo(
    () =>
      data?.reduce((result: PatientPartialWithOfflineStatuses[], patient) => {
        // Filter out "Review complete" if filter is set
        if (hideReviewCompletePrefs && patient.status === "Review complete") {
          return result;
        }

        const matchedPatientOfflineStatus =
          projectPatientsOfflineStatuses?.find(
            ({ id }) => String(patient.patientId) === String(id)
          );

        if (!matchedPatientOfflineStatus) {
          result.push(patient);
        } else {
          const { goingOffline, goingOnline, offlineStatus } =
            matchedPatientOfflineStatus;

          // Merging patients offline statuses with the rest of data we pass to the table
          const patientWithOfflineStatuses = {
            offlineStatus,
            goingOffline,
            goingOnline,
            ...patient,
          };

          result.push(patientWithOfflineStatuses);
        }

        return result;
      }, []),
    [data, hideReviewCompletePrefs, projectPatientsOfflineStatuses]
  );

  return {
    getProjectPatientsOfflineStatusesFetching,
    tableData: tableData || [],
  };
};

export default usePatientsTableData;
