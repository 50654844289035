import React, { memo, useCallback, useState } from "react";
import { Field, InjectedFormProps, reduxForm } from "redux-form";

import { Button, Icon } from "pattern-library";

import { SEARCH_GENE_FOR_GENE_PANEL_FORM } from "../../../../constants";
import { GeneListProps } from "../../../../types";
import EditNotification from "../../EditNotification";

import SearchGenesTable from "./SearchGenesTable";

import useGenePanelGeneSearch from "hooks/useGenePanelGeneSearch";

const SearchTab = memo(
  ({
    projectId,
    genePanelId,
    canEdit,
    handleSubmit,
  }: GeneListProps & InjectedFormProps<Index, GeneListProps>) => {
    const [lastQuery, setLastQuery] = useState("");

    const {
      searchGenes,
      result: { genes = [] } = {},
      isLoading,
    } = useGenePanelGeneSearch(projectId, genePanelId);

    const onFormSubmit = useCallback(
      ({ query }) => {
        if (!!query) {
          searchGenes({ projectId, genePanelId, query });
        }
        setLastQuery(query ?? "");
      },
      [projectId, genePanelId, searchGenes]
    );

    if (!canEdit)
      return (
        <EditNotification projectId={projectId} genePanelId={genePanelId} />
      );
    return (
      <div className="row">
        <div className="col-xs-12">
          <form id="gene-search" onSubmit={handleSubmit(onFormSubmit)}>
            <div className="form-group">
              <div className="input-group col-xs-10">
                <Field
                  component="input"
                  name="query"
                  className="form-control col-xs-5 query"
                  placeholder="Please enter a search phrase"
                />
                <span className="input-group-btn">
                  <Button type="submit">
                    <Icon type="search" />
                  </Button>
                </span>
              </div>
            </div>
          </form>
          <div id="gene-search-results-list">
            {lastQuery.length > 0 ? (
              <SearchGenesTable
                projectId={projectId}
                genePanelId={genePanelId}
                isGenesSearchInProgress={isLoading}
                genes={genes}
                query={lastQuery}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
);

export default reduxForm<Index, GeneListProps>({
  form: SEARCH_GENE_FOR_GENE_PANEL_FORM,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(SearchTab);
