import React, { memo, useMemo } from "react";

import { ComposableTabs, Tab } from "pattern-library";

import GenesFoundTable from "./GenesFoundTable";
import GenesNotFoundTable from "./GenesNotFoundTable";
import NameAndDescriptionForm from "./NameAndDescriptionForm";

import legacyApi from "api/legacy-api";

const {
  useGetTempGenePanelQuery,
  useGetTempGenePanelFoundGenesQuery,
  useGetTempGenePanelNotFoundGenesQuery,
} = legacyApi;

interface Props {
  projectId: number;
  tempGenePanelId: number;
  onClose: () => void;
}

const UploadGenesTabs = ({ projectId, tempGenePanelId, onClose }: Props) => {
  const { data: tempGenePanel } = useGetTempGenePanelQuery({
    projectId,
    tempGenePanelId,
  });
  const { data: foundGenes } = useGetTempGenePanelFoundGenesQuery({
    projectId,
    tempGenePanelId,
  });
  const { data: notFoundGenes } = useGetTempGenePanelNotFoundGenesQuery({
    projectId,
    tempGenePanelId,
  });
  const tabs = useMemo(
    () => [
      {
        component: GenesFoundTable,
        name: "Genes found",
        count: foundGenes?.length,
        props: { projectId, tempGenePanelId, onClose },
      },
      {
        component: GenesNotFoundTable,
        name: "Genes not found",
        count: notFoundGenes?.length,
        props: { projectId, tempGenePanelId, onClose },
      },
      {
        component: NameAndDescriptionForm,
        name: "Name and description",
        props: {
          projectId,
          tempGenePanelId,
          onClose,
          initialValues: tempGenePanel,
        },
      },
    ],
    [
      projectId,
      tempGenePanelId,
      onClose,
      tempGenePanel,
      foundGenes,
      notFoundGenes,
    ]
  );

  return (
    <ComposableTabs navStyle>
      {tabs.map(tab => {
        const {
          name,
          component: Component,
          props = {} as Props,
          ...rest
        } = tab;
        return (
          <Tab key={name} name={name} {...rest}>
            <Component {...props} />
          </Tab>
        );
      })}
    </ComposableTabs>
  );
};

export default memo(UploadGenesTabs);
