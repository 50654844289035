import classNames from "classnames";
import { useFormikContext } from "formik";
import React, { FC, useMemo } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { connect, ConnectedProps } from "react-redux";

import * as messageActions from "modules/messages/actions";
import FileUploader from "modules/oncologyInterpretationRequests/components/fileUploader/FileUploader";
import {
  QC_ALLOWED_EXTENSION,
  QC_SELECT_FILE_TEST_ID,
  VCF_ALLOWED_EXTENSION,
  VCF_SELECT_FILE_TEST_ID,
} from "modules/oncologyInterpretationRequests/constants";
import {
  FORM_FILE_TYPES,
  IrFormValues,
} from "modules/oncologyInterpretationRequests/types";
import { toBase64 } from "modules/utils/base64";

const ONE_MB = 1048576;
const FILE_SIZE_THRESHOLD = 1048576;

const ACCEPTED_FILE_TYPES_MESSAGE =
  "Accepted file formats: FASTQ (bgzip or gzip)";

interface Props extends PropsFromRedux {
  isModal: boolean;
  withRna: boolean;
  disabled: boolean;
}

const FilesPanel: FC<Props> = ({ showError, isModal, withRna, disabled }) => {
  const {
    setValues,
    values,
    values: { patientId, fileType, uploadedVcfFileName, uploadedQcFileName },
  } = useFormikContext<IrFormValues>();

  const onSelectVcfFile = async (file: File) => {
    if (file.size > FILE_SIZE_THRESHOLD) {
      showError(
        `File size should be less than ${(FILE_SIZE_THRESHOLD / ONE_MB).toFixed(
          2
        )} MB`
      );
    } else {
      try {
        const fileBase64Contents = await toBase64(file);
        setValues({
          ...values,
          uploadedVcfFileName: file.name,
          uploadedVcfFileBase64Contents: fileBase64Contents,
        });
      } catch (readerError) {
        showError(`Error: ${readerError}`);
      }
    }
  };

  const onSelectQcFile = async (file: File) => {
    if (file.size > FILE_SIZE_THRESHOLD) {
      showError(
        `File size should be less than ${(FILE_SIZE_THRESHOLD / ONE_MB).toFixed(
          2
        )} MB`
      );
    } else {
      try {
        const fileBase64Contents = await toBase64(file);
        setValues({
          ...values,
          uploadedQcFileName: file.name,
          uploadedQcFileBase64Contents: fileBase64Contents,
        });
      } catch (readerError) {
        showError(`Error: ${readerError}`);
      }
    }
  };

  const hasSample = useMemo<boolean>(
    () => Boolean(patientId && patientId.length > 0),
    [patientId]
  );

  if (fileType === FORM_FILE_TYPES.VCF) {
    if (isModal) {
      return null;
    }

    return (
      <DndProvider backend={HTML5Backend}>
        <div className="row">
          <div className="col-md-6" data-testid="file-uploader-vcf">
            <FileUploader
              uploadedFileName={uploadedVcfFileName}
              title="Upload VCF File"
              allowedFileExtension={VCF_ALLOWED_EXTENSION}
              disabled={disabled}
              selectFileButtonTestId={VCF_SELECT_FILE_TEST_ID}
              onChange={onSelectVcfFile}
            />
          </div>
          <div className="col-md-6" data-testid="file-uploader-qc">
            <FileUploader
              uploadedFileName={uploadedQcFileName}
              title="Upload QC JSON File"
              allowedFileExtension={QC_ALLOWED_EXTENSION}
              disabled={disabled}
              selectFileButtonTestId={QC_SELECT_FILE_TEST_ID}
              onChange={onSelectQcFile}
            />
          </div>
        </div>
      </DndProvider>
    );
  }

  return (
    <div
      className={classNames("file-checklist-container", {
        "bg-warning": isModal,
      })}
    >
      <div className="file-checklist">
        {!isModal && (
          <div className="file-checklist-header">
            <h3>Expected SFTP file checklist</h3>
          </div>
        )}

        {isModal && patientId && (
          <>
            <div key="file-entry-1" className="file-entry">
              <i className="fa fa-file-archive-o" aria-hidden="true" />
              <span className="file-entry-name">{`${patientId}_S1_R1_001_dna.fastq.gz`}</span>
            </div>
            <div key="file-entry-2" className="file-entry">
              <i className="fa fa-file-archive-o" aria-hidden="true" />
              <span className="file-entry-name">{`${patientId}_S1_R2_001_dna.fastq.gz`}</span>
            </div>
            {withRna && (
              <>
                <div key="file-entry-3" className="file-entry">
                  <i className="fa fa-file-archive-o" aria-hidden="true" />
                  <span className="file-entry-name">{`${patientId}_S1_R1_001_rna.fastq.gz`}</span>
                </div>
                <div key="file-entry-4" className="file-entry">
                  <i className="fa fa-file-archive-o" aria-hidden="true" />
                  <span className="file-entry-name">{`${patientId}_S1_R2_001_rna.fastq.gz`}</span>
                </div>
              </>
            )}
          </>
        )}

        {!isModal && (
          <>
            <small data-testid="file-checklist">
              Filename uses Illumina name convention (except underscores in the
              Patient ID are forbidden):
              <br />
              <code>
                &lt;Patient
                ID&gt;_S&lt;number&gt;_R&lt;number&gt;_001_&lt;NucleicAcid&gt;.fastq.gz
              </code>
              <br />
              OR
              <br />
              <code>
                &lt;Patient
                ID&gt;_S&lt;number&gt;_L&lt;number&gt;_R&lt;number&gt;_001_&lt;NucleicAcid&gt;.fastq.gz
              </code>
              <h4>Template Legend:</h4>
              <b>Patient ID</b> - Must only contain digits, Latin characters,
              hyphen or full stop. Underscores are forbidden as they are used as
              separators between parts.
              <br />
              <b>S</b> - Sample number in IR (any number allowed, e.g. <i>1</i>
              ).
              <br />
              <b>L</b> (optional) - Lane number (any number allowed, e.g.{" "}
              <i>1</i>
              ). Multiple lane files should be concatenated via Linux command:
              <br />
              <code>
                cat &lt;Patient
                ID&gt;_S&lt;number&gt;_L*_R&lt;number&gt;_001_&lt;NucleicAcid&gt;.fastq.gz&gt;
                Patient1_S1_R1_001_dna.fastq.gz
              </code>
              <br />
              <b>R</b> - Forward/backward read number (either <i>1</i> or{" "}
              <i>2</i> is allowed).
              <br />
              <b>001</b> - The last portion of the Illumina file name is always{" "}
              <i>001</i>.
              <br />
              <b>NucleicAcid</b> - Nucleic acid type (either dna or rna is
              allowed).
              <br />
              <h4>Suggested names:</h4>
              <code>
                {`${
                  hasSample
                    ? patientId
                    : `<Patient ID - substitute this for real id from user input>`
                }_S1_R1_001_dna.fastq.gz`}
              </code>
              <br />
              <code>
                {`${
                  hasSample ? patientId : `<Patient ID>`
                }_S1_R2_001_dna.fastq.gz`}
              </code>
              <br />
              {withRna && (
                <>
                  <code>
                    {`${
                      hasSample ? patientId : `<Patient ID>`
                    }_S1_R1_001_rna.fastq.gz`}
                  </code>
                  <br />
                  <code>
                    {`${
                      hasSample ? patientId : `<Patient ID>`
                    }_S1_R2_001_rna.fastq.gz`}
                  </code>
                  <br />
                </>
              )}
              <br />
            </small>
            <div className="footer-note text-danger">
              <span>
                <i className="fa fa-info-circle" aria-hidden="true" />
              </span>
              <span>
                Please upload the files specified above to the SFTP server after
                the IR submission
              </span>

              <span>
                <i className="fa fa-info-circle" aria-hidden="true" />
              </span>
              <span>{ACCEPTED_FILE_TYPES_MESSAGE}</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps: { showError: (message: string) => void } = {
  showError: messageActions.error,
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FilesPanel);
