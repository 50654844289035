import { Field } from "formik";
import React, { useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";

import { Icon, Link } from "pattern-library";

import FormikFormField from "modules/forms/components/formikFormField/FormikFormField";
import { minAndMaxNumber } from "modules/forms/rules";
import IRMetadataFormFields from "modules/oncologyInterpretationRequests/components/IRMetadataFormFields";
import {
  IR_FORM_FIELD_KEYS,
  IR_FORM_FIELDS_LABELS,
} from "modules/oncologyInterpretationRequests/constants";
import { getBaitsetsAsOptions } from "modules/oncologyInterpretationRequests/redux/selectors";
import {
  FORM_FILE_TYPES,
  IrFormValues,
} from "modules/oncologyInterpretationRequests/types";
import { getStandardTextFieldPlaceholder } from "modules/oncologyInterpretationRequests/utils";
import { oncologyAllowThirdPartyVariantCalling } from "modules/systemConfig";

import { TumourTypeField } from "./TumourTypeField";

const FASTQ_FILE_TYPE_OPTION = {
  label: FORM_FILE_TYPES.FASTQ.toUpperCase(),
  value: FORM_FILE_TYPES.FASTQ,
};
const VCF_FILE_TYPE_OPTION = {
  label: FORM_FILE_TYPES.VCF.toUpperCase(),
  value: FORM_FILE_TYPES.VCF,
};

interface Props extends PropsFromRedux {
  values: IrFormValues;
  disabled: boolean;
}

const IRFormFields = ({
  disabled,
  values: { fileType },
  baitsets,
  oncologyAllowThirdPartyVariantCalling,
}: Props) => {
  const fastqSelected = fileType === FORM_FILE_TYPES.FASTQ;

  const fileTypeOptions = useMemo(
    () =>
      oncologyAllowThirdPartyVariantCalling
        ? [FASTQ_FILE_TYPE_OPTION, VCF_FILE_TYPE_OPTION]
        : [FASTQ_FILE_TYPE_OPTION],
    [oncologyAllowThirdPartyVariantCalling]
  );

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <Field
            component={FormikFormField}
            name={IR_FORM_FIELD_KEYS.patientId}
            id={IR_FORM_FIELD_KEYS.patientId}
            disabled={disabled}
            type="text"
            trimOnBlur
            placeholder={getStandardTextFieldPlaceholder(
              IR_FORM_FIELDS_LABELS.patientId
            )}
            label={IR_FORM_FIELDS_LABELS.patientId}
            narrow
            data-test-id={IR_FORM_FIELD_KEYS.patientId}
          />
        </div>
        <div className="col-md-6">
          <Field
            name={IR_FORM_FIELD_KEYS.tumourType}
            id={IR_FORM_FIELD_KEYS.tumourType}
            component={TumourTypeField}
            disabled={disabled}
            label={IR_FORM_FIELDS_LABELS.tumourType}
            description={
              <Link
                target="_blank"
                href="http://oncotree.mskcc.org/#/home?version=oncotree_2019_12_01"
              >
                <Icon type="newWindow" className="mr-1" />
                Explore Oncotree tumour types and codes
              </Link>
            }
            narrow
            data-test-id={IR_FORM_FIELD_KEYS.tumourType}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <Field
            name={IR_FORM_FIELD_KEYS.neoplasticCellularity}
            id={IR_FORM_FIELD_KEYS.neoplasticCellularity}
            component={FormikFormField}
            type="number"
            normalize={minAndMaxNumber(0, 100)}
            // only allow to enter digits
            inputFilter={/^\d+$/}
            changeOnBlur
            disabled={disabled}
            placeholder={getStandardTextFieldPlaceholder(
              IR_FORM_FIELDS_LABELS.neoplasticCellularity
            )}
            label={IR_FORM_FIELDS_LABELS.neoplasticCellularity}
            title="Neoplastic Cellularity is the pathology estimate of the percentage of the sample going into DNA extraction (after any sectioning and macrodissection) that is tumour cells as opposed to normal cells"
            narrow
            data-test-id={IR_FORM_FIELD_KEYS.neoplasticCellularity}
          />
        </div>
        <div className="col-md-6">
          <Field
            name={IR_FORM_FIELD_KEYS.sex}
            id={IR_FORM_FIELD_KEYS.sex}
            component={FormikFormField}
            type="dropdown"
            emptyPlaceholder="Select Sex"
            options={{
              Male: "Male",
              Female: "Female",
            }}
            disabled={disabled}
            label={IR_FORM_FIELDS_LABELS.sex}
            narrow
            data-test-id={IR_FORM_FIELD_KEYS.sex}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <Field
            name={IR_FORM_FIELD_KEYS.fileType}
            id={IR_FORM_FIELD_KEYS.fileType}
            component={FormikFormField}
            type="radiobuttons"
            disabled={disabled}
            label={IR_FORM_FIELDS_LABELS.fileType}
            items={fileTypeOptions}
            isInline
            narrow
            data-test-id={IR_FORM_FIELD_KEYS.fileType}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          {fileType !== FORM_FILE_TYPES.VCF && (
            <Field
              name={IR_FORM_FIELD_KEYS.baitset}
              id={IR_FORM_FIELD_KEYS.baitset}
              component={FormikFormField}
              emptyPlaceholder="Select baitset"
              type="dropdown"
              options={baitsets}
              disabled={disabled}
              label={IR_FORM_FIELDS_LABELS.baitset}
              narrow
              data-test-id={IR_FORM_FIELD_KEYS.baitset}
            />
          )}
        </div>
        <div className="col-md-6">
          {fastqSelected && (
            <Field
              name={IR_FORM_FIELD_KEYS.withRna}
              id={IR_FORM_FIELD_KEYS.withRna}
              component={FormikFormField}
              type="checkbox"
              disabled={disabled}
              label={IR_FORM_FIELDS_LABELS.withRna}
              narrow
              data-test-id={IR_FORM_FIELD_KEYS.withRna}
            />
          )}
        </div>
      </div>

      <IRMetadataFormFields disabled={disabled} />
    </>
  );
};

const mapStateToProps = state => ({
  baitsets: getBaitsetsAsOptions(state),
  oncologyAllowThirdPartyVariantCalling:
    oncologyAllowThirdPartyVariantCalling(state),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(IRFormFields);
