import { Middleware, MiddlewareAPI, isFulfilled } from "@reduxjs/toolkit";

import { message as messageAction } from "modules/messages/actions";

const headerMessageLevels = ["success", "info", "warning", "error"];

/**
 * Handler that checks a completed API request, and looks for any `x-message-{level}`
 * headers on the response. If any are found, dispatches these as messages so they can be displayed
 */
const rtkQueryResponseMessageHandler: Middleware =
  (api: MiddlewareAPI) => next => action => {
    if (isFulfilled(action)) {
      const { headers } = action.meta?.baseQueryMeta?.response;
      headerMessageLevels.forEach(level => {
        const message = headers.get(`x-message-${level}`);
        if (message) {
          api.dispatch(messageAction(level, decodeURIComponent(message)));
        }
      });
    }

    return next(action);
  };

export default rtkQueryResponseMessageHandler;
