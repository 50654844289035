/* File contains old track code, could be refactored in the future */

import { Track } from "../core/SequenceVariantsCuratedList";
import { Colors, createBadge, getEnsemblUrl } from "../utils";

export const CurateListConfig = () => ({
  id: "Congenica.SequenceVariantsCuratedList",
  namespace: "Congenica.SequenceVariantsCuratedList",
});

export const HGMPropConfig = ({ genome }) => ({
  id: "Congenica.SequenceVariantsCuratedListHGMPro",
  namespace: "Congenica.SequenceVariantsCuratedListHGMPro",
  getPathogenicity(feature) {
    const val_mapping = {
      FTV: "Frameshift or truncating variant",
      FP: "In vitro/laboratory or in vivo functional polymorphism",
      DFP: "Disease associated polymorphism with additional supporting functional evidence",
      DM: "Disease causing mutation",
      R: "Retired entry",
      "DM?": "Questionable disease causing mutation",
      DP: "Disease associated polymorphism",
    };
    return val_mapping[feature.curated_data.N];
  },
  getFeatureColor(feature) {
    const mapping = {
      "Frameshift or truncating variant": {
        color: Colors.ORANGE_DARK,
        labelColor: Colors.WHITE,
      },
      "In vitro/laboratory or in vivo functional polymorphism": {
        color: Colors.RED,
        labelColor: Colors.WHITE,
      },
      "Disease associated polymorphism with additional supporting functional evidence":
        {
          color: Colors.RED,
          labelColor: Colors.WHITE,
        },
      "Disease causing mutation": {
        color: Colors.RED,
        labelColor: Colors.WHITE,
      },
      "Retired entry": {
        color: Colors.GREY_LIGHT,
        labelColor: Colors.BLACK,
      },
      "Questionable disease causing mutation": {
        color: Colors.ORANGE_DARK,
        labelColor: Colors.WHITE,
      },
      "Disease associated polymorphism": {
        color: Colors.RED,
        labelColor: Colors.WHITE,
      },
    };
    return mapping[this.prop("getPathogenicity")(feature)];
  },
  getMenuItems(feature) {
    // BARRRF
    const gdbid = feature.curated_data.B;
    const omimid = feature.curated_data.C;
    const amino = feature.curated_data.D;
    const deletion = feature.curated_data.E;
    const insertion = feature.curated_data.F;
    const codon_aff = feature.curated_data.H;
    const description = feature.curated_data.I;
    const hgvs = feature.curated_data.J;
    const hgvs_all = feature.curated_data.K;
    const dbsnp = feature.curated_data.M;
    const author = feature.curated_data.O;
    const fullname = feature.curated_data.P;
    const vol = feature.curated_data.R;
    const page = feature.curated_data.S;
    const year = feature.curated_data.T;
    const pmid = feature.curated_data.U;
    const comments = feature.curated_data.X;
    const new_date = feature.curated_data.Y;
    const base = feature.curated_data.Z;
    const disease = feature.curated_data.AC;
    const gene = feature.curated_data.AD;
    const band = feature.curated_data.AE;
    const genename = feature.curated_data.AF;

    const tag_map = {
      FTV: "Frameshift or truncating variant",
      FP: "In vitro/laboratory or in vivo functional polymorphism",
      DFP: "Disease associated polymorphism with additional supporting functional evidence",
      DM: "Disease causing mutation",
      R: "Retired entry",
      "DM?": "Questionable disease causing mutation",
      DP: "Disease associated polymorphism",
    };
    feature.legend = tag_map[feature.tag];

    //Base from the Allmut table map to textual descriptions from the docs:
    const base_map = {
      X: {
        name: "Small indel",
        title: "Micro-indels (20 bp or less)",
      },
      S: {
        name: "Splicing",
        title:
          "Single base-pair substitutions with consequences for mRNA splicing",
      },
      D: {
        name: "Small deletion",
        title: "Micro-deletions (20 bp or less)",
      },
      I: {
        name: "Small insertion",
        title: "Micro-insertions (20 bp or less)",
      },
      M: {
        name: "Missense/nonsense",
        title: "Single base-pair substitutions in coding regions",
      },
      G: {
        name: "Gross deletion",
        title: "Deletions over 20 bp ",
      },
      N: {
        name: "Gross insertion",
        title: "Insertions over 20 bp",
      },
      P: {
        name: "Complex rearrangement",
        title: "Recorded in narrative format ",
      },
      E: {
        name: "Repeat variation",
        title: "Recorded in narrative format",
      },
      R: {
        name: "Regulatory",
        title:
          "Single base-pair substitutions causing regulatory abnormalities",
      },
    };

    const pathogenicity = this.prop("getPathogenicity").call(this, feature);
    const color = this.prop("getFeatureColor").call(this, feature);
    const base_obj = base_map[base];

    const output = {
      title: `<a style="font-size:18px;" target="_new" href="https://portal.biobase-international.com/hgmd/pro/mut.php?accession=${feature.title}">
          ${feature.title}
          </a>`,
      Location: `${feature.chr}:${feature.start}-${feature.end} (${band})`,
      "Codon Affected": codon_aff,
      Inclusion: createBadge(color.color) + pathogenicity,
      Consequence: `<span title="${base_obj.title}">${base_obj.name}</span> (${description})`,
      Gene: gene ? gene + " - " + genename : undefined,
      Amino: amino || undefined,
      Deletion: deletion || undefined,
      Insertion: insertion || undefined,
      HGVS: hgvs || undefined,
      "Alt. HGVS": hgvs_all ? hgvs_all.replace(" |", ",") : undefined,
      Disease: disease || undefined,
      Reference: fullname
        ? `${author} et al. (${year}) ${fullname} vol(${vol}) p ${page}`
        : undefined,
      Comments: comments || undefined,
      "GDB ID": gdbid || undefined,
      "OMIM ID": omimid
        ? `<a target="_new" href="https://www.omim.org/entry/${omimid}">${omimid}</a>`
        : undefined,
      dbSNP: dbsnp
        ? `<a target="_new" href="${getEnsemblUrl(
            genome
          )}/Homo_sapiens/Variation/Summary?v=${dbsnp}">${dbsnp}</a>`
        : undefined,
      Added: new_date || undefined,
    };

    const pubmed_link = !isNaN(Number(pmid))
      ? "https://www.ncbi.nlm.nih.gov/pubmed/?term=" + pmid
      : undefined;
    if (pubmed_link) {
      output.Reference = `<a target="_new" href="${pubmed_link}">${output.Reference}</a>`;
    }

    return output;
  },
});

export const HGMDProNewConfig = ({ genome }) => ({
  id: "Congenica.SequenceVariantsCuratedListHGMDProNew",
  namespace: "Congenica.SequenceVariantsCuratedListHGMDProNew",
  getPathogenicity(feature) {
    const val_mapping = {
      FTV: "Frameshift or truncating variant",
      FP: "In vitro/laboratory or in vivo functional polymorphism",
      DFP: "Disease associated polymorphism with additional supporting functional evidence",
      DM: "Disease causing mutation",
      R: "Retired entry",
      "DM?": "Questionable disease causing mutation",
      DP: "Disease associated polymorphism",
    };
    return val_mapping[feature.curated_data.CLASS];
  },
  getFeatureColor(feature) {
    const mapping = {
      "Frameshift or truncating variant": {
        color: Colors.ORANGE_DARK,
        labelColor: Colors.WHITE,
      },
      "In vitro/laboratory or in vivo functional polymorphism": {
        color: Colors.RED,
        labelColor: Colors.WHITE,
      },
      "Disease associated polymorphism with additional supporting functional evidence":
        {
          color: Colors.RED,
          labelColor: Colors.WHITE,
        },
      "Disease causing mutation": {
        color: Colors.RED,
        labelColor: Colors.WHITE,
      },
      "Retired entry": {
        color: Colors.GREY_LIGHT,
        labelColor: Colors.BLACK,
      },
      "Questionable disease causing mutation": {
        color: Colors.ORANGE_DARK,
        labelColor: Colors.WHITE,
      },
      "Disease associated polymorphism": {
        color: Colors.RED,
        labelColor: Colors.WHITE,
      },
    };
    return mapping[this.prop("getPathogenicity")(feature)];
  },
  getMenuItems(feature) {
    // Return an obj that can be inserted into populateMenu
    const output = this.base(feature);

    // DNA annotation               DNA
    output.HGVSc = feature.curated_data.DNA;
    // Protein annotation           PROT
    output.HGVSp = feature.curated_data.PROT;

    // Gene symbol                  GENE
    output["Gene symbol"] = feature.curated_data.GENE;
    // Gene strand                  STRAND
    output.Strand =
      feature.curated_data.STRAND === "+" ? "Positive (+)" : "Negative (-)";

    // dbSNP identifier, build 137  DB
    output["dbSNP ID"] = feature.curated_data.DB;
    // HGMD primary phenotype       PHEN
    output["HGMD primary phenotype"] = feature.curated_data.PHEN.replace(
      /_/g,
      " "
    );

    const db = feature.curated_data.DB;
    const ensembl = getEnsemblUrl(genome);

    output.links = [
      `<a target="_blank" href="https://www.ncbi.nlm.nih.gov/SNP/snp_ref.cgi?rs=${db}">dbSNP</a>`,
      `<a target="_blank" href="${ensembl}/Homo_sapiens/Variation/Summary?v=${db}">ensembl</a>`,
    ].join(", ");

    return output;
  },
});

export const ClinvarConfig = () => ({
  id: "Congenica.SequenceVariantsCuratedListClinvar",
  namespace: "Congenica.SequenceVariantsCuratedListClinvar",
  legend: true,
  getPathogenicity(feature) {
    // If clnsig is not provided OR pathogenicity is not provided OR it's in the old format of an array return false
    if (
      !feature.curated_data.CLNSIG ||
      Array.isArray(feature.curated_data.CLNSIG) ||
      Number.isInteger(
        parseInt(feature.curated_data.CLNSIG) ||
          !feature.curated_data.PATHOGENICITY
      )
    ) {
      return false;
    }
    // Return clnsig if available, return pathogenicity otherwise.
    return feature.curated_data.CLNSIG || feature.curated_data.PATHOGENICITY;
  },
  getFeatureColor(feature) {
    const mapping = {
      "Uncertain significance": {
        color: Colors.LIGHT_YELLOW,
        highlight: Colors.LIGHT_YELLOW,
      }, //0 - Uncertain significance
      "Not provided": {
        color: Colors.GREY_LIGHT,
        highlight: Colors.GREY_LIGHT,
      }, //1 - not provided
      Benign: {
        color: Colors.LIGHT_BLUE,
        highlight: Colors.LIGHT_BLUE,
      }, //2 - Benign
      "Likely benign": {
        color: Colors.LIGHT_BLUE,
        highlight: Colors.LIGHT_BLUE,
      }, //3 - Likely benign
      "Likely pathogenic": {
        color: Colors.ORANGE_DARK,
        highlight: Colors.ORANGE_DARK,
      }, //4 - Likely pathogenic
      Pathogenic: {
        color: Colors.RED_BRIGHT,
        highlight: Colors.RED_BRIGHT,
      }, //5 - Pathogenic
      "Drug response": {
        color: Colors.BLUE_DARK,
        highlight: Colors.WHITE,
      }, //6 - drug response
      Histocompatibility: {
        color: Colors.LIGHT_GREEN,
        highlight: Colors.WHITE,
      }, //7 - histocompatibility
      Other: {
        color: Colors.CYAN,
        highlight: Colors.WHITE,
      }, //255 - other
    };

    let pathogenicity = this.prop("getPathogenicity")(feature);

    if (typeof pathogenicity === "string") {
      // Fix values which are Likely_benign
      pathogenicity = pathogenicity.replace(/_/g, " ");
      if (mapping[pathogenicity]) {
        return mapping[pathogenicity];
      }
    }

    return {
      color: Colors.MINE_SHAFT,
      highlight: Colors.WHITE,
    };
  },
  getMenuItems(feature) {
    // Return an obj that can be inserted into populateMenu
    const output = this.base(feature);
    const measuresetId = feature.curated_data.MEASURESET_ID;

    output.title =
      "VCV" +
      String(measuresetId || "").padStart(9, "0") +
      " - " +
      feature.list_name;
    if (measuresetId) {
      output.link = `<a target="_blank" href="https://www.ncbi.nlm.nih.gov/clinvar/variation/${measuresetId}/">ClinVar</a>`;
    }

    output.type = feature.curated_data.VC;

    if (feature.curated_data.DSS) {
      output["in-donor splice site"] = "yes";
    }

    // in intron yes/no
    if (feature.curated_data.INT) {
      output["in intron"] = "yes";
    }

    //variant names from hgvs curated_data.CLNHGVS
    if (feature.curated_data.CLNHGVS) {
      output["variant names"] = feature.curated_data.CLNHGVS.join("<br/>");
    }

    //Has non-synonymous frameshift  .NSF
    if (feature.curated_data.NSF) {
      output["has non-synonymous frameshift"] = "yes";
    }
    // .R3
    if (feature.curated_data.R3) {
      output["In 3' gene region"] = "yes";
    }

    // Allele origin .CLNORIGIN
    if (feature.curated_data.CLNORIGIN) {
      /* One or more of the following values may be added:
    0 - unknown;
    1 - germline;
    2 - somatic;
    4 - inherited;
    8 - paternal;
    16 - maternal;
    32 - de-novo;
    64 - biparental;
    128 - uniparental;
    256 - not-tested;
    512 - tested-inconclusive;
    1073741824 - other;
    */

      const bitmask = feature.curated_data.CLNORIGIN[0];

      // Basically is this a string value we just join and return
      if (isNaN(parseInt(bitmask)) && feature.curated_data.CLNORIGIN) {
        output["Allele Origin"] = feature.curated_data.CLNORIGIN.join(
          ","
        ).replace("_", " ");
        //handle the head and tail 'special' values differently
      } else if (bitmask === "0") {
        output["Allele Origin"] = "unknown";
      } else if (bitmask >= 1073741824) {
        output["Allele Origin"] = "other";
      } else {
        const mask_values = [
          { val: 1, name: "germline" },
          { val: 2, name: "somatic" },
          { val: 4, name: "inherited" },
          { val: 8, name: "paternal" },
          { val: 16, name: "maternal" },
          { val: 32, name: "de-novo" },
          { val: 64, name: "biparental" },
          { val: 128, name: "uniparental" },
          { val: 256, name: "not-tested" },
          { val: 512, name: "tested-inconclusive" },
        ];

        output["Allele Origin"] = mask_values
          .filter(
            i => bitmask & i.val // eslint-disable-line no-bitwise
          ) // filter out the ones that don't match the mask
          .map(i => i.name) // only return the names
          .join(","); // join the arrays into a string
      }
    }
    return output;
  },
});

export const DecipherConfig = () => ({
  id: "Congenica.SequenceVariantsCuratedListDecipher",
  namespace: "Congenica.SequenceVariantsCuratedListDecipher",
  legend: true,
  getPathogenicity(feature) {
    const pathogenicityValue = feature.curated_data.PATHOGENICITY;
    if (!pathogenicityValue) {
      return false;
    }
    return pathogenicityValue;
  },
  getFeatureColor(feature) {
    const mapping = {
      Pathogenic: {
        color: Colors.RED_BRIGHT,
        highlight: Colors.RED_BRIGHT,
      },
      Likely_pathogenic: {
        color: Colors.ORANGE_DARK,
        highlight: Colors.ORANGE_DARK,
      },
      Uncertain_significance: {
        color: Colors.LIGHT_YELLOW,
        highlight: Colors.LIGHT_YELLOW,
      },
      Likely_benign: {
        color: Colors.LIGHT_BLUE,
        highlight: Colors.LIGHT_BLUE,
      },
      Benign: {
        color: Colors.LIGHT_BLUE,
        highlight: Colors.LIGHT_BLUE,
      },
    };
    const pathogenicity = this.prop("getPathogenicity")(feature);
    if (mapping[pathogenicity]) {
      return mapping[pathogenicity];
    }
    return {
      color: Colors.MINE_SHAFT,
      highlight: Colors.WHITE,
    };
  },
  getMenuItems(feature) {
    const getDecipherLinks = function (feature) {
      const patientIds = feature.curated_data.PATIENT;
      if (patientIds && patientIds.length) {
        const maxLinksNumber = 10;
        const links = (patientIds || [])
          .slice(0, maxLinksNumber)
          .map(
            patientId =>
              `<a target="_blank" href="https://www.deciphergenomics.org/patient/${patientId}/genotype">${patientId}</a>`
          );
        return links.join(", ");
      }
      return "";
    };
    const output = this.base(feature);
    const decipherLinks = getDecipherLinks(feature);
    if (decipherLinks) {
      output["Decipher Links"] = decipherLinks;
    }
    return output;
  },
});

export const MasterMindConfig = () => ({
  id: "Congenica.SequenceVariantsCuratedListMasterMind",
  namespace: "Congenica.SequenceVariantsCuratedListMasterMind",
  threshold: 1e5,
  legend: true,
  getFeatureColor() {
    return {
      color: Colors.MINE_SHAFT,
      highlight: Colors.WHITE,
    };
  },
  getMenuItems(feature) {
    const { title, list_name } = feature;
    const { MMCNT1, MMCNT2, MMCNT3, MMURI3, GENE } = feature.curated_data;

    const createMastermindLinks = urls => {
      const maxLinksNumber = 10;
      return (urls || [])
        .slice(0, maxLinksNumber)
        .map(uri => {
          const url = new URL(uri);
          const label = url.searchParams.get("mutation");
          url.searchParams.set("ref", "rs-congenica");
          return `<a target="_blank" href="${url.toString()}">${label}</a>`;
        })
        .join(" ,");
    };

    const popup = {
      title: `${title} - ${list_name}`,
      Gene: (GENE || []).map(gene => gene).join(", "),
      "The number of referenced articles": `MMCNT1=${MMCNT1}, MMCNT2=${MMCNT2}, MMCNT3=${MMCNT3}`,
    };

    if (MMURI3 && MMURI3.length) {
      popup["Links to Mastermind"] = createMastermindLinks(MMURI3);
    }

    return popup;
  },
});

export const SequenceVariantsCuratedList = config =>
  Track.extend(CurateListConfig(config));

export const SequenceVariantsCuratedListHGMPro = config =>
  Track.extend(HGMPropConfig(config));

export const SequenceVariantsCuratedListHGMDProNew = config =>
  Track.extend(HGMDProNewConfig(config));

export const SequenceVariantsCuratedListClinvar = () =>
  Track.extend(ClinvarConfig());

export const SequenceVariantsCuratedListDecipher = () =>
  Track.extend(DecipherConfig());

export const SequenceVariantsCuratedListMasterMind = () =>
  Track.extend(MasterMindConfig());
