import { isNil } from "ramda";

import { VariantType } from "modules/oncologyExplore/common-types";

export const prepareOncologyVariantsPatch = (
  type:
    | "oncology_dna_copy_number_variant"
    | "oncology_snv_variants"
    | "oncology_fusion_variants",
  variants: VariantType[],
  patch: Record<string, boolean>
) =>
  variants.reduce((acc, e) => {
    if (!isNil(patch[e.id])) {
      acc.push({
        data: {
          type,
          attributes: {
            reclassified_driver: patch[e.id],
          },
          id: e.id,
        },
      });
    }
    return acc;
  }, [] as any[]);
