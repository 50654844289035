import Genoverse from "genoverse";

import { Colors, isValidAllele } from "../utils";

export const SequenceVariationViewConfig = {
  height: 100, // Height of entire track
  featureHeight: 15, // Height of feature - NB. this also affects the reference sequence height
  baseHeight: 15, // Height of base element
  featureMargin: { top: 20, right: 0, bottom: 10, left: 0 },
  bump: false,
  showLegend: false,
  markerSize: 16,

  draw(features, featureContext, labelContext, scale) {
    const featureIds = {}; // Lookup for unique snv features
    const drawing = { seq: [], snv: [] }; // seq = reference sequence; snv = variants

    // Order features so that current snv is bottom (and drawn last)
    // This is only useful if overlapping features
    features.sort((a, b) => {
      if (a.isCurrent && !b.isCurrent) return -1;
      if (!a.isCurrent && b.isCurrent) return 1;
      return 0;
    });

    // Separate out reference sequence(seq) from snv(snv)
    features.forEach(feature => {
      // snvId is the patient_snv_id, not a variant_id
      const patient_snv_id = feature.snvId;

      const either_id = feature.snv_id || patient_snv_id;
      const drawingKey = isValidAllele(feature) ? "snv" : "seq";
      //we can have multiple features with the same start position
      const featureIdKey =
        (either_id ? feature.start + ":" + either_id : feature.start) +
        "_" +
        drawingKey;

      if (!featureIds[featureIdKey]) {
        drawing[drawingKey].push(feature);
        featureIds[featureIdKey] = 1;
      }
    });

    // 1. Highlight SNVs
    // (Region spanning ref allele on ref sequence and alt allele just below)
    this.highlightSNVs(drawing.snv, featureContext, scale, true, false);

    // 2. Draw reference sequence
    this.base(drawing.seq, featureContext, labelContext, scale);

    // 3. Draw SNV BPs (NB. after highlighting region)
    this.drawSNV(drawing.snv, featureContext, labelContext, scale);

    // 4. Outline SNVs
    // (Region spanning ref allele on ref sequence and alt allele just below)
    this.highlightSNVs(drawing.snv, featureContext, scale, false, true);

    // 5. Draw genotype and map marker
    this.drawGenotypes(drawing.snv, featureContext, scale);
  },

  highlightSNVs(features, context, scale, isHighlight, isOutline) {
    features.forEach(feature => {
      const position = feature.position[scale];
      const posX = position.X;

      /*********************************************************
       Highlight coordinates
       1. Top left (ref allele)
       2. Bottom left (alt allele)
       3. Bottom right (alt allele)
       4. Top right (alt allele)
       5. Bottom right (ref allele)
       6. Top right (ref allele)
       *********************************************************/

      const positionX = [
        posX,
        posX,
        posX + feature.alt_allele.length * scale,
        posX + feature.alt_allele.length * scale,
        posX + feature.ref_allele.length * scale,
        posX + feature.ref_allele.length * scale,
      ];

      const positionY = [
        position.Y,
        position.Y + 3 * this.baseHeight,
        position.Y + 3 * this.baseHeight,
        position.Y + 2 * this.baseHeight,
        position.Y + this.baseHeight,
        position.Y,
      ];

      // Polygon path
      context.beginPath();

      context.moveTo(positionX[0], positionY[0]);

      [...Array(positionX.length - 1)].forEach((e, i) => {
        const j = i + 1;
        context.lineTo(positionX[j], positionY[j]);
      });

      context.closePath();

      if (!feature.highlightColor) {
        this.setHighlightColor(feature);
      }
      context.fillStyle = feature.highlightColor;
      context.strokeStyle = feature.outlineColor;

      // Stroke outline
      if (isOutline) {
        context.save();
        context.lineWidth = 2;
        context.stroke();
        context.restore();
      }

      // Shade/highlight region
      if (isHighlight) {
        context.save();
        context.globalAlpha = 0.5;
        context.fill();
        context.restore();
      }
    });
  },

  drawSNV(features, featureContext, labelContext, scale) {
    featureContext.textBaseline = "middle";
    featureContext.textAlign = "left";

    if (!this.labelWidth[this.widestLabel]) {
      this.labelWidth[this.widestLabel] =
        Math.ceil(this.context.measureText(this.widestLabel).width) + 1;
    }

    const width = Math.max(scale, this.minScaledWidth);

    [...Array(features.length)].forEach((_, i) => {
      this.drawSNVSequence(features[i], featureContext, scale, width);
    });
  },

  drawSNVSequence(feature, context, scale, width) {
    const drawLabels = this.labelWidth[this.widestLabel] < width - 1;
    const yPos = feature.position[scale].Y + 2 * this.baseHeight;

    [...Array(feature.sequence.length)].forEach((_, i) => {
      const start = feature.position[scale].X + i * scale;

      if (start < -scale || start > context.canvas.width) {
        return;
      }

      const bp = feature.sequence.charAt(i);

      context.fillStyle = this.colors[bp] || this.colors.default;

      context.fillRect(start, yPos, width, this.baseHeight);

      if (!this.labelWidth[bp]) {
        this.labelWidth[bp] = Math.ceil(context.measureText(bp).width) + 1;
      }

      if (drawLabels) {
        context.fillStyle = this.labelColors[bp] || this.labelColors.default;
        context.fillText(
          bp,
          start + (width - this.labelWidth[bp]) / 2,
          yPos + this.labelYOffset
        );
      }
    });
  },

  drawGenotypes(features, context, scale) {
    features.forEach(feature => {
      const position = feature.position[scale];
      const posX = position.X + scale / 2;
      const posY = position.Y;

      this.drawGenotype(
        feature.genotype,
        feature.genoTypeColor,
        context,
        posX,
        posY + 3 * this.baseHeight,
        scale
      );

      if (feature.isCurrent) {
        this.drawMapMarker(
          context,
          posX,
          posY,
          this.prop("markerSize"),
          feature.color
        );
      }
    });
  },

  drawMapMarker(context, x, y, markerSize, color) {
    context.save();

    if (color) context.fillStyle = color;

    context.font = markerSize + "px Glyphicons Halflings";
    //\e062
    context.fillText(
      String.fromCharCode(57442),
      x - markerSize / 2,
      y - markerSize / 2
    );
    context.restore();
  },

  drawGenotype(genoType, color, context, x, y, scale) {
    const minRadius = 1; //Minimum size of circle
    const maxRadius = 4; //Maximum size of circle
    const scaleRadius = 0.06; //Scaled radius
    const radius = Math.min(maxRadius, minRadius + scale * scaleRadius);

    const minSpacing = 4;
    const maxSpacing = 14;
    const scaleSpacing = 0.15; //Scaled radius
    const spacing = Math.min(maxSpacing, minSpacing + scale * scaleSpacing);

    context.save();

    //Draw first dot
    context.beginPath();
    context.arc(x, y + spacing, radius, 0, 2 * Math.PI, false);

    if (color) context.fillStyle = color;
    context.fill();

    //Draw second dot below if homozygous
    if (genoType === "Homozygous") {
      context.beginPath();
      context.arc(x, y + 2 * spacing, radius, 0, 2 * Math.PI, false);
      context.fill();
    }

    context.restore();
  },

  setHighlightColor(feature) {
    const { outlineColor, alt_allele, ref_allele } = feature;
    if (outlineColor) {
      feature.highlightColor = outlineColor;
    } else {
      feature.highlightColor =
        alt_allele === "." || alt_allele.length < ref_allele.length
          ? Colors.LIGHT_RED
          : Colors.LIGHT_GREEN;
    }
  },
};
export const SequenceVariationView = Genoverse.Track.View.Sequence.extend({
  ...SequenceVariationViewConfig,
});

export const SequenceVariationModelConfig = {
  setDefaults() {
    this.base();
    this.seqModel = Genoverse.Track.Model.Sequence.extend({
      url: `/API/Seq/${this.track.genome.toLowerCase()}/__CHR__:__START__-__END__`,
    });
  },

  insertFeature(feature) {
    return this.base(feature, true);
  },
};
export const SequenceVariationModel =
  Genoverse.Track.Model.SequenceVariation.extend({
    ...SequenceVariationModelConfig,
  });
