import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Field } from "redux-form";

import { Label } from "pattern-library";

import { ReduxFormField } from "modules/forms/components";
import { Mode } from "modules/projectUsers/types";
import { getProjectRoles } from "modules/roles";
import { getAuthProvidersOptions } from "modules/systemConfig";

import { ADD_PROJECT_USER, EDIT_PROJECT_USER } from "../constants";
import {
  getCurrentUserAuthProviderOptions,
  getPatientVisibilityEnabled,
  getVisibilityLevelsOptions,
} from "../selectors";

type Props = {
  mode: Mode;
  isAdmin: boolean;
  doubleUserCheck: boolean;
} & PropsFromRedux;

const getAuthProvidersField = (authProvidersOptions: Array<AuthProvider>) => (
  <>
    {Object.keys(authProvidersOptions).length > 1 && (
      <>
        <Label>Authentication provider</Label>
        <Field
          data-testid="authenticationProviderId"
          name="authenticationProviderId"
          component={ReduxFormField}
          type="dropdown"
          options={authProvidersOptions}
        />
      </>
    )}
  </>
);

export const UserForm: React.FC<Props> = ({
  visibilityLevelsOptions,
  patientVisibilityEnabled,
  allRoles,
  mode,
  isAdmin,
  doubleUserCheck,
  systemProviderOptions,
  userAuthProviderOptions,
}) => {
  const getRoles = roles =>
    roles.map(({ name, label, description, onlyDoubleUserCheck }) => {
      // We don't want to show the admin role twice, as this is handled separately,
      // and when the double user check is turned off, we need to filter those results out
      const roleIsFilteredOut =
        name === "admin" || (!doubleUserCheck && onlyDoubleUserCheck);

      if (roleIsFilteredOut) {
        return null;
      }

      return (
        <div
          key={name}
          data-testid={`user-form-role-${name}`}
          className="users-form-editor"
        >
          <Field
            className="users-form-editor-label"
            component={ReduxFormField}
            type="toggle"
            name={`roles.${name}`}
          />
          <div>
            <strong>{label}</strong>
            <br />
            <em>({description})</em>
          </div>
        </div>
      );
    });

  return (
    <fieldset className="d-table">
      <Label>Name</Label>
      <Field
        data-testid="fullName"
        disabled={mode !== ADD_PROJECT_USER}
        component={ReduxFormField}
        type="text"
        name="fullName"
      />
      <Label>Email Address</Label>
      <Field
        data-testid="email"
        disabled={mode !== ADD_PROJECT_USER}
        component={ReduxFormField}
        type="text"
        name="email"
      />
      <Label>Project</Label>
      <Field
        data-testid="projectName"
        disabled
        component={ReduxFormField}
        type="text"
        name="projectName"
      />

      <Label>Make project administrator?</Label>
      <div className="users-form-editor">
        <Field
          data-testid="roles.admin"
          className="users-form-editor-label"
          component={ReduxFormField}
          type="toggle"
          name="roles.admin"
        />
      </div>

      <div className="form-group" id="project_user_access_level_row">
        <Label>Access level</Label>
        <div className="row">
          <div className="col-md-12">
            By default all users within the project have at least read-only
            access level.
          </div>
          <div className="col-md-12">
            An individual can have multiple additional access permissions within
            the project:
          </div>
        </div>
        {!isAdmin && (
          <div data-testid="user-modal-form-roles">
            {allRoles && getRoles(allRoles)}
          </div>
        )}
      </div>

      {patientVisibilityEnabled && (
        <>
          <Label>Visibility Level</Label>
          <Field
            data-testid="visibilityLevelId"
            name="visibilityLevelId"
            component={ReduxFormField}
            type="dropdown"
            options={visibilityLevelsOptions}
          />
        </>
      )}
      {getAuthProvidersField(
        mode === EDIT_PROJECT_USER
          ? userAuthProviderOptions
          : systemProviderOptions
      )}
    </fieldset>
  );
};

const mapStateToProps = state => ({
  visibilityLevelsOptions: getVisibilityLevelsOptions(state),
  userAuthProviderOptions: getCurrentUserAuthProviderOptions(state),
  systemProviderOptions: getAuthProvidersOptions(state),
  patientVisibilityEnabled: getPatientVisibilityEnabled(state),
  allRoles: getProjectRoles(state),
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UserForm);
