import { FC, useMemo } from "react";

import { Button, Icon } from "pattern-library";

import { titleCase } from "modules/utils/string";

interface Props {
  actionName: string;
  disabled: boolean;
  onClick: (actionName: string) => void;
}

// TODO: unify component to show actions in QC, Explore & Report tabs
const Action: FC<Props> = ({ actionName, disabled, onClick }) => {
  const handleClick = () => {
    onClick(actionName);
  };

  const { text, context, icon } = useMemo(() => {
    let context = "success";
    let icon = "okSign";

    if (actionName.includes("undo")) {
      context = "default";
      icon = "repeat";
    } else if (actionName.includes("reject")) {
      context = "danger";
      icon = "cross";
    }

    return {
      text: titleCase(actionName),
      context,
      icon,
    };
  }, [actionName]);

  return (
    <Button
      data-testid={`${actionName}-button`}
      disabled={disabled}
      onClick={handleClick}
      context={context}
    >
      <Icon type={icon} className="oncology-report__button__icon" />
      {text}
    </Button>
  );
};

export default Action;
