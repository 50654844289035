import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { camelizeKeys } from "humps";

const baseQuery = fetchBaseQuery({
  prepareHeaders: (headers, { extra }) => {
    if ((extra as ExtraOptions)?.legacy)
      headers.set("Accept", "application/vnd.congenica.json");
    return headers;
  },
});

type ExtraOptions = {
  /**
   * Camel case the keys on response object
   */
  camelize?: boolean;

  /**
   * Is this a legacy endpoint that requires the `application/vnd.congenica.json`
   * Accept header to be set?
   */
  legacy?: boolean;
};

export const baseQueryWithCamelize: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  ExtraOptions
> = async (args, api, extraOptions) => {
  const modifiedApi = {
    ...api,
    extra: extraOptions,
  };
  const result = await baseQuery(args, modifiedApi, extraOptions);
  if (result.data && extraOptions?.camelize) {
    result.data = camelizeKeys(result.data as any);
  }
  return result;
};

const baseApi = createApi({
  reducerPath: "congenicaApi",
  baseQuery: baseQueryWithCamelize,
  tagTypes: [
    "ReportProposal",
    "PatientStatus",
    "ProjectFeaturesSettings",
    "TempGenePanel",
    "TempGenePanelGenes",
    "GenePanelInfo",
    "GenePanelPatients",
    "GenePanelGenes",
    "GenePanelGenesSearch",
    "ProjectGenePanels",
    "OncologyVariants",
    "ProjectPatientsOfflineStatuses",
  ],
  endpoints: () => ({}),
});

export default baseApi;
