import React, { useMemo } from "react";

import { Modal } from "pattern-library";

import FilesPanel from "modules/oncologyInterpretationRequests/components/FilesPanel";
import {
  FORM_FILE_TYPES,
  IrFormValues,
} from "modules/oncologyInterpretationRequests/types";

const ModalHeader = () => (
  <div className="text-danger attention-message">
    <span>
      <i className="fa fa-info-circle" aria-hidden="true" />
    </span>
    <span>Please upload the following files to the SFTP server.</span>
  </div>
);

interface Props {
  show: boolean;
  values: IrFormValues;
  close: () => void;
  createNewIR: () => void;
}

const IRSubmittedModal: React.FC<Props> = ({
  show,
  values,
  close,
  createNewIR,
}) => {
  const hideModalHeader = useMemo(
    () => values.fileType === FORM_FILE_TYPES.VCF,
    [values]
  );

  const { withRna } = values;

  return (
    <Modal
      title="Your Interpretation Request has been successfully submitted"
      close={close}
      show={show}
    >
      <div className="alert alert-success">
        <h5 className="text-center">
          Your interpretation Request has been successfully submitted
        </h5>

        {!hideModalHeader && <ModalHeader />}

        <FilesPanel isModal withRna={withRna} disabled={false} />

        <div className="button-panel">
          <button
            type="button"
            className="btn btn-success"
            onClick={createNewIR}
          >
            Create new IR
          </button>
          <button
            type="button"
            className="btn btn-primary pull-right"
            onClick={close}
          >
            OK
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default IRSubmittedModal;
