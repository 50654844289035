import { createSelector } from "@reduxjs/toolkit";
import { isEmpty, isNil, omit, path } from "ramda";

import {
  getConfigExclusions,
  getCurrentProjectCuratedLists,
} from "modules/config/selectors";
import * as projectSelectors from "modules/project/selectors";

import { isRequestInProgress } from "../../../../common/selectors";
import { getFormValues } from "../../../forms/selectors";

import {
  MODULE_NAME,
  SV,
  SNV,
  ANY_GENE_PANEL_ID,
  CVL_VARIANT_TYPES,
  SNV_PRESET_DEFAULTS,
} from "./constants";
import * as constants from "./constants";
import { filterSNVExclusions } from "./utils";

export const uiPath = (...items: any) => path([MODULE_NAME, "ui", ...items]);
export const dataPath = (...items: any) =>
  path([MODULE_NAME, "data", ...items]);

export const getFilterPresets = dataPath("filterPresets");
export const getSelectedPreset = dataPath("selectedPreset");
export const isConfigurationInherited = dataPath("isConfigurationInherited");
export const getSVColumnsStateValues = dataPath(
  "selectedPreset",
  "config",
  "columns"
);

export const getSNVColumnsStateValues = dataPath(
  "selectedPreset",
  "config",
  "columns"
);

export const getDictionary = (state, dictionaryName) =>
  dataPath("dictionaries", dictionaryName)(state);

export const getFilterPresetsRequestStatus = uiPath(
  "filterPresetsRequestStatus"
);
export const getPresetSubmitStatus = uiPath("filterPresetSubmitStatus");
export const getPresetRemoveStatus = uiPath("filterPresetRemoveStatus");
export const getInheritanceSettingsSubmitStatus = uiPath(
  "inheritanceSettingsSubmitStatus"
);

export const isPresetSubmitInProgress = state =>
  isRequestInProgress(getPresetSubmitStatus(state));

export const isLoading = state =>
  isRequestInProgress(getFilterPresetsRequestStatus(state)) ||
  isRequestInProgress(getInheritanceSettingsSubmitStatus(state)) ||
  isRequestInProgress(getPresetSubmitStatus(state)) ||
  isRequestInProgress(getPresetRemoveStatus(state));

export const getSVFilterPresets = createSelector(getFilterPresets, presets =>
  presets.filter(({ variantType }) => variantType === SV)
);

export const getSNVFilterPresets = createSelector(getFilterPresets, presets =>
  presets.filter(({ variantType }) => variantType === SNV)
);

export const addKeyProp = (array, keyProp = "value") =>
  array.map(item => ({ ...item, key: item[keyProp] }));

export const getDictionaryOptions = createSelector(
  (state, dictionaryName) => getDictionary(state, dictionaryName),
  (dictionary = []) => addKeyProp(dictionary)
);

const isAnyGeneOption = option => option.value === ANY_GENE_PANEL_ID;

export const getFilterGenePanelOptions = createSelector(
  (state, projectId) => getGenePanelsOptions(state, projectId),
  (state, projectId) => getArchivedGenePanelsOptions(state, projectId),
  getSelectedPreset,
  state => getFormValues(state, constants.FORM, "config", "genePanel"),
  (
    genePanelOptions,
    archivedGenePanelOptions,
    selectedFilterPreset,
    selectedPanelsIds = []
  ) => {
    const isAnyGeneSelected = selectedPanelsIds.some(
      panelId => panelId === ANY_GENE_PANEL_ID
    );

    const hasSelectedValues =
      !isNil(selectedPanelsIds) && !isEmpty(selectedPanelsIds);

    const selectedFilterPresetGenePanels = path([
      "config",
      "filters",
      "genePanel",
    ])(selectedFilterPreset);

    const archivedGenePanelsInValue = archivedGenePanelOptions.filter(
      ({ value }) =>
        selectedFilterPresetGenePanels &&
        selectedFilterPresetGenePanels.length &&
        selectedFilterPresetGenePanels.some(
          genePanelId => genePanelId === value
        )
    );

    const result = [...genePanelOptions];

    result.push({
      label: "Archived panels",
      options: archivedGenePanelsInValue,
    });

    return result.map(groupOption => ({
      ...groupOption,
      options: groupOption.options.map(option => {
        const isDisabled =
          hasSelectedValues &&
          ((!isAnyGeneOption(option) && isAnyGeneSelected) ||
            (isAnyGeneOption(option) && !isAnyGeneSelected));
        return {
          ...option,
          isDisabled,
        };
      }),
    }));
  }
);

export const mapGenePanelOption = panel => ({
  label: panel.title,
  value: panel.genePanelId,
  key: panel.genePanelId,
  ...(panel.version && {
    tags: [{ label: `version ${panel.version}`, className: "version-label" }],
  }),
});

export const getArchivedGenePanelsOptions = createSelector(
  (state, projectId) => projectSelectors.getSingleProject(state, projectId),
  project => {
    const { genePanels = [] } = project || {};
    return genePanels
      .filter(panel => panel.archived === 1)
      .map(mapGenePanelOption);
  }
);

export const getGenePanelsOptions = createSelector(
  (state, projectId) => projectSelectors.getSingleProject(state, projectId),
  project => {
    const { genePanels = [] } = project || {};
    return [
      {
        label: "",
        options: [
          {
            label: "Any SV associated with a gene",
            value: ANY_GENE_PANEL_ID,
            key: ANY_GENE_PANEL_ID,
          },
        ],
      },
      {
        label: "Project panels",
        options: genePanels
          .filter(panel => panel.archived === 0)
          .map(mapGenePanelOption),
      },
    ];
  }
);

export const getCVLsForProjectOptions = createSelector(
  state => getCurrentProjectCuratedLists(state),
  (lists = []) =>
    lists.reduce((accumulator, list) => {
      const { variantType, curatedVariantListId, name } = list;
      if (
        variantType === CVL_VARIANT_TYPES.SV ||
        variantType === CVL_VARIANT_TYPES.CNV
      ) {
        accumulator.push({
          label: name,
          value: curatedVariantListId,
          key: curatedVariantListId,
        });
      }
      return accumulator;
    }, [])
);

export const getSNVInitialValues = createSelector(
  state => getSelectedPreset(state),
  selectedPreset => {
    const { filters, prioritisation, columns } = selectedPreset.config;
    return {
      ...omit(["config"], selectedPreset),
      ...filters,
      prioritisation,
      columns,
    };
  }
);

export const getSNVPresetDefaults = createSelector(
  getConfigExclusions,
  exclusions => filterSNVExclusions(SNV_PRESET_DEFAULTS, exclusions)
);
