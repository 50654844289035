import React from "react";

import { Checkbox, Icon } from "pattern-library";

import { DriverClassificationCellProps } from "../common-types";

import styles from "./DriverClassificationCell.module.scss";

export const DriverClassificationCell =
  ({
    variantDriverClassifications,
    setVariantDriverClassifications,
    reclassificationInProgress,
  }: DriverClassificationCellProps) =>
  rowInfo => {
    const {
      row: {
        original: { id },
      },
    } = rowInfo;

    const handleClick = e => e.stopPropagation();
    const handleChange = ({ target: { checked } }) =>
      setVariantDriverClassifications({
        ...variantDriverClassifications,
        [id]: checked,
      });

    const className = styles["driver-checkbox"];

    return reclassificationInProgress ? (
      <Checkbox
        containerClass={className}
        name={id}
        onChange={handleChange}
        onClick={handleClick}
        value={variantDriverClassifications[id]}
      />
    ) : variantDriverClassifications[id] ? (
      <Icon className={className} type="check" />
    ) : (
      <Icon className={className} type="unchecked" />
    );
  };
