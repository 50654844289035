import React, { memo, useMemo } from "react";

import {
  DriverClassificationCellProps,
  OncologyExploreComponentProps,
} from "../common-types";
import { CommonContainer } from "../components/CommonContainer";
import { DriverClassificationCell } from "../components/DriverClassificationCell";
import { getRowProps } from "../components/utils";

const getColumns = (
  driverClassificationCellProps: DriverClassificationCellProps
) => [
  {
    Header: "Fusion Name",
    accessor: "name",
    id: "name",
  },
  {
    Header: "Gene A",
    accessor: "gene_a_symbol",
    id: "gene_a_symbol",
  },
  {
    Header: "Gene B",
    accessor: "gene_b_symbol",
    id: "gene_b_symbol",
  },
  {
    Header: "Chromosome A",
    accessor: "chrom_a",
    id: "chrom_a",
  },
  {
    Header: "Chromosome B",
    accessor: "chrom_b",
    id: "chrom_b",
  },
  {
    Header: "Position A",
    accessor: "pos_a",
    id: "pos_a",
  },
  {
    Header: "Position B",
    accessor: "pos_b",
    id: "pos_b",
  },
  {
    Header: "Junction Read Count",
    accessor: "junction_read_count",
    id: "junction_read_count",
  },
  {
    Header: "Spanning Fragment Count",
    accessor: "spanning_frag_count",
    id: "spanning_frag_count",
  },
  {
    Header: "Driver",
    disableFilters: true,
    accessor: "driver",
    Cell: DriverClassificationCell(driverClassificationCellProps),
    disableSortBy: true,
  },
];

const FusionsContainer: React.FC<OncologyExploreComponentProps> = ({
  data,
  variantDriverClassifications,
  setVariantDriverClassifications,
  reclassificationInProgress,
}) => {
  const defaultSortBy = useMemo(() => [{ id: "name", desc: false }], []);

  const columns = useMemo(
    () =>
      getColumns({
        variantDriverClassifications,
        setVariantDriverClassifications,
        reclassificationInProgress,
      }),
    [
      reclassificationInProgress,
      setVariantDriverClassifications,
      variantDriverClassifications,
    ]
  );

  return (
    <CommonContainer
      titleSegment="fusions"
      data={data}
      columns={columns}
      getRowProps={getRowProps(variantDriverClassifications)}
      defaultSortBy={defaultSortBy}
    />
  );
};

export default memo(FusionsContainer);
