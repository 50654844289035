import { isNil } from "ramda";
import React from "react";

import { Link } from "pattern-library";

import { CURATED_LIST_TYPE_KNOWLEDGEBASE } from "../../common/constants";
import { formatDateTime } from "../utils";

import {
  CNV_CVL_VARIANTS_TYPE,
  CVL_SEARCH_FIELDS,
  FAILED_STATUS,
  LAST_UPDATED_FIELD,
  LOADING_STATUS,
  SUCCESS_STATUS,
  VARIANTS_TYPE_FIELD,
  NAME_FIELD,
  LAST_CURATOR_EMAIL_FIELD,
  LAST_CURATOR_NAME_FIELD,
  SNV_CVL_VARIANTS_TYPE,
  EXPORT_TOOLTIP_DISABLED,
  EXPORT_TOOLTIP_ENABLED,
} from "./constants";
import type {
  BaseProjectCuratedVariantList,
  CuratedListVariantsType,
  CuratedVariantListResponseEntry,
  Curator,
  CuratorOption,
  CuratorResponseEntry,
  RawCvlOptions,
  ProjectCuratedVariantList,
  CuratedDataSchema,
  CuratedDataSchemaResponse,
  CuratedDataSchemaItem,
  CVLAction,
} from "./types";

export const flattenCuratedDataSchema = (
  schemaResponse: CuratedDataSchemaResponse
): CuratedDataSchema => {
  const { properties } = schemaResponse;
  return Object.entries(properties)
    .map(([key, value]) => {
      const item: CuratedDataSchemaItem = {
        ...value,
        code: key,
      };
      return item;
    })
    .sort(
      ({ propertyOrder: propertyOrderA }, { propertyOrder: propertyOrderB }) =>
        propertyOrderA - propertyOrderB
    );
};

export const flattenCVL = (
  responseCVL: CuratedVariantListResponseEntry
): ProjectCuratedVariantList => {
  const {
    id,
    attributes,
    attributes: { curatedDataSchema, curators },
  } = responseCVL;
  const cvl: ProjectCuratedVariantList = {
    curatedVariantListId: id,
    ...attributes,
  };

  if (curators) {
    cvl.curators = flattenCurators(curators);
  }

  if (curatedDataSchema) {
    cvl.curatedDataSchema = flattenCuratedDataSchema(curatedDataSchema);
  }

  return cvl;
};

export const flattenCurator = (
  responseCurator: CuratorResponseEntry
): Curator => {
  const {
    data: { id, attributes },
  } = responseCurator;
  return {
    id: id + "",
    ...attributes,
  };
};

export const flattenCurators = (
  entities: Array<CuratorResponseEntry>
): Array<Curator> =>
  entities.map((curator: CuratorResponseEntry) => flattenCurator(curator));

export const flattenCVLs = (
  cvls: Array<CuratedVariantListResponseEntry> = []
): Array<ProjectCuratedVariantList> =>
  cvls.map(
    (responseCVL: CuratedVariantListResponseEntry): ProjectCuratedVariantList =>
      flattenCVL(responseCVL)
  );

export const getEmptyCVL = (
  variantType: CuratedListVariantsType
): BaseProjectCuratedVariantList => ({
  type: CURATED_LIST_TYPE_KNOWLEDGEBASE,
  variantType,
  isAutomatedAnnotationDecisions: false,
  showPathogenicity: true,
  description: "",
  isReference: false,
});

export const convertCvlOptions = (options: RawCvlOptions) =>
  Object.entries(options).reduce((acc, entry) => {
    const [key, value] = entry;
    if (key === "curators") {
      acc[key] = value.map((curator: CuratorOption) => {
        const {
          data: {
            id,
            attributes: { fullname },
          },
        } = curator;
        return {
          label: fullname,
          value: id,
        };
      });
    } else if (key === "listTypes") {
      acc[key] = value.map(item => {
        const label = getCvlTypeLabel(item);
        return {
          label,
          value: item,
        };
      });
    } else {
      acc[key] = value.map(item => ({ label: item, value: item }));
    }
    return acc;
  }, {});

export const isCVLStatusSuccess = status => status === SUCCESS_STATUS;
export const isCVLStatusFailed = status => status === FAILED_STATUS;
export const isCVLStatusLoading = status => status === LOADING_STATUS;

export const isProjectOwnList = (cvl: ProjectCuratedVariantList) =>
  isNil(cvl.parentProjectName);
export const isInheritedCVL = cvl => !isProjectOwnList(cvl);

export const isReferenceList = ({ isReference }) => isReference === true;
export const isCNVType = cvl =>
  cvl[VARIANTS_TYPE_FIELD] === CNV_CVL_VARIANTS_TYPE;
export const isSNVType = cvl =>
  cvl[VARIANTS_TYPE_FIELD] === SNV_CVL_VARIANTS_TYPE;

export const isReferenceListInOwnProject = cvl =>
  isReferenceList(cvl) && isProjectOwnList(cvl);

export const formatCVLDate = date =>
  !isNil(date) ? formatDateTime(date * 1000) : null;

export const containsFilterValue = (key, value, filterValue) => {
  if (isNil(value)) {
    return false;
  }
  const valueToCheck =
    key === LAST_UPDATED_FIELD ? formatCVLDate(value) : value;
  return valueToCheck
    .toString()
    .toUpperCase()
    .includes(filterValue.toUpperCase());
};

export const filterCVLList = (items, filterValue) =>
  items.filter(cvl =>
    Object.entries(cvl).some(
      ([key, value]) =>
        CVL_SEARCH_FIELDS.includes(key) &&
        containsFilterValue(key, value, filterValue)
    )
  );

export const getCVLIconStyles = action => `curated-lists-${action}-btn btn-xs`;

export const getYesNoLabel = value => (value === true ? "Yes" : "No");

export const getCvlTypeLabel = value =>
  value.replace("Black", "Exclude").replace("White", "Include");

export const getViewEditAction = (
  action: any,
  curatedVariantListId: string,
  originProjectId?: string
): CVLAction => ({
  action: () => action(curatedVariantListId, originProjectId),
  className: getCVLIconStyles("view-edit"),
  icon: "viewEdit",
  tooltip: {
    content: "View/edit list",
    container: "span",
  },
});

export const getLastCuratorEmailLink = ({
  [NAME_FIELD]: name,
  [LAST_CURATOR_EMAIL_FIELD]: lastCuratorEmail,
  [LAST_CURATOR_NAME_FIELD]: lastCuratorName,
}: ProjectCuratedVariantList) => {
  if (isNil(lastCuratorName)) {
    return null;
  }
  return (
    <Link
      href={`mailto:${lastCuratorEmail}?subject=About the Congenica curated list '${name}'&body=Dear ${lastCuratorName},`}
    >
      {lastCuratorName}
    </Link>
  );
};

export const getExportButtonTooltip = variantCount =>
  variantCount === 0 ? EXPORT_TOOLTIP_DISABLED : EXPORT_TOOLTIP_ENABLED;

export const getCuratedVariantsExportAction = (
  action,
  curatedVariantListId,
  variantCount,
  ensemblVersion: string
) => ({
  action: () => action(curatedVariantListId, ensemblVersion),
  isDisabled: variantCount === 0,
  className: getCVLIconStyles("export"),
  icon: "export",
  tooltip: {
    content: getExportButtonTooltip(variantCount),
    container: "span",
  },
});

export const getCuratedDataValue = (type, value, itemsType?) => {
  switch (type) {
    case "boolean":
      if (value && value.toUpperCase() === "TRUE") {
        return "Yes";
      }
      return "No";
    case "array":
      return Array.isArray(value)
        ? value.map(v => getCuratedDataValue(itemsType, v)).join(", ")
        : "";
    default:
      return value;
  }
};

export const shouldShowEligibleForAutomatedAnnotationDecisionsWarning = (
  automation: boolean,
  eligibleForAutomatedAnnotationDecisions?: boolean
) => automation && eligibleForAutomatedAnnotationDecisions === false;

export const formatVariantCount = ({
  isReference,
  variantCount,
}: ProjectCuratedVariantList) => (isReference ? "-" : variantCount);
