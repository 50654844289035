import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { Table } from "pattern-library/elements/table7";

import { formatDate } from "modules/utils/formatDate";
import { filterProcessor } from "modules/utils/table";

import { PatientOperation } from "../../../types";
import useUpdateGenePanelPatients from "../../../useUpdateGenePanelPatients";
import {
  getGenePanelPatientsCombined,
  isAllGenePanelPatientsSelected,
} from "../../../utils";

import legacyApi from "api/legacy-api";

const { useGetGenePanelPatientsQuery } = legacyApi;

const getPropertyMap = () => ({
  phenotypeNames: value => value.join(", "),
  updated: value => formatDate(new Date(value * 1000)),
});

const patientProperties = [
  "reference",
  "status",
  "phenotypeSummary",
  "phenotypeNames",
  "updated",
];
const patientFilter = filterProcessor(patientProperties, getPropertyMap);

interface Props {
  projectId: number;
  genePanelId: number;
}
export const PatientsTab = memo(({ projectId, genePanelId }: Props) => {
  const {
    data: { writePermissions, patients: panelPatients, projectPatients } = {},
    isLoading,
    isFetching,
  } = useGetGenePanelPatientsQuery({
    projectId,
    genePanelId,
  });

  const [filterValue, setFilterValue] = useState("");
  const [filteredPatients, setFilteredPatients] = useState([]);

  const { updatePatients, resultMap, operation } = useUpdateGenePanelPatients();

  const patients = useMemo(
    () => getGenePanelPatientsCombined(projectPatients, panelPatients),
    [projectPatients, panelPatients]
  );

  useEffect(() => {
    setFilteredPatients(patientFilter(patients, filterValue));
  }, [patients, filterValue, setFilteredPatients]);

  const allPatientsSelected = useMemo(
    () => isAllGenePanelPatientsSelected(projectPatients, panelPatients),
    [projectPatients, panelPatients]
  );

  const columns = useMemo(() => {
    const result: Array<unknown> = [
      {
        Header: "#",
        accessor: "reference",
        headerStyle: { width: "10em" },
      },
      {
        Header: "Status",
        accessor: "status",
        headerStyle: { width: "10em" },
      },
      {
        Header: "Phenotype summary",
        accessor: "phenotypeSummary",
        headerStyle: { width: "20em" },
      },
      {
        Header: "HPO Terms",
        headerStyle: { width: "20em" },
        accessor: patient => patient.phenotypeNames.join(", "),
      },
      {
        Header: "Last Update",
        accessor: "updated",
        headerStyle: { width: "10em" },
        Cell: ({
          row: {
            original: { updated },
          },
        }) => formatDate(new Date(updated * 1000)),
      },
    ];

    if (writePermissions) {
      result.unshift({
        Header: () => {
          const operation = allPatientsSelected
            ? PatientOperation.REMOVE_ALL
            : PatientOperation.ADD_ALL;
          return (
            <input
              type="checkbox"
              checked={allPatientsSelected}
              onChange={() => {
                updatePatients(operation, projectId, genePanelId);
              }}
            />
          );
        },
        headerStyle: { width: "1em" },
        accessor: patient => {
          const operation = patient.isSelected
            ? PatientOperation.REMOVE_ONE
            : PatientOperation.ADD_ONE;
          return (
            <input
              type="checkbox"
              checked={patient.isSelected}
              onChange={() => {
                updatePatients(
                  operation,
                  projectId,
                  genePanelId,
                  patient.patientId
                );
              }}
            />
          );
        },
        id: "actions",
      });
    }

    return result;
  }, [
    writePermissions,
    allPatientsSelected,
    projectId,
    genePanelId,
    updatePatients,
  ]);

  const onFilterChange = useCallback(
    ({ filter }) => {
      setFilterValue(filter);
    },
    [setFilterValue]
  );

  return (
    <div className="gene-panel-patients-tab">
      <Table
        id="project-gene-panel-patients-table"
        columns={columns}
        data={filteredPatients}
        disableSortBy
        autoResetPage={false}
        loading={
          isLoading ||
          isFetching ||
          (operation && resultMap[operation]?.isLoading)
        }
        fetchData={onFilterChange}
        enableFilter
      />
    </div>
  );
});

export default PatientsTab;
