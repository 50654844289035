import { react as autoBind } from "auto-bind";
import { FormikErrors } from "formik";
import React, { PureComponent } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "pattern-library";

import { IrFormValues } from "modules/oncologyInterpretationRequests/types";

import { IR_FORM_FIELDS_LABELS } from "../constants";

interface Props {
  closeModal: () => void;
  show: boolean;
  /**
   * form errors object
   */
  errors: FormikErrors<IrFormValues>;
}

export class FormSubmissionErrorsModal extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    autoBind(this);
  }

  generateErrors = (errors: FormikErrors<IrFormValues>) => {
    const generatedErrors: { message: React.ReactNode }[] = [];

    Object.entries(errors).forEach(([key, value]) => {
      if (!IR_FORM_FIELDS_LABELS[key]) {
        generatedErrors.push({
          message: <span>{value}</span>,
        });
      } else {
        generatedErrors.push({
          message: (
            <span>
              Field <strong>{IR_FORM_FIELDS_LABELS[key]}</strong>
              {` - `}
              {value}
            </span>
          ),
        });
      }
    });

    return generatedErrors;
  };

  render() {
    const { show = false, closeModal, errors } = this.props;

    if (!show) {
      return null;
    }

    const errorsToShow = this.generateErrors(errors);

    return (
      <Modal show={show} close={closeModal}>
        <ModalHeader close={closeModal} className="text-warning">
          <h5>Please Fix The Following Form Submission Errors</h5>
        </ModalHeader>
        <ModalBody>
          <ul className="list-group">
            {errorsToShow.map(({ message }, index) => {
              const key = `modal-warning-${index}`;
              return (
                <li className="list-group-item" key={key}>
                  {message}
                </li>
              );
            })}
          </ul>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            context="primary"
            className="button"
            onClick={closeModal}
          >
            OK
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default FormSubmissionErrorsModal;
