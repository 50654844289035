// @flow

import { get, set } from "dot-prop-immutable";
import { camelizeKeys, camelize, decamelize } from "humps";
import _ from "lodash";
import { isEmpty, isNil } from "ramda";

export const arrayToKeyValueObject: (
  arr: Array<Object>,
  idKey: string
) => Object = (arr = [], idKey) =>
  arr.reduce((acc, t) => {
    const id = t[idKey];
    acc[id] = t;

    return acc;
  }, {});

export const camelizeObject = (obj: any, pathToPropToSkip: string) => {
  if (isNil(pathToPropToSkip) || isEmpty(pathToPropToSkip)) {
    return camelizeKeys(obj);
  }
  const objectToSkip = get(obj, pathToPropToSkip);
  return set(camelizeKeys(obj), camelize(pathToPropToSkip), objectToSkip);
};

export const camelizeResponse = (
  response: ApiResponse<any>,
  propToSkip: string
) => {
  if (isNil(propToSkip) || isEmpty(propToSkip)) {
    return camelizeKeys(response);
  }
  const pathToData = "payload.data";
  const data = get(response, pathToData);
  const pathToPropToSkip = `attributes.${propToSkip}`;
  if (Array.isArray(data)) {
    return set(
      response,
      pathToData,
      data.map(item => camelizeObject(item, pathToPropToSkip))
    );
  } else {
    return set(response, pathToData, camelizeObject(data, pathToPropToSkip));
  }
};

/**
 *
 * @param ref is a reference to some Element created by React hook 'useRef'.
 * It is not flow-typed well coz RefObject is not exported by React
 */
export const scrollToRef = (ref: any) => {
  setTimeout(() => {
    if (ref.current.scrollIntoView) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, 200);
};

export const scrollRefIntoView = (ref: any, offsetY = 0) => {
  if (ref && ref.current) {
    const elementPosition = ref.current.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - offsetY;
    window.scrollTo({
      top: offsetPosition,
    });
  }
};

export const getVepConsequenceLabel = (code: string): string =>
  code ? _.upperFirst(code).replace(/_/g, " ") : "";

export const upperCaseRegex = new RegExp(/^[A-Z0-9_]+$/);
export const camelizeIgnoreUpperCase = value =>
  upperCaseRegex.test(value) ? value : camelize(value);
export const decamelizeIgnoreUpperCase = value =>
  upperCaseRegex.test(value) ? value : decamelize(value);

export const csvDataToObjectsArray = (
  data: Array<Array<string>>,
  camelizeHeaders: boolean = false
): Array<any> => {
  if (!data || isEmpty(data)) {
    return [];
  }
  const [csvRawHeaders, ...csvRows] = data;
  const csvHeaders = camelizeHeaders
    ? csvRawHeaders.map(header => camelizeIgnoreUpperCase(header))
    : csvRawHeaders;
  return csvRows.map(csvRow =>
    csvRow.reduce((accObj, columnValue, columnIndex) => {
      const csvHeader = csvHeaders[columnIndex];
      accObj[csvHeader] = columnValue?.trim();
      return accObj;
    }, {})
  );
};
