import { memo, useMemo } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import { Tabs } from "pattern-library";

import { isRootProject } from "modules/project/selectors";
import { ONCOLOGY_PROJECT_TYPE } from "modules/projects/constants";

import {
  NotFoundMessage,
  RouteTabContent,
  RouteTabHeader,
} from "../../layout/partials";

import {
  AdminConfiguration,
  Automation,
  FilterPresets,
  PatientExport,
  PatientMetadata,
  ProjectDefaults,
  ProjectDetails,
  ReportTemplates,
  VariantPanel,
  CustomerSettings,
} from "./components";
import { ProjectType } from "./types";

const TABS = (
  projectId: string,
  canAdminProject: boolean,
  user: CurrentUser,
  tabs: ProjectCurrentUserFeatures = {},
  isRootProject = false
) => ({
  PROJECT_DETAILS: {
    name: "Project details",
    component: ProjectDetails,
    componentProps: {
      projectId: `${projectId}`,
    },
    url: `/info`,
  },
  REPORT_TEMPLATES_LEGACY: {
    name: "Report templates (legacy)",
    isHidden: !user.admin,
    tabBaseUrl: "/projects",
    hash: `my-projects/${projectId}/admin/settings/report-templates-legacy`,
  },
  REPORT_TEMPLATES: {
    name: "Report templates",
    component: ReportTemplates,
    isHidden: !user.admin,
    componentProps: {
      projectId,
      // TODO: SAP-17882 - Handle loading state
      loading: false,
    },
    url: "/report-templates",
  },
  PATIENT_METADATA: {
    name: "Patient metadata",
    component: PatientMetadata,
    componentProps: {
      projectId,
    },
    url: "/patient-metadata",
  },
  EXPORT_PATIENT_DATA: {
    name: "Export patient data",
    isHidden: !user.admin,
    component: PatientExport,
    componentProps: {
      projectId: `${projectId}`,
    },
    url: "/patient-export",
  },
  PROJECT_DEFAULTS: {
    name: "Project defaults",
    component: ProjectDefaults,
    componentProps: {
      projectId: `${projectId}`,
      //todo: need from server
      autoAcmgFeatureId: 12,
    },
    url: `/project-defaults`,
  },
  FILTER_PRESETS: {
    name: "Filter presets",
    component: FilterPresets,
    componentProps: {
      projectId: `${projectId}`,
    },
    url: `/project-filter-presets`,
  },
  ADMIN_CONFIGURATION: {
    name: "Admin configuration",
    isHidden: !user.admin,
    component: AdminConfiguration,
    componentProps: {
      projectId: `${projectId}`,
    },
    url: `/project-config`,
  },
  VARIANT_PANEL: {
    name: "Variant panel",
    isHidden: !canAdminProject,
    component: VariantPanel,
    componentProps: {
      projectId: `${projectId}`,
    },
    url: `/variant-panel`,
  },
  AUTOMATION: {
    name: "Automation",
    isHidden: !canAdminProject || !tabs.automation,
    component: Automation,
    componentProps: {
      projectId: `${projectId}`,
    },
    url: `/automation`,
  },
  CUSTOMER: {
    name: "Customer",
    isHidden: isRootProject || !canAdminProject,
    component: CustomerSettings,
    componentProps: {
      projectId: `${projectId}`,
    },
    url: `/customer`,
  },
});

const getRareDiseaseProjectTabs = (
  projectId,
  canAdminProject,
  tabs,
  user,
  isRootProject
) => {
  const projectTabs = TABS(
    projectId,
    canAdminProject,
    user,
    tabs,
    isRootProject
  );
  return [
    projectTabs.PROJECT_DETAILS,
    projectTabs.REPORT_TEMPLATES_LEGACY,
    projectTabs.REPORT_TEMPLATES,
    projectTabs.PATIENT_METADATA,
    projectTabs.EXPORT_PATIENT_DATA,
    projectTabs.PROJECT_DEFAULTS,
    projectTabs.FILTER_PRESETS,
    projectTabs.ADMIN_CONFIGURATION,
    projectTabs.VARIANT_PANEL,
    projectTabs.AUTOMATION,
    projectTabs.CUSTOMER,
  ];
};

const getOncologyProjectTabs = (projectId, canAdminProject, user) => {
  const projectTabs = TABS(projectId, canAdminProject, user);
  return [
    projectTabs.PROJECT_DETAILS,
    projectTabs.REPORT_TEMPLATES,
    projectTabs.ADMIN_CONFIGURATION,
  ];
};

interface Props {
  projectId: string;
  projectCurrentUser: ProjectCurrentUser;
  user: User;
  projectType: ProjectType;
  isRootProject: boolean;
}

export const getTabs = ({
  projectId,
  projectCurrentUser: { canAdminProject = false, tabs = {} },
  user,
  projectType,
  isRootProject,
}: Props) =>
  projectType === ONCOLOGY_PROJECT_TYPE
    ? getOncologyProjectTabs(projectId, canAdminProject, user)
    : getRareDiseaseProjectTabs(
        projectId,
        canAdminProject,
        tabs,
        user,
        isRootProject
      );

export const ProjectSettingsContainer = memo(
  ({
    projectId,
    projectCurrentUser,
    user,
    projectType,
    isRootProject,
  }: Props) => {
    const tabs = useMemo(
      () =>
        getTabs({
          projectId,
          projectCurrentUser,
          user,
          projectType,
          isRootProject,
        }),
      [projectId, projectCurrentUser, user, projectType, isRootProject]
    );

    const urlPrefix = `/projects/${projectId}/settings`;

    return (
      <div>
        {projectId && (
          <Switch>
            <Route exact path={`${urlPrefix}`}>
              <Redirect to={`${urlPrefix}/info`} />
            </Route>
            <Tabs
              tabs={tabs}
              baseUrl={`/projects/${projectId}/settings`}
              routePath="/projects/:projectId/settings"
              tabComponent={RouteTabHeader}
              stopUnmount
              contentComponent={RouteTabContent}
              projectId={projectId}
            />
            <Route component={NotFoundMessage} />
          </Switch>
        )}
      </div>
    );
  }
);

const mapStateToProp = state => ({
  isRootProject: isRootProject(state),
});
export default connect(mapStateToProp)(ProjectSettingsContainer);
