import { isNil } from "ramda";
import React, { ReactElement, ReactNode, useCallback } from "react";

import { Link } from "pattern-library";

export type TabHeaderProps = {
  name: string;
  /**
   * classname for the tab header link or a custom header component if specified
   */
  className: string;
  /**
   * if the tab has value it will be hightlighted
   */
  hasValue: boolean;
  /**
   * Flag to load the count number or a number to display
   */
  count: number | boolean;
  /**
   * if true, the tab header text will be emboldened
   */
  strong: boolean;
  /**
   * tab's label
   */
  label: string | number;
  /**
   * tab's icon
   */
  icon: ReactElement;
  /**
   * href for tab link element
   */
  href: string;
  /**
   * Classname for tab link element
   */
  navLinkClassName: string;
  /**
   * Tab click handler
   */
  onClick: (name: string) => void;

  info?: ReactNode;
};

const DefaultTabHeader: React.FC<TabHeaderProps> = ({
  name,
  navLinkClassName,
  strong,
  onClick,
  href = "",
  count = false,
  label,
  icon,
  info,
}) => {
  const onTabClicked = useCallback(
    e => {
      if (!href) {
        e.preventDefault();
      }
      onClick(name);
    },
    [name, href, onClick]
  );

  const countElement = Number.isInteger(count) && (
    <span className="ajax nocache label label-default">{count}</span>
  );

  const resultName = strong ? <strong>{name}</strong> : name;
  return (
    <Link href={href} className={navLinkClassName} onClick={onTabClicked}>
      {icon} {resultName} {countElement}
      {info}
      {!isNil(label) && (
        <>
          <span className="label label-default">{label}</span>
        </>
      )}
    </Link>
  );
};

export default DefaultTabHeader;
