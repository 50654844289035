import React, { useMemo } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { cvlSelector } from "modules/audit";
import { BUFFER_ZOOM_SNV, selectTrackConfig } from "modules/genoverse";
import GenoverseBrowser from "modules/genoverse/GenoverseBrowser/GenoverseBrowser";
import { GenoverseConfig } from "modules/genoverse/GenoverseBrowser/GenoverseBrowser.types";
import GenoverseTracks from "modules/genoverse/GenoverseTracks";
import { getCuratedVariantTracks } from "modules/genoverseBrowser/trackLibraryHelpers";

import { useAssemblyConfig } from "hooks/useAssemblyConfig";

const { Coverage, SequenceAlignment, Patient } = GenoverseTracks;

interface Props {
  patientId: number;
  chrom?: string;
  pos?: number;
  cvl?: {
    id: string;
  };
  trackConfig?: Array<any>;
}
const RefNoCallBrowser: React.FC<Props> = ({
  patientId,
  chrom,
  pos = 1,
  cvl: { id: cvlTrackId } = {},
  trackConfig = [],
}) => {
  const assembly = useAssemblyConfig();

  const config = useMemo<GenoverseConfig>(
    () => ({
      genome: assembly,
      chr: chrom,
      patientId,
      start: pos - BUFFER_ZOOM_SNV,
      end: pos + BUFFER_ZOOM_SNV,
      saveKey: "congenicaGenoverse-genotype",
      saveable: true,
      storageType: "localStorage",
      highlights: [
        {
          chr: chrom,
          start: pos,
          end: pos,
          label: "NoCall position",
          removable: true,
        },
      ],
    }),
    [patientId, chrom, pos, assembly]
  );

  const tracksLibrary = useMemo(() => {
    const tracks = [
      Patient(config).extend({ trackKey: "patientSNV" }),
      Coverage(config).extend({ trackKey: "Coverage" }),
      SequenceAlignment(config).extend({ trackKey: "Sequence alignment" }),
    ];
    if (cvlTrackId) {
      const cvlConfig = trackConfig.find(
        ({ curatedVariantListId }) => cvlTrackId === "" + curatedVariantListId
      );
      if (cvlConfig) {
        tracks.push({
          name: "Curated Variant Tracks",
          tracks: getCuratedVariantTracks(config, [cvlConfig]),
        });
      }
    }
    return tracks;
  }, [config, cvlTrackId, trackConfig]);

  const defaultTracks = useMemo(() => {
    const tracks = ["patientSNV", "Coverage", "Sequence alignment"];
    if (tracksLibrary.some(({ name }) => name === "Curated Variant Tracks")) {
      tracks.push(`CuratedList${cvlTrackId}`);
    }
    return tracks;
  }, [tracksLibrary, cvlTrackId]);

  return chrom && pos && assembly ? (
    <GenoverseBrowser
      className="genoverse"
      config={{
        ...config,
        tracksLibrary,
        tracks: defaultTracks,
      }}
    />
  ) : null;
};

const mapStateToProps = createStructuredSelector({
  cvl: cvlSelector,
  trackConfig: selectTrackConfig,
});

export default connect(mapStateToProps, null)(RefNoCallBrowser);
