import { FC } from "react";

import AssayQcRow from "modules/oncologyQC/AssayQcRow";
import { RNA_ASSAY_QC_FIELDS_CONFIG } from "modules/oncologyQC/constants";

import { OncologyRnaQcData } from "types/oncologyQc";

interface Props {
  rnaQcData: OncologyRnaQcData;
}

const RnaAssayQcTables: FC<Props> = ({ rnaQcData }) => (
  <>
    <div className="col-md-6">
      <table
        className="qc__oncology__json_table table table-striped"
        data-test-id="general-rna-qc-fields"
      >
        <tbody>
          {RNA_ASSAY_QC_FIELDS_CONFIG.GENERAL_FIELDS.fields.map(fieldConfig => (
            <AssayQcRow
              key={fieldConfig.field}
              value={rnaQcData[fieldConfig.field]}
              {...fieldConfig}
            />
          ))}
        </tbody>
      </table>

      <h3 className="mt-3">
        {RNA_ASSAY_QC_FIELDS_CONFIG.UNIQUE_READS_FIELDS.heading}
      </h3>
      <table
        className="qc__oncology__json_table table table-striped"
        data-test-id="unique-reads-rna-qc-fields"
      >
        <tbody>
          {RNA_ASSAY_QC_FIELDS_CONFIG.UNIQUE_READS_FIELDS.fields.map(
            fieldConfig => (
              <AssayQcRow
                key={fieldConfig.field}
                value={rnaQcData[fieldConfig.field]}
                {...fieldConfig}
              />
            )
          )}
        </tbody>
      </table>
    </div>

    <div className="col-md-6">
      <h3 className="mt-0">
        {RNA_ASSAY_QC_FIELDS_CONFIG.MULTI_MAPPING_READS_FIELDS.heading}
      </h3>
      <table
        className="qc__oncology__json_table table table-striped"
        data-test-id="multi-mapping-reads-rna-qc-fields"
      >
        <tbody>
          {RNA_ASSAY_QC_FIELDS_CONFIG.MULTI_MAPPING_READS_FIELDS.fields.map(
            fieldConfig => (
              <AssayQcRow
                key={fieldConfig.field}
                value={rnaQcData[fieldConfig.field]}
                {...fieldConfig}
              />
            )
          )}
        </tbody>
      </table>

      <h3 className="mt-3">
        {RNA_ASSAY_QC_FIELDS_CONFIG.UNMAPPED_READS_FIELDS.heading}
      </h3>
      <table
        className="qc__oncology__json_table table table-striped"
        data-test-id="unmapped-reads-rna-qc-fields"
      >
        <tbody>
          {RNA_ASSAY_QC_FIELDS_CONFIG.UNMAPPED_READS_FIELDS.fields.map(
            fieldConfig => (
              <AssayQcRow
                key={fieldConfig.field}
                value={rnaQcData[fieldConfig.field]}
                {...fieldConfig}
              />
            )
          )}
        </tbody>
      </table>

      <h3 className="mt-3">
        {RNA_ASSAY_QC_FIELDS_CONFIG.CHIMERIC_READS_FIELDS.heading}
      </h3>
      <table
        className="qc__oncology__json_table table table-striped"
        data-test-id="chimeric-reads-rna-qc-fields"
      >
        <tbody>
          {RNA_ASSAY_QC_FIELDS_CONFIG.CHIMERIC_READS_FIELDS.fields.map(
            fieldConfig => (
              <AssayQcRow
                key={fieldConfig.field}
                value={rnaQcData[fieldConfig.field]}
                {...fieldConfig}
              />
            )
          )}
        </tbody>
      </table>
    </div>
  </>
);

export default RnaAssayQcTables;
