import { Colors } from "./colors";
import { Genomes } from "./genomes";

export const limitValue = (lowerLimit, upperLimit, value) =>
  Math.min(Math.max(value, lowerLimit), upperLimit);

const EnsemblRestHash = {
  [Genomes.GRCH37]: "https://grch37.rest.ensembl.org",
  [Genomes.GRCH38]: "https://rest.ensembl.org",
};

const EnsemblHash = {
  [Genomes.GRCH37]: "https://grch37.ensembl.org",
  [Genomes.GRCH38]: "https://www.ensembl.org",
};

export const getEnsemblUrl = genome => EnsemblHash[genome];
export const getEnsemblRestUrl = genome => EnsemblRestHash[genome];

export const isValidAllele = ({ alt_allele }) =>
  alt_allele || alt_allele === "";

export const isFeatureExcluded = ({ excluded }) => parseInt(excluded) === 1;

/*
 * Use protein colour groupings that are biochemically meaningful
 * Positive charge       H, K, R          Light Red
 * Negative charge       D, E, O          Blue Dark
 * Polar neutral         N, Q, S, T       Whisper
 * Aliphatic             A, I, L, V       Light Green
 * Aromatic              F, W, Y          Green
 * Structure breaking    G, P             Brown
 * Chalcogen containing  M, C, U          Light Yellow
 * Uncertain/Stop        B, Z, X, J, *    Silver
 */
export const aminoAcids = {
  A: {
    color: Colors.LIGHT_GREEN,
    name: "Alanine",
    code: "Ala",
    type: "Aliphatic",
    polarity: "nonpolar",
    charge: "neutral",
    formula: "C3H5ON",
    mass: 71.0788,
  },
  I: {
    color: Colors.LIGHT_GREEN,
    name: "Isoleucine",
    code: "Ile",
    type: "Aliphatic",
    polarity: "nonpolar",
    charge: "neutral",
    formula: "C6H11ON",
    mass: 113.1594,
  },
  L: {
    color: Colors.LIGHT_GREEN,
    name: "Leucine",
    code: "Leu",
    type: "Aliphatic",
    polarity: "nonpolar",
    charge: "neutral",
    formula: "C6H11ON",
    mass: 113.1594,
  },
  M: {
    color: Colors.LIGHT_YELLOW,
    name: "Methionine",
    code: "Met",
    type: "Chalcogen containing",
    polarity: "nonpolar",
    charge: "neutral",
    formula: "C5H9ONS",
    mass: 131.1926,
  },
  N: {
    color: Colors.WHISPER,
    name: "Asparagine",
    code: "Asn",
    type: "Polar neutral",
    polarity: "polar",
    charge: "neutral",
    formula: "C4H6O2N2",
    mass: 114.1038,
  },
  D: {
    color: Colors.BLUE_DARK,
    name: "Aspartic acid",
    code: "Asp",
    type: "Negatively charged",
    polarity: "acidic-polar",
    charge: "negative",
    formula: "C4H5O3N",
    mass: 115.0886,
  },
  C: {
    color: Colors.LIGHT_YELLOW,
    name: "Cysteine",
    code: "Cys",
    type: "Chalcogen containing",
    polarity: "nonpolar",
    charge: "neutral",
    formula: "C3H5ONS",
    mass: 103.1388,
  },
  Q: {
    color: Colors.WHISPER,
    name: "Glutamine",
    code: "Gln",
    type: "Polar neutral",
    polarity: "polar",
    charge: "neutral",
    formula: "C5H8O2N2",
    mass: 128.1307,
  },
  E: {
    color: Colors.BLUE_DARK,
    name: "Glutamic acid",
    code: "Glu",
    type: "Negatively charged",
    polarity: "acidic-polar",
    charge: "negative",
    formula: "C5H7O3N",
    mass: 129.1155,
  },
  G: {
    color: Colors.BROWN,
    name: "Glycine",
    code: "Gly",
    type: "Structure breaking",
    polarity: "nonpolar",
    charge: "neutral",
    formula: "C2H3ON",
    mass: 57.0519,
  },
  H: {
    color: Colors.LIGHT_RED,
    name: "Histidine",
    code: "His",
    type: "Positively charged",
    polarity: "basic-polar",
    charge: "positive(10%) neutral(90%)",
    formula: "C6H7ON3",
    mass: 137.1411,
  },
  K: {
    color: Colors.LIGHT_RED,
    name: "Lysine",
    code: "Lys",
    type: "Positively charged",
    polarity: "basic-polar",
    charge: "positive",
    formula: "C6H12ON2",
    mass: 128.1741,
  },
  F: {
    color: Colors.GREEN,
    name: "Phenylalanine",
    code: "Phe",
    type: "Aromatic",
    polarity: "nonpolar",
    charge: "neutral",
    formula: "C9H9ON",
    mass: 147.1766,
  },
  P: {
    color: Colors.BROWN,
    name: "Proline",
    code: "Pro",
    type: "Structure breaking",
    polarity: "nonpolar",
    charge: "neutral",
    formula: "C5H7ON",
    mass: 97.1167,
  },
  R: {
    color: Colors.LIGHT_RED,
    name: "Arginine",
    code: "Arg",
    type: "Positively charged",
    polarity: "basic-polar",
    charge: "positive",
    formula: "C6H12ON4",
    mass: 156.1875,
  },
  S: {
    color: Colors.WHISPER,
    name: "Serine",
    code: "Ser",
    type: "Polar neutral",
    polarity: "polar",
    charge: "neutral",
    formula: "C3H5O2N",
    mass: 87.0782,
  },
  T: {
    color: Colors.WHISPER,
    name: "Threonine",
    code: "Thr",
    type: "Polar neutral",
    polarity: "polar",
    charge: "neutral",
    formula: "C4H7O2N",
    mass: 101.1051,
  },
  W: {
    color: Colors.GREEN,
    name: "Tryptophan",
    code: "Trp",
    type: "Aromatic",
    polarity: "nonpolar",
    charge: "neutral",
    formula: "C11H10ON2",
    mass: 186.2132,
  },
  Y: {
    color: Colors.GREEN,
    name: "Tyrosine",
    code: "Tyr",
    type: "Aromatic",
    polarity: "polar",
    charge: "neutral",
    formula: "C9H9O2N",
    mass: 163.176,
  },
  V: {
    color: Colors.LIGHT_GREEN,
    name: "Valine",
    code: "Val",
    type: "Aliphatic",
    polarity: "nonpolar",
    charge: "neutral",
    formula: "C5H9ON",
    mass: 99.1326,
  },
  U: {
    color: Colors.LIGHT_YELLOW,
    name: "Selenocysteine",
    code: "Sec",
    type: "Chalcogen containing",
    polarity: "nonpolar",
    charge: "neutral",
    formula: "C3H5ONSe",
    mass: 168.05,
  },
  O: {
    color: Colors.LIGHT_RED,
    name: "Pyrrolysine",
    code: "Pyl",
    type: "Positively charged",
    polarity: "basic-polar",
    charge: "positive",
    formula: "C12H21N3O3",
    mass: 255.31,
  },
  B: {
    color: Colors.SILVER,
    name: "Asparagine or aspartic acid",
    code: "Asx",
    type: "Uncertain",
    polarity: "polar",
    charge: "neutral or negative",
  },
  Z: {
    color: Colors.SILVER,
    name: "Glutamine or glutamic acid",
    code: "Glx",
    type: "Uncertain",
    polarity: "polar",
    charge: "neutral or negative",
  },
  J: {
    color: Colors.LIGHT_GREEN,
    name: "Leucine or Isoleucine",
    code: "Xle",
    type: "Uncertain",
    polarity: "nonpolar",
    charge: "neutral",
  },
  X: {
    color: Colors.SILVER,
    name: "Unknown",
    code: "???",
    type: "Uncertain",
    polarity: "n/a",
    charge: "n/a",
  },
  "*": {
    color: Colors.SILVER,
    name: "Stop",
    code: "___",
    type: "Stop",
    polarity: "n/a",
    charge: "n/a",
  },
  "-": {
    color: Colors.SILVER,
    name: "-",
    code: "-",
    type: "Uncertain",
    polarity: "n/a",
    charge: "n/a",
  },
};

export const isParent = relation =>
  relation === "Father" || relation === "Mother";

export const getRelativeTrackBaseConfig = (
  name,
  relation,
  relativePatientId
) => {
  // if the same type of relationship occurs twice or more
  // there should be separate track types for each relative of the type
  const relativeTrackIdentifier = !isParent(relation)
    ? `${relation}.${relativePatientId}`
    : relation;

  return {
    id: `${name}.${relativeTrackIdentifier}`,
    namespace: `${name}.${relativeTrackIdentifier}`,
  };
};

export function isForwardTranscribedGene(feature) {
  return parseInt(feature.strand) === 1;
}

export function getExonRank(feature, chr, x) {
  let exonWithRank = null;
  feature?.exons?.some((exon, j) => {
    const subMenuBuffer = 4;
    const tolerance = this.scale <= 1 ? subMenuBuffer / this.scale : 0;

    if (x >= parseInt(exon.start) - tolerance && x <= parseInt(exon.end)) {
      exonWithRank = {
        exon,
        rank: isForwardTranscribedGene(feature)
          ? j + 1
          : feature.exons.length - j,
      };
      return true;
    }
    return false;
  });
  return exonWithRank;
}

export function getOnClickFeatures(e, buffer = 4) {
  let x =
    e.pageX -
    this.container.parent().offset().left +
    this.browser.scaledStart -
    buffer;
  const y = e.offsetY + buffer;
  const features = this[
    e.target.className === "gv-labels" ? "labelPositions" : "featurePositions"
  ]
    .search({ x, y, w: buffer * 2, h: buffer * 2 })
    .sort((a, b) => a.sort - b.sort);
  x = Math.floor(x / this.scale);
  return {
    features,
    x,
    y,
  };
}
