import React, { useMemo } from "react";
import { connect } from "react-redux";

import GenePanels from "modules/config/components/forms/GenePanels";
import * as configSelectors from "modules/config/selectors";
import * as selectors from "modules/str/selectors";

import TabsLayout from "../TabsLayout";

import CustomData from "./CustomData";

import { useEnsemblVersion } from "hooks/useEnsemblVersion";

const getTabs = ({ isCustomDataEnabled, genePanels }) =>
  [
    {
      name: "Gene panels",
      component: GenePanels,
      componentProps: {
        genePanels,
      },
    },
    isCustomDataEnabled
      ? {
          name: "Custom",
          component: CustomData,
        }
      : null,
  ].filter(Boolean);

export const Filters = props => {
  const { isCustomDataEnabled, genePanels, ensemblVersion } = props;

  const filteredGenePanels = useMemo(
    () =>
      genePanels.filter(panel =>
        // Only filter the panels if there is an ensembl_version property on them
        // (so this works with and without the API changes)
        panel.ensemblVersion ? panel.ensemblVersion === ensemblVersion : true
      ),
    [genePanels, ensemblVersion]
  );

  const tabsToUse = useMemo(
    () => getTabs({ isCustomDataEnabled, genePanels: filteredGenePanels }),
    [isCustomDataEnabled, filteredGenePanels]
  );

  return <TabsLayout stacked tabs={tabsToUse} {...props} />;
};

const ConnectedFiltersComponent = props => {
  const { patientId } = props;
  const { ensemblVersion } = useEnsemblVersion(patientId);

  return <Filters {...props} ensemblVersion={ensemblVersion} />;
};

const mapStateToProps = state => {
  const patientId = selectors.getPatientId(state);
  return {
    isCustomDataEnabled: selectors.isCustomDataEnabled(state),
    genePanels: configSelectors.getGenePanelsCheckBoxItems(state, patientId),
    patientId,
  };
};

export default connect(mapStateToProps)(ConnectedFiltersComponent);
