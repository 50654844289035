import { useMemo } from "react";

import congenicaApi from "api/congenica-api";
import { OncologyCNV } from "types/CNV";
import { OncologyPatientWorkflowStatus } from "types/oncologyPatientWorkflowStatus";

const {
  useGetOncologyVariantsQuery,
  useReclassifyOncologyVariantsMutation,
  useApproveOncologyVariantsMutation,
  useRejectOncologyVariantsMutation,
  useGetOncologyPatientWorkflowStatusQuery,
} = congenicaApi;

interface OncologyExploreData {
  reclassifyVariants: ReturnType<
    typeof useReclassifyOncologyVariantsMutation
  >[0];
  approveVariants: ReturnType<typeof useApproveOncologyVariantsMutation>[0];
  rejectVariants: ReturnType<typeof useRejectOncologyVariantsMutation>[0];
  snv: OncologySNV[] | undefined;
  cnv: OncologyCNV[] | undefined;
  rnaFusions: OncologyFusion[] | undefined;
  oncologyPatientWorkflowStatus: OncologyPatientWorkflowStatus | undefined;
  isLoading: boolean;
}

export const useOncologyExploreData = (
  patientId: number
): OncologyExploreData => {
  const [reclassifyVariants, { isLoading: isReclassifyVariantsLoading }] =
    useReclassifyOncologyVariantsMutation();

  const [approveVariants, { isLoading: isApproveVariantsLoading }] =
    useApproveOncologyVariantsMutation();

  const [rejectVariants, { isLoading: isRejectVariantsLoading }] =
    useRejectOncologyVariantsMutation();

  const { isOncologyVariantsLoading, snv, cnv, rnaFusions } =
    useGetOncologyVariantsQuery(
      {
        patientId,
      },
      {
        selectFromResult: ({
          isLoading: isOncologyVariantsLoading,
          data: { snv, cnv, rnaFusions } = {},
        }) => ({
          isOncologyVariantsLoading,
          snv,
          cnv,
          rnaFusions,
        }),
      }
    );

  const {
    data: oncologyPatientWorkflowStatus,
    isLoading: oncologyPatientWorkflowStatusLoading,
  } = useGetOncologyPatientWorkflowStatusQuery({
    patientId,
  });

  const isLoading = useMemo(
    () =>
      isOncologyVariantsLoading ||
      isReclassifyVariantsLoading ||
      oncologyPatientWorkflowStatusLoading ||
      isApproveVariantsLoading ||
      isRejectVariantsLoading,
    [
      isOncologyVariantsLoading,
      isReclassifyVariantsLoading,
      isApproveVariantsLoading,
      isRejectVariantsLoading,
      oncologyPatientWorkflowStatusLoading,
    ]
  );

  return {
    reclassifyVariants,
    approveVariants,
    rejectVariants,
    oncologyPatientWorkflowStatus,
    snv,
    cnv,
    rnaFusions,
    isLoading,
  };
};
