import { createSelector } from "@reduxjs/toolkit";
import { camelize } from "humps";

export const convertGenePanelPatientsResponse = (
  projectPatients: Array<GenePanelPatientResponse>
): Array<GenePanelPatient> =>
  projectPatients.map(patient => {
    const tempPatient: GenePanelPatient = {
      ...patient,
      genePanelTitles: patient.genePanelTitles
        ? JSON.parse(patient.genePanelTitles)
        : [],
      phenotypeNames: patient.phenotypeNames
        ? JSON.parse(patient.phenotypeNames)
        : [],
      familyRefs: patient.familyRefs ? JSON.parse(patient.familyRefs) : [],
    };
    return tempPatient;
  });

export const formatGenePanelPatients = createSelector(
  (result: GenePanelPatientsResponse) => result.genePanelPatients,
  (result: GenePanelPatientsResponse) => result.projectPatients,
  (result: GenePanelPatientsResponse) => result.genePanelWritePermission,
  (
    genePanelPatients,
    projectPatients,
    writePermissions
  ): GenePanelPatients => ({
    patients: genePanelPatients || [],
    projectPatients: convertGenePanelPatientsResponse(projectPatients || []),
    writePermissions,
  })
);

export const formatGeneResponse = createSelector(
  (result: GeneInfo) => result,
  (result: GeneInfo) => result.keys,
  (result, keys): GeneInfo => ({
    ...result,
    keys: keys.map(camelize),
  })
);
