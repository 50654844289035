import { createSelector } from "@reduxjs/toolkit";
import { path } from "ramda";

import {
  hasRequestFailed,
  isRequestCompleted,
  isRequestInProgress,
  isRequestSuccessful,
} from "common/selectors";
import { MODULE_NAME } from "modules/oncologyInterpretationRequests/constants";
import {
  Baitsets,
  Entries,
} from "modules/oncologyInterpretationRequests/types";

export const uiPath = (...items: any) => path([MODULE_NAME, "ui", ...items]);

export const dataPath = (...items: any) =>
  path([MODULE_NAME, "data", ...items]);

const projectRequestStatus = uiPath("projectRequestStatus");

export const isProjectLoading = state =>
  isRequestInProgress(projectRequestStatus(state));

export const isProjectLoadCompleted = state =>
  isRequestCompleted(projectRequestStatus(state));

export const hasProjectRequestFailed = state =>
  hasRequestFailed(projectRequestStatus(state));

export const getIRSubmissionStatus = uiPath("irSubmissionStatus");

export const isIRSubmissionSuccessful = state =>
  isRequestSuccessful(getIRSubmissionStatus(state));

export const isIRSubmissionInProgress = state =>
  isRequestInProgress(getIRSubmissionStatus(state));

export const isIRSubmissionCompleted = state =>
  isRequestCompleted(getIRSubmissionStatus(state));

export const getBaitsets = dataPath("baitsets");

export const getBaitsetsAsOptions = createSelector(
  getBaitsets,
  (baitsets: Baitsets = {}): Array<DropdownOption> => {
    const accumulator: Array<DropdownOption> = [];
    for (const [key, { name }] of Object.entries(
      baitsets
    ) as Entries<Baitsets>) {
      accumulator.push({ value: key, label: name });
    }
    return accumulator.sort((a, b) => a.label.localeCompare(b.label));
  }
);
