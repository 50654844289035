/* File contains old track code, could be refactored in the future */

import Genoverse from "genoverse";

import { afFormat } from "modules/config/utils";

import { Colors, createBadge } from "../utils";

export const SequenceVariantsViewConfig = {
  featureMargin: { top: 20, right: 0, bottom: 0, left: 0 },
  markerSize: 16,

  draw(features, featureContext, labelContext, scale) {
    features.forEach(feature => {
      if (feature.position[scale].visible !== false) {
        // TODO: extend with feature.position[scale], rationalize keys
        this.drawFeature(
          {
            ...feature,
            x: feature.position[scale].X,
            y: feature.position[scale].Y,
            width: feature.position[scale].width,
            height: feature.position[scale].height,
            labelPosition: feature.position[scale].label,
          },
          featureContext,
          labelContext,
          scale
        );

        //Add pointer for current snv
        if (feature.isCurrent) {
          this.drawMapMarker(
            featureContext,
            feature.position[scale].X,
            feature.position[scale].Y,
            this.markerSize
          );
        }

        //Add genotype icon
        this.drawGenotype(
          feature.genotype,
          feature.genoTypeColor,
          featureContext,
          feature.position[scale].X,
          feature.position[scale].Y + feature.position[scale].height
        );
      }
    });
  },

  drawMapMarker(context, x, y, markerSize) {
    context.save();
    context.font = markerSize + "px Glyphicons Halflings";
    //\e062
    context.fillText(String.fromCharCode(57442), x, y - markerSize / 2);
    context.restore();
  },

  drawGenotype(genoType, color, context, x, y) {
    const radius = 1;
    const spacing = 4;
    context.save();

    //Draw first dot
    context.beginPath();
    context.arc(x + radius / 2, y + spacing, radius, 0, 2 * Math.PI, false);
    if (color) context.fillStyle = color;
    context.fill();

    //Draw second dot below
    if (genoType === "Homozygous") {
      context.beginPath();
      context.arc(
        x + radius / 2,
        y + 2 * spacing,
        radius,
        0,
        2 * Math.PI,
        false
      );
      context.fill();
    }

    context.restore();
  },
};
export const SequenceVariantsView = Genoverse.Track.View.extend({
  ...SequenceVariantsViewConfig,
});

export const Controller = {
  populateMenu(feature, extras) {
    const menu = {};
    let entries = [];

    if (feature.alt_allele || feature.alt_allele === "") {
      const { gene, transcript_id, patient_snv_id, patient_id, hgvs_c } =
        feature;

      const url = `/patient/${patient_id}#variants/gene/${gene}/transcript/${transcript_id}/snv/${patient_snv_id}/summary`;

      entries = [
        ["title", `<a href="${url}">${gene} ${hgvs_c}</a>`],
        [
          "Location",
          feature.ref_allele.length === 1
            ? feature.chr + ":" + feature.start
            : feature.chr +
              ":" +
              feature.start +
              "-" +
              (Number(feature.start) + feature.ref_allele.length - 1),
        ],
        ["Genotype", feature.genotype || undefined],
        ["Type", feature.variant_type || undefined],
        [
          "Consequence",
          feature.vep_category
            ? createBadge(this.prop("colorMap")[feature.vep_category].color) +
              feature.vep_category
            : undefined,
        ],
        ["VEP consequence", feature.vep_consequence || undefined],
        ["Codons", feature.codons || undefined],
        ["Amino acid", feature.amino_acid || undefined],
        ["HGVSc", feature.hgvs_c || undefined],
        ["HGVSp", feature.hgvs_p || undefined],
        ["Protein position", feature.protein_position || undefined],
        [
          "PolyPhen prediction",
          feature.polyphen_prediction
            ? feature.polyphen_prediction + " (" + feature.polyphen_score + ")"
            : undefined,
        ],
        [
          "SIFT prediction",
          feature.sift_prediction
            ? feature.sift_prediction + " (" + feature.sift_score + ")"
            : undefined,
        ],
        ["REVEL", feature.revel_score || undefined],
        ["GERP", feature.gerp || undefined],
        ["MAX AF", afFormat(feature.af_max) || undefined],
        ["Depth", feature.depth || undefined],
        ["Reads split", feature.readsplit || undefined],
        ["Quality score", feature.score || undefined],
      ];
    }

    for (const i in entries) {
      if (typeof entries[i][1] !== "undefined") {
        const [key, value] = entries[i];
        menu[key] = value;
      }
    }

    return { ...menu, ...extras };
  },
};

export const Config = {
  height: 35,
  featureHeight: 15,
  tags: [],
  colorMap: {
    "Likely LOF": {
      color: Colors.RED,
      labelColor: Colors.WHITE,
    },
    "Protein Altering": {
      color: Colors.ORANGE_DARK,
      labelColor: Colors.BLACK,
    },
    "Transcript amplification": {
      color: Colors.BLUE,
      labelColor: Colors.WHITE,
    },
    "In annotated regulatory region": {
      color: Colors.BLUE,
      labelColor: Colors.WHITE,
    },
    "In mRNA, not protein altering": {
      color: Colors.BLUE,
      labelColor: Colors.WHITE,
    },
    ncRNA: {
      color: Colors.BLUE,
      labelColor: Colors.WHITE,
    },
    "Not in mRNA or annotation regulatory region": {
      color: Colors.BLUE,
      labelColor: Colors.WHITE,
    },
    "In UTR": {
      color: Colors.BLUE,
      labelColor: Colors.WHITE,
    },
    Excluded: {
      color: Colors.GREY_LIGHT,
      labelColor: Colors.BLACK,
    },
    "Unknown consequence": {
      color: Colors.GREY,
      labelColor: Colors.BLACK,
    },
  },
  controller: Controller,
};

export const Track = Genoverse.Track.extend({
  ...Config,
  controller: Genoverse.Track.Controller.extend(Config.controller),
});
