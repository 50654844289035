import React, { FC } from "react";

import { ComposableTabs, Tab } from "pattern-library";

import DnaQc from "modules/oncologyQC/dna/DnaQc";
import RnaQc from "modules/oncologyQC/rna/RnaQc";

import { OncologyDnaQcData, OncologyRnaQcData } from "types/oncologyQc";

interface Props {
  rnaQc: OncologyRnaQcData | undefined;
  dnaQc: OncologyDnaQcData | undefined;
}

const DNA_TAB_NAME = "DNA";
const RNA_TAB_NAME = "RNA";

const OncologyQcTabs: FC<Props> = ({ rnaQc, dnaQc }) => {
  const dnaQcPresent = dnaQc !== undefined;
  const rnaQcPresent = rnaQc !== undefined;

  if (!dnaQcPresent && !rnaQcPresent) {
    return null;
  }

  return (
    <ComposableTabs
      navStyle
      defaultActive={dnaQcPresent ? DNA_TAB_NAME : RNA_TAB_NAME}
    >
      <Tab className="pull-right" name={RNA_TAB_NAME} isHidden={!rnaQcPresent}>
        {rnaQcPresent && <RnaQc rnaQcData={rnaQc} />}
      </Tab>
      <Tab className="pull-right" name={DNA_TAB_NAME} isHidden={!dnaQcPresent}>
        {dnaQcPresent && <DnaQc dnaQcData={dnaQc} />}
      </Tab>
    </ComposableTabs>
  );
};

export default OncologyQcTabs;
