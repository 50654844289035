import GenoverseTracks from "../genoverse/GenoverseTracks";
import { RelativeVCF } from "../genoverse/tracks/config/SNV/PatientVCF";

const {
  StructuralVariants,
  StructuralVariantsCuratedList,
  StructuralVariantsGnomAD,
  RelativeStructuralVariants,
  Transcripts,
  Log2Ratio,
  RelativeLog2Ratio,
  Coverage,
  RelativeCoverage,
  SpliceSiteKnown,
  GERP,
  dbSNP,
  Exac,
  Mappability,
  Gene,
  SequenceAlignment,
  RelativeSequenceAlignment,
  SequenceVariantsCuratedList,
  SequenceVariantsCuratedListHGMPro,
  SequenceVariantsCuratedListHGMDProNew,
  SequenceVariantsCuratedListClinvar,
  SequenceVariantsCuratedListDecipher,
  SequenceVariantsCuratedListMasterMind,
  Patient,
  PatientVCF,
  RelativeSNVs,
  Ortholog,
  TranscriptProtein,
} = GenoverseTracks;

export const GENOMES = {
  GRCH37: "grch37",
  GRCH38: "grch38",
};

const TYPES = {
  SV: "sv",
  SNV: "snv",
};

const CURATED_TRACKS_MAP = {
  "": SequenceVariantsCuratedList,
  "Genoverse.Track.SNV.CuratedList.HGMDPro": SequenceVariantsCuratedListHGMPro,
  "Genoverse.Track.SNV.CuratedList.HGMDProNew":
    SequenceVariantsCuratedListHGMDProNew,
  "Genoverse.Track.SNV.CuratedList.Clinvar": SequenceVariantsCuratedListClinvar,
  "Genoverse.Track.SNV.CuratedList.Mastermind":
    SequenceVariantsCuratedListMasterMind,
  "Genoverse.Track.SNV.CuratedList.DECIPHER":
    SequenceVariantsCuratedListDecipher,
  "Genoverse.Track.SV.CuratedList.DecipherCNV": StructuralVariantsCuratedList,
};

export const getCuratedVariantTracks = (config, curatedList) =>
  curatedList.reduce(
    (
      tracks,
      { genoverseTrackClass, curatedVariantListId, name, description }
    ) => {
      const track = Object.entries(CURATED_TRACKS_MAP).reduce(
        (acc, [className, value]) => ({
          ...acc,
          [className.toLowerCase()]: value,
        }),
        {}
      )[genoverseTrackClass.toLowerCase()];

      if (track) {
        const id = `CuratedList-${genoverseTrackClass}-${curatedVariantListId}`;
        tracks.push(
          track(config).extend({
            id,
            info: description || `Curated variants from list: ${name}`,
            name,
            configName: [name, "Curated Variant List"],
            urlParams: {
              curated_variant_list_id: curatedVariantListId,
              patient_id: config.patientId,
            },
            trackKey: `CuratedList${curatedVariantListId}`,
          })
        );
      }

      return tracks;
    },
    []
  );

const getFamilyTracks = (config, relatives) =>
  relatives.reduce((tracks, relative) => {
    const { patient } = relative;

    if (!patient) return tracks;

    const { hasCoverage, hasSvs, hasBam, hasLog2ratios, hasSnvs, hasSnvVcf } =
      patient;

    if (hasCoverage) tracks.push(RelativeCoverage(relative));
    if (hasSvs) tracks.push(RelativeStructuralVariants(relative));
    if (hasBam) tracks.push(RelativeSequenceAlignment(relative));
    if (hasLog2ratios) tracks.push(RelativeLog2Ratio(relative));
    const { transcriptId } = config;
    if (transcriptId) {
      if (hasSnvs) {
        tracks.push(RelativeSNVs(config, relative));
      }
      if (hasSnvVcf) {
        tracks.push(RelativeVCF(config, relative));
      }
    }
    return tracks;
  }, []);

export const getTracksLibrary = (config, patient: PatientEntity) => {
  const {
    curatedVariantLists,
    hasCoverage,
    hasLog2ratios,
    patientRelatives,
    hasSnvVcf,
  } = patient;
  const tracks = [
    StructuralVariants(config).extend({ trackKey: "patientSV" }),
    Transcripts(config).extend({ trackKey: "transcript" }),
    SpliceSiteKnown(config).extend({ trackKey: "spliceSiteKnown" }),
    dbSNP(config).extend({ trackKey: "dbSNP" }),
    Exac(config).extend({ trackKey: "exac" }),
    Gene(config).extend({ trackKey: "gene" }),
    SequenceAlignment(config).extend({ trackKey: "BAM" }),
  ];

  if (config.genome === GENOMES.GRCH37) {
    tracks.push(GERP().extend({ trackKey: "GERP" }));
    tracks.push(Mappability().extend({ trackKey: "mappability" }));
  }

  if (hasLog2ratios) tracks.push(Log2Ratio(config).extend({ trackKey: "" }));

  if (hasCoverage) tracks.push(Coverage(config).extend({ trackKey: "" }));

  // Explicitly disabled for now, as the full functionality is not ready/reference data is not prepped
  // eslint-disable-next-line no-constant-condition
  if (false && config.genome === GENOMES.GRCH37 && config.type === TYPES.SV)
    tracks.push(
      StructuralVariantsGnomAD(config).extend({ trackKey: "gnomadSV" })
    );

  if (curatedVariantLists && curatedVariantLists.length) {
    const curatedTracks = getCuratedVariantTracks(config, curatedVariantLists);
    tracks.push({
      name: "Curated Variant Tracks",
      tracks: curatedTracks,
    });
  }
  if (patientRelatives && patientRelatives.length) {
    const familyTracks = getFamilyTracks(config, patientRelatives);

    if (familyTracks.length) {
      tracks.push({
        name: "Family Tracks",
        tracks: familyTracks,
      });
    }
  }
  const { transcriptId } = config;
  if (transcriptId) {
    tracks.push(Patient(config).extend({ trackKey: "patientSNV" }));
    if (hasSnvVcf) {
      tracks.push(PatientVCF(config).extend({ trackKey: "patientVCF" }));
    }
    tracks.push(Ortholog(config).extend({ trackKey: "ortholog" }));
    tracks.push(
      TranscriptProtein(config).extend({ trackKey: "transcriptProtSingle" })
    );
  }
  return tracks;
};

const getPlainTracks = tracksLibrary =>
  tracksLibrary.reduce((acc, track) => {
    const { tracks: nestedTracks } = track;
    if (nestedTracks) {
      acc.push(...getPlainTracks(nestedTracks));
    } else {
      acc.push(track);
    }
    return acc;
  }, []);

export const getTracksByKeys = (tracksLibrary, keys) => {
  const tracks = getPlainTracks(tracksLibrary);
  return tracks.filter(({ prototype = {} }: { prototype: any }) => {
    const { trackKey } = prototype;
    if (trackKey) {
      return keys.includes(trackKey);
    }
    return false;
  });
};
