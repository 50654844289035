import { Coverage, RelativeCoverage } from "./tracks/config/Coverage";
import { Exac } from "./tracks/config/Exac";
import { GERP } from "./tracks/config/GERP";
import { Gene } from "./tracks/config/Gene";
import { Log2Ratio, RelativeLog2Ratio } from "./tracks/config/Log2Ratio";
import { Mappability } from "./tracks/config/Mappability";
import { Ortholog } from "./tracks/config/Ortholog";
import { Patient, RelativeSNVs } from "./tracks/config/SNV/Patient";
import { PatientVCF } from "./tracks/config/SNV/PatientVCF";
import {
  SequenceAlignment,
  RelativeSequenceAlignment,
} from "./tracks/config/SequenceAlignment";
import {
  SequenceVariantsCuratedList,
  SequenceVariantsCuratedListHGMPro,
  SequenceVariantsCuratedListHGMDProNew,
  SequenceVariantsCuratedListClinvar,
  SequenceVariantsCuratedListDecipher,
  SequenceVariantsCuratedListMasterMind,
} from "./tracks/config/SequenceVariantsCuratedList";
import { SpliceSiteKnown } from "./tracks/config/SpliceSite";
import {
  StructuralVariants,
  RelativeStructuralVariants,
} from "./tracks/config/StructuralVariants";
import { StructuralVariantsCuratedList } from "./tracks/config/StructuralVariantsCuratedList";
import { StructuralVariantsGnomAD } from "./tracks/config/StructuralVariantsGnomAD";
import { TranscriptProtein } from "./tracks/config/TranscriptProtein";
import { Transcripts } from "./tracks/config/Transcripts";
import { dbSNP } from "./tracks/config/dbSNP";

export default {
  Transcripts,
  dbSNP,
  Gene,
  StructuralVariants,
  RelativeStructuralVariants,
  StructuralVariantsCuratedList,
  StructuralVariantsGnomAD,
  SequenceVariantsCuratedList,
  SequenceVariantsCuratedListHGMPro,
  SequenceVariantsCuratedListHGMDProNew,
  SequenceVariantsCuratedListClinvar,
  SequenceVariantsCuratedListDecipher,
  SequenceVariantsCuratedListMasterMind,
  Coverage,
  RelativeCoverage,
  Log2Ratio,
  RelativeLog2Ratio,
  SpliceSiteKnown,
  Mappability,
  GERP,
  Exac,
  SequenceAlignment,
  RelativeSequenceAlignment,
  Patient,
  PatientVCF,
  RelativeSNVs,
  Ortholog,
  TranscriptProtein,
};
