import { react as autoBind } from "auto-bind";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import { notEmpty } from "../../../modules/utils/array";
import { Tag } from "../../elements/base";

export default class AccordionSectionHeader extends PureComponent {
  static displayName = "AccordionSectionHeader";

  static propTypes = {
    /**
     * accordion section header label
     */
    sectionName: PropTypes.string.isRequired,
    /**
     * accordion section header tags
     */
    sectionTags: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        className: PropTypes.string,
      })
    ),
    /**
     * accordion section header custom content
     */
    children: PropTypes.node,
    /**
     * if true header is disabled and the corresponding accordion section cannot be expanded
     */
    isDisabled: PropTypes.bool,
    /**
     * section header click handler that should modify section expand/collapse state
     */
    onClick: PropTypes.func.isRequired,
    /**
     * whether the section header should show the error state
     */
    hasError: PropTypes.bool,
  };

  static defaultProps = {
    isDisabled: false,
    hasError: false,
  };

  constructor(props) {
    super(props);

    autoBind(this);
  }

  onClick(e) {
    e.preventDefault();
    const { onClick, sectionName, isDisabled } = this.props;
    if (isDisabled === false) {
      // should not expand empty section
      onClick(sectionName);
    }
  }

  render() {
    const {
      sectionName,
      children,
      isDisabled,
      hasError,
      sectionSubtitle,
      sectionTags = [],
    } = this.props;
    return (
      <div
        onClick={this.onClick}
        className={classNames(
          "accordion-section-header",
          {
            "accordion-section-header-disabled": isDisabled,
          },
          {
            "accordion-section-header-error": hasError,
          }
        )}
      >
        <div>
          {sectionName}
          {isDisabled === false && (
            <i className="accordion-section-header-arrow" />
          )}
          {sectionSubtitle && <div>{sectionSubtitle}</div>}
          {notEmpty(sectionTags) && (
            <div>
              {sectionTags.map(({ label, className = "" }) => (
                <Tag key={label} className={className}>
                  {label}
                </Tag>
              ))}
            </div>
          )}
        </div>
        {children}
      </div>
    );
  }
}
