import React, { useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from "redux-form";

import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Tag,
  Tooltip,
} from "pattern-library";

import { ReduxFormField } from "modules/forms/components";
import { getRoles } from "modules/roles";

import { deleteProjectUser } from "../actions";
import * as constants from "../constants";
import * as selectors from "../selectors";

export const rolesProcessor = (allRoles: Role[] = [], roles = {}) =>
  allRoles.map(({ name, label, description }) => {
    if (roles[name]) {
      return (
        <Tooltip key={name} placement="left" content={description}>
          <Tag className={`role-${label.replace(/\s+/g, "-").toLowerCase()}`}>
            {label}
          </Tag>
        </Tooltip>
      );
    }
    return null;
  });

type Props = PropsFromRedux & {
  close: () => void;
  show: boolean;
};

export const DeleteUserModal: React.FC<
  Props & InjectedFormProps<EditableProjectUser, Props>
> = ({
  handleSubmit,
  close,
  show,
  deleteProjectUser,
  roles,
  allRoles,
  projectName,
}) => {
  const submitHandler = useCallback(
    values => {
      const { projectId, userId } = values;
      deleteProjectUser(projectId, userId);
    },
    [deleteProjectUser]
  );

  return (
    <Modal data-testid="delete-user-modal" close={close} show={show}>
      <ModalHeader close={close}>
        <strong>{`Remove user from project "${projectName}"?`}</strong>
      </ModalHeader>
      <ModalBody>
        <fieldset className="d-table">
          <Label>Name</Label>
          <Field
            disabled
            component={ReduxFormField}
            type="text"
            name="fullName"
          />
          <Label>Email Address</Label>
          <Field disabled component={ReduxFormField} type="text" name="email" />
          <Label>Project</Label>
          <Field
            disabled
            component={ReduxFormField}
            type="text"
            name="projectName"
          />
          <Label>Access</Label>
          <div className="form-group row col-sm-12">
            {rolesProcessor(allRoles, roles)}
          </div>
        </fieldset>
      </ModalBody>
      <ModalFooter>
        <Button type="button" className="button" onClick={close}>
          Cancel
        </Button>
        <Button
          type="button"
          className="button btn-danger"
          onClick={handleSubmit(submitHandler)}
        >
          Remove user
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = state => {
  const selector = formValueSelector(constants.USER_DELETE_FORM);
  return {
    initialValues: selectors.getEditableUser(state),
    projectName: selector(state, "projectName"),
    roles: selector(state, "roles"),
    allRoles: getRoles(state),
  };
};

const mapDispatchToProps = {
  deleteProjectUser,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedForm = reduxForm<EditableProjectUser, Props>({
  form: constants.USER_DELETE_FORM,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(DeleteUserModal);

export default connector(ConnectedForm);
