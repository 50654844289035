import { decamelize } from "humps";
import React, { useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";

import {
  Modal,
  ModalBody,
  ModalFooter,
  PrimaryModalHeader,
  Button,
} from "pattern-library";

import { hideModal } from "modules/modalRegistry/actions";
import { modalTypes } from "modules/modalRegistry/constants";

import legacyApi from "api/legacy-api";

const { useGetGeneInfoQuery } = legacyApi;

// NOTE: calling from modal registry need to pass this data:
//    showModal(modalTypes.GENE_INFO_MODAL, {
//      geneName: "AARS",
//    });

interface Props extends PropsFromRedux {
  geneName: string;
  ensemblVersion: string;
}

export const GeneInfoModal = ({
  geneName,
  ensemblVersion,
  hideModal,
}: Props) => {
  const { data: { gene, keys: columns, skipCols: columnsToOmit } = {} } =
    useGetGeneInfoQuery({
      geneName,
      ensemblVersion,
    });

  const onCloseHandler = useCallback(() => {
    hideModal(modalTypes.GENE_INFO_MODAL);
  }, [hideModal]);

  if (!gene || !columnsToOmit || !columns) return null;

  return (
    <Modal show close={onCloseHandler}>
      <PrimaryModalHeader close={onCloseHandler}>{geneName}</PrimaryModalHeader>
      <ModalBody>
        <table className="table table-striped table-hover">
          <tbody>
            {gene &&
              gene.name === geneName &&
              columns
                .filter(column => !columnsToOmit[column])
                .map(column => {
                  let value = gene[column];
                  if (Array.isArray(value)) {
                    value = value.join(", ");
                  }

                  if (column.includes("Url")) {
                    value = (
                      <a href={value} target="_blank" rel="noopener noreferrer">
                        {value}
                      </a>
                    );
                  }
                  const columnTitle = decamelize(column);

                  return (
                    <tr key={column}>
                      <td className="col-md-6">
                        <strong>{columnTitle}</strong>
                      </td>
                      <td className="col-md-6">{value}</td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </ModalBody>
      <ModalFooter>
        <Button context="primary" onClick={onCloseHandler}>
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapDispatchToProps = { hideModal };

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(GeneInfoModal);
