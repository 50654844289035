import { isNil } from "ramda";
import { FC, MouseEvent, useEffect, useMemo } from "react";

import { Button, Icon } from "pattern-library";

import styles from "./PatientOnliningButton.module.scss";

import congenicaApi from "api/congenica-api";

interface Props {
  patientId: number;
  goOnlineText: string;
  goingOnlineText: string;
  goingOfflineText: string;
  patientGoingOnline?: boolean;
  patientGoingOffline?: boolean;
}

const {
  useLazyGetPatientOfflineStatusQuery,
  useSetPatientFamilyOfflineStatusMutation,
} = congenicaApi;

const PatientOnliningButton: FC<Props> = ({
  patientId,
  goOnlineText,
  goingOnlineText,
  goingOfflineText,
  patientGoingOnline,
  patientGoingOffline,
}) => {
  const [
    getPatientOfflineStatusQuery,
    {
      data: patientOfflineStatusData,
      isFetching: patientOfflineStatusFetching,
    },
  ] = useLazyGetPatientOfflineStatusQuery();
  const [
    setPatientFamilyOfflineStatus,
    {
      isLoading: setPatientFamilyOfflineStatusLoading,
      data: setPatientFamilyOfflineStatusData,
    },
  ] = useSetPatientFamilyOfflineStatusMutation();

  useEffect(() => {
    if (isNil(patientGoingOnline) || isNil(patientGoingOffline)) {
      getPatientOfflineStatusQuery({
        patientId,
      });
    }
  }, [
    getPatientOfflineStatusQuery,
    patientGoingOnline,
    patientGoingOffline,
    patientId,
  ]);

  const onlinePatient = () => {
    setPatientFamilyOfflineStatus({ patientId, offlineStatus: false });
  };

  const handleClick = (event: MouseEvent<Button>) => {
    event.stopPropagation();

    onlinePatient();
  };

  const currentPatientOfflineStatusResponse =
    setPatientFamilyOfflineStatusData?.familyMemberStatuses.find(
      ({ id }) => String(patientId) === id
    );

  const loading =
    patientOfflineStatusFetching || setPatientFamilyOfflineStatusLoading;
  const goingOnline =
    patientGoingOnline ||
    patientOfflineStatusData?.goingOnline ||
    (isNil(patientGoingOnline) &&
      currentPatientOfflineStatusResponse?.goingOnline);
  const goingOffline =
    patientGoingOffline ||
    patientOfflineStatusData?.goingOffline ||
    (isNil(patientGoingOffline) &&
      currentPatientOfflineStatusResponse?.goingOffline);

  const icon = useMemo(
    () =>
      loading ? (
        <Icon type="spinner" className={styles.icon} />
      ) : goingOnline || goingOffline ? (
        <Icon type="hourglassHalf" className={styles.icon} />
      ) : null,
    [loading, goingOnline, goingOffline]
  );
  const buttonText = useMemo(() => {
    if (goingOnline) {
      return goingOnlineText;
    } else if (goingOffline) {
      return goingOfflineText;
    }

    return goOnlineText;
  }, [
    goOnlineText,
    goingOffline,
    goingOfflineText,
    goingOnline,
    goingOnlineText,
  ]);

  return (
    <>
      <Button
        disabled={loading || goingOnline || goingOffline}
        className="btn-primary"
        onClick={handleClick}
      >
        {icon}
        {buttonText}
      </Button>
    </>
  );
};

export default PatientOnliningButton;
