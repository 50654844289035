import React from "react";
import { connect } from "react-redux";

import { error } from "modules/messages/actions";

import UploadForm from "./upload/UploadForm";
import UploadGenesTabs from "./upload/UploadGenesTabs";

import legacyApi from "api/legacy-api";

const { useUploadGenePanelFileMutation } = legacyApi;

type Props = {
  projectId: number;
  onClose: any;
};

const UploadGenePanelTab: React.FC<Props> = ({ projectId, onClose }) => {
  const [uploadGenePanel, { isLoading: isProcessing, data }] =
    useUploadGenePanelFileMutation();

  const onGenePanelUpload = data => {
    if (!data.genePanelFile) {
      error("No file has been selected for upload");
      return;
    }
    uploadGenePanel({
      projectId,
      genePanelFile: {
        csv_format: data.csvFormat,
        gene_panel_file: data.genePanelFile,
        ensembl_version: data.ensemblVersion,
      },
    });
  };

  if (isProcessing) {
    return <div className="form_processing">Processing</div>;
  }

  return (
    <>
      {data?.tempGenePanelId ? (
        <UploadGenesTabs
          projectId={projectId}
          tempGenePanelId={data.tempGenePanelId}
          onClose={onClose}
        />
      ) : (
        <UploadForm onSubmit={onGenePanelUpload} />
      )}
    </>
  );
};

const mapDispatchToProps = {
  error,
};

export default connect(null, mapDispatchToProps)(UploadGenePanelTab);
