import React, { useCallback, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { formValueSelector, InjectedFormProps, reduxForm } from "redux-form";
import * as yup from "yup";

import {
  Button,
  ComposableTabs,
  Heading,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Tab,
} from "pattern-library";

import validator from "modules/forms/validator";

import {
  addPermissionsProjectUser,
  addProjectUser,
  fetchCurrentUserAuthProviders,
  updateProjectUser,
} from "../actions";
import {
  ADD_EXISTING_USER,
  ADD_PROJECT_USER,
  EDIT_PROJECT_USER,
  USER_FORM,
} from "../constants";
import * as selectors from "../selectors";
import { Mode } from "../types";

import UserForm from "./UserForm";
import UsersLookupView from "./UsersLookupView";

const FULL_NAME_MESSAGE = "Please provide a valid user name";
const EMAIL_MESSAGE = "Please provide a valid email";

export const validationSchema = yup.object({
  fullName: yup
    .string("")
    .trim()
    .required(FULL_NAME_MESSAGE)
    .min(3, FULL_NAME_MESSAGE),
  email: yup.string("").trim().required(EMAIL_MESSAGE).email(EMAIL_MESSAGE),
});

type Props = PropsFromRedux & {
  projectId: number;
  close: () => void;
  mode: Mode;
  doubleUserCheck: boolean;
  show: boolean;
};

export const UserModal: React.FC<
  Props & InjectedFormProps<EditableProjectUser, Props>
> = ({
  close,
  show,
  mode,
  handleSubmit,
  addProjectUser,
  updateProjectUser,
  addPermissionsProjectUser,
  isAdmin,
  reset,
  projectId,
  doubleUserCheck,
  fetchCurrentUserAuthProviders,
}) => {
  useEffect(() => {
    if (mode !== ADD_PROJECT_USER) {
      fetchCurrentUserAuthProviders();
    }
  }, [mode, fetchCurrentUserAuthProviders]);

  const submitHandler = useCallback(
    values => {
      if (mode === ADD_PROJECT_USER) {
        addProjectUser(values);
      } else if (mode === ADD_EXISTING_USER) {
        addPermissionsProjectUser(values);
      } else {
        updateProjectUser(values);
      }
    },
    [mode, addProjectUser, updateProjectUser, addPermissionsProjectUser]
  );

  const onClose = useCallback(() => {
    close();
    reset();
  }, [reset, close]);
  return (
    <Modal data-testid="user-modal" close={onClose} show={show}>
      <ModalHeader close={onClose}>
        <Heading type="h3">
          {mode === ADD_PROJECT_USER && "Add project user"}
          {mode === ADD_EXISTING_USER && "Add user rights and permissions"}
          {mode === EDIT_PROJECT_USER && "Edit user rights and permissions"}
        </Heading>
      </ModalHeader>
      <ModalBody className="users-form-body">
        <form
          id={
            mode === ADD_PROJECT_USER
              ? "form_project_user_add"
              : "form_project_user"
          }
          onSubmit={handleSubmit(submitHandler)}
        >
          {mode !== ADD_PROJECT_USER && (
            <>
              <UserForm
                mode={mode}
                isAdmin={Boolean(isAdmin)}
                doubleUserCheck={Boolean(doubleUserCheck)}
              />
              <ModalFooter className="users-form-footer">
                <Button data-testid="submit" type="submit" context="primary">
                  Update
                </Button>
              </ModalFooter>
            </>
          )}
          {mode === ADD_PROJECT_USER && (
            <ComposableTabs navStyle>
              <Tab key="New" name="New">
                <UserForm
                  mode={mode}
                  isAdmin={isAdmin}
                  doubleUserCheck={doubleUserCheck}
                />
                <ModalFooter className="users-form-footer">
                  <Button data-testid="submit" type="submit" context="primary">
                    Submit
                  </Button>
                </ModalFooter>
              </Tab>
              <Tab key="Existing" name="Existing">
                <UsersLookupView projectId={projectId} />
              </Tab>
            </ComposableTabs>
          )}
        </form>
      </ModalBody>
    </Modal>
  );
};
const mapStateToProps = state => {
  const selector = formValueSelector(USER_FORM);

  return {
    initialValues: selectors.getEditableUser(state),
    isAdmin: selector(state, "roles.admin"),
    mode: selectors.getUserMode(state),
  };
};

const mapDispatchToProps = {
  addProjectUser,
  updateProjectUser,
  fetchCurrentUserAuthProviders: fetchCurrentUserAuthProviders.start,
  addPermissionsProjectUser,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedForm = reduxForm<EditableProjectUser, Props>({
  form: USER_FORM,
  destroyOnUnmount: true,
  enableReinitialize: true,
  shouldAsyncValidate(params) {
    return params.trigger === "submit";
  },
  asyncValidate: values => validator(validationSchema)(values),
})(UserModal);

export default connector(ConnectedForm);
