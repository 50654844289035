import { useMemo } from "react";

import { GenomeAssemblyType } from "modules/systemConfig/types";

import congenicaApi from "api/congenica-api";

const { useGetSystemConfigQuery } = congenicaApi;

export const useAssemblyConfig = (): GenomeAssemblyType | undefined => {
  const { data } = useGetSystemConfigQuery();

  return useMemo(
    () =>
      (typeof data?.sapientiaAssembly === "string"
        ? data?.sapientiaAssembly.toLowerCase()
        : data?.sapientiaAssembly) as GenomeAssemblyType | undefined,
    [data]
  );
};
