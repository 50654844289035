import { isEmpty } from "ramda";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Column } from "react-table-7";

import { DEFAULT_PAGE_SIZE, Table } from "pattern-library/elements/table7";

import { filterProcessor } from "modules/utils/table";

import { makeSafeForCSS } from "../../../common/utils";
import { VariantType } from "../common-types";

import { useSplitDriverPassengerVariants } from "./useSplitDriverPassengerVariants";
import { getCellProps } from "./utils";

export const FILTER_PLACEHOLDER = "Filter by Gene...";

interface CommonContainerProps {
  data: VariantType[];
  columns: Column[];
  titleSegment: string;
  className?: string;
  getRowProps: any;
  defaultSortBy: SortBy[];
}

interface SortBy {
  id: string;
  desc?: boolean;
}

interface ReactTable7RefPartial {
  setSortBy: (sortBy: SortBy[]) => void;
}

const filter = filterProcessor(
  ["gene", "gene_a_symbol", "gene_b_symbol"],
  null
);

export const CommonContainer: FC<CommonContainerProps> = ({
  titleSegment,
  columns,
  getRowProps,
  data,
  className,
  defaultSortBy,
}) => {
  const [filterValue, setFilterValue] = useState<string>("");
  const [filteredVariants, setFilteredVariants] = useState<VariantType[]>([]);

  const { drivers, passengers } =
    useSplitDriverPassengerVariants(filteredVariants);

  const driverTableRef = useRef<ReactTable7RefPartial>(null);
  const passengerTableRef = useRef<ReactTable7RefPartial>(null);

  useEffect(() => {
    if (data && !isEmpty(data)) {
      setFilteredVariants(filter(data, filterValue));
    }
  }, [data, filterValue, setFilteredVariants]);

  const onFilterChange = useCallback(
    ({ filter = "" }) => {
      setFilterValue(filter);
    },
    [setFilterValue]
  );

  const handleSortDrivers = useCallback((sortedColumns: SortBy[]) => {
    if (passengerTableRef.current?.setSortBy) {
      passengerTableRef.current.setSortBy(sortedColumns);
    }
  }, []);

  const handleSortPassengers = useCallback((sortedColumns: SortBy[]) => {
    if (driverTableRef.current?.setSortBy) {
      driverTableRef.current.setSortBy(sortedColumns);
    }
  }, []);

  return (
    <>
      <Table
        id={`react-oncology-drivers-${makeSafeForCSS(titleSegment)}`}
        className={className}
        showTitleInfo
        title={`Driver ${titleSegment}`}
        fetchData={onFilterChange}
        enableFilter
        columns={columns}
        data={drivers}
        showPagination={drivers.length > DEFAULT_PAGE_SIZE}
        autoResetPage={false}
        filterPlaceholder={FILTER_PLACEHOLDER}
        getRowProps={getRowProps}
        getCellProps={getCellProps}
        sortBy={defaultSortBy}
        autoResetSortBy={false}
        onSort={handleSortDrivers}
        tableRef={driverTableRef}
      />
      <Table
        id={`react-oncology-passengers-${makeSafeForCSS(titleSegment)}`}
        className={className}
        showTitleInfo
        title={`Passenger ${titleSegment}`}
        columns={columns}
        data={passengers}
        showPagination={passengers.length > DEFAULT_PAGE_SIZE}
        autoResetPage={false}
        getRowProps={getRowProps}
        getCellProps={getCellProps}
        sortBy={defaultSortBy}
        autoResetSortBy={false}
        onSort={handleSortPassengers}
        tableRef={passengerTableRef}
      />
    </>
  );
};
