import { camelize } from "humps";
import React, { useCallback, useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";

import { hideModal } from "modules/modalRegistry/actions";
import { modalTypes } from "modules/modalRegistry/constants";

import {
  getEditGeneFormValues,
  transformFieldsOptions,
} from "../../../../utils";
import EditGeneForm from "../../../EditGeneForm";

import legacyApi from "api/legacy-api";
import { useAssemblyConfig } from "hooks/useAssemblyConfig";

const { useGetTempGenePanelGeneQuery, useUpdateTempGenePanelGeneMutation } =
  legacyApi;

// NOTE: calling from modal registry need to pass this data:
//    showModal(modalTypes.EDIT_TEMPORARY_GENE_MODAL, {
//      projectId: 123,
//      tempGenePaneId: 123,
//      geneName: "AARS",
//    });

interface Props extends PropsFromRedux {
  projectId: number;
  tempGenePanelId: number;
  geneName: string;
}

const EditTempGeneModal = ({
  projectId,
  tempGenePanelId,
  geneName,
  hideModal,
}: Props) => {
  const { data: { gene, fields: rawFields, keys } = {} } =
    useGetTempGenePanelGeneQuery({
      projectId,
      tempGenePanelId,
      geneName,
    });

  const assembly = useAssemblyConfig();

  const [updateEditGene] = useUpdateTempGenePanelGeneMutation();

  const columns: Array<string> = useMemo(
    () => (keys || []).map(camelize),
    [keys]
  );
  const fields = useMemo(() => transformFieldsOptions(rawFields), [rawFields]);
  const initialValues = useMemo(
    () => getEditGeneFormValues(gene, columns, fields),
    [gene, columns, fields]
  );

  const hideModalHandler = useCallback(() => {
    hideModal(modalTypes.EDIT_TEMPORARY_GENE_MODAL);
  }, [hideModal]);

  return gene && keys && fields ? (
    <EditGeneForm
      projectId={projectId}
      genePanelId={tempGenePanelId}
      geneName={geneName}
      updateGene={updateEditGene}
      gene={gene}
      fields={fields}
      columns={columns}
      assembly={assembly}
      initialValues={initialValues}
      hideModal={hideModalHandler}
    />
  ) : null;
};

const mapDispatchToProps = {
  hideModal,
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditTempGeneModal);
