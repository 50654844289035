import { isNil } from "ramda";

import {
  fetchCamelizeData,
  fetchData,
  fetchPerlEndpointData,
} from "modules/utils/fetchData";

import { prepareCatalystUsers } from "../../api/utils/users";
import { API_ENTITIES_BASE_URL, CATALYST_BASE_URL } from "../utils/baseUrls";

export const ADMIN_ROLE = {
  name: "admin",
  label: "Administrator",
  description: "Administrator",
};

const rolesToAccessLevels = (allRoles, roles = []) => {
  if (roles.admin) {
    return { coordinator: 1 };
  }
  return allRoles.reduce((accum, { name, lookupId }) => {
    if (name !== "admin" && roles[name]) {
      accum = {
        ...accum,
        [`project_user_access_level_${lookupId}`]: lookupId,
      };
    }
    return accum;
  }, {});
};

export function addUser(
  allRoles,
  {
    projectId,
    fullName,
    email,
    visibilityLevelId,
    roles,
    authenticationProviderId,
  }
) {
  const request = {
    user_name: fullName,
    email,
    ...rolesToAccessLevels(allRoles, roles),
  };
  if (!isNil(visibilityLevelId)) {
    request.visibility_level = visibilityLevelId;
  }
  if (!isNil(authenticationProviderId)) {
    request.authentication_provider_id = authenticationProviderId;
  }
  return fetchCamelizeData(`/project/${projectId}/admin/user/add`, {
    method: "POST",
    body: JSON.stringify(request),
    credentials: "include",
  });
}

export function editUser(
  allRoles,
  { projectId, userId, visibilityLevelId, roles, authenticationProviderId }
) {
  const request = {
    ...rolesToAccessLevels(allRoles, roles),
  };
  if (!isNil(visibilityLevelId)) {
    request.visibility_level = visibilityLevelId;
  }
  if (!isNil(authenticationProviderId)) {
    request.authentication_provider_id = authenticationProviderId;
  }
  return fetchCamelizeData(`/project/${projectId}/admin/user/${userId}/edit`, {
    method: "POST",
    body: JSON.stringify(request),
    credentials: "include",
  });
}

export function addPermissions(
  allRoles,
  { projectId, userId, visibilityLevelId, roles }
) {
  return fetchCamelizeData(
    `/project/${projectId}/admin/user/${userId}/add_permissions`,
    {
      method: "POST",
      body: JSON.stringify({
        visibility_level: visibilityLevelId,
        ...rolesToAccessLevels(allRoles, roles),
      }),
      credentials: "include",
    }
  );
}

const prepareRoles = roles => {
  const result = roles.map(({ id, attributes }) => ({
    id,
    ...attributes,
  }));
  result.push(ADMIN_ROLE);
  return result.sort((a, b) => a.label.localeCompare(b.label));
};

export const getRoles = async () => {
  const result = await fetchCamelizeData(
    `${API_ENTITIES_BASE_URL}/projects/roles`
  );

  if (result.ok) {
    const { data = [] } = result.payload || {};

    return {
      ...result,
      payload: prepareRoles(data),
    };
  }
  return result;
};

const toBoolean = numberValue => !isNil(numberValue) && numberValue !== 0;

const getUserRoles = accessLevels =>
  accessLevels
    .filter(({ assigned }) => toBoolean(assigned))
    .map(
      ({
        roleName,
        onlyDoubleUserCheck,
        userProjectAccessLevel,
        description,
        id,
        label,
      }) => ({
        name: roleName,
        label,
        description,
        id,
        onlyDoubleUserCheck: toBoolean(onlyDoubleUserCheck),
        userProjectAccessLevel,
      })
    );

const prepareUser = user => {
  const parsedRoles = user.roles
    ? typeof user.roles === "string"
      ? JSON.parse(user.roles)
      : user.roles
    : [];
  const roles = user.accessLevel === "admin" ? [ADMIN_ROLE] : parsedRoles;

  return {
    ...user,
    visibilityLevelId: user.visibilityLevelId
      ? Number(user.visibilityLevelId)
      : user.visibilityLevelId,
    roles,
  };
};

export const getUsers = async projectId => {
  const result = await fetchData(
    `${CATALYST_BASE_URL}/project/${projectId}/users`
  );

  if (result.ok) {
    const { project_users = [], inherited_users = [] } = result.payload || {};
    return {
      ...result,
      payload: {
        projectUsers: prepareCatalystUsers(project_users),
        inheritedUsers: prepareCatalystUsers(inherited_users),
      },
    };
  }
  return result;
};

export const getExistingUsers = async projectId => {
  const result = await fetchPerlEndpointData(
    `/project/${projectId}/admin/user/add/existing`
  );
  if (result.ok) {
    return {
      ...result,
      payload: result.payload.map(({ fullname, ...rest }) => ({
        fullName: fullname,
        ...rest,
      })),
    };
  }
  return result;
};

export const getExistingUserDetails = async (projectId, userIdParam) => {
  const result = await fetchPerlEndpointData(
    `/project/${projectId}/admin/user/${userIdParam}/add_permissions`
  );
  if (result.ok) {
    const {
      projectUser: {
        visibilityLevelId,
        fullname,
        userId,
        accessLevel,
        authenticationProviderId,
        email,
      },
      project: { code },
      accessLevels = [],
    } = result.payload || {};
    return {
      ...result,
      payload: prepareUser({
        email,
        authenticationProviderId,
        userId,
        accessLevel,
        visibilityLevelId,
        fullName: fullname,
        roles: getUserRoles(accessLevels),
        projectCode: code,
      }),
    };
  }
  return result;
};

export function removeProjectUser(projectId, userId) {
  return fetchCamelizeData(
    `/project/${projectId}/admin/user/${userId}/delete`,
    {
      method: "POST",
      credentials: "include",
    }
  );
}
