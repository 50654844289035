import React from "react";
import { connect, ConnectedProps } from "react-redux";

import { Actions } from "pattern-library";

import { getCVLDetails, exportCvlVariants } from "../actions";
import { canExportCVL } from "../selectors";
import { CVLAction, ProjectCuratedVariantList } from "../types";
import { getCuratedVariantsExportAction, getViewEditAction } from "../utils";

import { useEnsemblVersion } from "hooks/useEnsemblVersion";

interface InheritedCVLRowActionsConnectedFormProps extends PropsFromRedux {
  cvl: ProjectCuratedVariantList;
}

interface InheritedCVLRowActionsProps
  extends InheritedCVLRowActionsConnectedFormProps {
  ensemblVersion?: string;
}

export const InheritedCVLRowActions = ({
  cvl,
  getCVLDetails,
  canExportCVL,
  exportCvlVariants,
  ensemblVersion,
}: InheritedCVLRowActionsProps) => {
  const actions: Array<CVLAction> = [];

  const { curatedVariantListId, originProjectId, variantCount } = cvl;

  if (curatedVariantListId) {
    actions.push(
      getViewEditAction(getCVLDetails, curatedVariantListId, originProjectId)
    );
  }

  if (canExportCVL && ensemblVersion) {
    actions.push(
      getCuratedVariantsExportAction(
        exportCvlVariants,
        curatedVariantListId,
        variantCount,
        ensemblVersion
      )
    );
  }

  return <Actions actions={actions} justifyContent="space-around" />;
};

const InheritedCVLRowActionsConnectedForm = (
  props: InheritedCVLRowActionsConnectedFormProps
) => {
  const { ensemblVersion } = useEnsemblVersion();

  return <InheritedCVLRowActions {...props} ensemblVersion={ensemblVersion} />;
};

const mapStateToProps = (state, { cvl }) => ({
  canExportCVL: canExportCVL(state, cvl),
});

const mapDispatchToProps = {
  getCVLDetails,
  exportCvlVariants,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(InheritedCVLRowActionsConnectedForm);
