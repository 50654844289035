import classNames from "classnames";
import React, { useState, memo, useCallback, useMemo } from "react";

import { Alert, Button, GenePanelTitle } from "pattern-library";

import GenePanelsTable from "../../GenePanelsTable";

import congenicaApi from "api/congenica-api";
import legacyApi from "api/legacy-api";

const { useGetThirdPartyDataVersionsQuery } = congenicaApi;
const {
  useGetExistingGenePanelsForProjectQuery,
  useAddExistingGenePanelToProjectMutation,
} = legacyApi;

const Actions: React.FC<{
  projectId: number;
  genePanel: CopyProjectGenePanel;
}> = ({ projectId, genePanel }) => {
  const [added, setAdded] = useState(false);

  const [addGenePanel, { isLoading: isGenePanelAdding }] =
    useAddExistingGenePanelToProjectMutation();

  const { genePanelId } = genePanel;

  const addClickHandler = useCallback(async () => {
    await addGenePanel({ projectId, genePanelId }).unwrap();
    setAdded(true);
  }, [addGenePanel, projectId, genePanelId]);

  if (isGenePanelAdding) return <>Adding gene panel</>;

  return (
    <>
      {added ? (
        <em>Gene panel added</em>
      ) : (
        <Button context="primary" onClick={addClickHandler}>
          Add gene panel
        </Button>
      )}
    </>
  );
};

type Props = {
  projectId: number;
};

const CopyExistingPanelTab: React.FC<Props> = memo(({ projectId }) => {
  const { data: ensemblVersions = [], isLoading: ensemblVersionsLoading } =
    useGetThirdPartyDataVersionsQuery({
      name: "ENSEMBL",
    });

  const { data, isLoading } = useGetExistingGenePanelsForProjectQuery({
    projectId,
  });

  const genePanels = useMemo(() => data?.genePanels || [], [data?.genePanels]);
  const writePermissions = data?.writePermissions;

  const columns = useMemo(
    () =>
      [
        {
          Header: "Gene panel",
          accessor: "title",
          Cell: ({
            row: {
              original: { title, version },
            },
          }) => <GenePanelTitle title={title} version={version} />,
        },
        ensemblVersions.length > 1 && {
          Header: "Ensembl Version",
          accessor: "ensemblVersion",
        },
        {
          Header: "Description",
          accessor: "description",
        },
        {
          Header: "Total genes",
          accessor: "genesCount",
        },
        writePermissions && {
          Header: "Actions",
          id: "actions",
          width: 124,
          accessor: (genePanel: CopyProjectGenePanel) => (
            <Actions projectId={projectId} genePanel={genePanel} />
          ),
        },
      ].filter(Boolean),
    [ensemblVersions.length, writePermissions, projectId]
  );

  return (
    <>
      {!ensemblVersionsLoading && ensemblVersions.length > 1 && (
        <Alert severity="info">
          Copying of gene panels is only possible within the Ensembl version of
          the base panel
        </Alert>
      )}
      <GenePanelsTable
        columns={columns}
        ensemblVersions={ensemblVersions}
        genePanels={genePanels}
        isLoading={isLoading || ensemblVersionsLoading}
        title="Gene panel templates"
        id="existingGenePanels"
        showTitleInfo
        className={classNames({
          "copy-existing-gene-panels-table": genePanels.length === 0,
        })}
        sortBy={[
          {
            id: "title",
            desc: false,
          },
        ]}
      />
    </>
  );
});

export default CopyExistingPanelTab;
