import { createSelector } from "@reduxjs/toolkit";
import { path } from "ramda";

import { getCurrentProject } from "modules/project/selectors";
import { getRoles } from "modules/roles";

import { NAMESPACE } from "./constants";

const dataPath = (...args) => path([NAMESPACE, ...args]);
const uiPath = (...args) => path([NAMESPACE, "ui", ...args]);
const usersPath = (...args) => path([NAMESPACE, "users", ...args]);

export const getUsersLoading = uiPath("loading");
export const isExistingUserLoading = uiPath("existingUserLoading");

export const getProjectUsers: (state: any) => ProjectUser[] =
  usersPath("projectUsers");
export const getInheritedUsers: (state: any) => ProjectUser[] =
  usersPath("inheritedUsers");

export const getExistingUsers: (state: any) => User[] = dataPath(
  "existingUsers",
  "data"
);
export const getExistingUsersLoading = dataPath("existingUsers", "loading");

export const getUser = uiPath("userInfo", "user");
export const getUserMode = uiPath("userInfo", "mode");
export const getDoubleUserCheck = uiPath("doubleUserCheck");

export const getVisibilityLevelsOptions = createSelector(
  getCurrentProject,
  (currentProject: Project) => {
    const { defaults: { visibilityLevels = {} } = {} } = currentProject
      ? currentProject
      : {};
    const result = {};
    for (const [key, value] of Object.entries(visibilityLevels)) {
      result[key] = `${value.name} - ${value.description}`;
    }
    return result;
  }
);

export const getPatientVisibilityEnabled = createSelector(
  getCurrentProject,
  project => {
    const { defaults: { patientVisibility: { enabled = false } = {} } = {} } =
      project || {};
    return enabled;
  }
);

export const getEditableUser = createSelector(
  getUser,
  getCurrentProject,
  getRoles,
  (
    {
      userId,
      fullName,
      email,
      roles = [],
      visibilityLevelId,
      authenticationProviderId,
    } = {},
    currentProject,
    allRoles
  ) => {
    const { projectId, code: projectName } = currentProject || {};
    const user: EditableProjectUser = {
      projectId,
      projectName,
      userId,
      fullName,
      email,
      visibilityLevelId,
      authenticationProviderId: authenticationProviderId || "internal",
      roles: {},
    };
    if (allRoles) {
      allRoles.forEach(({ name }) => {
        const selected = !!roles.find(({ name: e }) => e === name);
        if (user.roles) user.roles[name] = selected;
      });
    }
    return user;
  }
);

export const getCurrentUserAuthProviders = uiPath("currentUserAuthProviders");
export const getCurrentUserAuthProviderOptions = createSelector(
  getCurrentUserAuthProviders,
  authProviders =>
    authProviders.reduce(
      (acc, currentAuthProvider) => {
        acc[currentAuthProvider.id] = currentAuthProvider.attributes.name;
        return acc;
      },
      { internal: "Internal" }
    )
);
