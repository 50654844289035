import { set } from "dot-prop-immutable";
import { decamelize } from "humps";
import { isNil } from "ramda";

import { API_ENTITIES_BASE_URL } from "modules/utils/baseUrls";

import { fetchCamelizeData, fetchData } from "../utils";
import { camelizeResponse } from "../utils/common";
import { buildTableQueryParams } from "../utils/fetchData";
import type { FetchDataResponse } from "../utils/fetchData";
import {
  flattenApiEntity,
  formatPaginatedResponse,
} from "../utils/formatApiResponse";

export function getCuratedListsOptions(projectId) {
  return fetchCamelizeData(
    `${API_ENTITIES_BASE_URL}/projects/${projectId}/curated_variant_list_options`
  );
}

export function getProjectCuratedLists(projectId) {
  return fetchCamelizeData(
    `${API_ENTITIES_BASE_URL}/projects/${projectId}/curated_variant_lists`
  );
}

export async function getProjectCuratedListDetails(
  projectId,
  curatedVariantListId,
  originProjectId
) {
  const result: FetchDataResponse<unknown> = await fetchData(
    `${API_ENTITIES_BASE_URL}/projects/${projectId}/curated_variant_lists/${curatedVariantListId}?origin_project_id=${originProjectId}`
  );

  if (result.ok) {
    return camelizeResponse(result, "curated_data_schema");
  }
  return result;
}

const buildCVLAudit = (list: APIList): Array<CVLAuditAction> =>
  list.map(({ attributes: action }) => {
    const { relatedData } = action;
    if (relatedData && relatedData.patientId) {
      return set(action, "relatedData.patient", {
        id: relatedData.patientId,
        reference: relatedData.patientReference,
      });
    }
    return action;
  });

export const getProjectCuratedListAudit = async (
  projectId: string,
  curatedVariantListId: string,
  originProjectId: string,
  requestParams: CVLAuditRequestParams
): Promise<PaginatedTableResponse<CVLAuditAction>> => {
  const { sort, desc, pageNumber, pageSize, filter, dateFrom, dateTo } =
    requestParams;
  const queryParams = {
    pageNumber,
    pageSize,
    filter,
    sort: !isNil(sort) ? decamelize(sort) : undefined,
    desc,
    origin_project_id: originProjectId,
    date_from: dateFrom,
    date_to: dateTo,
  };

  const result: FetchDataResponse<any> = await fetchData(
    `${API_ENTITIES_BASE_URL}/projects/${projectId}/curated_variant_lists/${curatedVariantListId}/audit?${buildTableQueryParams(
      queryParams
    )}`
  );

  const response = result.ok
    ? camelizeResponse(result, "delta_for_ui.curated_data_values")
    : result;

  return formatPaginatedResponse(response, buildCVLAudit);
};

export const buildCVLVariantUrl = (
  projectId,
  curatedVariantListId,
  requestParams = {}
) =>
  `${API_ENTITIES_BASE_URL}/projects/${projectId}/curated_variant_lists/${curatedVariantListId}/variants?${buildTableQueryParams(
    requestParams
  )}`;

const buildCVLVariants = (list: APIList): Array<CuratedListVariant> =>
  list.map(
    ({
      id: curatedVariantId,
      attributes: {
        curatedVariantListId,
        name,
        pathogenicity,
        curatedData,
        variant,
        gene,
        updated,
        updater,
      },
    }) => ({
      curatedVariantId,
      curatedVariantListId,
      name,
      pathogenicity,
      curatedData,
      variant: flattenApiEntity(variant),
      gene: flattenApiEntity(gene),
      updated,
      lastCurator: flattenApiEntity(updater),
    })
  );

export const getProjectCuratedListVariants = async (
  projectId,
  curatedVariantListId,
  requestParams
): Promise<PaginatedTableResponse<CuratedListVariant>> => {
  const result: FetchDataResponse<any> = await fetchData(
    buildCVLVariantUrl(projectId, curatedVariantListId, requestParams)
  );

  const response = result.ok
    ? camelizeResponse(result, "curated_data")
    : result;

  return formatPaginatedResponse(response, buildCVLVariants);
};

export const getProjectCuratedListVariantsExport = (
  curatedVariantListId,
  ensemblVersion,
  includeErroneous
) => {
  const queryParams = [
    !isNil(includeErroneous)
      ? `include_erroneous=${includeErroneous ? 1 : 0}`
      : null,
    ensemblVersion ? `ensembl_version=${ensemblVersion}` : null,
  ]
    .filter(Boolean)
    .join("&");

  const queryParamsString = queryParams ? `?${queryParams}` : "";

  return fetchData(
    `${API_ENTITIES_BASE_URL}/curated_variant_lists/snv/${curatedVariantListId}/csv${queryParamsString}`,
    undefined,
    true
  );
};

export function getProjectUnrelatedCuratedLists(projectId) {
  return fetchCamelizeData(
    `${API_ENTITIES_BASE_URL}/projects/${projectId}/unrelated_curated_variant_lists`
  );
}

export function getExistingListsAvailability(projectId) {
  return fetchData(
    `${API_ENTITIES_BASE_URL}/projects/${projectId}/unrelated_curated_variant_lists/exist`
  );
}

export function removeCVL(curatedVariantListId) {
  return fetchCamelizeData(
    `${API_ENTITIES_BASE_URL}/curated_variant_lists/${curatedVariantListId}`,
    {
      method: "DELETE",
    }
  );
}

export function removeCVLFromProject(projectId, curatedVariantListId) {
  return fetchCamelizeData(
    `${API_ENTITIES_BASE_URL}/projects/${projectId}/relationships/curated_variant_list/${curatedVariantListId}`,
    {
      method: "DELETE",
    }
  );
}
