import classNames from "classnames";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import { Loading } from "pattern-library";
import { SinglePagePDFViewer } from "pattern-library/modules/pdf/SinglePagePDFViewer";

import { error } from "modules/messages/actions";
import { useStateSafe } from "modules/utils";

import congenicaApi from "../../api/congenica-api";

import { REVIEW_COMPLETE } from "./constants";

import { OncologyPatientWorkflowStatus } from "types/oncologyPatientWorkflowStatus";

const { useGetLatestReportProposalQuery, useGetOncologyPatientDataQuery } =
  congenicaApi;

interface ReportParams {
  patientId: number;
}

// if the report PDF doesn't exist, and it should then the endpoint should attempt to recreate the report
export function getGeneratedReport({
  patientId,
}: ReportParams): Promise<Response> {
  return fetch(`/webapi/entities/patients/${patientId}/oncology/lab_report`);
}

export function getReportPreview({
  patientId,
}: ReportParams): Promise<Response> {
  return fetch(`/patient/${patientId}/report/preview`, {
    credentials: "include",
    method: "POST",
  });
}

interface Props {
  patientId: number;
  reportId: string | null;
  status: OncologyPatientWorkflowStatus["currentStatus"];
}

const PDFReport: FC<Props> = ({ patientId, reportId, status }) => {
  const dispatch = useDispatch();
  const [fetchReportLoading, setFetchReportLoading] = useStateSafe(false);
  const [pdf, setPdf] = useStateSafe(null);

  const { previewReportFileName, oncologyPatientDataLoading } =
    useGetOncologyPatientDataQuery(
      { patientId },
      {
        selectFromResult: ({ data, isLoading }) => {
          const prefix = data?.reference ? data.reference : patientId;

          return {
            oncologyPatientDataLoading: isLoading,
            previewReportFileName: `${prefix}-report-preview.pdf`,
          };
        },
      }
    );

  const { reportSummary, jurisdiction, reportServiceTemplateUuid } =
    useGetLatestReportProposalQuery(
      { patientId },
      {
        selectFromResult: ({
          data: { reportSummary, jurisdiction, reportServiceTemplateUuid } = {},
        }) => ({
          reportSummary,
          jurisdiction,
          reportServiceTemplateUuid,
        }),
      }
    );

  const { className, dataTestId, fetchReport } = useMemo(() => {
    if (status === REVIEW_COMPLETE) {
      return {
        fetchReport: getGeneratedReport,
        dataTestId: "oncology-report",
        className: "oncology-report__report__document",
      };
    }
    return {
      fetchReport: getReportPreview,
      dataTestId: "oncology-report-preview",
      className: "oncology-report__report__preview__document",
    };
  }, [status]);

  const onError = useCallback(
    msg => {
      setPdf(null);
      dispatch(error(msg));
    },
    [setPdf, dispatch]
  );

  const reload = useCallback(async () => {
    if (!patientId) {
      return;
    }

    try {
      setFetchReportLoading(true);
      const result = await fetchReport({
        patientId,
      });
      if (result.ok) {
        const blob = await result.blob();
        const reader = new FileReader();

        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          const { result } = reader;
          if (
            typeof result === "string" &&
            result.includes("data:application/pdf")
          ) {
            setPdf(result);
          } else {
            onError("Wrong report pdf content");
          }
        };
        reader.onerror = function () {
          onError(`Failed to read pdf document!\n${reader.error}`);
        };
      } else {
        onError(
          `Error loading report\nStatus: ${result.status}${
            result.statusText ? ` Details: ` + result.statusText : ""
          }`
        );
      }
    } catch ({ message }) {
      onError(message);
    } finally {
      setFetchReportLoading(false);
    }
  }, [patientId, setFetchReportLoading, fetchReport, setPdf, onError]);

  // We use AbortController for aborting fetch responses if change tab selection while pdf document stil is loading
  useEffect(() => {
    const abortController = new AbortController();
    reload();
    return () => {
      abortController.abort();
    };
  }, [
    patientId,
    reportSummary,
    jurisdiction,
    reportServiceTemplateUuid,
    status,
    reload,
  ]);

  const loading = useMemo(
    () => fetchReportLoading || oncologyPatientDataLoading,
    [fetchReportLoading, oncologyPatientDataLoading]
  );

  return (
    <>
      <span data-testid={dataTestId} />
      {loading ? (
        <Loading />
      ) : (
        pdf && (
          <SinglePagePDFViewer
            className={classNames(className)}
            fileName={
              status === REVIEW_COMPLETE
                ? `${reportServiceTemplateUuid}.pdf`
                : previewReportFileName
            }
            file={pdf}
          />
        )
      )}
    </>
  );
};

export default PDFReport;
