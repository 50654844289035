import { call, put, select, takeLatest } from "redux-saga/effects";

import {
  COMMON_ERROR_MESSAGE,
  FAILURE_STATUS,
  IN_PROGRESS_STATUS,
  SUCCESS_STATUS,
} from "common/constants";
import { postInterpretationRequest } from "modules/api";
import { getBaitsets } from "modules/api/projects";
import { error } from "modules/messages/actions";
import {
  INIT_IR,
  SUBMIT_IR,
} from "modules/oncologyInterpretationRequests/constants";
import {
  InitIRAction,
  resetIRSubmissionStatus,
  setBaitsets,
  setIRSubmissionStatus,
  setProjectRequestStatus,
  SubmitIRAction,
} from "modules/oncologyInterpretationRequests/redux/actions";
import { isIRSubmissionInProgress } from "modules/oncologyInterpretationRequests/redux/selectors";
import { IrFormValues } from "modules/oncologyInterpretationRequests/types";
import { mapIrRequest } from "modules/oncologyInterpretationRequests/utils";
import {
  READ_PROJECT,
  READ_PROJECT_FAILURE,
  READ_PROJECT_SUCCESS,
} from "modules/project/constants";
import { getOrReloadCurrentProject } from "modules/project/saga";

export function* init() {
  yield takeLatest(SUBMIT_IR, submitInterpretationRequest);
  yield takeLatest(INIT_IR, initIR);

  yield takeLatest(READ_PROJECT, projectReadStarted);
  yield takeLatest(READ_PROJECT_FAILURE, projectReadFailed);
  yield takeLatest(READ_PROJECT_SUCCESS, projectInitSuccess);
}

export function* initIR({ payload: { projectId } }: InitIRAction) {
  yield call(getOrReloadCurrentProject, projectId);

  yield put(resetIRSubmissionStatus());

  yield call(fetchBaitsets, projectId);
}

export function* fetchBaitsets(projectId) {
  try {
    const response = yield call(getBaitsets, projectId);
    if (response.ok) {
      yield put(setBaitsets(response.payload.baitsets));
    } else {
      yield call(loadingBaitsetsFailed, response.statusText);
    }
  } catch (e) {
    yield call(loadingBaitsetsFailed, e);
  }
}

export function* setIRSubmissionFailed(errorMessage) {
  yield put(error(errorMessage));
  yield put(setIRSubmissionStatus(FAILURE_STATUS));
}

export function* submitFullIRWorkflow(
  payload: IrFormValues & ProjectDependant
) {
  yield put(setIRSubmissionStatus(IN_PROGRESS_STATUS));
  const interpretationRequest = mapIrRequest(payload);

  try {
    const response = yield call(
      postInterpretationRequest,
      interpretationRequest
    );
    const body = yield call([response, response.json]);
    if (response.ok) {
      yield put(setIRSubmissionStatus(SUCCESS_STATUS));
    } else {
      yield call(setIRSubmissionFailed, body.error);
    }
  } catch (e) {
    yield call(setIRSubmissionFailed, "Unknown error");
  }
}

export function* submitInterpretationRequest({ payload }: SubmitIRAction) {
  const isSubmissionInProgress = yield select(isIRSubmissionInProgress);
  if (isSubmissionInProgress) {
    return;
  }

  yield call(submitFullIRWorkflow, payload);
}

function* loadingIRDataFailed() {
  yield put(error(COMMON_ERROR_MESSAGE));
}

export function* loadingBaitsetsFailed(message) {
  const m = message || "Unknown error";
  yield put(error(`Baitsets loading failed:\n${m}`));
  yield put(setBaitsets([]));
}

function* projectReadStarted() {
  yield put(setProjectRequestStatus(IN_PROGRESS_STATUS));
}

function* projectInitSuccess() {
  yield put(setProjectRequestStatus(SUCCESS_STATUS));
}

function* projectReadFailed() {
  yield put(setProjectRequestStatus(FAILURE_STATUS));
  yield loadingIRDataFailed();
}
