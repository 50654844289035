import React, { useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";

import { ConfirmationModal } from "pattern-library";

import { hideModal } from "modules/modalRegistry/actions";
import { modalTypes } from "modules/modalRegistry/constants";

import legacyApi from "api/legacy-api";

const { useArchiveGenePanelMutation } = legacyApi;

// NOTE: calling from modal registry need to pass this data:
//    showModal(modalTypes.ARCHIVE_GENE_PANEL_MODAL, {
//      projectId: 123,
//      genePanelId: 123,
//    });

interface Props extends PropsFromRedux {
  projectId: number;
  genePanelId: number;
}

export const ArchiveGenePanelModal = ({
  projectId,
  genePanelId,
  hideModal,
}: Props) => {
  const [archiveGenePanel] = useArchiveGenePanelMutation();

  const onCloseHandler = useCallback(() => {
    hideModal(modalTypes.ARCHIVE_GENE_PANEL_MODAL);
  }, [hideModal]);

  const onConfirm = useCallback(() => {
    archiveGenePanel({ projectId, genePanelId });
    onCloseHandler();
  }, [projectId, genePanelId, archiveGenePanel, onCloseHandler]);

  return (
    <ConfirmationModal
      confirmationText="Are you sure you want to archive this gene panel?"
      showConfirmationModal
      isDeleteModal
      closeText="Cancel"
      confirmText="Archive gene panel"
      yesClickHandler={onConfirm}
      closeClickHandler={onCloseHandler}
      className="archive-gene-panel-modal"
    />
  );
};

const mapDispatchToProps = {
  hideModal,
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ArchiveGenePanelModal);
