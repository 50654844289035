import { FC, memo } from "react";

import { ButtonGroup } from "pattern-library";

import { REVIEW_COMPLETE } from "../constants";

import Action from "./Action";
import PatientDataDownload from "./PatientDataDownload";
import PatientGeneratedReportDownload from "./PatientGeneratedReportDownload";
import Status from "./Status";

import congenicaApi from "api/congenica-api";

const {
  useGetOncologyPatientWorkflowStatusQuery,
  useSetOncologyPatientWorkflowStatusMutation,
} = congenicaApi;

interface Props {
  actionsToSkip: string[];
  patientId: number;
  reportServiceTemplateUuid: string | undefined;
}

const Actions: FC<Props> = ({
  patientId,
  actionsToSkip,
  reportServiceTemplateUuid,
}) => {
  const {
    data: oncologyPatientWorkflowStatus,
    isLoading: oncologyPatientWorkflowStatusLoading,
  } = useGetOncologyPatientWorkflowStatusQuery({
    patientId,
  });

  const [
    setOncologyPatientWorkflowStatus,
    { isLoading: setOncologyPatientWorkflowStatusLoading },
  ] = useSetOncologyPatientWorkflowStatusMutation();

  const loading =
    oncologyPatientWorkflowStatusLoading ||
    setOncologyPatientWorkflowStatusLoading;

  const handleClickAction = (actionName: string) => {
    setOncologyPatientWorkflowStatus({ patientId, actionName });
  };

  return (
    <div className="row oncology-report__actions">
      <ButtonGroup className="col-md-12" size="sm" vertical={false}>
        <Status key="status" patientId={patientId} />
        <ButtonGroup size="sm">
          <PatientDataDownload loading={loading} patientId={patientId} />
        </ButtonGroup>
        {oncologyPatientWorkflowStatus?.currentStatus === REVIEW_COMPLETE && (
          <PatientGeneratedReportDownload
            patientId={patientId}
            reportServiceTemplateUuid={reportServiceTemplateUuid}
          />
        )}
        <>
          {oncologyPatientWorkflowStatus?.availableActions.map(
            ({ name, userCanPerform }) => {
              if (actionsToSkip.indexOf(name) !== -1) {
                return null;
              }

              return (
                <Action
                  key={name}
                  actionName={name}
                  disabled={loading || !userCanPerform}
                  onClick={handleClickAction}
                />
              );
            }
          )}
        </>
      </ButtonGroup>
    </div>
  );
};

export default memo<FC<Props>>(Actions);
