import React from "react";

import {
  Button,
  Modal,
  MODAL_SIZES,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "pattern-library";

interface Props {
  show: boolean;
  bodyText: string;
  close: () => void;
  onYesButtonClick: () => void;
}

export const ConfirmationModal = ({
  show,
  bodyText,
  close,
  onYesButtonClick,
}: Props) => (
  <Modal show={show} close={close} size={MODAL_SIZES.STANDARD}>
    <ModalHeader close={close}>
      <h3>Confirmation</h3>
    </ModalHeader>
    <ModalBody>{bodyText}</ModalBody>
    <ModalFooter>
      <Button type="button" className="button" onClick={close}>
        No
      </Button>
      <Button type="button" context="primary" onClick={onYesButtonClick}>
        Yes
      </Button>
    </ModalFooter>
  </Modal>
);
