import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Actions, LoadingOverlay } from "pattern-library";

import {
  fetchExistingUserDetails,
  fetchExistingUsers,
  setUserInfo,
} from "../actions";
import * as selectors from "../selectors";
import { getExistingUsers, isExistingUserLoading } from "../selectors";

import { UsersTable } from "./UsersTable";

type Props = { projectId: number } & PropsFromRedux;

export const UsersLookupView: React.FC<Props> = ({
  isLoading,
  data = [],
  init,
  fetchExistingUserDetails,
  projectId,
  existingUserLoading,
}) => {
  useEffect(() => {
    init(projectId);
  }, [init, projectId]);

  const columns = [
    {
      Header: "Name",
      accessor: "fullName",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Actions",
      id: "actions",
      accessor: ({ userId }) => {
        const actions = [
          {
            label: "Add user",
            className: "btn-primary  btn-small",
            action: () => fetchExistingUserDetails(projectId, userId),
            type: "button",
          },
        ];
        return <Actions sticky justifyContent="start" actions={actions} />;
      },
    },
  ];

  return (
    <LoadingOverlay loading={existingUserLoading}>
      <UsersTable
        columns={columns}
        items={data}
        isLoading={Boolean(isLoading)}
        title="Add existing user"
      />
    </LoadingOverlay>
  );
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectors.getExistingUsersLoading,
  data: getExistingUsers,
  existingUserLoading: isExistingUserLoading,
});

const mapDispatchToProps = {
  init: fetchExistingUsers.start,
  setUserInfo,
  fetchExistingUserDetails: fetchExistingUserDetails.start,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UsersLookupView);
