import React, { useMemo } from "react";

import { BUFFER_ZOOM_SNV } from "modules/genoverse";
import GenoverseBrowser from "modules/genoverse/GenoverseBrowser/GenoverseBrowser";
import { GenoverseConfig } from "modules/genoverse/GenoverseBrowser/GenoverseBrowser.types";

import { OncologyGene } from "./OncologyGene";
import { OncologyPatient } from "./SNV/OncologyPatient";

type Props = {
  patientId: number;
  pos: number;
  chrom: string;
};

const start = pos => {
  const start = pos - BUFFER_ZOOM_SNV;
  return start > 0 ? start : 1;
};
const end = pos => Math.max(pos + BUFFER_ZOOM_SNV, BUFFER_ZOOM_SNV * 2);

export const OncologySNVBrowser: React.FC<Props> = ({
  patientId,
  pos,
  chrom,
}) => {
  const config = useMemo<GenoverseConfig>(
    () => ({
      genome: "grch38",
      chr: chrom.replaceAll("chr", ""),
      patientId,
      start: start(pos),
      end: end(pos),
      tracks: ["oncologyPatientSNV", "oncologyGene"],
      saveKey: "congenicaGenoverse-oncology",
      saveable: true,
      storageType: "localStorage",
    }),
    [patientId, chrom, pos]
  );

  const tracksLibrary = useMemo(
    () => [OncologyPatient(config), OncologyGene(config)],
    [config]
  );

  return (
    <GenoverseBrowser
      className="congenica-genoverse-browser"
      config={{
        ...config,
        tracksLibrary,
      }}
    />
  );
};
