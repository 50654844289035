import _ from "lodash";
import React, { memo, useState } from "react";

import { getOncoTree } from "modules/api/interpretationRequest";
import FormikFormField from "modules/forms/components/formikFormField/FormikFormField";

const DEBOUNCE_DELAY = 400;

interface Option {
  value: string;
  label: string;
}

export const TumourTypeField = memo(props => {
  const [options, setOptions] = useState<Option[]>([]);

  const loadOncoTree = _.debounce((query, callback) => {
    getOncoTree(query).then((resp: Option[]) => {
      callback(resp);
    });
  }, DEBOUNCE_DELAY);

  const handleChange = (params: Option) => {
    setOptions(params ? [params] : []);
  };

  return (
    <FormikFormField
      type="select"
      async
      isClearable
      placeholder="Search Tumour Type..."
      loadOptions={loadOncoTree}
      onChange={handleChange}
      options={options}
      cacheOptions={false}
      isMulti={false}
      {...props}
    />
  );
});
