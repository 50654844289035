import { react as autoBind } from "auto-bind";
import classNames from "classnames";
import PropTypes from "prop-types";
import { isNil } from "ramda";
import React, { PureComponent } from "react";

import { Icon } from "../elements/base";
import { Button, ButtonDropdown } from "../elements/form";

import Tooltip from "./Tooltip";

export default class Actions extends PureComponent {
  static displayName = "Actions";

  static propTypes = {
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * Class to apply to the component
         */
        className: PropTypes.string,
        /**
         *  The type of icon to render
         */
        icon: PropTypes.string,
        /**
         * The label to render
         */
        label: PropTypes.string,
        /**
         * Handler of the button click
         */
        action: PropTypes.func,
        /**
         * Disabled flag
         */
        isDisabled: PropTypes.bool,
        /**
         * Array rendered as list elements for action
         */
        dropdownMenu: PropTypes.arrayOf(PropTypes.object),

        /**
         * Where to justify actions to (start or end)
         */
        justifyContent: PropTypes.oneOf(["start", "end"]),

        /**
         * Whether to group the buttons next to each other (no gap between each action)
         */
        group: PropTypes.bool,
      })
    ),
  };

  static defaultProps = {
    actions: [],
    title: "",
    className: "",
    tooltipProps: {
      placement: "left",
    },
    sticky: false,
    justifyContent: "end",
    group: false,
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    const { actions, sticky, justifyContent, group, className } = this.props;

    if (!actions?.length) {
      return null;
    }

    return (
      <div
        className={classNames(
          className,
          "actions",
          `actions--${justifyContent}`,
          {
            "btn-group": group,
            "actions--sticky": sticky,
          }
        )}
      >
        {actions.map(
          (
            {
              icon,
              label = "",
              className,
              action,
              tooltip,
              title,
              isDisabled,
              dropdownMenu,
              ...otherProps
            },
            index
          ) => {
            const key = label.length ? `${icon}-${label}` : `${icon}-${index}`;
            const button = isNil(dropdownMenu) ? (
              <Button
                key={key}
                disabled={isDisabled}
                onClick={action}
                className={classNames("btn actions-btn", className)}
                {...otherProps}
              >
                {!!icon && <Icon type={icon} />}
                {!!label && <span className="actions-label">{label}</span>}
              </Button>
            ) : (
              <ButtonDropdown
                key={key}
                disabled={isDisabled}
                className={classNames("btn actions-btn", className)}
                list={dropdownMenu}
                btnContent={
                  <>
                    {!!icon && <Icon type={icon} />}
                    {!!label && <span className="actions-label">{label}</span>}
                  </>
                }
              />
            );

            if (tooltip) {
              return (
                // Note that tooltip will not be shown if element is disabled. To make it work would require wrapping
                // disabled button into a span but then styles do not get applied properly which is a can of worms to fix.
                // It would require to fish out all the places where this component is used and testing changes
                <Tooltip key={key} {...tooltip}>
                  {button}
                </Tooltip>
              );
            }
            return button;
          }
        )}
      </div>
    );
  }
}
