import GenoverseTracks from "modules/genoverse/GenoverseTracks";

import { flattenApiEntity } from "api/utils/common";

const { Gene } = GenoverseTracks;

export const populateOncologyGeneMenu = (feature, extras) => {
  const menu = {};
  const entries: Array<Array<string | number>> = [
    [
      "title",
      `<a target="_blank" href="https://www.genenames.org/data/gene-symbol-report/#!/symbol/${feature.name}">${feature.name}</a>`,
    ],
    ["Location", `${feature.chr}:${feature.start}-${feature.end}`],
  ];

  for (const [title, value] of entries) {
    if (value) {
      menu[title] = value;
    }
  }

  return { ...menu, ...extras };
};

export const OncologyGene = config =>
  Gene(config).extend({
    trackKey: "oncologyGene",
    configName: ["Genes"],
    url: `/webapi/entities/patients/${config.patientId}/oncology/genoverse/genes?filter[chr]=__CHR__&filter[start]=__START__&filter[end]=__END__`,
    populateMenu(feature, extras) {
      return populateOncologyGeneMenu(feature, extras);
    },
    parseData(data, start, end) {
      this.base(data.data.map(flattenApiEntity), start, end);
    },
  });
