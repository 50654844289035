import { fetchCamelizeData, getFormData } from "modules/utils/fetchData";

export const exportGenePanel = (
  projectId,
  genePanelId,
  { panelContent, panelFormat }
) => {
  const body = getFormData({
    "panel-content": panelContent,
    "panel-format": panelFormat,
  });
  return fetchCamelizeData(
    `/project/${projectId}/gene-panel/${genePanelId}/export`,
    { method: "POST", body },
    undefined,
    true
  );
};
