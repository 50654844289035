import React, { memo } from "react";

import { Tag } from "pattern-library";

interface GenePanelTitleProps {
  title: string;
  version?: string | null;
}
const GenePanelTitle = ({ title, version }: GenePanelTitleProps) => (
  <>
    {title}
    {version && <Tag className="version-label">version {version}</Tag>}
  </>
);

export default memo(GenePanelTitle);
