import classNames from "classnames";
import React, { useEffect, useState, memo, useMemo, useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";

import { Actions, Button, Icon } from "pattern-library";
import ConfirmationModal from "pattern-library/elements/modals/ConfirmationModal";
import { DEFAULT_PAGE_SIZE, Table } from "pattern-library/elements/table7";

import { toBoolean } from "modules/interpretationRequests/components/csvUtils";
import { showModal } from "modules/modalRegistry/actions";
import { modalTypes } from "modules/modalRegistry/constants";
import { filterProcessor } from "modules/utils/table";

import legacyApi from "api/legacy-api";

const {
  useGetTempGenePanelQuery,
  useGetTempGenePanelFoundGenesQuery,
  useRemoveGeneFromTempGenePanelMutation,
  useSaveTempGenePanelMutation,
} = legacyApi;

const getPropertyMap = () => ({
  autosomalRecessive: value => (value ? "Yes" : "No"),
  autosomalDominant: value => (value ? "Yes" : "No"),
  xLinked: value => (value ? "Yes" : "No"),
});

const geneProperties = [
  "name",
  "uploadedGeneName",
  "oldNames",
  "synonyms",
  "transcriptName",
  "autosomalRecessive",
  "autosomalDominant",
  "xLinked",
  "condition",
];

const geneFilter = filterProcessor(geneProperties, getPropertyMap);

const Tick = ({ show = false }) =>
  toBoolean(show) ? <Icon type="okSign" className="icon-success" /> : null;

interface Props extends PropsFromRedux {
  projectId: number;
  onClose: () => void;
  tempGenePanelId: number;
}

const GenesFoundTable = memo(
  ({ projectId, onClose, tempGenePanelId, showModal }: Props) => {
    const [filterValue, setFilterValue] = useState("");
    const [deleteGeneName, setDeleteGeneName] = useState("");
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [filteredGenes, setFilteredGenes] = useState([]);

    const { data: tempGenePanel, isLoading: isTempGenePanelLoading } =
      useGetTempGenePanelQuery({
        projectId,
        tempGenePanelId,
      });

    const { data: genes, isLoading: areFoundGenesLoading } =
      useGetTempGenePanelFoundGenesQuery({
        projectId,
        tempGenePanelId,
      });

    const [removeGene] = useRemoveGeneFromTempGenePanelMutation();
    const [saveTempGenePanel, { isSuccess, isError }] =
      useSaveTempGenePanelMutation();

    useEffect(() => {
      setFilteredGenes(geneFilter(genes, filterValue));
    }, [genes, filterValue, setFilteredGenes]);

    useEffect(() => {
      if (isSuccess && !isError) {
        onClose();
      }
    }, [isSuccess, isError, onClose]);

    const onFilterChange = useCallback(
      ({ filter }) => {
        setFilterValue(filter);
      },
      [setFilterValue]
    );

    const showGeneInfo = useCallback(
      geneName => {
        showModal(modalTypes.GENE_INFO_MODAL, {
          geneName,
          ensemblVersion: tempGenePanel?.ensemblVersion,
        });
      },
      [showModal, tempGenePanel]
    );

    const showEditGeneForm = useCallback(
      (projectId, tempGenePanelId, geneName) => () => {
        showModal(modalTypes.EDIT_TEMPORARY_GENE_MODAL, {
          geneName,
          projectId,
          tempGenePanelId,
        });
      },
      [showModal]
    );

    const getActions = useCallback(
      geneName => [
        {
          icon: "pencil",
          className: "btn-default btn-xs",
          action: showEditGeneForm(projectId, tempGenePanelId, geneName),
          tooltip: {
            content: "Edit gene",
          },
        },
        {
          icon: "trash",
          className: "btn-default btn-xs",
          action: () => {
            setDeleteGeneName(geneName);
            setShowDeleteModal(true);
          },
          tooltip: {
            content: "Delete gene",
          },
        },
      ],
      [
        setDeleteGeneName,
        setShowDeleteModal,
        showEditGeneForm,
        projectId,
        tempGenePanelId,
      ]
    );

    const columns = useMemo(
      () => [
        {
          Header: "Name",
          accessor: "name",
          id: "name",
          Cell: ({ row: { original } }) => {
            const { name, uploadedGeneName } = original;
            return (
              <>
                {name !== uploadedGeneName ? (
                  <>
                    <Icon type="exclamationSign" className="icon-error" />{" "}
                    <a onClick={() => showGeneInfo(name)}>{name}</a>
                    <em>({uploadedGeneName})</em>
                  </>
                ) : (
                  <>
                    <Tick show />{" "}
                    <a onClick={() => showGeneInfo(name)}>{name}</a>
                  </>
                )}
              </>
            );
          },
        },
        {
          Header: "Old Name",
          accessor: "oldName",
          Cell: ({ row: { original } }) => {
            const { oldName: oldNames, uploadedGeneName } = original;
            return (
              <>
                {oldNames &&
                  oldNames.split(",").map((oldName, index, array) => {
                    const oldNameTrimmed = oldName.trim();
                    return (
                      <React.Fragment key={oldNameTrimmed}>
                        <Tick show={uploadedGeneName === oldNameTrimmed} />
                        {oldNameTrimmed}
                        {index + 1 < array.length && ", "}
                      </React.Fragment>
                    );
                  })}
              </>
            );
          },
        },
        {
          Header: "Synonyms",
          accessor: "synonyms",
          Cell: ({ row: { original } }) => {
            const { synonyms, uploadedGeneName } = original;
            return (
              <>
                {synonyms &&
                  synonyms.split(",").map((synonym, index, array) => {
                    const synonymTrimmed = synonym.trim();
                    return (
                      <React.Fragment key={synonymTrimmed}>
                        <Tick show={uploadedGeneName === synonymTrimmed} />
                        {synonymTrimmed}
                        {index + 1 < array.length && ", "}
                      </React.Fragment>
                    );
                  })}
              </>
            );
          },
        },
        {
          Header: "Transcript",
          accessor: "transcriptId",
          Cell: ({
            row: {
              original: { transcriptName, transcriptUrl },
            },
          }) => (
            <>
              {transcriptName ? (
                <>
                  <Tick show />{" "}
                  <a
                    href={transcriptUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {transcriptName}
                  </a>
                </>
              ) : null}
            </>
          ),
        },
        {
          Header: "Autosomal Recessive",
          accessor: "autosomalRecessive",
          Cell: ({ row: { original } }) => {
            const { autosomalRecessive } = original;
            return (
              <div style={{ textAlign: "center" }}>
                <Tick show={autosomalRecessive} />
              </div>
            );
          },
        },
        {
          Header: "Autosomal Dominant",
          accessor: "autosomalDominant",
          Cell: ({ row: { original } }) => {
            const { autosomalDominant } = original;
            return (
              <div style={{ textAlign: "center" }}>
                <Tick show={autosomalDominant} />
              </div>
            );
          },
        },
        {
          Header: "X Linked",
          accessor: "xLinked",
          Cell: ({ row: { original } }) => {
            const { xLinked } = original;
            return (
              <div style={{ textAlign: "center" }}>
                <Tick show={xLinked} />
              </div>
            );
          },
        },
        {
          Header: "Condition",
          accessor: "condition",
        },
        {
          Header: "Actions",
          id: "actions",
          accessor: ({ name }) => <Actions actions={getActions(name)} />,
        },
      ],
      [getActions, showGeneInfo]
    );

    return (
      <div>
        <ConfirmationModal
          confirmationText="Are you sure you want to remove this gene?"
          showConfirmationModal={showDeleteModal && !!deleteGeneName}
          isDeleteModal
          closeText="Cancel"
          confirmText="Remove gene"
          yesClickHandler={() => {
            removeGene({
              projectId,
              tempGenePanelId,
              geneName: deleteGeneName,
            });
            setDeleteGeneName("");
            setShowDeleteModal(false);
          }}
          closeClickHandler={() => setShowDeleteModal(false)}
          className="remove-gene-modal"
        />
        <Table
          showTitleInfo
          title="Genes to be uploaded"
          fetchData={onFilterChange}
          enableFilter
          className={classNames({
            "project-temp-gene-panel-genes-table": filteredGenes.length === 0,
          })}
          columns={columns}
          data={filteredGenes}
          showPagination={filteredGenes.length > DEFAULT_PAGE_SIZE}
          autoResetPage={false}
          loading={isTempGenePanelLoading || areFoundGenesLoading}
          sortBy={[
            {
              id: "name",
              desc: false,
            },
          ]}
        />
        <Button
          context="primary"
          onClick={() => saveTempGenePanel({ projectId, tempGenePanelId })}
        >
          Save as new panel
        </Button>
      </div>
    );
  }
);

const mapDispatchToProps = {
  showModal,
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(GenesFoundTable);
