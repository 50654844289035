import classNames from "classnames";
import React, { FC } from "react";

import styles from "./ReclassificationLegend.module.scss";

const ReclassificationLegend: FC = () => (
  <div className={styles.legend}>
    <div className={styles["legend-item"]}>
      <span
        className={classNames(
          styles["legend-color-block"],
          styles["reclassified-as-driver"]
        )}
      />
      <span> - Reclassified as driver</span>
    </div>

    <div className={styles["legend-item"]}>
      <span
        className={classNames(
          styles["legend-color-block"],
          styles["reclassified-as-passenger"]
        )}
      />
      <span> - Reclassified as passenger</span>
    </div>
  </div>
);

export default ReclassificationLegend;
