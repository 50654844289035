import React, { memo, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";

import { Breadcrumbs, Loading } from "pattern-library";

import IRFormWrapper from "modules/oncologyInterpretationRequests/components/IRFormWrapper";
import { initIR } from "modules/oncologyInterpretationRequests/redux/actions";
import {
  isProjectLoadCompleted,
  isProjectLoading,
} from "modules/oncologyInterpretationRequests/redux/selectors";
import { getSingleProject } from "modules/project/selectors";

interface UploadBreadcrumbsProps {
  project: {
    code: string;
    projectId: string | number;
  };
}

const UploadBreadcrumbs: React.FC<UploadBreadcrumbsProps> = ({ project }) => {
  const { code, projectId } = project;

  if (!code) {
    return null;
  }

  return (
    <Breadcrumbs
      crumbs={[
        {
          url: "/projects",
          name: "Projects",
        },
        {
          url: `/projects/${projectId}/patients`,
          name: code,
        },
        {
          name: "IR Submission",
        },
      ]}
    />
  );
};

interface IRContainerProps extends PropsFromRedux {
  projectId: number;
}

const OncologyIRContainer = memo(
  ({
    projectId,
    project,
    initIR,
    isProjectLoading,
    isProjectLoadCompleted,
  }: IRContainerProps) => {
    useEffect(() => {
      if (projectId) {
        initIR(projectId);
      }
    }, [projectId, initIR]);

    return (
      <div>
        {project && <UploadBreadcrumbs project={project} />}
        {isProjectLoading && <Loading />}
        {!isProjectLoading && isProjectLoadCompleted && (
          <div className="container ir-container">
            <h3>Interpretation request</h3>
            <div className="mt-3">
              <IRFormWrapper projectId={projectId} />
            </div>
          </div>
        )}
      </div>
    );
  }
);

const mapStateToProps = (state, { projectId }) => ({
  project: getSingleProject(state, projectId),
  isProjectLoading: isProjectLoading(state),
  isProjectLoadCompleted: isProjectLoadCompleted(state),
});

const mapDispatchToProps = {
  initIR,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(OncologyIRContainer);
