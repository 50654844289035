import PropTypes from "prop-types";
import React, { memo, useMemo } from "react";

import { DataTable, Button, GenePanelTitle } from "pattern-library";

import { genePanelShape } from "./propTypes";

const GenePanelsTable = memo(
  ({ genePanels = [], addGenePanel, removeGenePanel, disabled = false }) => {
    const columns = useMemo(
      () => [
        {
          Header: "Gene Panel",
          accessor: "title",
          Cell: ({ original: { title, version } }) => (
            <GenePanelTitle title={title} version={version} />
          ),
        },
        {
          Header: "Description",
          accessor: "description",
        },
        {
          Header: "Total Genes",
          accessor: "genesCount",
        },
        {
          Header: "Selected",
          accessor: "isAdded",
          sortMethod: (prev, current) => (current ? 1 : -1),
          Cell: ({ original: { isAdded = false, genePanelId } }) => (
            <Button
              type="button"
              className={`btn ${isAdded ? "btn-danger" : "btn-primary"}`}
              onClick={
                isAdded
                  ? () => removeGenePanel(genePanelId)
                  : () => addGenePanel(genePanelId)
              }
              disabled={disabled}
            >
              {`${isAdded ? "Remove" : "Add"} Gene Panel`}
            </Button>
          ),
        },
      ],
      [removeGenePanel, addGenePanel, disabled]
    );

    return (
      <DataTable
        data={genePanels}
        columns={columns}
        defaultPageSize={10}
        minRows={0}
      />
    );
  }
);

GenePanelsTable.propTypes = {
  genePanels: PropTypes.arrayOf(genePanelShape).isRequired,
  addGenePanel: PropTypes.func.isRequired,
  removeGenePanel: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default GenePanelsTable;
