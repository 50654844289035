import { Track } from "../core/Ortholog";
import { Genomes } from "../utils";

export const humanReference = genome =>
  genome === Genomes.GRCH38 ? "hg38" : "hg19";
export const cowReference = genome =>
  genome === Genomes.GRCH38 ? "bosTau8" : "bosTau7";
export const greenMonkeyReference = genome =>
  genome === Genomes.GRCH38 ? "chlSab2" : "chlSab1";
export const paintedTurtleReference = genome =>
  genome === Genomes.GRCH38 ? "chrPic2" : "chrPic1";
export const zebraFishReference = genome =>
  genome === Genomes.GRCH38 ? "danRer10" : "danRer7";
export const catReference = genome =>
  genome === Genomes.GRCH38 ? "felCat8" : "felCat5";
export const baboonReference = genome =>
  genome === Genomes.GRCH38 ? "papAnu2" : "papHam1";
export const ratReference = genome =>
  genome === Genomes.GRCH38 ? "rn6" : "rn5";

export const Ortholog = ({ transcriptId, genome, ensemblVersion }) =>
  Track.extend({
    url: `/api/orthologs/region.json?transcript_id=${transcriptId}${
      ensemblVersion ? `&ensembl_version=${ensemblVersion}` : ""
    }`,
    10000: false,
    controls: [
      `<select data-control="species">
        <option value="ModelOrganisms">Model Organisms (9)</option>
        <option value="Apes">Apes (4)</option>
        <option value="Primates">Primates (12)</option>
        <option value="PlacentalMammals">Placental Mammals (58)</option>
        <option value="Mammals">Mammals (62)</option>
        <option value="Vertebrates">Vertebrates (100)</option>
      </select>`,
      `<select data-control="color">
        <option value="default">Shade by frequency matching Human</option>
        <option value="amino">Colour by Amino Acid</option>
      </select>`,
    ],
    configSettings: {
      species: {
        ModelOrganisms: "Model Organisms",
        Apes: "Apes",
        Primates: "Primates",
        PlacentalMammals: "Placental Mammals",
        Mammals: "Mammals",
        Vertebrates: "Vertebrates",
      },
      color: {
        default: "monochrome",
        amino: "color",
      },
    },
    humanReference: humanReference(genome),
    groups: {
      ModelOrganisms: [
        humanReference(genome),
        "rheMac3",
        "mm10",
        "canFam3",
        "loxAfr3",
        "galGal4",
        "xenTro7",
        zebraFishReference(genome),
        "petMar2",
      ],
      Apes: [humanReference(genome), "panTro4", "gorGor3", "ponAbe2"],
      Primates: [
        humanReference(genome),
        "panTro4",
        "gorGor3",
        "ponAbe2",
        "nomLeu3",
        "rheMac3",
        "macFas5",
        baboonReference(genome),
        greenMonkeyReference(genome),
        "calJac3",
        "saiBol1",
        "otoGar3",
      ],
      PlacentalMammals: [
        humanReference(genome),
        "panTro4",
        "gorGor3",
        "ponAbe2",
        "nomLeu3",
        "rheMac3",
        "macFas5",
        baboonReference(genome),
        greenMonkeyReference(genome),
        "calJac3",
        "saiBol1",
        "otoGar3",
        "tupChi1",
        "speTri2",
        "jacJac1",
        "micOch1",
        "criGri1",
        "mesAur1",
        "mm10",
        ratReference(genome),
        "hetGla2",
        "cavPor3",
        "chiLan1",
        "octDeg1",
        "oryCun2",
        "ochPri3",
        "susScr3",
        "vicPac2",
        "camFer1",
        "turTru2",
        "orcOrc1",
        "panHod1",
        cowReference(genome),
        "oviAri3",
        "capHir1",
        "equCab2",
        "cerSim1",
        catReference(genome),
        "canFam3",
        "musFur1",
        "ailMel1",
        "odoRosDiv1",
        "lepWed1",
        "pteAle1",
        "pteVam1",
        "myoDav1",
        "myoLuc2",
        "eptFus1",
        "eriEur2",
        "sorAra2",
        "conCri1",
        "loxAfr3",
        "eleEdw1",
        "triMan1",
        "chrAsi1",
        "echTel2",
        "oryAfe1",
        "dasNov3",
      ],
      Mammals: [
        humanReference(genome),
        "panTro4",
        "gorGor3",
        "ponAbe2",
        "nomLeu3",
        "rheMac3",
        "macFas5",
        baboonReference(genome),
        greenMonkeyReference(genome),
        "calJac3",
        "saiBol1",
        "otoGar3",
        "tupChi1",
        "speTri2",
        "jacJac1",
        "micOch1",
        "criGri1",
        "mesAur1",
        "mm10",
        ratReference(genome),
        "hetGla2",
        "cavPor3",
        "chiLan1",
        "octDeg1",
        "oryCun2",
        "ochPri3",
        "susScr3",
        "vicPac2",
        "camFer1",
        "turTru2",
        "orcOrc1",
        "panHod1",
        cowReference(genome),
        "oviAri3",
        "capHir1",
        "equCab2",
        "cerSim1",
        catReference(genome),
        "canFam3",
        "musFur1",
        "ailMel1",
        "odoRosDiv1",
        "lepWed1",
        "pteAle1",
        "pteVam1",
        "myoDav1",
        "myoLuc2",
        "eptFus1",
        "eriEur2",
        "sorAra2",
        "conCri1",
        "loxAfr3",
        "eleEdw1",
        "triMan1",
        "chrAsi1",
        "echTel2",
        "oryAfe1",
        "dasNov3",
        "monDom5",
        "sarHar1",
        "macEug2",
        "ornAna1",
      ],
      Vertebrates: [
        humanReference(genome),
        "panTro4",
        "gorGor3",
        "ponAbe2",
        "nomLeu3",
        "rheMac3",
        "macFas5",
        baboonReference(genome),
        greenMonkeyReference(genome),
        "calJac3",
        "saiBol1",
        "otoGar3",
        "tupChi1",
        "speTri2",
        "jacJac1",
        "micOch1",
        "criGri1",
        "mesAur1",
        "mm10",
        ratReference(genome),
        "hetGla2",
        "cavPor3",
        "chiLan1",
        "octDeg1",
        "oryCun2",
        "ochPri3",
        "susScr3",
        "vicPac2",
        "camFer1",
        "turTru2",
        "orcOrc1",
        "panHod1",
        cowReference(genome),
        "oviAri3",
        "capHir1",
        "equCab2",
        "cerSim1",
        catReference(genome),
        "canFam3",
        "musFur1",
        "ailMel1",
        "odoRosDiv1",
        "lepWed1",
        "pteAle1",
        "pteVam1",
        "myoDav1",
        "myoLuc2",
        "eptFus1",
        "eriEur2",
        "sorAra2",
        "conCri1",
        "loxAfr3",
        "eleEdw1",
        "triMan1",
        "chrAsi1",
        "echTel2",
        "oryAfe1",
        "dasNov3",
        "monDom5",
        "sarHar1",
        "macEug2",
        "ornAna1",
        "falChe1",
        "falPer1",
        "ficAlb2",
        "zonAlb1",
        "geoFor1",
        "taeGut2",
        "pseHum1",
        "melUnd1",
        "amaVit1",
        "araMac1",
        "colLiv1",
        "anaPla1",
        "galGal4",
        "melGal1",
        "allMis1",
        "cheMyd1",
        paintedTurtleReference(genome),
        "pelSin1",
        "apaSpi1",
        "anoCar2",
        "xenTro7",
        "latCha1",
        "tetNig2",
        "fr3",
        "takFla1",
        "oreNil2",
        "neoBri1",
        "hapBur1",
        "mayZeb1",
        "punNye1",
        "oryLat2",
        "xipMac1",
        "gasAcu1",
        "gadMor1",
        zebraFishReference(genome),
        "astMex1",
        "lepOcu1",
        "petMar2",
      ],
    },
  });
